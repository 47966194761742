import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {STYLES} from 'core/constants/uiConstants';
import {TooltipIcon, TooltipText} from '../uicore';
import {usePCTranslation} from 'core/hooks';

const TileButtons = ({
  getchangeCallback,
  getfocusCallback,
  lostfocusCallback,
  text,
  name,
  autoFocus,
  disableChoice,
  readOnly,
  options,
  stateData,
  refItem,
  propStyle,
  isDisabled = false,
}) => {
  const { t } = usePCTranslation();
  const { controlItem } = useSelector((state) => ({
    controlItem: state.setupItems.controlItems
      ? state.setupItems.controlItems[name]
      : null,
  }));

  let optionItems = {};
  if (controlItem && controlItem.useOptions) {
    optionItems = controlItem.optionItems;
  }
  const controlOnChange = (event) => {
    //setSelectedOption(event.target.value);
    event.persist();
    //the event.target.value is the after-change value here but...
    getchangeCallback(event, event.target.name, event.target.value);
    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnFocus = (event) => {
    getfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnLostFocus = (event) => {
    lostfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  let containerClass = `${STYLES.INPUTCONTAINER} ${propStyle.inputcontainer}`;
  if (controlItem && controlItem.displayDisabled) containerClass += " disabled";
  if (controlItem && controlItem.displayHidden)
    containerClass += " hiddenField";

  const pillLabel = () => {
    return (
      <label
        className={`${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`}
        id={name}
      >
        {t(`${name}-caption`)}
      </label>
    );
  };

  return (
    <div id={name} className={containerClass}>
      {!controlItem.showTooltip ? (
        pillLabel()
      ) : controlItem.showTooltipIcon ? (
        <Fragment>
          {pillLabel()}
          <div className={`${STYLES.TOOLTIP}`}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
        </Fragment>
      ) : (
        <div className={`${STYLES.TOOLTIP}`}>
          <TooltipText name={name} />
          {pillLabel()}
        </div>
      )}
      <div id={name} className={`tiles ${propStyle.tiles}`}>
        {Object.keys(optionItems).map((key) => (
          <div id={name} key={key} className={`${propStyle.tilecontainer}`}>
            <input
              className={`${propStyle.control} ${
                disableChoice === key ? "disablePill" : "cursorPointer"
              }`}
              type="radio"
              name={name}
              value={key}
              checked={stateData[name] === key}
              readOnly={(controlItem && controlItem.readOnly) || readOnly}
              onFocus={controlOnFocus}
              onBlur={controlOnLostFocus}
              onChange={controlOnChange}
              autoFocus={autoFocus}
              ref={refItem}
              disabled={
                disableChoice === key ||
                controlItem?.disabled ||
                isDisabled ||
                controlItem?.displayDisabled
              }
            />
            <label
              className={`${STYLES.BASIC} ${STYLES.CHILD} ${propStyle.child}`}
              id={name}
            >
              {t(optionItems[key])}
              {controlItem.isSecondaryText && (
                <span className={`secondarylabel`} id={name}>
                  {t(controlItem.secondaryText[key])}
                </span>
              )}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

TileButtons.defaultProps = {
  autoFocus: false,
  disableChoice: '',
  readOnly: false,
};

export {TileButtons};
