import React, {Fragment, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {aboutLogo, userLogo, threedotsLogo, signOffLogo, switchLogo, closeIcon} from 'assets';
import {ButtonBar, Modal, Logo} from 'core/uicore';
import {Button} from 'core/controls';
import {useRouter, usePCTranslation} from 'core/hooks';
import {logout} from 'core/redux/actions';
import {updateReportOptionsProfile} from 'custom/newbiz/redux/actions';
import {STYLES} from 'core/constants/uiConstants';
import SwitchProductModal from './SwitchProductModal';
import ProfileModal from './ProfileModal';
import SharedOwnershipModal from 'custom/sharedOwnership/SharedOwnershipModal';
import {
  NEW_BUSINESS,
  INFORCE,
  SHARED_OWNERSHIP,
  SWITCH_PRODUCTS,
  PROFILE,
  ABOUT,
  NONE,
} from '../utils/constants';
import AboutModal from './AboutModal';
import {useMsal} from '@azure/msal-react';

const PageHeaderM = ({...rest}) => {
  const {push, pathname, location} = useRouter();
  const dispatch = useDispatch();
  const {t} = usePCTranslation();
  const {instance, accounts} = useMsal();

  const {user, routes, storeFormData} = useSelector((state) => ({
    user: state.auth.user,
    storeFormData: state.formData['newBusiness'],
    routes: state.routes,
  }));
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [isAboutModal, setIsAboutModal] = useState(false);
  const [isSharedOwnershipModal, setIsSharedOwnershipModal] = useState(false);
  const [isSwitchProductModalOpen, setIsSwitchProductModalOpen] = useState(false);
  const [isHeaderModal, setIsHeaderModal] = useState(false);
  const [pageheaderData, setPageheaderData] = useState({});

  useEffect(() => {
    let headerData = {...pageheaderData};
    if (pathname) {
      let appName = pathname.split('/')[pathname.split('/').length - 1].toLowerCase();
      headerData['formName'] = appName;
      setPageheaderData(headerData);
    }
  }, [pathname]);

  const elementItems = {};
  const setElement = (element) => {
    if (element !== null) {
      elementItems[element.name] = element;
    }
  };
  const updatePageheaderData = (name, value) => {
    let pageHeader = {...pageheaderData};
    pageHeader[name] = value;
    setPageheaderData(pageHeader);
  };
  const controlClick = (event, name, value) => {
    event.persist();
    rest.globalClick(name, pageheaderData, elementItems, updatePageheaderData);
  };

  const controlLostFocus = (event, name, value) => {
    rest.globalLostFocus(event, pageheaderData, elementItems, updatePageheaderData);
  };
  const controlGetFocus = (event, name, value) => {
    rest.globalGetFocus(event, pageheaderData, elementItems, updatePageheaderData);
  };

  /**
   * Example for below line of code:
   * location.pathname -> /inforce/alphalookup
   * page -> inforce
   * if invalid page, redirect to login, login redirects user to default app if logged in
   */
  let pathArray = pathname.split('/');
  const page = pathArray[1].toLowerCase();

  const handleClickRoute = (event, name, value, route) => {
    const tempArr = location.pathname.split('/');
    if (route.subModule.toLowerCase() !== tempArr[tempArr.length - 1].toLowerCase()) {
      controlClick(event, name, value);
      push(route.paths[0]);
    }
  };
  const isActive = (route) => {
    const tempArr = location.pathname.split('/');
    if (route.subModule.toLowerCase() !== tempArr[tempArr.length - 1].toLowerCase()) {
      return false;
    }
    return true;
  };

  const logOff = async () => {
    const tokenMethod = sessionStorage.getItem('tokenMethod');
    if (tokenMethod === 'AD') {
      dispatch(logout());
      try {
        await instance.logoutRedirect({
          account: accounts[0],
          // mainWindowRedirectUri: 'http://localhost:44376/login',
          postLogoutRedirectUri: 'https://localhost:5001/',
        });
      } catch (error) {
        console.log(error);
        // dispatch(logout());
      }
    } else {
      dispatch(logout());
      push('/login');
    }
  };

  const handleClick = async (event, name, value) => {
    controlClick(event, name, value);
    switch (name) {
      case 'btnSwitchProducts':
        setIsSwitchProductModalOpen(!isSwitchProductModalOpen);
        break;
      case 'btnProfile':
        //onAbout(); // About modal window
        setIsProfileModal(true);
        break;
      case 'btnLogout':
        if (page === NEW_BUSINESS && !pathArray[2] && storeFormData) {
          await dispatch(updateReportOptionsProfile(storeFormData));
        }
        logOff();
        break;
      case 'btnAbout':
        setIsAboutModal(true);
      default:
        break;
    }
  };

  const handleSelectProduct = (product) => {
    setIsSwitchProductModalOpen(!isSwitchProductModalOpen);
    if (page === NEW_BUSINESS && product === SHARED_OWNERSHIP) {
      setIsSharedOwnershipModal(true);
    } else if (page !== product) {
      push(`/${product}`);
    }
    handleCancelPageHeaderModal();
  };
  const handleSwitchProductCancel = () => {
    setIsSwitchProductModalOpen(!isSwitchProductModalOpen);
  };

  const handleCancelAbout = () => {
    setIsAboutModal(false);
  };

  const handleCancelProfile = () => {
    setIsProfileModal(false);
  };
  const handleSaveProfile = () => {
    setIsProfileModal(false);
  };
  const handleCancelSharedOwnership = (formData) => {
    rest.updateIllustrationForm(formData, false);
    setIsSharedOwnershipModal(false);
  };
  const handleFinishSharedOwnership = (formData) => {
    rest.updateIllustrationForm(formData, true);
    setIsSharedOwnershipModal(false);
  };
  const handlePageHeaderModal = () => {
    setIsHeaderModal(true);
  };
  const handleCancelPageHeaderModal = () => {
    setIsHeaderModal(false);
  };

  return (
    <Fragment>
      <div
        className={`PRODUCTHEADER flexContainer alignStretch logoHdr${routes[page][0].appName} bgWhite borderThin borderBottom borderNeutral paddingLgHoriz`}
      >
        <div
          id={routes[page][0].appName}
          className="flexContainer autoCols justifyEnd alignCenter"
        >
          <label
            id={routes[page][0].appName}
            className="PAGETITLE borderNone fontMed"
          >
            {t(routes[page][0].displayName)}
          </label>
        </div>
        <Button
          text="3dots"
          getfocusCallback={controlGetFocus}
          getclickCallback={(event, name, value) => {
            controlClick(event, name, value);
            handlePageHeaderModal();
          }}
          lostfocusCallback={controlLostFocus}
          name="btn3DotsHeader"
          refItem={setElement}
          propStyle={{
            label: "buttonlabel",
            control: `buttoncontrol borderRadiusAll bgNone paddingNone`,
          }}
          showIcon={true}
          logo={{
            srcLogo: threedotsLogo,
            altLogo: "",
            classLogo: "3dots-logo",
          }}
        />

        <div className="LINKS flexContainer">
          {routes[page].map((route) => {
            return (
              <div
                key={route.routeId}
                className={`${isActive(route) ? "activeHeader" : ""}`}
              >
                <Button
                  text=""
                  getfocusCallback={controlGetFocus}
                  getclickCallback={(event, name, value) =>
                    handleClickRoute(event, name, value, route)
                  }
                  lostfocusCallback={controlLostFocus}
                  //stateData={storeFormData}
                  name={route.button}
                  refItem={setElement}
                  propStyle={{
                    label: "buttonlabel",
                    control: "buttoncontrol buttonHdr",
                  }}
                />
                {/* <button onClick={(event) => handleClickRoute(event, route)}>{route.button}</button> */}
              </div>
            );
          })}
        </div>
      </div>

      {isHeaderModal && (
        <Modal onClick={(e) => e.stopPropagation()} propStyle="modalDark">
          <div className="modalcontainer bgPrimaryShade paddingLg borderRadiusAllLg">
            <Button
              text="Cancel"
              getfocusCallback={controlGetFocus}
              getclickCallback={(event, name, value) => {
                controlClick(event, name, value);
                handleCancelPageHeaderModal();
              }}
              lostfocusCallback={controlLostFocus}
              name="btnXProfileMCancel"
              refItem={setElement}
              propStyle={{
                label: "buttonlabel",
                control: `modalClose bgNone`,
              }}
              logo={{
                srcLogo: closeIcon,
                altLogo: "",
                classLogo: "new-logo branding-image-filter",
              }}
              showIcon={true}
            />
            <div className={`${STYLES.PAGEHEADERM} header-container`}>
              <Logo
                companyName="PROCOMP LIFE"
                name="companyName"
                propStyle={{ logo: "" }}
              />
              <ButtonBar
                name="Profile Header"
                buttonbar="flexContainer noCols justifyEnd"
              >
                <Button
                  text="Switch Products"
                  getfocusCallback={controlGetFocus}
                  getclickCallback={handleClick}
                  lostfocusCallback={controlLostFocus}
                  //stateData={storeFormData}
                  name="btnSwitchProducts"
                  refItem={setElement}
                  propStyle={{
                    label: "buttonlabel",
                    control:
                      "buttoncontrol buttonHdr justifyStart paddingLgVertical borderBottom borderThin borderDark",
                  }}
                  logo={{
                    srcLogo: switchLogo,
                    altLogo: "",
                    classLogo: "help-logo",
                  }}
                  showIcon={true}
                />
                <Button
                  text="About"
                  getfocusCallback={controlGetFocus}
                  getclickCallback={handleClick}
                  lostfocusCallback={controlLostFocus}
                  //stateData={storeFormData}
                  name="btnAbout"
                  refItem={setElement}
                  propStyle={{
                    label: "buttonlabel",
                    control:
                      "buttoncontrol buttonHdr justifyStart paddingLgVertical borderBottom borderThin borderDark",
                  }}
                  logo={{
                    srcLogo: aboutLogo,
                    altLogo: "",
                    classLogo: "help-logo",
                  }}
                  showIcon={true}
                />
                <Button
                  text="Your Profile"
                  getfocusCallback={controlGetFocus}
                  getclickCallback={handleClick}
                  lostfocusCallback={controlLostFocus}
                  //stateData={storeFormData}
                  name="btnProfile"
                  refItem={setElement}
                  propStyle={{
                    label: "buttonlabel",
                    control:
                      "buttoncontrol buttonHdr justifyStart paddingLgVertical borderBottom borderThin borderDark",
                  }}
                  logo={{
                    srcLogo: userLogo,
                    altLogo: "",
                    classLogo: "profile-logo",
                  }}
                  showIcon={true}
                />
                <Button
                  text="Sign Out"
                  getfocusCallback={controlGetFocus}
                  getclickCallback={handleClick}
                  lostfocusCallback={controlLostFocus}
                  //stateData={storeFormData}
                  name="btnLogout"
                  refItem={setElement}
                  propStyle={{
                    label: "buttonlabel",
                    control:
                      "buttoncontrol buttonHdr justifyStart paddingLgVertical borderBottom borderThin borderDark",
                  }}
                  logo={{
                    srcLogo: signOffLogo,
                    altLogo: "",
                    classLogo: "help-logo",
                  }}
                  showIcon={true}
                />
              </ButtonBar>
            </div>
          </div>
        </Modal>
      )}
      {isSwitchProductModalOpen && (
        <SwitchProductModal
          controlGetFocus={controlGetFocus}
          controlClick={controlClick}
          controlLostFocus={controlLostFocus}
          setElement={setElement}
          onCancel={handleSwitchProductCancel}
          onSelectProduct={handleSelectProduct}
          route={page}
          isMobile={true}
        />
      )}
      {isProfileModal && (
        <ProfileModal
          {...rest}
          onCancel={handleCancelProfile}
          onSaveProfile={handleSaveProfile}
        />
      )}
      {isSharedOwnershipModal && (
        <SharedOwnershipModal
          onCancel={handleCancelSharedOwnership}
          onFinish={handleFinishSharedOwnership}
          {...rest}
        />
      )}
      {isAboutModal && (
        <AboutModal
          controlGetFocus={controlGetFocus}
          controlClick={controlClick}
          controlLostFocus={controlLostFocus}
          setElement={setElement}
          onCancel={handleCancelAbout}
          route={page}
        />
      )}
    </Fragment>
  );
};

export default PageHeaderM;
