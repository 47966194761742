import React from 'react';
import { useSelector } from "react-redux";

const ModalTitle = ({title, fontSize, t, showUserName=false}) => {
  const { username } = useSelector(state => state.auth.user);

  return <h2 className={fontSize}>{t(title)}{showUserName && `- ${username}`}</h2>;
};

export default ModalTitle;
