import React from 'react';
import {STYLES} from 'core/constants/uiConstants';

const PanelItemGroup = ({children, styling, onClick}) => {
  return (
    <div className={`${STYLES.PANELITEMGROUP} ${styling}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default PanelItemGroup;
