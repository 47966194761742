import React, {Fragment, memo, useState} from 'react';
import {useSelector} from 'react-redux';
import {STYLES} from 'core/constants/uiConstants';
import {TooltipIcon, TooltipText} from '../uicore';
import {ComboModal} from 'core/controls';
import {usePCTranslation} from 'core/hooks';

const Selectbox = ({
  getchangeCallback,
  getfocusCallback,
  lostfocusCallback,
  getclickCallback,
  stateData,
  name,
  text,
  options,
  autoFocus,
  refItem,
  propStyle,
  disable,
  showLabel,
  styleProp,
  isModalOptions,
  isUseInformation,
  btnXCancel,
  btnCancel,
  btnChoose,
  modalTitle,
}) => {
  const {t} = usePCTranslation();
  const {controlItem} = useSelector((state) => ({
    controlItem: state.setupItems.controlItems ? state.setupItems.controlItems[name] : null,
  }));
  const [isModalOpen, setIsModalOpen] = useState(false);

  let optionItems = controlItem?.optionItems;

  /**
   * Below condition is used by Data Reports and other apps where options is passed as a prop. Use Options is false
   * For Pro needs case, child's Uni year options is coming via controlItems. But useOptions is false in that case too.
   *
   */
  if (!controlItem || !controlItem.useOptions) {
    optionItems = options;
  }
  if (!optionItems) {
    optionItems = {};
  }

  const controlOnChange = (event) => {
    event.persist();
    getchangeCallback(event, event.target.name, event.target.value);
    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnFocus = (event) => {
    getfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnLostFocus = (event) => {
    if (!isModalOptions) {
      lostfocusCallback(event, event.target.name, event.target.value);
      //DO NOT propagate this event up as it should only be for the current control
      event.stopPropagation();
    }
  };
  let containerClass = `${STYLES.INPUTCONTAINER} ${propStyle.selectcontainer}`;
  if ((controlItem && controlItem.displayDisabled) || disable) {
    containerClass += ' disabled';
  }
  if (controlItem && controlItem.displayHidden) {
    containerClass += ' hiddenField';
  }

  const handleModalOpen = () => {
    if (isModalOptions) {
      setIsModalOpen(true);
    }
  };
  const handleSelectOption = async (event) => {
    await controlOnChange(event);
    // lostfocusCallback(event, event.target.name, event.target.value);
    setIsModalOpen(false);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  const selectInput = () => {
    return (
      // <div className={`${isModalOptions?" hiddenField":""}`}>
      // {/* <div style={{zIndex: 1}}> */}
      <select
        className={`${STYLES.BASIC} ${STYLES.SELECT} ${propStyle.control}`}
        value={
          stateData && stateData[name]
            ? stateData[name]
            : Object.keys(optionItems)[0]
        }
        name={name}
        onFocus={controlOnFocus}
        onBlur={controlOnLostFocus}
        onChange={controlOnChange}
        autoFocus={autoFocus}
        ref={refItem}
        disabled={disable || controlItem?.disabled ? true : false}
        style={styleProp}
      >
        {!isModalOptions &&
          Object.keys(optionItems).map((key) => (
            <option value={key} key={key}>
              {controlItem && controlItem.isTranslateOptions === true
                ? t(optionItems[key])
                : optionItems[key]}
            </option>
          ))}
      </select>
      // </div>
    );
  };
  return (
    <div id={name} className={containerClass} onClick={handleModalOpen}>
      {showLabel && (
        <label className={`${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`} id={name}>
          {t(`${name}-caption`)}
        </label>
      )}
      {!controlItem || !controlItem.showTooltip ? (
        selectInput()
      ) : controlItem.showTooltipIcon ? (
        <Fragment>
          <div className={STYLES.TOOLTIP} id={name}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
          {selectInput()}
        </Fragment>
      ) : (
        <div className={`${STYLES.TOOLTIP} ${propStyle.tooltip}`} id={name}>
          <TooltipText name={name} />
          {selectInput()}
        </div>
      )}
      {isModalOptions && isModalOpen && (
        <ComboModal
          name={name}
          controlItem={controlItem}
          optionItems={optionItems}
          controlClick={getclickCallback}
          controlGetFocus={getfocusCallback}
          onSelectProduct={handleSelectOption}
          onCancel={handleCancelModal}
          controlLostFocus={lostfocusCallback}
          setElement={refItem}
          btnX={btnXCancel}
          btnCancel={btnCancel}
          btnChoose={btnChoose}
          modalTitle={`${name}-caption`}
          value={stateData[name]}
          isUseInformation={isUseInformation}
        />
      )}
    </div>
  );
};

Selectbox.defaultProps = {
  isModalOptions: false,
  autoFocus: false,
  showLabel: true,
  styleProp: {},
  disable: false,
  options: {},
  getclickCallback: (event, name, value) => {},
  btnChoose: '',
  btnCancel: '',
  btnXCancel: '',
  modalTitle: '',
  isUseInformation: true,
};

export {Selectbox};
