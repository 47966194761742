import {companyLogo, execTimeLogo, ProcompGreyLogo} from 'assets';
import React from 'react';

const IntroModal15 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents fullWidth">
        <header className="sharedOwnershipHdr flexContainer alignCenter">
          <img
            id="intro-15"
            className=""
            style={{width: '6rem', height: '6rem'}}
            src={ProcompGreyLogo}
            alt=""
          />
          <h3 className="fontHdrCapitalize textAlignCenter">TAXATION ISSUES</h3>
        </header>
        <section className="sharedOwnershipContainer noWrap">
          <section className="flexContainerColumn alignCenter marginMed">
            <img id="exec-time-15" src={execTimeLogo} alt="ULBriefcase" />
            <div className="paddingLg bgWhite boxShadowAll">
              <h2 className="fontHdr2 textAlignCenter fontPrimaryColor">
                For Executives (Employee)
              </h2>
              <h3 className="textAlignCenter fontAltColorB fontMed">
                at the end of Benefit Period when Corporation transfers its interests to Executive
              </h3>
            </div>
          </section>
          <section style={{maxWidth: '50rem', margin: '0 auto'}}>
            <p className="fontLg textAlignCenter marginMed paddingLg">
              Depends if there is any 'value' in the corporation's portion of the policy
            </p>
            <p className="textAlignCenter fontLg paddingMed">
              Caution: there may be 'value' if Executive is: Terminally ill or death is 'imminent'
              or Uninsurable
            </p>
            <p className="textAlignCenter paddingLg fontHdr2">*See CRA document IC#89-3</p>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal15;
