import {companyLogo, execTimeLogo, ProcompGreyLogo} from 'assets';
import React from 'react';

const IntroModal14 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents fullWidth">
        <header className="sharedOwnershipHdr flexContainer alignCenter">
          <img
            id="intro-14"
            className=""
            style={{width: '6rem', height: '6rem'}}
            src={ProcompGreyLogo}
            alt=""
          />
          <h3 className="fontHdrCapitalize textAlignCenter">TAXATION ISSUES</h3>
        </header>
        <section className="sharedOwnershipContainer noWrap">
          <section className="flexContainerColumn alignCenter marginMed">
            <img id="exec-time-14" src={execTimeLogo} alt="ULBriefcase" />
            <div className="paddingLg bgWhite boxShadowAll">
              <h2 className="fontHdr2 textAlignCenter fontPrimaryColor">
                For Executives (Employee)
              </h2>
              <h3 className="textAlignCenter fontAltColorB fontMed">during Benefit Period</h3>
            </div>
          </section>
          <section style={{maxWidth: '50rem', margin: '0 auto'}}>
            <p className="fontHdr2 textAlignCenter marginMed">
              Care must be take to ensure a divison of premiums that is tax-effective
            </p>
            <p className="textAlignCenter fontMed">
              If Corporation pays more than mortality charges, premium taxes & administrative fees
              portion of its premium, then these additional amounts will be taxable benefit to
              Executive
            </p>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal14;
