import {companyLogo, corpLogo, execLogo, gavelLogo, ProcompGreyLogo} from 'assets';
import React from 'react';

const IntroModal10 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <header className="sharedOwnershipHdr flexContainer alignCenter">
          <img
            id="intro-10"
            className=""
            style={{width: '6rem', height: '6rem'}}
            src={ProcompGreyLogo}
            alt=""
          />
          <h3 className="fontHdrCapitalize textAlignCenter">THE SHARED OWNERSHIP AGREEMENT</h3>
        </header>
        <section className="sharedOwnershipContainer noWrap">
          <section className="flexContainer noCols justifyCenter alignCenter">
            <img id="exec-10" src={execLogo} alt="ULBriefcase" />
            <img id="gavel-10" src={gavelLogo} alt="ULBriefcase" />
            <img id="corp-10" src={corpLogo} alt="ULBriefcase" />
          </section>
          <section className="paddingLg">
            <p className="fontHdr2 textAlignCenter marginMed">
              It is the responsibility of the Executive and the Corporation to engage their own{' '}
              <span className="fontHdr2 fontWeightBold" style={{color: 'purple'}}>
                knowledgeable legal and tax advisors
              </span>{' '}
              to negotiate and document a Shared Ownership Agreement that is appropriate to their
              respective needs
            </p>
            <p className="fontHdr2 textAlignCenter marginMed">
              The insurer is not a party to the Agreement and is not bound by its terms
            </p>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal10;
