import React from 'react';
import {STYLES} from 'core/constants/uiConstants';
import {companyLogo} from 'assets';
import {Label} from 'core/controls';

export const Logo = ({name, companyName, propStyle}) => {
  return (
    <div className={`${STYLES.LOGO} ${propStyle.logo} logoPrimary`} name={name}>
      {/* <img src={companyLogo} alt="Header" />
      <Label
        text={companyName}
        name={name}
        propStyle={{ label: "companyName fontSm" }}
        useTranslation={false}
      /> */}
    </div>
  );
};
export default Logo;
