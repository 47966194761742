import React from 'react';
import {STYLES} from 'core/constants/uiConstants';
import {usePCTranslation} from 'core/hooks';

const TooltipText = ({name}) => {
  const {t} = usePCTranslation();
  return (
    <div className={`${STYLES.TOOLTIPTEXT}`} name={name}>
      <span>{t(`${name}-tooltip`)}</span>
    </div>
  );
};

export default TooltipText;
