import React from 'react';
import {STYLES} from 'core/constants/uiConstants';

const ButtonBar = ({children, name, buttonbar}) => (
  <div className={`${STYLES.BUTTONBAR} ${buttonbar}`} name={name}>
    {children}
  </div>
);

export default ButtonBar;
