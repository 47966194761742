export const biReportsFormData = {
  formName: "dataReports",
  CurrentField: "",
  reportNumber: 0,
  chkStoreInExcel: false,
  dtBIReportsStartDate: new Date(2022, 1, 1),
  dtBIReportsEndDate: new Date(2022, 12, 31),
};

export const ReportGroups = {
  PROQUOTE_REPORTS: 0,
  ILLUSTRATION_REPORTS: 1,
  NEWBUSINESS_REPORTS: 2,
  ADMIN_REPORTS: 3,
};

export const ReportGroupsName = [
  {
    name: "PROQUOTE REPORTS",
    permission: "isProQuote",
    reportAPIPath: "/api/BI/BIProQuoteReport",
  },
  {
    name: "ILLUSTRATION REPORTS",
    permission: "isNewBusiness",
    reportAPIPath: "/api/BI/BINewBizReport",
  },
  {
    name: "INFORCE REPORTS",
    permission: "isInforce",
    reportAPIPath: "/api/BI/BIInforceReport",
  },
  {
    name: "ADMIN REPORTS",
    permission: "isAdmin",
    reportAPIPath: "/api/BI/BIAdminReport",
  },
];

const PROQUOTE_REPORT_LIST = {
  MAXIMUM_STEPS_PER_USER: 1,
  FLOWS_FINISHED_PER_USER: 2,
  USERS_LEAVE_AT_ABOUT_YOU: 3,
  BACK_CLICKS_COUNT_PER_USER: 4,
  PROGRESSION_OF_FLOW: 5,
  PROGRESSION_OF_FLOW_WITH_TIME_PER_PAGE: 6,
  COUNT_OF_START_OVERS_PER_USER: 7,
  COUNT_OF_PRODUCTS_RUN: 8,
  SUMMARY_OF_PRODUCTS_RUN: 9,
  COUNT_OF_LINKS_CLICKED: 10,
  COUNT_OF_LINKs_CLICKED_PER_USER: 11,
  COUNT_OF_PROCOMP_LINKS_CLICKED: 12,
  COUNT_OF_PROCOMP_LINKS_CLICKED_PER_USER: 13,
};

const ILLUSTRATION_LIST = {
  NBIllustrationByProductType: 1,
  Top10AgentsByIllustration: 2,
  IncomePayoutImpact: 3,
  BenefitsIllustrated: 4,
};

const INFORCE_LIST = {
    InforceIllustrationByProductType: 1,
    InforcePoliciesSoldByProductType: 2,
    Top10AgentsByLifePoliciesSold: 3,
    InforceIllustrationByProductName: 4,
    InforcePoliciesSoldByProductName: 5,
    InforceDataReportSummary: 6,
};

const ADMIN_LIST = {
    BIDataReportSummary: 1,
};

export const buildBICardList = () => {
  const ProquoteReportsList = [
    {
      key: PROQUOTE_REPORT_LIST.MAXIMUM_STEPS_PER_USER,
      value: "Maximum Steps per User",
    },
    {
      key: PROQUOTE_REPORT_LIST.FLOWS_FINISHED_PER_USER,
      value: "Flows finished per User",
    },
    {
      key: PROQUOTE_REPORT_LIST.USERS_LEAVE_AT_ABOUT_YOU,
      value: "Users that stop at About You",
    },
    {
      key: PROQUOTE_REPORT_LIST.BACK_CLICKS_COUNT_PER_USER,
      value: "Count of Back clicks per User",
    },
    {
      key: PROQUOTE_REPORT_LIST.PROGRESSION_OF_FLOW,
      value: "Progression of Flow",
    },
    {
      key: PROQUOTE_REPORT_LIST.PROGRESSION_OF_FLOW_WITH_TIME_PER_PAGE,
      value: "Progression of Flow with Time per Page",
    },
    {
      key: PROQUOTE_REPORT_LIST.COUNT_OF_START_OVERS_PER_USER,
      value: "Count of Start overs per User",
    },
    {
      key: PROQUOTE_REPORT_LIST.COUNT_OF_PRODUCTS_RUN,
      value: "Count of Products Run",
    },
    {
      key: PROQUOTE_REPORT_LIST.SUMMARY_OF_PRODUCTS_RUN,
      value: "Summary of Products Run",
    },
    {
      key: PROQUOTE_REPORT_LIST.COUNT_OF_LINKS_CLICKED,
      value: "Count of Links clicked",
    },
    {
      key: PROQUOTE_REPORT_LIST.COUNT_OF_LINKs_CLICKED_PER_USER,
      value: "Count of Links clicked per User",
    },
    {
      key: PROQUOTE_REPORT_LIST.COUNT_OF_PROCOMP_LINKS_CLICKED,
      value: "Count of Procomp links clicked",
    },
    {
      key: PROQUOTE_REPORT_LIST.COUNT_OF_PROCOMP_LINKS_CLICKED_PER_USER,
      value: "Count of Procomp links clicked per User",
    },
    
  ];

  const illustrationList = [
    {
      key: ILLUSTRATION_LIST.NBIllustrationByProductType,
      value: "New Business Illustrations By Product Type",
    },
    {
      key: ILLUSTRATION_LIST.Top10AgentsByIllustration,
      value: "Top 10 Agents By Illustration",
    },
    {
      key: ILLUSTRATION_LIST.IncomePayoutImpact,
      value: "Income Payout Impact",
    },
    {
      key: ILLUSTRATION_LIST.BenefitsIllustrated,
      value: "Benefits Illustrated",
    },
  ];

    const inforceList = [
        {
            key: INFORCE_LIST.InforceIllustrationByProductType,
            value: "Inforce Illustrations By Product Type",
        },
        {
            key: INFORCE_LIST.InforcePoliciesSoldByProductType,
            value: "Inforce Policies Sold By Product Type",
        },
        {
            key: INFORCE_LIST.Top10AgentsByLifePoliciesSold,
            value: "Top 10 Agents By Life Policies Sold",
        },
        {
            key: INFORCE_LIST.InforceIllustrationByProductName,
            value: "Inforce Illustrations By Product Name",
        },
        {
            key: INFORCE_LIST.InforcePoliciesSoldByProductName,
            value: "Inforce Policies Sold by Product Name",
        },
        {
            key: INFORCE_LIST.InforceDataReportSummary,
            value: "Inforce Data Reports Summary",
        },
    ];

    const adminList = [
        {
            key: ADMIN_LIST.BIDataReportSummary,
            value: "BI Data Reports Summary",
        },
    ];

    return [ProquoteReportsList, illustrationList, inforceList, adminList];
};
