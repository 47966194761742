import { ActionTypes } from "custom/common/redux/Types";

const loadInforceDataReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.INFORCE_LOAD:
      return payload;
    case ActionTypes.INFORCE_REMOVE:
      return {};
    case ActionTypes.INFORCE_LOAD_ERROR:
      if (payload.status === 500) {
        return {
          errorCode: payload.status,
          errorMessage: "Internal server error, please try after sometime"
        };
      }
      return { errorCode: 2, errorMessage: "There is some error with request" };
    default:
      return state;
  }
};

const loadTreeDataReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.TREE_BUILD:
      return payload;
    case ActionTypes.TREE_REMOVE:
      return {};
    default:
      return state;
  }
};

export {
  loadInforceDataReducer as inforceData,
  loadTreeDataReducer as treeData
};
