import React, { Fragment } from "react";
import spinner from "assets/spinner.gif";

const Spinner = ({ styling="" }) => (
  <div className={`spinner ${styling}`}>
    <img
      src={spinner}
      // style={{ width: '200px', margin: 'auto', display: 'block' }}
      alt="Loading..."
    />
  </div>
);

export default Spinner;
