import {ActionTypes} from 'custom/common/redux/Types';
import axios from 'axios';
import {setAlert, removeAlert} from 'core/redux/actions';

/***
 * queryObject:
 * {firstName, middleName, lastName, user: {}}
 */
export const loadAlphaLookupData = ({firstName, middleName, lastName}) => async (
  dispatch,
  getState
) => {
  let queryObject = {
    firstName,
    middleName,
    lastName,
    user: getState().auth.user,
  };
  console.log('Query object for alphaLookup: ', queryObject);

  // This is to remove alert if any existing on data fetch
  if (getState().alert['alphaLookup']) {
    dispatch(removeAlert('alphaLookup'));
  }

  let res;
  try {
    res = await axios.post('/api/AlphaLookup', queryObject);
    console.log('AlphaLookup Result', res, res.data);

    dispatch({
      type: ActionTypes.FETCH_ALPHALOOKUP,
      payload: res.data,
    });

    if (res.data[0].errorCode === 2) {
      dispatch(setAlert(res.data[0].errorMessage, 'Secondary', 'alphaLookup', 5000));
    }
  } catch (error) {
    console.log('Alpha Lookup Error: ', error, error?.response, error?.response?.data);
    dispatch({
      type: ActionTypes.FETCHERROR_ALPHALOOKUP,
    });
    if (error?.response?.data && error?.response?.data[0]?.errorMessage) {
      dispatch(setAlert(error.response.data[0].errorMessage, 'Error', 'alphaLookup', 5000));
    }
  }
};
export const clearAlphaLookupData = () => (dispatch, getState) => {
  // This is to remove alert if any existing on data fetch
  if (getState().alert['alphaLookup']) {
    dispatch(removeAlert('alphaLookup'));
  }
  dispatch({type: ActionTypes.CLEAR_ALPHALOOKUP});
};
