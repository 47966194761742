import React, {Fragment, memo, useContext} from 'react';
import {useSelector} from 'react-redux';
import {STYLES} from 'core/constants/uiConstants';
import {TooltipIcon, TooltipText} from '../uicore';
import {usePCTranslation} from 'core/hooks';
import {ValidationContext} from 'core/formValidation';

const Checkbox = ({
  getchangeCallback,
  getfocusCallback,
  lostfocusCallback,
  stateData,
  checked,
  readOnly,
  name,
  text,
  autoFocus,
  refItem,
  propStyle,
  displayName,
  disableControl,
  customLabel = undefined,
}) => {
  const {t} = usePCTranslation();
  const {errors, formErrors} = useContext(ValidationContext);
  const {controlItem} = useSelector((state) => ({
    controlItem: state.setupItems.controlItems ? state.setupItems.controlItems[name] : null,
  }));

  const controlOnChange = (event) => {
    event.persist();
    //setIsChecked(event.target.checked);
    if (!controlItem || !controlItem.locked) {
      getchangeCallback(event, event.target.name, event.target.checked);
    }
    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    //event.stopPropagation();
  };

  const controlOnFocus = (event) => {
    getfocusCallback(event, event.target.name, event.target.checked);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnLostFocus = (event) => {
    lostfocusCallback(event, event.target.name, event.target.checked);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };
  let containerClass = `${STYLES.INPUTCONTAINER} ${propStyle.checkcontainer}`;
  if (disableControl || (controlItem && controlItem.displayDisabled)) {
    containerClass += ' disabled';
  }
  if (controlItem && controlItem.displayHidden) {
    containerClass += ' hiddenField';
  }
  if (controlItem && controlItem.locked) {
    containerClass += ' locked';
  } else {
    containerClass.replace('locked', '');
  }
  if ((formErrors[name] || errors[name]) && controlItem && controlItem.isDirty) {
    containerClass += ' errorState';
  }

  const checkInput = () => {
    return (
      <Fragment>
        <input
          className={`${STYLES.BASIC} ${STYLES.CHECK} ${propStyle.control}`}
          type="checkbox"
          checked={stateData && stateData[name] ? stateData[name] : false}
          //checked={isChecked}
          name={name}
          onFocus={controlOnFocus}
          onChange={controlOnChange}
          onBlur={controlOnLostFocus}
          autoFocus={autoFocus}
          ref={refItem}
          disabled={disableControl || controlItem?.disabled}
        />
        <label className={`${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`}>
          {customLabel
            ? customLabel
            : controlItem
            ? t(`${name}-caption`)
            : displayName || ""}
        </label>
      </Fragment>
    );
  };
  return (
    <div id={name} className={containerClass}>
      {!controlItem || !controlItem.showTooltip ? (
        checkInput()
      ) : controlItem.showTooltipIcon ? (
        <Fragment>
          {checkInput()}
          <div className={`${STYLES.TOOLTIP}`}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
        </Fragment>
      ) : (
        <div className={`${STYLES.TOOLTIP}`}>
          <TooltipText name={name} />
          {checkInput()}
        </div>
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  autoFocus: false,
  readOnly: false,
  disableControl: false,
};
export {Checkbox};
