import {styleTypes} from './constants';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import {globalVariables, toProperCase} from 'core/utils/util';
import {getCoverageItemByRiderType, getCoverageItemByType, getDisabilityItem, getCoverageByID, PCRound} from 'core/utils/calcUtils';
import {
  PolicyTypes,
  RiderTypes,
  CoverageTypes,
  COITypes,
  BillingMethodTypes,
  PayoutInsuredTypes,
  FundTypes,
  CstatCode,
  DividendPayoutTypes,
  SpouseContributorTypes,
  PayoutTypes,
  DBOptionTypes,
  PartyTypes,
} from 'core/constants/calcConstants';
import {
  formatDollar,
  Format5Decimals,
  formatAllocationPercent,
  formatInterestRate,
  format_Display_Date,
  format_Display_Date_Short,
  format_Display_Date_Object,
  TextLookup_SubTable1,
  TextLookup_SubTable2,
  TextLookup_SubTable3,
  TextLookup_SubTable4,
  TextLookup_ProductName,
  Translate_RRIF_Code,
  TextLookup_PayoutFrequency,
  TextLookup_PayoutMethodCode,
  formatIsAmountAboveZero,
  Translate_NextPaymentDate,
  TextLookup_PayoutType,
  IsProdIDFlexLife2,
  TextLookup_AccountType,
  TextLookup_PolicyName,
  TextLookup_Sex,
  TextLookup_SmokeClass,
  TextLookup_PremiumModePolicy,
  TextLookup_PolicyCStatCode,
  TextLookup_NFOption,
  TextLookup_DividendPayout,
  isProductHealth,
  Format2Decimals,
  FormatnDecimals,
  isProdIDSOSBAEndowment,
  isProdIDGradedPremium,
  isProdIDProPlan,
  isPolicyPaidUp,
  TextLookup_BeneficiaryCode,
  FormatPhoneNumber,
  isPlanExcludeHealthMatch,
  formatSIN,
  fullName,
  isInPayout,
  isFundLoan,
  modalFactor,
  calculateAgeFromJan1,
} from 'custom/common/utils/lookupFunctions';

let displayArray = [];
let policyTree = [];
let treeIndex = 0;
let globalBgColor = styleTypes.WHITE;
class Node {
  constructor(data, idx) {
    this.title = data.title;
    this.expanded = data.expanded;
    this.id = idx;
    this.head = data.head || false;
    this.displayStatusType = data.displayStatusType;
    this.children = [];
  }
}

class Tree {
  constructor(data) {
    data.expanded = true;
    this.root = new Node(data, data.id);
  }
}
export async function updateInforce(inforceData, formData) {
  let policy = inforceData.policy;
  let inforcePolicy = inforceData.inforcePolicy;
  console.log('Inside updateInforce');

  if (inforceData.policy.policyType === PolicyTypes.UL) {
    policy = await addT10('094', '08U101', 'UL - 2008 Term 10 Rider - Base Insured', formData, policy, false);
    inforcePolicy = await addT10('094', '08U101', 'UL - 2008 Term 10 Rider - Base Insured', formData, inforcePolicy, true);
  } else {
    policy = await addT10('093', '06T101', 'Term 10 - 2006 (Base Insured)', formData, policy, false);
    inforcePolicy = await addT10('093', '06T101', 'Term 10 - 2006 (Base Insured)', formData, inforcePolicy, true);
  }

  inforceData.policy = policy;
  inforceData.inforcePolicy = inforcePolicy;
  return inforceData;
}

async function addT10(coverageID, planCode, name, formData, aPolicy, isInforce) {
  let faceAmount = formData['txtTermRiderFaceAmount'];
  let insuredAge = formData['txtTermRiderAge'];
  let insuredName = formData['txtTermRiderInsuredName'];
  let insuredSex = formData['cboTermRiderSex'];
  let insuredSmokingClass = formData['cboTermRiderSmokeClass'];
  let insuredRateClass = formData['cboTermRiderRateClass'];
  let insuredRatings = formData['chkTermRiderRatings'];
  let insuredRating = formData['cboTermRiderRating'];
  let insuredFlatExtra = formData['txtTermRiderFlatExtra'];
  let insuredFlatExtraDuration = formData['txtTermRiderFlatExtraDuration'];

  let strInforce = '';
  if (isInforce) {
    strInforce = ' inforce';
  }
  console.log('In addT10');
  console.log(strInforce + ' policy at start of AddT10', aPolicy);
  let baseCoverage = aPolicy.coverages[0];

  let coverage = cloneDeep(baseCoverage); //first clone baseCoverage so that all of the fields are there then overwrite the ones we want
  coverage.isActive = true;
  coverage.coverageID = coverageID;
  coverage.parentCoverageID = '001';
  coverage.displayStatusType = 1; //Shows that this coverage was added through add T10

  coverage.planCode = planCode;
  coverage.prodID = aPolicy.prodID;

  coverage.issueDate = aPolicy.illustrationDate;
  coverage.issueAge = insuredAge;

  coverage.name = name;
  //coverage.Name = TextLookup_PolicyName(coverage.planCode);

  coverage.meRating = insuredRating;
  coverage.coiType = COITypes.YRT;

  coverage.unitValue = 1000;
  coverage.units = faceAmount / coverage.unitValue;
  coverage.faceAmount = faceAmount;
  coverage.initialFaceAmount = coverage.faceAmount;
  coverage.meRatingDuration = coverage.expiryDuration;
  coverage.meRatingExpiryDate = coverage.expiryDate;
  coverage.flatExtraDuration = insuredFlatExtraDuration;
  coverage.flatExtra = insuredFlatExtra;
  coverage.isUseRatings = insuredRatings;

  coverage.issuedUnits = coverage.units;
  coverage.issuedUnitValue = coverage.unitValue;
  coverage.issuedFaceAmount = coverage.faceAmount;

  coverage.premiumPayMode = baseCoverage.premiumPayMode;
  coverage.premiumMode = baseCoverage.premiumMode;

  let insured = coverage.insureds[0];
  //insured = cloneDeep(baseCoverage.insured);
  //insured.attainedAge = baseCoverage.insured.attainedAge;
  //insured.category = baseCoverage.insured.category;
  //insured.clientID = baseCoverage.insured.clientID;
  //insured.duration = baseCoverage.insured.duration;
  //insured.gender = baseCoverage.insured.gender;
  //insured.insuredType = baseCoverage.insured.insuredType;
  insured.issueAge = insuredAge;
  insured.rateClass = insuredRateClass;
  if (insured.rateClass === '') {
    insured.rateClass = 'S';
    //} else {
    //    insured.rateClass = baseCoverage.insured.rateClass;
  }

  //insured.smokeClass = baseCoverage.insured.smokeClass;
  insured.smokeClass = insuredSmokingClass;
  insured.gender = insuredSex;
  insured.name = insuredName;
  insured.firstName = '';
  insured.lastName = '';

  //if ((baseCoverage.insured.smokeClass === "C") || (baseCoverage.insured.smokeClass === "")) {
  //    insured.smokeClass = "N";
  //} else {
  //    insured.smokeClass = baseCoverage.insured.smokeClass;
  //}

  //if (isInforce) {
  //insured.sIN = baseCoverage.insured.sIN;
  //}

  coverage.insured = insured;
  //let insureds = [];
  //insureds.push(insured);
  //coverage.insureds = insureds;

  coverage.premiumRateData = null;
  coverage.maxPremiumRateData = null;
  coverage.cashValueRateData = null;
  coverage.dividendRateData = null;
  coverage.ncpiRateData = null;
  coverage.nprRateData = null;
  coverage.coiyrtRateData = null;
  coverage.coiLevelRateData = null;
  coverage.etpyrtRateData = null;
  coverage.etpLevelRateData = null;
  coverage.rpuRateData = null;
  coverage.policyFeeRateData = null;
  coverage.surrenderChargeRateData = null;
  coverage.guaranteedCOIRateData = null;
  coverage.meRateData = null;
  coverage.deathBenefitRateData = null;

  let funds = [];
  coverage.funds = funds;

  //We do not want to clear out all coverages
  //for now we will keep waiver if it exists
  let coverages = [];
  globalVariables.isAddRiderWP = false;
  for (let aCoverageItem of coverage.coverages) {
    if (aCoverageItem.coverageType === CoverageTypes.BENEFIT && aCoverageItem.riderType === RiderTypes.WAIVER) {
      let newCoverage = cloneDeep(aCoverageItem);
      globalVariables.isAddRiderWP = true;
      newCoverage.issueDate = coverage.issueDate;
      newCoverage.planCode = coverage.planCode;
      newCoverage.prodID = coverage.prodID;

      let newInsured = newCoverage.insureds[0];
      newInsured = coverage.insured;

      //newInsured.issueAge = insuredAge;
      //newInsured.rateClass = insuredRateClass;
      //if (newInsured.rateClass === "") {
      //    newInsured.rateClass = "S";
      //}

            //newInsured.smokeClass = insuredSmokingClass;
            //newInsured.gender = insuredSex;
            newCoverage.insured = newInsured;

      newCoverage.premiumRateData = null;
      newCoverage.maxPremiumRateData = null;
      newCoverage.cashValueRateData = null;
      newCoverage.dividendRateData = null;
      newCoverage.ncpiRateData = null;
      newCoverage.nprRateData = null;
      newCoverage.coiyrtRateData = null;
      newCoverage.coiLevelRateData = null;
      newCoverage.etpyrtRateData = null;
      newCoverage.etpLevelRateData = null;
      newCoverage.rpuRateData = null;
      newCoverage.policyFeeRateData = null;
      newCoverage.surrenderChargeRateData = null;
      newCoverage.guaranteedCOIRateData = null;
      newCoverage.meRateData = null;
      newCoverage.deathBenefitRateData = null;
      newCoverage.insured = newInsured;
      coverages.push(newCoverage);
    }
  }

  coverage.coverages = coverages;

  let productSetup = {};
  productSetup.name = 'T10';
  productSetup.PolicyType = 'None';

  let fundItems = [];
  productSetup.fundItems = fundItems;

  coverage.productSetup = productSetup;
  if (!isInforce) {
    //--------  RUN GET SETUP --------------
    console.log('before runGetSetup');
    coverage = await runGetSetup(coverage);
    console.log('after runGetSetup');
  }
  //--------------------------------------

    if (isInforce) {
        coverage.coverageType = CoverageTypes.RIDER;
        coverage.riderType = RiderTypes.TERM;
        coverage.insured = splitName(coverage.insured, insuredName);
        coverage.insureds[0] = splitName(coverage.insureds[0], insuredName);
        fillInforceCoverage(coverage, baseCoverage);
        for (let aCoverageItem of coverage.coverages) {
            fillInforceCoverage(aCoverageItem, baseCoverage);
            aCoverageItem.insured = splitName(aCoverageItem.insured, insuredName);
            aCoverageItem.insureds[0] = splitName(aCoverageItem.insureds[0], insuredName);
        }
    } else {
        //----- Handle coverageLayers ----- 
        let coverageLayers = [];
        console.log('coverage layer 1');
        console.log('coverage.coverageLayers', coverage.coverageLayers);
        if (coverage.coverageLayers === null) {
            console.log('coverage layer null');
        } else {
            console.log('coverage layer 2');
            if (coverage.coverageLayers.length > 0) {
                console.log('coverage layer 3');
                console.log('coverage.coverageLayers[0] = ', coverage.coverageLayers[0]);
                let newCoverageLayer = cloneDeep(coverage.coverageLayers[0]);
                newCoverageLayer.coverageID = coverageID;
                newCoverageLayer.coverageLayerType = 1;
                newCoverageLayer.faceAmount = faceAmount;
                newCoverageLayer.etpFaceAmount = faceAmount;
                newCoverageLayer.basisDate = coverage.issueDate;
                newCoverageLayer.durationStart = 1;
                newCoverageLayer.etpyrtRateData.insured = coverage.insured;
                newCoverageLayer.etpLevelRateData.insured = coverage.insured;
                newCoverageLayer.insured = coverage.insured;
                coverageLayers.push(newCoverageLayer);
                coverage.coverageLayers = coverageLayers;
            }
            console.log('coverage layer 4');
        }
        console.log('coverage layer 5');
    }

  let illustrationDate = new Date(aPolicy.illustrationDate);
  let issueDate = new Date(coverage.issueDate);

  coverage.expiryDate = new Date(
    issueDate.getFullYear() + coverage.expiryAge - coverage.insured.attainedAge,
    issueDate.getMonth(),
    issueDate.getDate()
  );
  //coverage.expiryDate = DateAdd(DateInterval.Year, coverage.expiryAge - coverage.insured.attainedAge, coverage.IssueDate)

  //-----------CalculateDurationInformation
  //Need to consider off-anniversary policies

  if (illustrationDate.getMonth() === issueDate.getMonth()) {
    coverage.durationStart = illustrationDate.getFullYear() - issueDate.getFullYear() + 1;
  } else {
    //Use the Calculate Age Last method to get the Duration Start for off-anniversary policies
    coverage.durationStart = illustrationDate.getFullYear() - issueDate.getFullYear() + 1;
    //coverage.durationStart = CalcAge(aCoverageItem.IssueDate, aPolicy.IllustrationDate, -1) + 1
  }

  //This is the expiry Duration assuming the illustration date is duration 1
  coverage.expiryDuration = coverage.expiryDate.getFullYear() - issueDate.getFullYear() + 1 - coverage.durationStart;
  coverage.expiryAge = coverage.issueAge + coverage.expiryDate.getFullYear() - issueDate.getFullYear();
  console.log('AdT10 coverage', coverage);
  aPolicy.coverages.push(coverage);
  console.log(strInforce + 'policy at end of AddT10', aPolicy);
  return aPolicy;
}

function fillInforceCoverage(coverage, baseCoverage) {
  coverage.displayStatusType = 1; //Shows that this coverage was added through add T10
  coverage.insured.currentAttainedAge = coverage.insured.attainedAge;
  coverage.insureds[0].currentAttainedAge = coverage.insureds[0].attainedAge;
  coverage.premCoverage = 0;
  coverage.premFlatExtra = 0;
  coverage.premMultipleExtra = 0;
  coverage.policyFee = 0;
  coverage.premWaiver = 0;
  coverage.premADB = 0;
  coverage.premAnnual = 0;
  coverage.cashValue = 0;
  coverage.modePremiumAmount = 0;

  coverage.beneficiaries = baseCoverage.beneficiaries;
  coverage.coverageStatus = 'Active Coverage';
}

function splitName(insured, name) {
  let nameArr = name.split(' ');
  insured.name = name;
  insured.middleName = '';

  if (nameArr.length > 1) {
    insured.lastName = nameArr.pop();
    insured.firstName = nameArr.join(' ');
  } else {
    insured.firstName = nameArr[0];
    //insured.lastName = nameArr[nameArr.length - 1];
    insured.lastName = '';
  }

  // return the copy
  return insured;
}

async function runGetSetup(coverage) {
  console.log('runGetSetup coverage', coverage);
  try {
    let res = await axios.post('/api/GetCoverageSetup', coverage);
    console.log(res.data);
    return res.data;
  } catch (error) {
    if (error.response) {
      console.log(error.responderEnd);
    }
  }
}

export function populateTree(inforcePolicy, t) {
  let data = {};
  data.title = '';
  data.id = 1;
  data.displayStatusType = 0;
  treeIndex = 0;
  let tree = new Tree(data);
  displayArray = [];
  policyTree = [];
  treeIndex++;
  switch (inforcePolicy.policyType) {
    case PolicyTypes.WholeLife:
    case PolicyTypes.DecreasingIncreasingTerm:
    case PolicyTypes.Endowment:
    case PolicyTypes.Enhanced:
    case PolicyTypes.LevelTerm:
    case PolicyTypes.PaidUpLife:
    case PolicyTypes.TermWithCV:
    case PolicyTypes.Disability:
    case PolicyTypes.CriticalIllness:
      addParLifeOwnerBranch(inforcePolicy, tree.root, t);
      break;
    case PolicyTypes.UL:
      addParLifeOwnerBranch(inforcePolicy, tree.root, t);
      break;
    case PolicyTypes.DeferredAnnuity:
    case PolicyTypes.ImmediateAnnuity:
      addParLifeOwnerBranch(inforcePolicy, tree.root, t);
      break;
    case PolicyTypes.RRIF:
    case PolicyTypes.ESA_ISA:
      addFundOwnerBranch(inforcePolicy, tree.root, t);
      break;
    case PolicyTypes.SegregatedFund:
    case PolicyTypes.SegregatedFundRRIF:
      addSegFundOwnerBranch(inforcePolicy, tree.root, t);
      break;
    default:
      break;
  }

  treeIndex++;
  addClientBranch(inforcePolicy, tree.root, t);
  treeIndex++;

  switch (inforcePolicy.policyType) {
    case PolicyTypes.WholeLife:
    case PolicyTypes.DecreasingIncreasingTerm:
    case PolicyTypes.Endowment:
    case PolicyTypes.Enhanced:
    case PolicyTypes.LevelTerm:
    case PolicyTypes.PaidUpLife:
    case PolicyTypes.TermWithCV:
    case PolicyTypes.Disability:
    case PolicyTypes.CriticalIllness:
      addParLifeBranches(inforcePolicy, tree.root, t);
      break;
    case PolicyTypes.UL:
      addULLifeBranches(inforcePolicy, tree.root, t);
      break;
    case PolicyTypes.DeferredAnnuity:
    case PolicyTypes.ImmediateAnnuity:
      addParLifeBranches(inforcePolicy, tree.root, t);
      break;
    case PolicyTypes.ESA_ISA:
    case PolicyTypes.SegregatedFund:
      addFundBranches(inforcePolicy, tree.root, t);
      break;
    case PolicyTypes.RRIF:
    case PolicyTypes.SegregatedFundRRIF:
      addFundBranches(inforcePolicy, tree.root, t);
      addPayoutHeaderBranch(inforcePolicy, tree.root, t);
      break;
    default:
      break;
  }

  policyTree.push(tree.root);
  return {policyTree, displayArray};
}
function addPayoutHeaderBranch(inforcePolicy, parentNode, t) {
  let data = {};
  data.expanded = true;
  data.head = true;
  let node;
  let strRRIFCode = Translate_RRIF_Code(inforcePolicy.prodID);
  data.title = TextLookup_ProductName(strRRIFCode, t);
  node = new Node(data, treeIndex);
  parentNode.children.push(node);
  displayArray[treeIndex] = [];
  treeIndex++;
  addPayoutBranch(inforcePolicy, node, t);
  treeIndex++;
}
function addPayoutBranch(inforcePolicy, parentNode, t) {
  let data = {};
  data.expanded = true;
  data.title = t('Payout Information');
  let node = new Node(data, treeIndex);
  let insuredItem = inforcePolicy.insured;
  let policyOwner = inforcePolicy.owners[0];
  let display = [];
  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  let insuredPolicyPayor = '';
  parentNode.children.push(node);
  globalBgColor = styleTypes.WHITE;
  if (inforcePolicy.fundsLockedIn) {
    display.push([t('LIF Information'), '', styleTypes.HEAD]);
  } else {
    display.push([t('RRIF Information'), '', styleTypes.HEAD]);
  }
  display.push([t('Plan Name'), baseCoverageItem.name, styleTypes.BOLD + getCurrentBgColor()]);
  display.push([t('Plan Identification'), baseCoverageItem.planCode, styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Issue Date'), format_Display_Date(inforcePolicy.issueDate), styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Payout Frequency'), TextLookup_PayoutFrequency(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
  if (inforcePolicy.payoutMethod === BillingMethodTypes.EFT) {
    display.push([
      t('Payout Method'),
      toProperCase(TextLookup_PayoutMethodCode(inforcePolicy.payoutMethodCode, t)),
      styleTypes.BOLD + toggleBgColor(),
    ]);

    if (inforcePolicy.payor) {
      insuredPolicyPayor = inforcePolicy.payor;
    } else {
      insuredPolicyPayor = policyOwner;
    }
    display.push([t('Bank Deposit Information'), `${insuredPolicyPayor.bankName} ${insuredPolicyPayor.bankNo}`, styleTypes.BOLD + toggleBgColor()]);
    display.push([
      '',
      ` ${t('Branch')} ${insuredPolicyPayor.bankBranchNo}  ${t('PCA Acct')} ${insuredPolicyPayor.bankAccountNo} `,
      styleTypes.BOLD + getCurrentBgColor(),
    ]);
    display.push(['', ` ${t('Depositor(s)')} ${insuredPolicyPayor.bankAccountHolderName}`, styleTypes.BOLD + getCurrentBgColor()]);
  } else {
    display.push([
      t('Payout Method'),
      toProperCase(TextLookup_PayoutMethodCode(inforcePolicy.payoutMethodCode, t)),
      styleTypes.BOLD + toggleBgColor(),
    ]);
  }
  console.log(`t("Gross Payout Amount") = ${t('Gross Payout Amount')}`);
  display.push([
    t('Gross Payout Amount'),
    formatIsAmountAboveZero(
      inforcePolicy.annualPayoutAmount,
      `${formatDollar(inforcePolicy.annualPayoutAmount)}   (${t('Annualized')} ${formatDollar(
        inforcePolicy.annualPayoutAmount * modalFactor(inforcePolicy.annualPayoutMode)
      )})`
    ),
    styleTypes.BOLD + toggleBgColor(),
  ]);
  display.push([t('Tax Withheld'), formatDollar(inforcePolicy.payoutTaxWithheld), styleTypes.BOLD + toggleBgColor()]);
  display.push([
    t('Tax Calculation Percent'),
    formatAllocationPercent(inforcePolicy.payoutTaxCalculationPercent / 100),
    styleTypes.BOLD + toggleBgColor(),
  ]);
  display.push([t('Additional Tax Withheld'), formatDollar(inforcePolicy.payoutAdditionalTaxWithheld), styleTypes.BOLD + toggleBgColor()]);
  display.push([
    t('Net Payout Amount'),
    `${formatDollar(inforcePolicy.annualPayoutAmount - inforcePolicy.payoutTaxWithheld - inforcePolicy.payoutAdditionalTaxWithheld)}   (${t(
      'Annualized'
    )} ${formatDollar(
      (inforcePolicy.annualPayoutAmount - inforcePolicy.payoutTaxWithheld - inforcePolicy.payoutAdditionalTaxWithheld) *
        modalFactor(inforcePolicy.annualPayoutMode)
    )})`,
    styleTypes.BOLD + toggleBgColor(),
  ]);
  display.push([
    t('Next Payment Date'),
    Translate_NextPaymentDate(inforcePolicy.payoutPaymentDate, inforcePolicy.payoutEffectiveDate, inforcePolicy.currentDate),
    styleTypes.BOLD + toggleBgColor(),
  ]);

  let intAge = calculateAgeFromJan1(inforcePolicy.payoutInsured, inforcePolicy.currentDate);

  if (inforcePolicy.payoutInsuredType === PayoutInsuredTypes.Annuitant) {
    display.push([t('Minimum Payout Based On'), `${t('Annuitant')}, aged ${intAge.toString()}`, styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Minimum Payout Based On'), `${t('Spouse, aged')} ${intAge.toString()}`, styleTypes.BOLD + toggleBgColor()]);
  }

  display.push([t('Payout Type'), TextLookup_PayoutType(inforcePolicy.payoutType, t), styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Minimum Payout this Year'), formatDollar(inforcePolicy.minPayoutThisYear), styleTypes.BOLD + toggleBgColor()]);
  if (inforcePolicy.fundsLockedIn) {
    if (inforcePolicy.maxPayoutThisYear < 100000 && inforcePolicy.maxPayoutThisYear > 0) {
      display.push([t('Maximum Payout this Year'), formatDollar(inforcePolicy.maxPayoutThisYear), styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push([t('Maximum Payout this Year'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
    }
    display.push([t('Funds are Locked In'), t('Yes'), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Funds are Locked In'), t('No'), styleTypes.BOLD + toggleBgColor()]);
  }
  if (inforcePolicy.isActive) {
    display.push([t('Maturity/Expiry Date'), format_Display_Date(inforcePolicy.expiryDate), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Maturity/Expiry Date'), format_Display_Date(inforcePolicy.terminatedDate), styleTypes.BOLD + toggleBgColor()]);
  }
  displayArray[treeIndex] = display;
}
function addParLifeBranches(inforcePolicy, parentNode, t) {
  addBasicCoverageBranch(inforcePolicy, parentNode, t);
  addTermRidersBranch(inforcePolicy, parentNode, t);
  addUnembeddedSupplementaryBenefitsBranch(inforcePolicy, parentNode, t);
}
function addULLifeBranches(inforcePolicy, parentNode, t) {
  addBasicCoverageBranch(inforcePolicy, parentNode, t);
  addULIncreaseRidersBranch(inforcePolicy, parentNode, t);
  addTermRidersBranch(inforcePolicy, parentNode, t);
  addUnembeddedSupplementaryBenefitsBranch(inforcePolicy, parentNode, t);

  if (IsProdIDFlexLife2(inforcePolicy.prodID)) {
    addDIABranch1(inforcePolicy, parentNode, TextLookup_AccountType('DIA', t), t);
    addSegFundBranch1(inforcePolicy, parentNode, TextLookup_AccountType('SEGFUND', t), t);
    addGIABranch1(inforcePolicy, parentNode, TextLookup_AccountType('GIA', t), t);
    if (inforcePolicy.isLoanOnPolicy) {
      addLoanBranch(inforcePolicy, parentNode, t('Loan Collateral Fund'), t);
    }
  }
}

function addFundBranches(inforcePolicy, parentNode, t) {
  if (inforcePolicy.policyType === PolicyTypes.SegregatedFund || inforcePolicy.policyType === PolicyTypes.SegregatedFundRRIF) {
    addSegFundBranch(inforcePolicy, parentNode, t);
  } else {
    addDIABranch(inforcePolicy, parentNode, t);
    addGIABranch(inforcePolicy, parentNode, t);
  }
}
function addDIABranch(inforcePolicy, parentNode, t) {
  addDIABranch1(inforcePolicy, parentNode, TextLookup_PolicyName(inforcePolicy.planCode, t), t);
}
//Doubt
function addDIABranch1(inforcePolicy, parentNode, strNodeText, t) {
  let data = {};
  data.expanded = true;
  if (isInPayout(inforcePolicy)) {
    data.title = strNodeText + t('(In Payout)');
  } else {
    data.title = strNodeText;
  }
  let fundCoverageNode;
  let node = new Node(data, treeIndex);
  parentNode.children.push(node);
  displayArray[treeIndex] = [];
  treeIndex++;
  if (!isInPayout(inforcePolicy)) {
    let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
    for (let fundItem of baseCoverageItem.funds) {
      if (fundItem.isActive && fundItem.fundType === FundTypes.DIA && !isFundLoan(fundItem.fundID)) {
        fundCoverageNode = addFundBranch(inforcePolicy, baseCoverageItem, fundItem, node, t);
        treeIndex++;
      }
    }
  }
}
function addGIABranch(inforcePolicy, parentNode, t) {
  let strText;
  if (inforcePolicy.policyType === PolicyTypes.RRIF) {
    strText = t('GIA - RRIF');
  } else {
    strText = t('GIA - ISA');
  }
  addGIABranch1(inforcePolicy, parentNode, strText, t);
}
function addGIABranch1(inforcePolicy, parentNode, strNodeText, t) {
  let data = {};
  data.expanded = true;
  data.title = strNodeText;
  let node = new Node(data, treeIndex);
  let fundCoverageNode;
  let policyOwner = inforcePolicy.owners[0];
  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  parentNode.children.push(node);
  displayArray[treeIndex] = [];
  treeIndex++;
  for (let fundItem of baseCoverageItem.funds) {
    if (fundItem.isActive && fundItem.fundType === FundTypes.GIA) {
      fundCoverageNode = addFundBranch(inforcePolicy, baseCoverageItem, fundItem, node, t);
      treeIndex++;
    }
  }
}
function addLoanBranch(inforcePolicy, parentNode, strNodeText, t) {
  let data = {};
  data.expanded = true;
  data.title = strNodeText;
  let fundCoverageNode;
  let termRidersNode = new Node(data, treeIndex);
  parentNode.children.push(termRidersNode);
  displayArray[treeIndex] = [];
  treeIndex++;

  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);

  for (let fundItem of baseCoverageItem.funds) {
    if (fundItem.isActive && fundItem.fundType === FundTypes.DIA && isFundLoan(fundItem.fundID)) {
      fundCoverageNode = addFundBranch(inforcePolicy, baseCoverageItem, fundItem, termRidersNode, t);
      treeIndex++;
    }
  }
}
function addSegFundBranch(inforcePolicy, parentNode, t) {
  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  addSegFundBranch1(inforcePolicy, parentNode, TextLookup_ProductName(baseCoverageItem.planCode, t), t);
  //addSegFundBranch1(inforcePolicy, parentNode, baseCoverageItem.productSetup.name, t);
}
function addSegFundBranch1(inforcePolicy, parentNode, strNodeText, t) {
  let data = {};
  let fundCoverageNode;
  data.expanded = true;
  data.head = true;
  if (isInPayout(inforcePolicy)) {
    data.title = strNodeText + t('(In Payout)');
  } else {
    data.title = strNodeText;
  }
  let termRidersNode = new Node(data, treeIndex);
  parentNode.children.push(termRidersNode);
  displayArray[treeIndex] = [];
  treeIndex++;

  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  if (inforcePolicy.isActive) {
    for (let fundItem of baseCoverageItem.funds) {
      if (fundItem.isActive && fundItem.fundType === FundTypes.SegFund) {
        fundCoverageNode = addSegFundBranch2(inforcePolicy, baseCoverageItem, fundItem, termRidersNode, t);
        treeIndex++;
      }
    }
  }
}
function addFundBranch(inforcePolicy, baseCoverageItem, fundItem, parentNode, t) {
  let display = [];
  let insuredItem = baseCoverageItem.insured;
  globalBgColor = styleTypes.WHITE;
  display.push([t('Deposit Information'), '', styleTypes.HEAD]);
  //display.push(["", "", styleTypes.BOLD+styleTypes.WHITE]);
  display.push([t('Deposit Type'), fundItem.fundName, styleTypes.BOLD + getCurrentBgColor()]);
  display.push([t('Deposit Identification'), fundItem.fundID, styleTypes.BOLD + toggleBgColor()]);
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  display.push([`${t('Annuitant')}:`, `${fullName(insuredItem)},   SIN# ${formatSIN(insuredItem.sin)}`, styleTypes.BOLD + toggleBgColor()]);
  if (fundItem.fundType === FundTypes.DIA) {
    display.push([t('Deposit Date'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Deposit Age'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Deposit Date'), format_Display_Date(fundItem.interestStartDate), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Deposit Age'), fundItem.depositAge.toString(), styleTypes.BOLD + toggleBgColor()]);
  }
  display.push([t('Sex'), TextLookup_Sex(insuredItem.gender, t), styleTypes.BOLD + toggleBgColor()]);
  display.push([
    t('Smoker Status'),
    TextLookup_SmokeClass(formatSmokeClass(insuredItem.smokeClass, insuredItem.issueAge), t),
    styleTypes.BOLD + toggleBgColor(),
  ]);
  display.push([t('Sub Table 1'), '', styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Sub Table 2'), '', styleTypes.BOLD + toggleBgColor()]);
  if (fundItem.fundType === FundTypes.DIA) {
    display.push([t('Cvg Attained Age'), insuredItem.currentAttainedAge.toString(), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Cvg Attained Age'), insuredItem.attainedAge.toString(), styleTypes.BOLD + toggleBgColor()]);
  }
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  if (fundItem.fundType === FundTypes.DIA) {
    display.push([t('Deposit Term'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Current Interest Rate'), formatInterestRate(fundItem.interestRate), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Deposit Amount'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Current Value'), formatDollar(fundItem.currentFundValue), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Maturity Value'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Maturity Date'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Deposit Term'), `${fundItem.depositTerm.toString()} ${t('months')}`, styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Guaranteed Interest Rate'), formatAllocationPercent(fundItem.interestRate * 100), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Deposit Amount'), formatDollar(fundItem.depositAmount), styleTypes.BOLD + toggleBgColor()]);
    display.push([
      t('Current Value'),
      `${formatDollar(fundItem.fundValue)}`,
      styleTypes.BOLD + toggleBgColor(),
      `${t('Mkt Value Adjust')}   ${formatDollar(fundItem.mva)}`,
    ]);
    display.push([t('Maturity Value'), formatDollar(fundItem.maturityValue), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Maturity Date'), format_Display_Date(fundItem.maturityDate), styleTypes.BOLD + toggleBgColor()]);
    display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
    display.push([t('Reinvestment Instructions'), t(fundItem.reInvestmentInstructions), styleTypes.BOLD + toggleBgColor()]);
  }
  let data = {};
  data.expanded = true;
  if (fundItem.fundType === FundTypes.DIA) {
    data.title = `${t('Value')}  ${formatDollar(fundItem.currentFundValue)}  ${t('Current Rate')} ${formatInterestRate(fundItem.interestRate)}`;
  } else {
    data.title = `${formatDollar(fundItem.fundValue)},  ${formatInterestRate(fundItem.interestRate)}    ${t(
      '(C) Matures'
    )}   ${format_Display_Date_Short(fundItem.maturityDate)}`;
  }
  parentNode.children.push(new Node(data, treeIndex));
  displayArray[treeIndex] = display;
}

function addSegFundBranch2(inforcePolicy, baseCoverageItem, fundItem, parentNode, t) {
  let data = {};
  data.expanded = true;
  data.title = t(fundItem.fundName);
  let owner = inforcePolicy.owners[0];
  parentNode.children.push(new Node(data, treeIndex));
  let display = [];
  let insuredItem = baseCoverageItem.insured;
  globalBgColor = styleTypes.WHITE;
  display.push([t('Segregated Fund Information'), '', styleTypes.HEAD]);
  //display.push(["", "", styleTypes.BOLD + styleTypes.WHITE]);
  display.push([t('Fund Name'), fundItem.fundName, styleTypes.BOLD + getCurrentBgColor()]);
  display.push([t('Fund Identification'), fundItem.fundID, styleTypes.BOLD + getCurrentBgColor()]);
  if (inforcePolicy.policyType === PolicyTypes.SegregatedFund || inforcePolicy.policyType === PolicyTypes.UL) {
    display.push([
      t('Deposit Allocation'),
      `${formatAllocationPercent(fundItem.depositAllocation.allocationPercent)} ${t('to this fund')}`,
      styleTypes.BOLD + toggleBgColor(),
    ]);
  } else {
    display.push([
      t('Payout Allocation'),
      `${formatAllocationPercent(fundItem.withdrawalAllocation.allocationPercent)} ${t('from this fund')}`,
      styleTypes.BOLD + toggleBgColor(),
    ]);
  }
  display.push([`${t('Annuitant')}:`, `${fullName(insuredItem)},   SIN# ${formatSIN(insuredItem.sin)}`, styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Sex'), TextLookup_Sex(insuredItem.gender, t), styleTypes.BOLD + toggleBgColor()]);
  display.push([
    t('Smoker Status'),
    TextLookup_SmokeClass(formatSmokeClass(insuredItem.smokeClass, insuredItem.issueAge), t),
    styleTypes.BOLD + toggleBgColor(),
  ]);
  display.push([t('Sub Table 1'), '', styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Sub Table 2'), '', styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Cvg Attained Age'), insuredItem.currentAttainedAge.toString(), styleTypes.BOLD + toggleBgColor()]);
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);

  display.push([t('Fund Valuation Date'), format_Display_Date(inforcePolicy.currentDate), styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Number of Units Held'), FormatnDecimals(fundItem.units, 4), styleTypes.BOLD + toggleBgColor()]); //format fundItem.units
  display.push([t('Unit Value'), '$' + FormatnDecimals(fundItem.unitPrice, 4), styleTypes.BOLD + toggleBgColor()]); //formatUnitValue
  display.push([t('Current Value'), formatDollar(fundItem.fundValue), styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Maturity Date'), format_Display_Date(fundItem.maturityDate), styleTypes.BOLD + toggleBgColor()]);

  displayArray[treeIndex] = display;
}

function addUnembeddedSupplementaryBenefitsBranch(inforcePolicy, parentNode, t) {
  let data = {};
  let riderRoot = {};
  data.expanded = true;
  data.head = true;
  data.title = t('Supplementary Benefits');
  let termRidersNode = new Node(data, treeIndex);
  parentNode.children.push(termRidersNode);
  displayArray[treeIndex] = [];
  treeIndex++;

  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  for (let coverageItem of inforcePolicy.coverages) {
    switch (coverageItem.riderType) {
      case RiderTypes.GPO:
      case RiderTypes.PIB:
      case RiderTypes.DMB:
      case RiderTypes.OTHER:
        riderRoot = addBaseCoverageBranch(inforcePolicy, coverageItem, termRidersNode, t);
        treeIndex++;
        if (coverageItem.riderType === RiderTypes.TERM) {
          addRiderCoveragesBranches(inforcePolicy, coverageItem, riderRoot, t);
        }
        break;
      default:
        break;
    }
  }
  for (let coverageItem of baseCoverageItem.coverages) {
    switch (coverageItem.riderType) {
      case RiderTypes.GPO:
      case RiderTypes.PIB:
      case RiderTypes.DMB:
      case RiderTypes.OTHER:
        riderRoot = addBaseCoverageBranch(inforcePolicy, coverageItem, termRidersNode, t);
        treeIndex++;
        break;
      default:
        break;
    }
  }
}
function addULIncreaseRidersBranch(inforcePolicy, parentNode, t) {
  let data = {};
  let riderRoot = {};
  data.expanded = true;
  data.head = true;
  data.title = t('U L Coverage Increase Riders');
  let termRidersNode = new Node(data, treeIndex);
  displayArray[treeIndex] = [];
  treeIndex++;
  parentNode.children.push(termRidersNode);

  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  for (let coverageItem of inforcePolicy.coverages) {
    if (coverageItem.riderType === RiderTypes.UL) {
      riderRoot = addBaseCoverageBranch(inforcePolicy, coverageItem, termRidersNode, t);

      treeIndex++;
      addRiderCoveragesBranches(inforcePolicy, coverageItem, riderRoot, t);
    }
  }
  for (let coverageItem of baseCoverageItem.coverages) {
    if (coverageItem.riderType === RiderTypes.UL) {
      riderRoot = addBaseCoverageBranch(inforcePolicy, coverageItem, termRidersNode, t);

      treeIndex++;
      addRiderCoveragesBranches(inforcePolicy, coverageItem, riderRoot, t);
    }
  }
}

function addTermRidersBranch(inforcePolicy, parentNode, t) {
  let data = {};
  let riderRoot = {};
  data.expanded = true;
  data.head = true;
  data.title = t('Term Riders');
  let termRidersNode = new Node(data, treeIndex);
  displayArray[treeIndex] = [];
  treeIndex++;
  parentNode.children.push(termRidersNode);

  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  for (let coverageItem of inforcePolicy.coverages) {
    if (
      coverageItem.riderType === RiderTypes.CHILD ||
      coverageItem.riderType === RiderTypes.TERM ||
      coverageItem.riderType === RiderTypes.AIB ||
      coverageItem.riderType === RiderTypes.ADB ||
      coverageItem.coverageType === CoverageTypes.OYT
    ) {
      riderRoot = addBaseCoverageBranch(inforcePolicy, coverageItem, termRidersNode, t);
      treeIndex++;
      if (coverageItem.riderType === RiderTypes.TERM) {
        addRiderCoveragesBranches(inforcePolicy, coverageItem, riderRoot, t);
      }
    }
  }

  for (let coverageItem of baseCoverageItem.coverages) {
    if (
      coverageItem.riderType === RiderTypes.CHILD ||
      coverageItem.riderType === RiderTypes.TERM ||
      coverageItem.riderType === RiderTypes.AIB ||
      coverageItem.coverageType === CoverageTypes.OYT
    ) {
      riderRoot = addBaseCoverageBranch(inforcePolicy, coverageItem, termRidersNode, t);
      treeIndex++;
      if (coverageItem.riderType === RiderTypes.TERM) {
        addRiderCoveragesBranches(inforcePolicy, coverageItem, riderRoot, t);
      }
    }
  }
}

function addBasicCoverageBranch(inforcePolicy, parentNode, t) {
  let data = {};
  data.expanded = true;
  data.head = true;
  data.title = t('Basic Coverage');
  // data.displayStatusType = 0;
  let basicCoverageNode = new Node(data, treeIndex);
  displayArray[treeIndex] = [];
  treeIndex++;
  parentNode.children.push(basicCoverageNode);

  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  let coverageRoot = addBaseCoverageBranch(inforcePolicy, baseCoverageItem, basicCoverageNode, t);
  treeIndex++;
  addRiderCoveragesBranches(inforcePolicy, baseCoverageItem, coverageRoot, t);
}
//Recurse through coverages to add child coverages
function addRiderCoveragesBranches(inforcePolicy, baseCoverageItem, coverageRoot, t) {
  let riderNode = {};
  for (let coverageItem of baseCoverageItem.coverages) {
    if (coverageItem.riderType === RiderTypes.WAIVER || coverageItem.riderType === RiderTypes.ADB) {
      riderNode = addRiderCoverageBranch(inforcePolicy, coverageItem, coverageRoot, treeIndex, t);
      treeIndex++;
      if (coverageItem.coverages.length > 0) {
        addRiderCoveragesBranches(inforcePolicy, coverageItem, riderNode, t);
      }
    }
  }
}
function addRiderCoverageBranch(inforcePolicy, riderCoverageItem, coverageRoot, treeIndex, t) {
  let data = {};
  let aParentCoverage;
  data.expanded = true;
  data.title = t(riderCoverageItem.name) || 'error testing';
  data.displayStatusType = riderCoverageItem.displayStatusType;
  let node = new Node(data, treeIndex);
  let insuredItem = riderCoverageItem.insured;
  coverageRoot.children.push(node);
  let blnIsWPADBonUL =
    (riderCoverageItem.riderType === RiderTypes.WAIVER || riderCoverageItem.riderType === RiderTypes.ADB) &&
    inforcePolicy.policyType === PolicyTypes.UL;
  let display = [];
  globalBgColor = styleTypes.WHITE;
  display.push([data.title, '', styleTypes.POLICYTYPE]);
  display.push([t('Coverage Information'), '', styleTypes.HEAD]);
  //display.push(["", "", styleTypes.BOLD + styleTypes.WHITE]);
  display.push([t('Plan Name'), t(riderCoverageItem.name), styleTypes.BOLD + getCurrentBgColor()]);
  aParentCoverage = getCoverageByID(inforcePolicy.coverages, riderCoverageItem.parentCoverageID, '', true);
  if (
    aParentCoverage /*this is just for running code, else null pointer exception thrown */ &&
    riderCoverageItem.planCode === aParentCoverage.planCode // ParentCoverage is null, fix  - TODO
  ) {
    display.push([t('Plan Identification'), t('None'), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Plan Identification'), riderCoverageItem.planCode, styleTypes.BOLD + toggleBgColor()]);
  }
  display.push([t('Life Insured'), `${fullName(insuredItem)}, SIN# ${formatSIN(insuredItem.sin)}`, styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Issue Date'), `${format_Display_Date(riderCoverageItem.issueDate)}`, styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Issue Age'), `${insuredItem.issueAge}`, styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Sex'), TextLookup_Sex(insuredItem.gender, t), styleTypes.BOLD + toggleBgColor()]); //LOOKUP
  display.push([
    t('Smoker Status'),
    TextLookup_SmokeClass(formatSmokeClass(insuredItem.smokeClass, insuredItem.issueAge), t),
    styleTypes.BOLD + toggleBgColor(),
  ]);
  display.push([t('Sub Table 1'), `${translateSubTable1(riderCoverageItem, true, t)}`, styleTypes.BOLD + toggleBgColor()]);
  if (isProductHealth(riderCoverageItem.prodID)) {
    display.push([t('Sub Table 2'), `EP: ${translateSubTable2(riderCoverageItem, false, t)}`, styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Sub Table 3'), `BP: ${translateSubTable3(riderCoverageItem, false, t)}`, styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Sub Table 4'), `${translateSubTable4(riderCoverageItem, false, t)}`, styleTypes.BOLD + toggleBgColor()]);
  } else if (blnIsWPADBonUL) {
    display.push([t('Sub Table 2'), `${translateSubTable2(aParentCoverage, false, t)}`, styleTypes.BOLD + toggleBgColor()]);
    if (aParentCoverage.riderType === RiderTypes.UL || aParentCoverage.coverageType === CoverageTypes.BASE) {
      display.push([t('COI Type'), translateCOIType(aParentCoverage, t), styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
    }
    display.push([t('Death Benefit Type'), translateDeathBenefitType(aParentCoverage, inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
  }

  display.push([t('Cvg Attained Age'), insuredItem.currentAttainedAge, styleTypes.BOLD + toggleBgColor()]);
  let blnFirst = true;
  let strBeneficiary = '';
  let beneficiaryColor = toggleBgColor();
  for (let beneficiaryItem of riderCoverageItem.beneficiaries) {
    if (blnFirst) {
      strBeneficiary = t('Beneficiary');
      blnFirst = false;
    } else strBeneficiary = '';
    display.push([
      strBeneficiary,
      `${fullName(beneficiaryItem)}, ${t(beneficiaryItem.beneficiaryRelationship)}, ${formatAllocationPercent(beneficiaryItem.beneficiaryPercent)}`,
      styleTypes.BOLD + beneficiaryColor,
    ]);
  }
  display.push([t('Cash Value'), formatDollar(0), styleTypes.BOLD + toggleBgColor()]);

  if (riderCoverageItem.productSetup.isUseParentFaceAmount) {
    display.push([t('Face Amount'), t('Not Applicable'), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Face Amount'), formatDollar(riderCoverageItem.faceAmount), styleTypes.BOLD + toggleBgColor()]);
  }
  let strPremLabel = '';
  if (blnIsWPADBonUL) {
    strPremLabel = t('Last Monthly COI');
  } else {
    strPremLabel = `${t('Annual Premium')}:`;
  }
  if (isCoveragePaidUp(riderCoverageItem)) {
    display.push([strPremLabel, t('Not Applicable'), styleTypes.BOLD + toggleBgColor()]);
  } else {
    if (riderCoverageItem.isUseRatings && riderCoverageItem.meRating !== 0) {
      display.push([
        strPremLabel,
        `${formatDollar(riderCoverageItem.premAnnual)}       ${Format2Decimals(riderCoverageItem.meRating)} ${t('times standard')}`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
    } else {
      display.push([strPremLabel, formatDollar(riderCoverageItem.premCoverage), styleTypes.BOLD + toggleBgColor()]);
    }
  }
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  if (blnIsWPADBonUL) {
    display.push([t('Expiry Date'), format_Display_Date(riderCoverageItem.expiryDate), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Premium Frequency'), TextLookup_PremiumModePolicy(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
    if (isCoveragePaidUp(riderCoverageItem)) {
      display.push([t('Mode Premium Amount'), t('Not Applicable'), styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push([
        t('Mode Premium Amount'),
        `${formatDollar(PCRound(riderCoverageItem.modePremiumAmount, 2))}`,
        styleTypes.BOLD + toggleBgColor(),
        `${t('Mode Factor')} ${Format5Decimals(riderCoverageItem.modalFactor)}`,
      ]);
    }
    display.push([t('Maturity/Expiry Date'), format_Display_Date(riderCoverageItem.expiryDate), styleTypes.BOLD + toggleBgColor()]);
  }

  displayArray[treeIndex] = display;
  return node;
}

function addBaseCoverageBranch(inforcePolicy, baseCoverageItem, parentNode, t) {
  let data = {};
  data.expanded = true;
  data.title = t(baseCoverageItem.name) || 'error testing';
  data.displayStatusType = baseCoverageItem.displayStatusType;
  let node = new Node(data, treeIndex);
  parentNode.children.push(node);

  let display = [];
  let strTempInsuredNames = '';
  let insuredItem = {};
  let intInsuredMaxAge = 0;
  let strBeneficiary = '';
  let waiverCoverage = {};
  globalBgColor = styleTypes.WHITE;
  for (let tempInsuredItem of baseCoverageItem.insureds) {
    if (tempInsuredItem.issueAge >= intInsuredMaxAge) {
      insuredItem = tempInsuredItem;
      intInsuredMaxAge = tempInsuredItem.issueAge;
    }
    strTempInsuredNames += fullName(tempInsuredItem) + ', ';
  }
  insuredItem = baseCoverageItem.insured;

  //remove last comma
  strTempInsuredNames = strTempInsuredNames.substring(0, strTempInsuredNames.length - 2);
  display.push([data.title, '', styleTypes.POLICYTYPE]);
  display.push([t('Coverage Information'), '', styleTypes.HEAD]);
  //display.push(["", "", styleTypes.BOLD+styleTypes.WHITE]);
  display.push([t('Plan Name'), baseCoverageItem.name, styleTypes.BOLD + getCurrentBgColor()]);
  display.push([t('Plan Identification'), baseCoverageItem.planCode, styleTypes.BOLD + toggleBgColor()]);

  //console.log(` ${}`);
  if (baseCoverageItem.insureds.length > 1) {
    if (baseCoverageItem.riderType === RiderTypes.CHILD) {
      display.push([t('Lives Insured'), strTempInsuredNames, styleTypes.BOLD + toggleBgColor()]);
    } else {
      let strTemp = '';
      let intInsuredCounter = 0;
      for (let tempInsuredItem of baseCoverageItem.insureds) {
        strTemp = fullName(tempInsuredItem) + ', SIN# ' + formatSIN(tempInsuredItem.sin);
        //console.log(` ${}`);
        if (intInsuredCounter === 0) {
          display.push([t('Lives Insured'), strTemp, styleTypes.BOLD + toggleBgColor()]);
        } else {
          display.push(['', strTemp, styleTypes.BOLD + getCurrentBgColor()]);
        }
        intInsuredCounter++;
      }
    }
  } else {
    display.push([t('Life Insured'), `${fullName(insuredItem)}, SIN# ${formatSIN(insuredItem.sin)}`, styleTypes.BOLD + toggleBgColor()]);
  }

  display.push([t('Issue Date'), format_Display_Date(baseCoverageItem.issueDate), styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Issue Age'), insuredItem.issueAge, styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Sex'), TextLookup_Sex(insuredItem.gender, t), styleTypes.BOLD + toggleBgColor()]);
  display.push([
    t('Smoker Status'),
    TextLookup_SmokeClass(formatSmokeClass(insuredItem.smokeClass, insuredItem.issueAge), t),
    styleTypes.BOLD + toggleBgColor(),
  ]);
  display.push([t('Sub Table 1'), `${translateSubTable1(baseCoverageItem, true, t)}`, styleTypes.BOLD + toggleBgColor()]);

  if (isProductHealth(baseCoverageItem.prodID)) {
    display.push([t('Sub Table 2'), `EP: ${translateSubTable2(baseCoverageItem, false, t)}`, styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Sub Table 3'), `BP: ${translateSubTable3(baseCoverageItem, false, t)}`, styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Sub Table 4'), `${translateSubTable4(baseCoverageItem, false, t)}`, styleTypes.BOLD + toggleBgColor()]);
  } else if (inforcePolicy.policyType === PolicyTypes.UL) {
    display.push([t('Sub Table 2'), `${translateSubTable2(baseCoverageItem, false, t)}`, styleTypes.BOLD + toggleBgColor()]);
    if (baseCoverageItem.riderType === RiderTypes.NONE || baseCoverageItem.riderType === RiderTypes.UL) {
      display.push([t('COI Type'), `${translateCOIType(baseCoverageItem, t)}`, styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
    }
    display.push([t('Death Benefit Type'), `${translateDeathBenefitType(baseCoverageItem, inforcePolicy, t)}`, styleTypes.BOLD + toggleBgColor()]);
  }

  display.push([t('Cvg Attained Age'), insuredItem.currentAttainedAge, styleTypes.BOLD + toggleBgColor()]);
  let policyBaseCoverage = {};
  let blnFirst = false;
  if (baseCoverageItem.riderType !== RiderTypes.GPO) {
    if (baseCoverageItem.coverageType !== CoverageTypes.BASE && baseCoverageItem.beneficiaries.length === 0) {
      policyBaseCoverage = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);

      if (baseCoverageItem.insured.clientID === policyBaseCoverage.insured.clientID) {
        blnFirst = true;
        let beneficiaryColor = toggleBgColor();
        for (let beneficiaryItem of policyBaseCoverage.beneficiaries) {
          if (blnFirst) {
            strBeneficiary = t('Beneficiary');
            blnFirst = false;
          } else {
            strBeneficiary = '';
          }
          display.push([
            strBeneficiary,
            `${fullName(beneficiaryItem)}, ${t(beneficiaryItem.beneficiaryRelationship)}, ${formatAllocationPercent(
              beneficiaryItem.beneficiaryPercent
            )}`,
            styleTypes.BOLD + beneficiaryColor,
          ]);
        }
      }
    } else {
      blnFirst = true;
      let beneficiaryColor = toggleBgColor();
      for (let beneficiaryItem of baseCoverageItem.beneficiaries) {
        if (blnFirst) {
          strBeneficiary = t('Beneficiary');
          blnFirst = false;
        } else {
          strBeneficiary = '';
        }
        display.push([
          strBeneficiary,
          `${fullName(beneficiaryItem)}, ${t(beneficiaryItem.beneficiaryRelationship)}, ${formatAllocationPercent(
            beneficiaryItem.beneficiaryPercent
          )}`,
          styleTypes.BOLD + beneficiaryColor,
        ]);
      }
    }
  }
  let strTemp = '';
  let strTemp2 = '';
  if (inforcePolicy.policyType === PolicyTypes.UL) {
    display.push([t('Investment Value'), formatDollar(baseCoverageItem.cashValue), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Face Amount'), formatDollar(baseCoverageItem.faceAmount), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Last Monthly COI'), '', styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Coverages'), formatDollar(baseCoverageItem.premCoverage), styleTypes.INDENT + getCurrentBgColor()]);
    if (baseCoverageItem.flatExtra > 0) {
      if (baseCoverageItem.flatExtraDuration === 999) {
        strTemp = t('for life');
      } else {
        strTemp = `${t('for')} ${baseCoverageItem.feRatingDurationFromIssue.toString()} ${t('years')}`;
      }
    } else {
      strTemp = '';
    }
    display.push([t('Flat Extra'), `${formatDollar(baseCoverageItem.premFlatExtra)}   ${strTemp}`, styleTypes.INDENT + getCurrentBgColor()]);
    if (baseCoverageItem.meRatingDurationFromIssue) {
      display.push([
        t('Multiple Ex'),
        `${formatDollar(baseCoverageItem.premMultipleExtra)}     ${t('for')} ${baseCoverageItem.meRatingDurationFromIssue.toString()} ${t('years')}`,
        styleTypes.INDENT + getCurrentBgColor(),
      ]);
    } else {
      display.push([t('Multiple Ex'), `${formatDollar(baseCoverageItem.premMultipleExtra)}`, styleTypes.INDENT + getCurrentBgColor()]);
    }
    display.push([t('Load Fee'), formatDollar(baseCoverageItem.policyFee), styleTypes.INDENT + toggleBgColor()]);
    if (baseCoverageItem.premWaiver > 0) {
      waiverCoverage = getCoverageItemByRiderType(baseCoverageItem.coverages, CoverageTypes.BENEFIT, RiderTypes.WAIVER);
      if (waiverCoverage.isUseRatings && waiverCoverage.meRating !== 1) {
        display.push([
          t('Waiver'),
          `${formatDollar(baseCoverageItem.premWaiver)}      ${Format2Decimals(waiverCoverage.meRatingExpiryDate)} ${t('times standard')}`,
          styleTypes.INDENT + getCurrentBgColor(),
        ]);
      } else {
        display.push([t('Waiver'), `${formatDollar(baseCoverageItem.premWaiver)}`, styleTypes.INDENT + getCurrentBgColor()]);
      }
    } else {
      display.push([t('Waiver'), formatDollar(baseCoverageItem.premWaiver), styleTypes.INDENT + getCurrentBgColor()]);
    }
    display.push([t('ADB'), formatDollar(baseCoverageItem.premADB), styleTypes.INDENT + getCurrentBgColor()]);
    display.push([t('Total'), formatDollar(baseCoverageItem.modePremiumAmount), styleTypes.INDENT + getCurrentBgColor()]);
  } else {
    display.push([t('Cash Value'), formatDollar(baseCoverageItem.cashValue), styleTypes.BOLD + toggleBgColor()]);
    if (baseCoverageItem.coverageType === CoverageTypes.OYT) {
      display.push([
        t('Face Amount'),
        `${formatDollar(inforcePolicy.dividendEnhancedAmount)}   ${t('Sum Insured')}:    ${formatDollar(baseCoverageItem.faceAmount)}`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
    } else if (
      // baseCoverageItem.policy.policyType === PolicyTypes.ImmediateAnnuity ||
      // baseCoverageItem.policy.policyType === PolicyTypes.DeferredAnnuity
      inforcePolicy.policyType === PolicyTypes.ImmediateAnnuity ||
      inforcePolicy.policyType === PolicyTypes.DeferredAnnuity
    ) {
      display.push([
        t('Number of Units'),
        `${baseCoverageItem.units.toString()}   ${t('Sum Insured')}:    ${formatDollar(baseCoverageItem.sumInsured)}`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
    } else if (
      (baseCoverageItem.isUseDeathBenefitRateTable || isProdIDSOSBAEndowment(baseCoverageItem.prodID)) &&
      baseCoverageItem.faceAmount !== baseCoverageItem.sumInsured
    ) {
      display.push([
        t('Face Amount'),
        `${formatDollar(baseCoverageItem.faceAmount)}   ${t('Sum Insured')}:    ${formatDollar(baseCoverageItem.sumInsured)}`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
    } else {
      display.push([t('Face Amount'), `${formatDollar(baseCoverageItem.faceAmount)}`, styleTypes.BOLD + toggleBgColor()]);
    }
    if (inforcePolicy.isRestrictPremium) {
      strTemp = t('Future Calculation Restricted');
    } else {
      strTemp = '';
    }
    if (isCoveragePaidUp(baseCoverageItem)) {
      display.push([t('Annual prem'), t('Not Applicable'), styleTypes.BOLD + toggleBgColor()]);
      display.push(['', '', styleTypes.EMPTY + toggleBgColor()]);
      display.push(['', '', styleTypes.EMPTY + toggleBgColor()]);
      display.push(['', '', styleTypes.EMPTY + toggleBgColor()]);
      display.push(['', '', styleTypes.EMPTY + toggleBgColor()]);
      display.push(['', '', styleTypes.EMPTY + toggleBgColor()]);
      display.push(['', '', styleTypes.EMPTY + toggleBgColor()]);
      display.push([t('Premium Frequency'), TextLookup_PremiumModePolicy(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
      display.push([t('Mode Premium Amount'), t('Not Applicable'), styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push([`${t('Annual Premium')}:`, '', styleTypes.BOLD + toggleBgColor()]);
      display.push([t('Coverage'), `${formatDollar(baseCoverageItem.premCoverage)} ${strTemp}`, styleTypes.INDENT + getCurrentBgColor()]);
      if (baseCoverageItem.flatExtra > 0) {
        if (baseCoverageItem.flatExtraDuration === 999) {
          strTemp = t('for life');
        } else {
          strTemp = `${t('for')} ${baseCoverageItem.feRatingDurationFromIssue.toString()}`;
        }
      } else {
        strTemp = '';
      }

      display.push([t('Flat Extra'), `${formatDollar(baseCoverageItem.premFlatExtra)}    ${strTemp}`, styleTypes.INDENT + getCurrentBgColor()]);

      if (baseCoverageItem.meRatingDurationFromIssue > 0) {
        display.push([
          t('Multiple Ex'),
          `${formatDollar(baseCoverageItem.premMultipleExtra)}     ${t('for')} ${baseCoverageItem.meRatingDurationFromIssue.toString()} ${t(
            'years'
          )}`,
          styleTypes.INDENT + getCurrentBgColor(),
        ]);
      } else {
        display.push([t('Multiple Ex'), `${formatDollar(baseCoverageItem.premMultipleExtra)} `, styleTypes.INDENT + getCurrentBgColor()]);
      }
      display.push([t('Policy Fee'), `${formatDollar(baseCoverageItem.policyFee)}`, styleTypes.INDENT + getCurrentBgColor()]);

      if (baseCoverageItem.premWaiver > 0) {
        waiverCoverage = getCoverageItemByRiderType(baseCoverageItem.coverages, CoverageTypes.BENEFIT, RiderTypes.WAIVER);
        if (waiverCoverage.isUseRatings && waiverCoverage.meRating !== 1) {
          display.push([
            t('Waiver'),
            `${formatDollar(baseCoverageItem.premWaiver)}      ${Format2Decimals(waiverCoverage.meRating)} ${t('times standard')}`,
            styleTypes.INDENT + getCurrentBgColor(),
          ]);
        } else {
          display.push([t('Waiver'), `${formatDollar(baseCoverageItem.premWaiver)}`, styleTypes.INDENT + getCurrentBgColor()]);
        }
      }

      display.push([t('ADB'), `${formatDollar(baseCoverageItem.premADB)}`, styleTypes.INDENT + getCurrentBgColor()]);
        if (isProdIDGradedPremium(inforcePolicy.prodID) || isProdIDProPlan(inforcePolicy.prodID)) {
        display.push([t('Subtract  Dividend'), `(${formatDollar(baseCoverageItem.premSubtractDividend)})`, styleTypes.INDENT + getCurrentBgColor()]);
      }
      display.push([t('Total'), formatDollar(baseCoverageItem.premAnnual), styleTypes.INDENT + getCurrentBgColor()]);
      display.push([t('Premium Frequency'), TextLookup_PremiumModePolicy(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
      strTemp = `${formatDollar(baseCoverageItem.modePremiumAmount)}`;
      strTemp2 = `${t('Factor')} ${Format5Decimals(baseCoverageItem.modalFactor)}`;
      if (baseCoverageItem.policyFee) {
        strTemp2 = `${strTemp2},  ${t('PFee')} ${Format5Decimals(baseCoverageItem.modalFactorPolicyFee)}`;
      }
      display.push([t('Mode Premium Amount'), strTemp, styleTypes.BOLD + toggleBgColor(), strTemp2]);
    }
  }

  display.push([t('Maturity/Expiry Date'), format_Display_Date(baseCoverageItem.expiryDate), styleTypes.BOLD + toggleBgColor()]);

  displayArray[treeIndex] = display;
  return node;
}
function addEmptyTab() {}
function addParLifeOwnerBranch(inforcePolicy, ownerNode, t) {
  // ownerNode.title = `Owner: ${fullName(inforcePolicy.owners[0])} SIN# ${
  //   inforcePolicy.owners[0].sin
  // }`;

  //This is not used
  // ownerNode.title = `Owner: ${fullName(inforcePolicy.insureds[0])} SIN# ${
  //   inforcePolicy.insureds[0].sin
  // }`;
  ownerNode.title = 'xxxx';

  let display = [];
  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  globalBgColor = styleTypes.WHITE;
  let policyOwner = inforcePolicy.owners[0];
  let insuredPolicyPayor = {};
  display.push([t('Policy Information'), '', styleTypes.HEAD]);
  //display.push([" ", "", styleTypes.BOLD+toggleBgColor()]);

  display.push([t('txtPolicyNumber-caption'), inforcePolicy.policyNumber, styleTypes.BOLD + toggleBgColor()]);

  let policyStatus = TextLookup_PolicyCStatCode(inforcePolicy.policyCStatCode, t);

  if (inforcePolicy.policyCStatCode === CstatCode.DEATH) {
    policyStatus = policyStatus + `     ${format_Display_Date(baseCoverageItem.insured.dateOfDeath)}`;
  }
  let style = styleTypes.BOLD + getCurrentBgColor();
  if (!inforcePolicy.isActive) {
    style = styleTypes.RED + getCurrentBgColor();
  }
  display.push([t('Policy Status'), policyStatus, style]);

  display.push([t('Policy Issue Date'), `${format_Display_Date(inforcePolicy.issueDate)}`, styleTypes.BOLD + toggleBgColor()]);

  if (inforcePolicy.policyType === PolicyTypes.UL) {
    display.push([t('Deposit Frequency'), TextLookup_PremiumModePolicy(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
    display.push([
      t('Last Billed Mode Deposit Amount'),
      `${formatDollar(inforcePolicy.totalModalPremium)}      ${t('Annualized Amt')}: ${formatDollar(inforcePolicy.totalAnnualizedPremium)}`,
      styleTypes.BOLD + toggleBgColor(),
    ]);
  } else {
    display.push([t('Premium Frequency'), TextLookup_PremiumModePolicy(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
    if (isPolicyPaidUp(inforcePolicy)) {
      display.push([t('Last Billed Mode Premium Amount'), t('NIL'), styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push([t('Last Billed Mode Premium Amount'), `${formatDollar(inforcePolicy.totalModalPremium)}`, styleTypes.BOLD + toggleBgColor()]);
    }
  }
  if (inforcePolicy.billingMethod === BillingMethodTypes.EFT) {
    if (inforcePolicy.payor) {
      insuredPolicyPayor = inforcePolicy.payor;
    } else {
      insuredPolicyPayor = policyOwner;
    }
    display.push([t('Bank Deposit Information'), `${insuredPolicyPayor.bankName} ${insuredPolicyPayor.bankNo}`, styleTypes.BOLD + toggleBgColor()]);
    display.push([
      '',
      ` ${t('Branch')} ${insuredPolicyPayor.bankBranchNo}  ${t('PCA Acct')} ${insuredPolicyPayor.bankAccountNo} `,
      styleTypes.BOLD + getCurrentBgColor(),
    ]);
    display.push(['', ` ${t('Depositor(s)')} ${insuredPolicyPayor.bankAccountHolderName}`, styleTypes.BOLD + getCurrentBgColor()]);
  }
  if (inforcePolicy.policyType === PolicyTypes.UL) {
    display.push([t('Next Monthiversary Date'), `${format_Display_Date(inforcePolicy.nextMonthiversaryDate)}`, styleTypes.BOLD + toggleBgColor()]);
    if (inforcePolicy.isRegistered || !inforcePolicy.isLoanAvailable) {
      display.push([t('Loan Available'), t('Not permitted on this policy'), styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push([
        t('Loan Available'),
        `${formatDollar(inforcePolicy.availableLoan)}`,
        styleTypes.BOLD + toggleBgColor(),
        `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.availableLoanInterestRate * 100)}`,
      ]);
    }
  } else {
    if (isPolicyPaidUp(inforcePolicy)) {
      display.push([t('Paid to Date'), t('Not Applicable'), styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push([t('Paid to Date'), `${format_Display_Date(inforcePolicy.paidToDate)}`, styleTypes.BOLD + toggleBgColor()]);
    }
    if (inforcePolicy.isRegistered === true || inforcePolicy.isLoanAvailable === false) {
      display.push([t('Loan Available'), t('Not permitted on this policy'), styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push([
        t('Loan Available'),
        `${formatDollar(inforcePolicy.availableLoan)}`,
        styleTypes.BOLD + toggleBgColor(),
        `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.availableLoanInterestRate * 100)}`,
      ]);
    }
    display.push([t('Nonforfeiture Option'), TextLookup_NFOption(inforcePolicy.nonForfeitureOption || '', t), styleTypes.BOLD + toggleBgColor()]);
    let fDividendPaidLabel = '';
    let currentDate = getCurrentDate();
    if (isAnniversaryPassed(currentDate, inforcePolicy.issueDate)) {
      fDividendPaidLabel = `${t('Div Paid This Year')} ( ${currentDate.getFullYear()} ):`;
    } else {
      fDividendPaidLabel = `${t('Div Payable This Yr')} ( ${currentDate.getFullYear()} ):`;
    }

    //Write this to globalVariables as it is used in the BuildScalars routine
    globalVariables.DividendPaidLabel = fDividendPaidLabel;

    if (inforcePolicy.dividendPayoutType === DividendPayoutTypes.Enhanced) {
      display.push([
        t('Dividend Option'),
        `${TextLookup_DividendPayout(inforcePolicy.dividendPayoutType, t)}  ${formatDollar(inforcePolicy.dividendEnhancedAmount)}`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
      display.push([fDividendPaidLabel, `${formatDollar(inforcePolicy.dividendThisYear)}`, styleTypes.BOLD + toggleBgColor()]);
      let TAFaceAmnt = inforcePolicy.dividendEnhancedAmount - inforcePolicy.puaFaceAmount;
      display.push([
        t('PUA Face Amount'),
        `${formatDollar(inforcePolicy.puaFaceAmount)}  ${t('TA Face Amt')}  ${formatDollar(TAFaceAmnt)}`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
    } else {
      display.push([t('Dividend Option'), TextLookup_DividendPayout(inforcePolicy.dividendPayoutType, t), styleTypes.BOLD + toggleBgColor()]);
      display.push([fDividendPaidLabel, `${formatDollar(inforcePolicy.dividendThisYear)}`, styleTypes.BOLD + toggleBgColor()]);
      display.push([t('PUA Face Amount'), `${formatDollar(inforcePolicy.puaFaceAmount)}`, styleTypes.BOLD + toggleBgColor()]);
    }
  }

  if (inforcePolicy.isActive) {
    display.push([t('Maturity/Expiry Date'), `${format_Display_Date(inforcePolicy.expiryDate)}`, styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Maturity/Expiry Date'), `${format_Display_Date(inforcePolicy.terminatedDate)}`, styleTypes.BOLD + toggleBgColor()]);
  }
  display.push([t('Assignee'), t('None'), styleTypes.BOLD + toggleBgColor()]);

  displayArray[treeIndex++] = display;

  //Policy Values separate branch implementation
  globalBgColor = styleTypes.WHITE;
  let display1 = [];

  display1.push([t('Policy Values'), '', styleTypes.HEAD]);

  if (inforcePolicy.policyType === PolicyTypes.UL) {
    display1.push([
      t('DIA Value'),
      `+${formatDollar(inforcePolicy.diaValue)}`,
      styleTypes.BOLD + getCurrentBgColor(),
      `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.diaInterestRate * 100)}`,
    ]);
    display1.push([t('GIA Value'), `+${formatDollar(inforcePolicy.giaValue)}`, styleTypes.BOLD + toggleBgColor()]);
    let indexedFundValue = inforcePolicy.indexedFundValue + inforcePolicy.segFundValue;
    if (IsProdIDFlexLife2(inforcePolicy.prodID)) {
      if (indexedFundValue > 0) {
        display1.push([
          t('Indexed Funds Value'),
          `+${formatDollar(indexedFundValue)}`,
          styleTypes.BOLD + toggleBgColor(),
          `Date: ${format_Display_Date(inforcePolicy.indexedFundValueDate)}`,
        ]);
      } else {
        display1.push([t('Indexed Funds Value'), `+${formatDollar(indexedFundValue)}`, styleTypes.BOLD + toggleBgColor()]);
      }
    } else {
      display1.push([t('Indexed Funds Value'), `+${formatDollar(indexedFundValue)}`, styleTypes.BOLD + toggleBgColor()]);
    }
    display1.push([
      t('PDF Balance'),
      `+${formatDollar(inforcePolicy.pdfBalance)}`,
      styleTypes.BOLD + toggleBgColor(),
      `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.pdfInterestRate * 100)}`,
    ]);
    display1.push([
      t('Loan Balance'),
      `-${formatDollar(inforcePolicy.loanBalance)}`,
      styleTypes.BOLD + toggleBgColor(),
      `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.loanInterestRate * 100)}`,
    ]);
    display1.push([t('Surr Chrg Market Val Adj'), `-${formatDollar(inforcePolicy.mva)}`, styleTypes.BOLD + toggleBgColor()]);
    display1.push([t('Early Withdrawal'), `-${formatDollar(inforcePolicy.surrenderCharge)}`, styleTypes.INDENT + getCurrentBgColor()]);
    display1.push([t('Policy Value'), `${formatDollar(inforcePolicy.policyValue)}`, styleTypes.BOLD + toggleBgColor()]);
  } else {
    display1.push([t('Basic Cash Value'), `+${formatDollar(inforcePolicy.basicCashValue)}`, styleTypes.BOLD + toggleBgColor()]);
    display1.push([t('PUA Cash Value'), `+${formatDollar(inforcePolicy.puaCashValue)}`, styleTypes.BOLD + toggleBgColor()]);
    display1.push([
      t('Div on Deposit Balance'),
      `+${formatDollar(inforcePolicy.dodBalance)}`,
      styleTypes.BOLD + toggleBgColor(),
      `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.dodInterestRate * 100)}`,
    ]);
    display1.push([
      t('PDF Balance'),
      `+${formatDollar(inforcePolicy.pdfBalance)}`,
      styleTypes.BOLD + toggleBgColor(),
      `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.pdfInterestRate * 100)}`,
    ]);
    display1.push([
      t('Loan Balance'),
      `-${formatDollar(inforcePolicy.loanBalance)}`,
      styleTypes.BOLD + toggleBgColor(),
      `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.loanInterestRate * 100)}`,
    ]);
    display1.push([t('Premium Refund (approx)'), `+${formatDollar(inforcePolicy.premiumRefund)}`, styleTypes.BOLD + toggleBgColor()]);
    display1.push([t('Policy Cash Value'), `${formatDollar(inforcePolicy.policyValue)}`, styleTypes.BOLD + toggleBgColor()]);
  }
  displayArray[treeIndex] = display1;
}
function addFundOwnerBranch(inforcePolicy, ownerNode, t) {
  console.log('inside Fund Owner Branch');
  ownerNode.title = `${t('Owner')}: ${fullName(inforcePolicy.owners[0])} SIN# ${inforcePolicy.insureds[0].sin}`;

  let display = [];
  let strPolicyStatus = '';
  let policyOwner = inforcePolicy.owners[0];
  let insuredPolicyPayor = {};
  let blnFirst = true;
  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  globalBgColor = styleTypes.WHITE;
    display.push([t('Policy Information'), '', styleTypes.HEAD]);
  if (inforcePolicy.policyType === PolicyTypes.RRIF) {
    display.push([t('This policy is registered as a RRIF'), '', styleTypes.BOLD + getCurrentBgColor()]);
  } else if (inforcePolicy.isRegistered) {
    display.push([t('This policy is registered as an RRSP'), '', styleTypes.BOLD + getCurrentBgColor()]);
  } else {
    display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  }

    display.push([t('txtPolicyNumber-caption'), inforcePolicy.policyNumber, styleTypes.BOLD + toggleBgColor()]);
    strPolicyStatus = TextLookup_PolicyCStatCode(inforcePolicy.policyCStatCode, t);

  let style = styleTypes.BOLD + toggleBgColor();
  if (!inforcePolicy.isActive) {
    style = styleTypes.RED + toggleBgColor();
  }

  if (inforcePolicy.policyCStatCode === CstatCode.DEATH) {
    display.push([t('Policy Status'), `${strPolicyStatus} ${format_Display_Date(baseCoverageItem.insured.dateOfDeath)}`, style]);
  } else {
    display.push([t('Policy Status'), `${strPolicyStatus}`, style]);
  }

  display.push([t('Policy Issue Date'), `${format_Display_Date(inforcePolicy.issueDate)}`, styleTypes.BOLD + toggleBgColor()]);

  if (isInPayout(inforcePolicy) || inforcePolicy.policyType === PolicyTypes.RRIF || inforcePolicy.policyType === PolicyTypes.SegregatedFundRRIF) {
    display.push([t('Payout Frequency'), TextLookup_PayoutFrequency(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
    //FormatAmountAboveZero & its paramters is TODO
    display.push([
      t('Gross Payout Amount'),
      formatIsAmountAboveZero(
        inforcePolicy.annualPayoutAmount,
        `${formatDollar(inforcePolicy.annualPayoutAmount)}   (${t('Annualized')} ${formatDollar(
          inforcePolicy.annualPayoutAmount * modalFactor(inforcePolicy.annualPayoutMode)
        )})`
      ),
      styleTypes.BOLD + toggleBgColor(),
    ]);
    display.push([
      t('Net Payout Amount'),
      formatIsAmountAboveZero(
        inforcePolicy.annualPayoutAmount,
        `${formatDollar(inforcePolicy.annualPayoutAmount - inforcePolicy.payoutTaxWithheld - inforcePolicy.payoutAdditionalTaxWithheld)}   (${t(
          'Annualized'
        )} ${formatDollar(
          (inforcePolicy.annualPayoutAmount - inforcePolicy.payoutTaxWithheld - inforcePolicy.payoutAdditionalTaxWithheld) *
            modalFactor(inforcePolicy.annualPayoutMode)
        )})`
      ),
      styleTypes.BOLD + toggleBgColor(),
    ]);
    display.push([
      t('Next Payment Date'),
      formatIsAmountAboveZero(
        inforcePolicy.annualPayoutAmount,
        Translate_NextPaymentDate(inforcePolicy.payoutPaymentDate, inforcePolicy.payoutEffectiveDate, inforcePolicy.currentDate)
      ),
      styleTypes.BOLD + toggleBgColor(),
    ]);
    display.push([
      t('Payout Method'),
      toProperCase(TextLookup_PayoutMethodCode(inforcePolicy.payoutMethodCode, t)),
      styleTypes.BOLD + toggleBgColor(),
    ]);
    if (inforcePolicy.annualPayoutAmount > 0) {
      if (inforcePolicy.payoutMethod === BillingMethodTypes.EFT) {
        if (inforcePolicy.payor) {
          insuredPolicyPayor = inforcePolicy.payor;
        } else {
          insuredPolicyPayor = policyOwner;
        }
        display.push([
          t('Bank Deposit Information'),
          `${insuredPolicyPayor.bankName}  (${insuredPolicyPayor.bankNo})`,
          styleTypes.BOLD + toggleBgColor(),
        ]);
        display.push([
          '',
          ` ${t('Branch')} ${insuredPolicyPayor.bankBranchNo}  ${t('PCA Acct')} ${insuredPolicyPayor.bankAccountNo} `,
          styleTypes.BOLD + getCurrentBgColor(),
        ]);
        display.push(['', ` ${t('Depositor(s)')} ${insuredPolicyPayor.bankAccountHolderName}`, styleTypes.BOLD + getCurrentBgColor()]);
      }
    }
  } else {
    display.push([t('Deposit Frequency'), TextLookup_PremiumModePolicy(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
    // TODO - PCAtuarial.Round and FormatDollar stuff
    display.push([
      t('Mode Deposit Amount'),
      `${formatDollar(PCRound(inforcePolicy.totalModalPremium, 2))}      ${t('Annualized Amt')}: ${formatDollar(
        inforcePolicy.totalAnnualizedPremium
      )}`,
      styleTypes.BOLD + toggleBgColor(),
    ]);
    if (inforcePolicy.billingMethod === BillingMethodTypes.EFT) {
      if (inforcePolicy.payor) {
        insuredPolicyPayor = inforcePolicy.payor;
      } else {
        insuredPolicyPayor = policyOwner;
      }
      display.push([
        t('Bank Deposit Information'),
        `${insuredPolicyPayor.bankName}  (${insuredPolicyPayor.bankNo})`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
      display.push([
        '',
        ` ${t('Branch')} ${insuredPolicyPayor.bankBranchNo}  ${t('PCA Acct')} ${insuredPolicyPayor.bankAccountNo} `,
        styleTypes.BOLD + getCurrentBgColor(),
      ]);
      display.push(['', ` ${t('Depositor(s)')} ${insuredPolicyPayor.bankAccountHolderName}`, styleTypes.BOLD + getCurrentBgColor()]);
    }
    display.push([t('Next Monthiversary Date'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
  }

  if (inforcePolicy.isRegistered || !inforcePolicy.isLoanAvailable) {
    display.push([t('Loan Available'), t('Not permitted on this policy'), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([
      t('Loan Available'),
      `${formatDollar(inforcePolicy.availableLoan)}`,
      styleTypes.BOLD + toggleBgColor(),
      `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.availableLoanInterestRate * 100)}`,
    ]);
  }
  if (inforcePolicy.isActive) {
    display.push([t('Maturity/Expiry Date'), `${format_Display_Date(inforcePolicy.expiryDate)}`, styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Maturity/Expiry Date'), `${format_Display_Date(inforcePolicy.terminatedDate)}`, styleTypes.BOLD + toggleBgColor()]);
  }
  if (baseCoverageItem.beneficiaries.length <= /*BENEFICIARIES_MAX_DISPLAY*/ 5) {
    let strBeneficiary = '';
    for (let beneficiaryItem of baseCoverageItem.beneficiaries) {
      if (blnFirst) {
        strBeneficiary = TextLookup_BeneficiaryCode(beneficiaryItem.beneficiaryTypeCode, t);
        strBeneficiary = t('Beneficiary (Class') + strBeneficiary + '):';
        blnFirst = false;
      } else {
        strBeneficiary = '';
      }
      display.push([
        strBeneficiary,
        `${fullName(beneficiaryItem)}, ${t(beneficiaryItem.beneficiaryRelationship)}, ${formatAllocationPercent(beneficiaryItem.beneficiaryPercent)}`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
    }
  } else {
    let beneficiaryItem = baseCoverageItem.beneficiaries[0];
    let strBeneficiary = '';
    strBeneficiary = TextLookup_BeneficiaryCode(beneficiaryItem.beneficiaryTypeCode, t);
    strBeneficiary = t('Beneficiary (Class') + strBeneficiary + '):';
    display.push([strBeneficiary, '', styleTypes.BOLD + toggleBgColor()]);
  }
  display.push([t('Assignee'), t('None'), styleTypes.BOLD + toggleBgColor()]);
  if (inforcePolicy.isRegistered) {
    if (inforcePolicy.spouseContributorType === SpouseContributorTypes.Spouse) {
      display.push([t('Spousal Contributor'), inforcePolicy.spouseContributor.fullNameWithSalutation, styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push([t('Spousal Contributor'), t('None'), styleTypes.BOLD + toggleBgColor()]);
    }
  }
  displayArray[treeIndex++] = display;
  //Policy Values separate branch implementation
  globalBgColor = styleTypes.WHITE;
  let display1 = [];

  display1.push([t('Policy Values'), '', styleTypes.HEAD]);
  if (inforcePolicy.diaValue === 0) {
    display1.push([t('DIA Value'), `+${formatDollar(inforcePolicy.diaValue)}`, styleTypes.BOLD + getCurrentBgColor()]);
  } else {
    display1.push([
      t('DIA Value'),
      `+${formatDollar(inforcePolicy.diaValue)}`,
      styleTypes.BOLD + toggleBgColor(),
      `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.diaInterestRate * 100)}`,
    ]);
  }
  display1.push([t('GIA Value'), `+${formatDollar(inforcePolicy.giaValue)}`, styleTypes.BOLD + toggleBgColor()]);
  display1.push([t('Indexed Funds Value'), `+${formatDollar(inforcePolicy.indexedFundValue)}`, styleTypes.BOLD + toggleBgColor()]);
  display1.push([
    t('PDF Balance'),
    `+${formatDollar(inforcePolicy.pdfBalance)}`,
    styleTypes.BOLD + toggleBgColor(),
    `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.pdfInterestRate * 100)}`,
  ]);
  display1.push([t('Surr Chrg Market Val Adj'), `-${formatDollar(inforcePolicy.mva)}`, styleTypes.BOLD + toggleBgColor()]);
  display1.push([t('Early Withdrawal'), `-${formatDollar(inforcePolicy.surrenderCharge)}`, styleTypes.INDENT + getCurrentBgColor()]);
  display1.push([t('Policy Value'), `${formatDollar(inforcePolicy.policyValue)}`, styleTypes.BOLD + toggleBgColor()]);
  displayArray[treeIndex] = display1;
}
function addSegFundOwnerBranch(inforcePolicy, ownerNode, t) {
  ownerNode.title = `Owner: ${fullName(inforcePolicy.owners[0])} SIN# ${inforcePolicy.owners[0].sin}`;
  let display = [];
  let strPolicyStatus = '';
  let policyOwner = inforcePolicy.owners[0];
  let insuredPolicyPayor = {};
  let blnFirst = true;
  let baseCoverageItem = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);
  globalBgColor = styleTypes.WHITE;
    display.push([t('Policy Information'), '', styleTypes.HEAD]);
  if (inforcePolicy.policyType === PolicyTypes.SegregatedFundRRIF) {
    display.push([t('This policy is registered as a RRIF'), '', styleTypes.BOLD + getCurrentBgColor()]);
  } else if (inforcePolicy.isRegistered) {
    display.push([t('This policy is registered as an RRSP'), '', styleTypes.BOLD + getCurrentBgColor()]);
  } else {
    display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);
  }

  display.push([t('txtPolicyNumber-caption'), inforcePolicy.policyNumber, styleTypes.BOLD + toggleBgColor()]);

  strPolicyStatus = TextLookup_PolicyCStatCode(inforcePolicy.policyCStatCode, t);

  let style = styleTypes.BOLD + toggleBgColor();
  if (!inforcePolicy.isActive) {
    style = styleTypes.RED + toggleBgColor();
  }

  if (inforcePolicy.policyCStatCode === CstatCode.DEATH) {
    display.push([t('Policy Status'), `${strPolicyStatus} ${format_Display_Date(baseCoverageItem.insured.dateOfDeath)}`, style]);
  } else {
    display.push([t('Policy Status'), `${strPolicyStatus}`, style]);
  }

  display.push([t('Policy Issue Date'), `${format_Display_Date(inforcePolicy.issueDate)}`, styleTypes.BOLD + toggleBgColor()]);

  if (isInPayout(inforcePolicy) || inforcePolicy.policyType === PolicyTypes.RRIF || inforcePolicy.policyType === PolicyTypes.SegregatedFundRRIF) {
    display.push([t('Payout Frequency'), TextLookup_PayoutFrequency(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
    //FormatAmountAboveZero & its paramters is TODO
    console.log(`t("Gross Payout Amount 3") = ${t('Gross Payout Amount')}`);
    display.push([
      t('Gross Payout Amount'),
      formatIsAmountAboveZero(
        inforcePolicy.annualPayoutAmount,
        `${formatDollar(inforcePolicy.annualPayoutAmount)}   (Annualized ${formatDollar(
          inforcePolicy.annualPayoutAmount * modalFactor(inforcePolicy.annualPayoutMode)
        )})`
      ),
      styleTypes.BOLD + toggleBgColor(),
    ]);
    display.push([
      t('Net Payout Amount'),
      formatIsAmountAboveZero(
        inforcePolicy.annualPayoutAmount,
        `${formatDollar(
          inforcePolicy.annualPayoutAmount - inforcePolicy.payoutTaxWithheld - inforcePolicy.payoutAdditionalTaxWithheld
        )}   (Annualized ${formatDollar(
          (inforcePolicy.annualPayoutAmount - inforcePolicy.payoutTaxWithheld - inforcePolicy.payoutAdditionalTaxWithheld) *
            modalFactor(inforcePolicy.annualPayoutMode)
        )})`
      ),
      styleTypes.BOLD + toggleBgColor(),
    ]);
    display.push([
      t('Next Payment Date'),
      formatIsAmountAboveZero(
        inforcePolicy.annualPayoutAmount,
        Translate_NextPaymentDate(inforcePolicy.payoutPaymentDate, inforcePolicy.payoutEffectiveDate, inforcePolicy.currentDate)
      ),
      styleTypes.BOLD + toggleBgColor(),
    ]);
    display.push([
      t('Payout Method'),
      toProperCase(TextLookup_PayoutMethodCode(inforcePolicy.payoutMethodCode, t)),
      styleTypes.BOLD + toggleBgColor(),
    ]);
    if (inforcePolicy.annualPayoutAmount > 0) {
      if (inforcePolicy.payoutMethod === BillingMethodTypes.EFT) {
        if (inforcePolicy.payor) {
          insuredPolicyPayor = inforcePolicy.payor;
        } else {
          insuredPolicyPayor = policyOwner;
        }
        display.push([
          t('Bank Deposit Information'),
          `${insuredPolicyPayor.bankName}  (${insuredPolicyPayor.bankNo})`,
          styleTypes.BOLD + toggleBgColor(),
        ]);
        display.push([
          '',
          ` ${t('Branch')} ${insuredPolicyPayor.bankBranchNo}  ${t('PCA Acct')} ${insuredPolicyPayor.bankAccountNo} `,
          styleTypes.BOLD + getCurrentBgColor(),
        ]);
        display.push(['', ` ${t('Depositor(s)')} ${insuredPolicyPayor.bankAccountHolderName}`, styleTypes.BOLD + getCurrentBgColor()]);
      }
    }
  } else {
    display.push([t('Deposit Frequency'), TextLookup_PremiumModePolicy(inforcePolicy, t), styleTypes.BOLD + toggleBgColor()]);
    // TODO - PCAtuarial.Round and FormatDollar stuff
    display.push([
      t('Mode Deposit Amount'),
      `${formatDollar(PCRound(inforcePolicy.totalModalPremium, 2))}      ${t('Annualized Amt')}: ${formatDollar(
        inforcePolicy.totalAnnualizedPremium
      )}`,
      styleTypes.BOLD + toggleBgColor(),
    ]);
    if (inforcePolicy.billingMethod === BillingMethodTypes.EFT) {
      if (inforcePolicy.payor) {
        insuredPolicyPayor = inforcePolicy.payor;
      } else {
        insuredPolicyPayor = policyOwner;
      }
      display.push([
        t('Bank Deposit Information'),
        `${insuredPolicyPayor.bankName}  (${insuredPolicyPayor.bankNo})`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
      display.push([
        '',
        ` ${t('Branch')} ${insuredPolicyPayor.bankBranchNo}  ${t('PCA Acct')} ${insuredPolicyPayor.bankAccountNo} `,
        styleTypes.BOLD + getCurrentBgColor(),
      ]);
      display.push(['', ` ${t('Depositor(s)')} ${insuredPolicyPayor.bankAccountHolderName}`, styleTypes.BOLD + getCurrentBgColor()]);
    }
    display.push([t('Next Monthiversary Date'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
  }

  if (inforcePolicy.isRegistered || !inforcePolicy.isLoanAvailable) {
    display.push([t('Loan Available'), t('Not permitted on this policy'), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([
      t('Loan Available'),
      `${formatDollar(inforcePolicy.availableLoan)}`,
      styleTypes.BOLD + toggleBgColor(),
      `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.availableLoanInterestRate * 100)}`,
    ]);
  }
  if (inforcePolicy.isActive) {
    display.push([t('Maturity/Expiry Date'), `${format_Display_Date(inforcePolicy.expiryDate)}`, styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Maturity/Expiry Date'), `${format_Display_Date(inforcePolicy.terminatedDate)}`, styleTypes.BOLD + toggleBgColor()]);
  }
  if (baseCoverageItem.beneficiaries.length <= /*BENEFICIARIES_MAX_DISPLAY*/ 5) {
    let strBeneficiary = '';
    for (let beneficiaryItem of baseCoverageItem.beneficiaries) {
      if (blnFirst) {
        strBeneficiary = TextLookup_BeneficiaryCode(beneficiaryItem.beneficiaryTypeCode, t);
        strBeneficiary = t('Beneficiary (Class') + strBeneficiary + '):';
        blnFirst = false;
      } else {
        strBeneficiary = '';
      }
      display.push([
        strBeneficiary,
        `${fullName(beneficiaryItem)}, ${t(beneficiaryItem.beneficiaryRelationship)}, ${formatAllocationPercent(beneficiaryItem.beneficiaryPercent)}`,
        styleTypes.BOLD + toggleBgColor(),
      ]);
    }
  } else {
    let beneficiaryItem = baseCoverageItem.beneficiaries[0];
    let strBeneficiary = TextLookup_BeneficiaryCode(beneficiaryItem.beneficiaryTypeCode, t);
    strBeneficiary = t('Beneficiary (Class') + strBeneficiary + '):';
    display.push([strBeneficiary, '', styleTypes.BOLD + toggleBgColor()]);
  }
  display.push([t('Assignee'), t('None'), styleTypes.BOLD + toggleBgColor()]);
  if (inforcePolicy.isRegistered) {
    if (inforcePolicy.spouseContributorType === SpouseContributorTypes.Spouse) {
      display.push([t('Spousal Contributor'), inforcePolicy.spouseContributor.fullNameWithSalutation, styleTypes.BOLD + toggleBgColor()]);
    } else {
      display.push([t('Spousal Contributor'), t('None'), styleTypes.BOLD + toggleBgColor()]);
    }
  }
  displayArray[treeIndex++] = display;

  //Policy Values separate branch implementation
  globalBgColor = styleTypes.WHITE;
  let display1 = [];

  display1.push([t('Policy Values'), '', styleTypes.HEAD]);
  if (inforcePolicy.isActive) {
    console.log(`inforcePolicy.currentDate = ${inforcePolicy.currentDate}`);
    display1.push([t('Fund Valuation Date'), format_Display_Date(inforcePolicy.currentDate), styleTypes.BOLD + getCurrentBgColor()]); //TODO
  } else {
    display1.push([t('Fund Valuation Date'), t('N/A'), styleTypes.BOLD + toggleBgColor()]);
  }
  display1.push([t('Current Value'), formatDollar(inforcePolicy.policyValue), styleTypes.BOLD + toggleBgColor()]);

  display1.push([
    t('PDF Balance'),
    `+${formatDollar(inforcePolicy.pdfBalance)}`,
    styleTypes.BOLD + toggleBgColor(),
    `${t('Interest Rate')} ${formatAllocationPercent(inforcePolicy.pdfInterestRate * 100)}`,
  ]);
  display1.push([t('Surr Chrg Market Val Adj'), `-${formatDollar(inforcePolicy.mva)}`, styleTypes.BOLD + toggleBgColor()]);
  display1.push([t('Early Withdrawal'), `-${formatDollar(inforcePolicy.surrenderCharge)}`, styleTypes.INDENT + getCurrentBgColor()]);
  display1.push([t('Policy Value'), `${formatDollar(inforcePolicy.policyValue)}`, styleTypes.BOLD + toggleBgColor()]);
  if (inforcePolicy.isActive) {
    display1.push([t('Gtd Maturity Date'), format_Display_Date(inforcePolicy.guaranteedMaturityDate), styleTypes.BOLD + toggleBgColor()]);
    display1.push([t('Gtd Maturity Amount'), formatDollar(inforcePolicy.guaranteedMaturityAmount), styleTypes.BOLD + toggleBgColor()]);
    display1.push([t('Gtd Death Amount'), formatDollar(inforcePolicy.guaranteedDeathAmount), styleTypes.BOLD + toggleBgColor()]);
  }
  displayArray[treeIndex] = display1;
}

function addClientBranch(inforcePolicy, parentNode, t) {
  let data = {};
  data.expanded = true;
  data.title = t('Client Information');
  let owner = inforcePolicy.owners[0];
  //let owner = inforcePolicy.insureds[0];

  parentNode.children.push(new Node(data, treeIndex));
  globalBgColor = styleTypes.WHITE;
  let display = [];

  display.push([data.title, '', styleTypes.HEAD]);
  //display.push(["", "", styleTypes.BOLD+toggleBgColor()]);
  display.push([t('Owner'), `${fullName(owner)}`, styleTypes.BOLD + getCurrentBgColor()]);
  display.push(['', `${t(owner.consent)}`, styleTypes.BOLD + getCurrentBgColor()]);

  if (owner.phoneNumber && owner.phoneNumber.length > 0) {
    display.push([t('Owner Phone'), FormatPhoneNumber(owner.phoneNumber), styleTypes.BOLD + toggleBgColor()]);
  } else {
    display.push([t('Owner Phone'), t('Not on File'), styleTypes.BOLD + toggleBgColor()]);
  }
  display.push([t('Owner Address'), `${owner.address1}`, styleTypes.BOLD + toggleBgColor()]);
  let strAddressTemp = ((owner.address2 + ' ' + owner.address3).trim() + ' ' + owner.address4).trim();

  if (strAddressTemp.length > 0) {
    display.push(['', strAddressTemp, styleTypes.BOLD + getCurrentBgColor()]);
  }
  strAddressTemp = ((owner.city + ' ' + owner.province).trim() + ' ' + owner.postalCode).trim();
  display.push(['', strAddressTemp, styleTypes.BOLD + getCurrentBgColor()]);
  //JA - Jan 26 - Removing church from display
  //display.push([`${t('Church')} (${owner.churchID}):`, formatChurchName(owner, t), styleTypes.BOLD + toggleBgColor()]);
  display.push(['', '', styleTypes.EMPTY + getCurrentBgColor()]);

  if (inforcePolicy.payoutType === PayoutTypes.None) {
    //We want our entry for each unique life insured
    for (let insured of inforcePolicy.insureds) {
      display.push([t('Life Insured'), fullName(insured), styleTypes.BOLD + toggleBgColor()]);
      display.push(['', t(insured.consent), styleTypes.BOLD + getCurrentBgColor()]);
    }
  } else {
    for (let insured of inforcePolicy.insureds) {
      display.push([`${t('Annuitant')}:`, fullName(insured), styleTypes.BOLD + toggleBgColor()]);
      display.push(['', t(insured.consent), styleTypes.BOLD + getCurrentBgColor()]);
    }
    if (inforcePolicy.payoutInsuredType === PayoutInsuredTypes.Spouse.None) {
      display.push([`${t('Annuitant')}:`, fullName(inforcePolicy.payoutInsured), styleTypes.BOLD + toggleBgColor()]);
      display.push(['', t(inforcePolicy.payoutInsured.consent), styleTypes.BOLD + getCurrentBgColor()]);
    }
  }
  display.push(['', '', styleTypes.EMPTY + toggleBgColor()]);

  // Client information update - 2 March, 2020
  display.push([t('Email/CASL Consent'), '', styleTypes.HEAD]);
  display.push([t('Owner'), `${fullName(owner)}`, styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Email'), `${getEmailAddress(owner.emails)}`, styleTypes.BOLD + toggleBgColor()]);
  display.push([t('Consent'), `${t(owner.caslConsent)}`, styleTypes.BOLD + toggleBgColor()]);
  display.push(['', '', styleTypes.BOLD + toggleBgColor()]);
  for (let insured of inforcePolicy.insureds) {
    display.push([t('Life Insured'), fullName(insured), styleTypes.BOLD + toggleBgColor()]);
    display.push([t('Email'), `${getEmailAddress(insured.emails)}`, styleTypes.BOLD + toggleBgColor()]); // No email, getting exception
    display.push([t('Consent'), `${t(insured.caslConsent)}`, styleTypes.BOLD + toggleBgColor()]);
  }

  displayArray[treeIndex] = display;
  //console.log("displayArray:", displayArray);
}

// ------------------  HELPER FUNCTIONS -------------------
function getEmailAddress(emails) {
  if (emails.length === 0) {
    return '';
  } else {
    return emails[0].emailAddress;
  }
}

function toggleBgColor() {
  globalBgColor = globalBgColor === styleTypes.WHITE ? styleTypes.GREY : styleTypes.WHITE;
  return globalBgColor;
}
function getCurrentBgColor() {
  return globalBgColor;
}

function getCurrentDate() {
  //our data is as of Oct 8, 2019
  return new Date(2019, 10, 8);
  /*
return new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);
*/
}

function isCoveragePaidUp(riderCoverageItem) {
  return riderCoverageItem.coverageStatus.toUpperCase() === 'PAID UP' || riderCoverageItem.coverageStatus.toUpperCase() === 'COVERAGE PAID UP';
}

function translateCOIType(riderCoverageItem, t) {
  switch (riderCoverageItem.coiType) {
    case COITypes.LevelGuaranteed:
      return t('Level');
    case COITypes.YRT:
      return t('Increasing');
    default:
      break;
  }
  return t('Increasing');
}

function translateDeathBenefitType(riderCoverageItem, inforcePolicy, t) {
  switch (riderCoverageItem.coiType) {
    case COITypes.LevelGuaranteed:
    case COITypes.YRT:
      if (inforcePolicy.dbOption === DBOptionTypes.Level) {
        return t('Level');
      } else {
        return t('Increasing');
      }
    case COITypes.None:
      return t('Level');
    default:
      break;
  }
  return '';
}

function translateSubTable1(riderCoverageItem, isUseProperCase, t) {
  let strCode = '';
  if (riderCoverageItem.coverageID === '056') {
    strCode = 'D';
  } else if (riderCoverageItem.coverageID === '060') {
    strCode = 'DD';
  } else if (isPlanExcludeHealthMatch(riderCoverageItem.planCode)) {
  } else {
    switch (riderCoverageItem.insured.rateClass) {
      case 'P':
        strCode = 'HP';
        break;
      case 'S':
        strCode = 'HS';
        break;
      case 'U':
        strCode = 'HU';
        break;
      default:
        return '';
    }
  }
  return TextLookup_SubTable1(strCode, isUseProperCase, t);
}

function translateSubTable2(riderCoverageItem, isUseProperCase, t) {
  let strCode = '';

  if (riderCoverageItem.policyType === PolicyTypes.UL) {
    switch (riderCoverageItem.coiType) {
      case COITypes.LevelGuaranteed:
        strCode = 'LEV';
        break;
      case COITypes.YRT:
        strCode = 'YRT';
        break;
      default:
        return '';
    }
  } else if (isProductHealth(riderCoverageItem.prodID)) {
    let disabilityItem = getDisabilityItem(riderCoverageItem.disabilityItems, 'EP');
    if (disabilityItem === undefined) {
      return '';
    } else {
      strCode = disabilityItem.disabilityCode;
    }
  }
  return TextLookup_SubTable2(strCode, isUseProperCase, t);
}

function translateSubTable3(riderCoverageItem, isUseProperCase, t) {
  let strCode = '';

  if (isProductHealth(riderCoverageItem.prodID)) {
    let disabilityItem = getDisabilityItem(riderCoverageItem.disabilityItems, 'BP');
    if (disabilityItem === undefined) {
      return '';
    } else {
      strCode = disabilityItem.disabilityCode;
    }
  }
  return TextLookup_SubTable3(strCode, isUseProperCase, t);
}

function translateSubTable4(riderCoverageItem, isUseProperCase, t) {
  let strCode = '';

  if (isProductHealth(riderCoverageItem.prodID)) {
    let disabilityItem = getDisabilityItem(riderCoverageItem.disabilityItems, 'OC');
    if (disabilityItem === undefined) {
      return '';
    } else {
      strCode = disabilityItem.disabilityCode;
    }
  }
  return TextLookup_SubTable4(strCode, isUseProperCase, t);
}

function formatSmokeClass(smokeClass, issueAge) {
  if (issueAge < 18 && smokeClass !== 'C') {
    return 'R';
  } else {
    return smokeClass;
  }
}

function isAnniversaryPassed(currentDate, issueDate) {
  //if the month is greater than the issue month
  //OR
  //current month = issue month and current day > issue day
  //then return true
  let aIssueDate = new Date(issueDate);
  return (
    currentDate.getMonth() > aIssueDate.getMonth() ||
    (currentDate.getMonth() === aIssueDate.getMonth() && currentDate.getDate() >= aIssueDate.getDate())
  );
}

function formatChurchName(insuredItem, t) {
  let strTemp = '';

  strTemp = toProperCase(insuredItem.churchName.trim() + ', ' + insuredItem.churchCity.trim()) + insuredItem.churchProvince;
  if (strTemp === ', ') {
    return t('NAME NOT ON FILE');
  }
  return strTemp;
}
