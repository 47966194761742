import {
  arrow45MoneyLogo,
  arrowStraightLogo,
  companyLogo,
  execLogo,
  ProcompGreyLogo,
  purpleExecCorpLogo,
} from 'assets';
import React from 'react';

const IntroModal8 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <header className="sharedOwnershipHdr">
          <div className="flexContainer alignCenter">
            <img
              id="intro-8"
              className=""
              style={{width: '6rem', height: '6rem'}}
              src={ProcompGreyLogo}
              alt=""
            />
            <h3 className="fontHdrCapitalize textAlignCenter">HOW THE CONCEPT WORKS</h3>
          </div>
          <h1 className="fontHdr1 fontPrimaryColor textAlignCenter">
            Death Benefit Rights - Post-retirement
          </h1>
        </header>

        <section className="sharedOwnershipContainer flexContainer threeCol alignStretch noWrap">
          <section className="">
            <h3 className="fontHdr2 textAlignCenter">Executive dies</h3>
            <p className="fontHdr2 textAlignCenter fontBold">After Benefit Period</p>
            <img id="purple-exec-corp-8" src={purpleExecCorpLogo} alt="RetireBrief" />
            <h3 className="fontHdr2 textAlignCenter bgWhite paddingLg boxShadowAll">
              Universal Life Policy
            </h3>
          </section>
          <section className="flexContainerColumn justifyCenter">
            <div className="flexContainer noCols noWrap marginMed" style={{marginBottom: '4rem'}}>
              <img id="arrow-45-money" src={arrow45MoneyLogo} alt="arrow45Money" />
              <div>
                <p className="fontBold fontPrimaryColor">Executive's Heirs</p>
                <p className="fontBold fontPrimaryColor">(or Estate)</p>
              </div>
            </div>
            <div className="flexContainer noCols noWrap marginMed">
              <img id="arrow-straight-money" src={arrowStraightLogo} alt="arrowStraight" />
              <p className="fontBold fontErrorColor">Corporation has released interest</p>
            </div>
          </section>
          <section className="flexContainerColumn justifyCenter">
            <div className="flexContainer noCols alignCenter" style={{marginBottom: '10rem'}}>
              <img id="exec-8" src={execLogo} alt="RetireBrief" />
              <h3 className="fontHdr2 fontPrimaryColor bgWhite paddingLg boxShadowAll">
                Full Death Benefit
              </h3>
            </div>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal8;
