import {companyLogo, ProcompGreyLogo, corpTimeLogo} from 'assets';
import React from 'react';

const IntroModal16 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents fullWidth">
        <header className="sharedOwnershipHdr flexContainer alignCenter">
          <img
            id="intro-16"
            className=""
            style={{width: '6rem', height: '6rem'}}
            src={ProcompGreyLogo}
            alt=""
          />
          <h3 className="fontHdrCapitalize textAlignCenter">TAXATION ISSUES</h3>
        </header>
        <section className="sharedOwnershipContainer noWrap">
          <section className="flexContainerColumn alignCenter marginMed">
            <img id="corp-time-16" src={corpTimeLogo} alt="ULBriefcase" />
            <div className="bgWhite boxShadowAll paddingLg">
              <h2 className="fontHdr2 fontErrorColor textAlignCenter">
                For Corporation (Employer)
              </h2>
              <h3 className="fontAltColorB textAlignCenter fontMed">
                at the end of Benefit Period when Corporation transfers its interests to Executive
              </h3>
            </div>
          </section>
          <section style={{maxWidth: '50rem', margin: '0 auto'}}>
            <p className="fontLg textAlignCenter paddingLg">
              Will depend if there is any 'value' in the Corporation's portion of the policy (i.e.
              Amount of Insurance at Risk)
            </p>
            <p className="textAlignCenter fontMed">
              Typically there is no value, and no tax implications to Corporation
            </p>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal16;
