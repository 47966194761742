import {ActionTypes} from 'custom/common/redux/Types';

const rolesReducer = (state = [], action) => {
  const {type, payload} = action;
  switch (type) {
    case ActionTypes.ALL_ROLES:
      return payload;
    default:
      return state;
  }
};

export {rolesReducer as roles};
