import React, {Suspense} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useRouter} from 'core/hooks';
import {Spinner} from 'core/uicore';
import BIReports from './ui/BIReports';

const Tracking = React.lazy(() => import('./ui/Tracking'));
const BIDashboard = React.lazy(() => import('./ui/BIDashboard'));

const BusinessIntelligence = ({...props}) => {
  const {match} = useRouter();
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route path={`${match.url}/widgets`}>
          <BIDashboard {...props} />
        </Route>
        <Route path={`${match.url}/tracking`}>
          <Tracking {...props} />
        </Route>
        <Route
          path={`${match.url}/biReports`}>
          <BIReports {...props} />
        </Route>
        <Redirect to={`${match.url}/widgets`} />
      </Switch>
    </Suspense>
  );
};

export default BusinessIntelligence;
