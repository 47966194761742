import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {STYLES} from 'core/constants/uiConstants';
import {usePCTranslation} from 'core/hooks';

const FieldContainer = ({
  children,
  name,
  tabName,
  activeTab,
  styling,
  // t,
  collapsable = true,
  // controlItems
}) => {
  let [isHidden, setIsHidden] = useState(false);
  let {t} = usePCTranslation();
  let {controlItem} = useSelector((state) => ({
    controlItem: state.setupItems.controlItems ? state.setupItems.controlItems[name] : null,
  }));
  let classContent = `${STYLES.FIELDCONTENT} ${styling.content}`;
  let classHeader = `${STYLES.FIELDCONTAINERHEADER}${
    collapsable ? ' cursorPointer collapseIndicator collapseBlue collapseLeft' : ''
  }`;
  let containerClass = `${STYLES.FIELDCONTAINER} ${styling.container}`;
  if (controlItem && controlItem.displayHidden) {
    classContent += ' hiddenField';
    containerClass += ' hiddenField';
  }
  if (isHidden) {
    classContent += ' hiddenField';
    classHeader += ' collapsedHdr';
  }

  return (
    <div className={containerClass} name={name}>
      {collapsable ? (
        <div className={classHeader} onClick={() => setIsHidden(!isHidden)} name={name}>
          {t(`${name}-label`)}
        </div>
      ) : (
        <div className={classHeader} name={name}>
          {t(`${name}-label`)}
        </div>
      )}

      <div className={classContent} name={name}>
        {children}
      </div>
    </div>
  );
};

FieldContainer.defaultProps = {
  activeTab: '',
  tabName: '',
};

export default FieldContainer;
