import {blueExecLogo, arrowCurvedLogo, ProcompGreyLogo, purpleExecPlusCorpLogo} from 'assets';
import React from 'react';

const IntroModal5 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <header className="sharedOwnershipHdr">
          <div className="flexContainer alignCenter fullWidth">
            <img
              id="intro-5"
              className=""
              style={{width: '6rem', height: '6rem'}}
              src={ProcompGreyLogo}
              alt=""
            />
            <h3 className="fontHdrCapitalize textAlignCenter">HOW THE CONCEPT WORKS</h3>
          </div>
        </header>
        <section className="sharedOwnershipContainer flexContainer threeCol noWrap">
          <section>
            <h2 className="fontHdr2 textAlignCenter">Executive owns</h2>
            <img id="blue-exec-3" src={blueExecLogo} alt="ULBriefcase" />
            <div className="bgWhite boxShadowAll paddingLg">
              <h3 className="fontHdr3 textAlignCenter">Universal Life Policy</h3>
              <p className="textAlignCenter">on Executive's Life</p>
            </div>
          </section>
          <section className="flexContainer noCols">
            <div className="flexContainer fullWidth justifyBetween">
              <img id="arrow-curved-in" src={arrowCurvedLogo} alt="curveArrow" />
              <img id="arrow-curved-out" src={arrowCurvedLogo} alt="curveArrow" />
            </div>
            <div id="shared-owner-agreement" className="fontHdr2 textAlignCenter">
              <p className="fontHdr2">Shared Ownership Agreement entered</p>
            </div>
          </section>
          <section>
            <h2 className="fontHdr2 textAlignCenter">Executive transfers policy to corporation</h2>
            <img id="purple-exec-plus-corp-3" src={purpleExecPlusCorpLogo} alt="JOBriefcase" />
            <div className="bgWhite boxShadowAll paddingLg">
              <h3 className="fontHdr3 textAlignCenter">Joint Ownership of Policy</h3>
              <div className="textAlignCenter fontAltColorB">
                <div className="marginSm fontMed">Shared Premium</div>
                <div className="marginSm fontMed">Shared Benefit & Obligation</div>
                <div className="marginSm fontMed">Allocation of Death Benefit</div>
              </div>
            </div>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal5;
