import axios from 'axios';

export const postReportData = async (finalReport) => {
  try {
    const {data} = await axios.post('/api/ReportData', finalReport);
    console.log('Result: ', data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const postNewBusinessReportData = async (finalReport) => {
    try {
        const { data } = await axios.post('/Report/NewBusinessReport', finalReport);
        console.log('Result: ', data);
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const postDataReportsData = async (finalReport) => {
  try {
    let res = await axios.post('/api/RunReportData', finalReport);
    console.log('Result: ', res.data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const postDataBIProQuoteReportsData = async (finalReport) => {
  try {
      let res = await axios.post('/api/BI/BIProQuote/RunReportData', finalReport);
      console.log('Result: ', res.data);
      return res.data;
  } catch (error) {
      console.log(error);
  }
};

export const postBIReportsData = async (finalReport, reportAPIPath) => {
  try {
    let res = await axios.post(`${reportAPIPath}/RunReportData`, finalReport);
    console.log("Result: ", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const exportDownload = async (id, excelFile) => {
  try {
    let res = await axios.get(`/DB${id}/${excelFile}`, {
      responseType: 'blob',
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement('a');
    a.href = url;
    a.download = excelFile;
    a.click();
  } catch (error) {
    console.log(error);
  }
};
