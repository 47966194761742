import React, {useEffect, useState} from 'react';
import {Textbox, Checkbox, Selectbox} from 'core/controls';
import {FormStatus, FormValidator} from 'core/formValidation';
import ValidateForm from 'custom/common/utils/customFormValidation';
import {FUND_SELECTION} from '../SharedOwnershipModal';
import {companyLogo, ProcompGreyLogo} from 'assets';

const BeneficiaryDesignationsModal = ({
  controlGetFocus,
  controlChange,
  controlLostFocus,
  setElement,
  elementItems,
  updateFormData,
  onLoad,
  formData,
  modalIndex,
  t,
}) => {
  useEffect(() => {
    onLoad(formData.beneficiaryDesignations, elementItems, updateFormData);
  }, []);
  const submitData = () => {};

  return (
    <FormStatus data={formData.beneficiaryDesignations}>
      <FormValidator
        data={formData.beneficiaryDesignations}
        submit={submitData}
        validateForm={ValidateForm}>
        <div className="slideshowContainer">
          <main className="slideshowContents">
            <header className="sharedOwnershipHdr">
              <div className="flexContainer alignCenter fullWidth">
                <img
                  id="beneficiary-designation"
                  className=""
                  style={{width: '6rem', height: '6rem'}}
                  src={ProcompGreyLogo}
                  alt=""
                />
                <h2 className="fontHdrCapitalize textAlignCenter">How the concept Works</h2>
              </div>
              <h1 className="fontHdr1 textAlignCenter fontPrimaryColor">
                Beneficiary Designations
              </h1>
            </header>
            <section className="sharedOwnershipContainer flexContainer threeCol noWrap">
              <section>
                <ul className="textList">
                  <li className="fontLg">
                    Corporation is usually named an irrevocable beneficiary of Amount of insurance
                    at Risk
                  </li>
                  <li className="fontLg">
                    Executive usually names spouse and/or as the beneficiary of the Account Value
                  </li>
                  <li className="fontLg">
                    Designations set out in Shared Ownership Agreement and beneficiary designations
                    filed with Insurer
                  </li>
                </ul>
              </section>
              <div className="paddingSm bgWhite boxShadowAll colspanTwo">
                <table className="beneficiary-table" id="beneficiary-table">
                  <thead>
                    <tr className="title-row">
                      <th className="title-header fund-header">{t('title-Beneficiary')}</th>
                      <th className="title-header fund-header">
                        {t('title-RelationshipToInsured')}
                      </th>
                      <th className="title-header amount-header">{t('title-PercentShare')}</th>
                      <th className="title-header rating-header">{t('title-Irrevocable')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="row-data data1">
                        <Textbox
                          text="Beneficiary Name"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedBeneficiaryName1']}
                          name="txtSharedBeneficiaryName1"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data2">
                        <Selectbox
                          text="Relationship to Life Insured"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          // options={optionsRating}
                          name="cboSharedRelationshipToInsured1"
                          refItem={setElement}
                          propStyle={{
                            label: 'selectlabel',
                            control: 'selectcontrol',
                            selectcontainer: `selectcontainer followUpQuestion`,
                          }}
                        />
                      </td>
                      <td className="row-data data3">
                        <Textbox
                          text="Percent Share"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedPercentShare1']}
                          name="txtSharedPercentShare1"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data4">
                        <Checkbox
                          text="Irrevocable"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          name="chkSharedIrrevocable1"
                          refItem={setElement}
                          propStyle={{
                            label: 'checklabel',
                            control: 'checkcontrol',
                            checkcontainer: 'checkcontainer stemQuestion fullWidth',
                          }}
                          showIcon={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="row-data data1">
                        <Textbox
                          text="Beneficiary Name"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedBeneficiaryName2']}
                          name="txtSharedBeneficiaryName2"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data2">
                        <Selectbox
                          text="Relationship to Life Insured"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          // options={optionsRating}
                          name="cboSharedRelationshipToInsured2"
                          refItem={setElement}
                          propStyle={{
                            label: 'selectlabel',
                            control: 'selectcontrol',
                            selectcontainer: `selectcontainer followUpQuestion`,
                          }}
                        />
                      </td>
                      <td className="row-data data3">
                        <Textbox
                          text="Percent Share"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedPercentShare2']}
                          name="txtSharedPercentShare2"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data4">
                        <Checkbox
                          text="Irrevocable"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          name="chkSharedIrrevocable2"
                          refItem={setElement}
                          propStyle={{
                            label: 'checklabel',
                            control: 'checkcontrol',
                            checkcontainer: 'checkcontainer stemQuestion fullWidth',
                          }}
                          showIcon={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="row-data data1">
                        <Textbox
                          text="Beneficiary Name"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedBeneficiaryName3']}
                          name="txtSharedBeneficiaryName3"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data2">
                        <Selectbox
                          text="Relationship to Life Insured"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          // options={optionsRating}
                          name="cboSharedRelationshipToInsured3"
                          refItem={setElement}
                          propStyle={{
                            label: 'selectlabel',
                            control: 'selectcontrol',
                            selectcontainer: `selectcontainer followUpQuestion`,
                          }}
                        />
                      </td>
                      <td className="row-data data3">
                        <Textbox
                          text="Percent Share"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedPercentShare3']}
                          name="txtSharedPercentShare3"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data4">
                        <Checkbox
                          text="Irrevocable"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          name="chkSharedIrrevocable3"
                          refItem={setElement}
                          propStyle={{
                            label: 'checklabel',
                            control: 'checkcontrol',
                            checkcontainer: 'checkcontainer stemQuestion fullWidth',
                          }}
                          showIcon={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="row-data data1">
                        <Textbox
                          text="Beneficiary Name"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedBeneficiaryName4']}
                          name="txtSharedBeneficiaryName4"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data2">
                        <Selectbox
                          text="Relationship to Life Insured"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          // options={optionsRating}
                          name="cboSharedRelationshipToInsured4"
                          refItem={setElement}
                          propStyle={{
                            label: 'selectlabel',
                            control: 'selectcontrol',
                            selectcontainer: `selectcontainer followUpQuestion`,
                          }}
                        />
                      </td>
                      <td className="row-data data3">
                        <Textbox
                          text="Percent Share"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedPercentShare4']}
                          name="txtSharedPercentShare4"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data4">
                        <Checkbox
                          text="Irrevocable"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          name="chkSharedIrrevocable4"
                          refItem={setElement}
                          propStyle={{
                            label: 'checklabel',
                            control: 'checkcontrol',
                            checkcontainer: 'checkcontainer stemQuestion fullWidth',
                          }}
                          showIcon={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="row-data data1">
                        <Textbox
                          text="Beneficiary Name"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedBeneficiaryName5']}
                          name="txtSharedBeneficiaryName5"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data2">
                        <Selectbox
                          text="Relationship to Life Insured"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          // options={optionsRating}
                          name="cboSharedRelationshipToInsured5"
                          refItem={setElement}
                          propStyle={{
                            label: 'selectlabel',
                            control: 'selectcontrol',
                            selectcontainer: `selectcontainer followUpQuestion`,
                          }}
                        />
                      </td>
                      <td className="row-data data3">
                        <Textbox
                          text="Percent Share"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations['txtSharedPercentShare5']}
                          name="txtSharedPercentShare5"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: 'textcontainer',
                          }}
                        />
                      </td>
                      <td className="row-data data4">
                        <Checkbox
                          text="Irrevocable"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.beneficiaryDesignations}
                          name="chkSharedIrrevocable5"
                          refItem={setElement}
                          propStyle={{
                            label: 'checklabel',
                            control: 'checkcontrol',
                            checkcontainer: 'checkcontainer stemQuestion fullWidth',
                          }}
                          showIcon={true}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </main>
        </div>
      </FormValidator>
    </FormStatus>
  );
};

export default BeneficiaryDesignationsModal;
