export const ActionTypes = {
  DATA_LOAD: 'data_load',
  DATA_LOADING: 'DATA_LOADING',
  DATA_REMOVE: 'data_remove',
  CONTROLITEMS_LOAD: 'controlitems_load',
  UPDATE_CONTROLITEMS: 'UPDATE_CONTROLITEMS',
  PRODUCTSETUPITEMS_LOAD: 'productsetupitems_load',
  INTERFACESETUPITEMS_LOAD: 'interfacesetupitems_load',
  INTERFACESETUPITEMS_UPDATE: 'INTERFACESETUPITEMS_UPDATE',
  ALLSETUPS_LOAD: 'ALLSETUPS_LOAD',
  SET_FORMDATA: 'set_formdata',
  UPDATE_FORMDATA: 'update_formdata',
  CLEAR_FORMDATA: 'clear_formdata',
  USER_LOADED: 'USER_LOADED',
  AUTH_ERROR: 'AUTH_ERROR',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  AUTH_FAIL: 'AUTH_FAIL',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',
  GET_PROFILE: 'GET_PROFILE',
  CLEAR_PROFILE: 'CLEAR_PROFILE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  LOGOUT: 'LOGOUT',
  SESSION_TIMEOUT: 'SESSION_TIMEOUT',
  SET_USERNAME: 'SET_USERNAME',
  ALL_USERS: 'ALL_USERS',
  SET_ALERT: 'SET_ALERT',
  REMOVE_ALERT: 'REMOVE_ALERT',
  CLEAR_CUSTOM_STORE: 'CLEAR_CUSTOM_STORE',
};
