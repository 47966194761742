import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {STYLES} from 'core/constants/uiConstants';
import {TooltipIcon, TooltipText} from '../uicore';
import {usePCTranslation} from 'core/hooks';

const Radiobuttons = ({
  getchangeCallback,
  getfocusCallback,
  lostfocusCallback,
  text,
  name,
  autoFocus,
  choice,
  readOnly,
  options,
  stateData,
  refItem,
  propStyle,
  isDisabled = false,
}) => {
  const { t } = usePCTranslation();
  const { controlItem } = useSelector((state) => ({
    controlItem: state.setupItems.controlItems
      ? state.setupItems.controlItems[name]
      : null,
  }));

  let optionItems = {};
  if (controlItem?.useOptions) {
    optionItems = controlItem.optionItems;
  }
  const controlOnChange = (event) => {
    //setSelectedOption(event.target.value);
    event.persist();
    //the event.target.value is the after-change value here but...
    getchangeCallback(event, event.target.name, event.target.value);
    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnFocus = (event) => {
    getfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnLostFocus = (event) => {
    lostfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  let containerClass = `${STYLES.INPUTCONTAINER} ${propStyle.inputcontainer}`;
  if (isDisabled || controlItem?.displayDisabled) {
    containerClass += " disabled";
  }
  if (controlItem?.displayHidden) {
    containerClass += " hiddenField";
  }
  let radioContainerClass = `${STYLES.BASIC} ${STYLES.RADIOCONTAINER} ${propStyle.radiocontainer}`;
  if (isDisabled || controlItem?.displayDisabled) {
    radioContainerClass += " disabled";
  }

  const radioLabel = () => {
    return (
      <label
        className={`${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`}
        id={name}
      >
        {t(`${name}-caption`)}
      </label>
    );
  };
  return (
    <div id={name} className={containerClass}>
      {!controlItem?.showTooltip ? (
        radioLabel()
      ) : controlItem?.showTooltipIcon ? (
        <Fragment>
          {radioLabel()}
          <div className={`${STYLES.TOOLTIP}`}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
        </Fragment>
      ) : (
        <div className={`${STYLES.TOOLTIP}`}>
          <TooltipText name={name} />
          {radioLabel()}
        </div>
      )}
      <div className={`radioGroup ${propStyle.radioGroup}`}>
        {Object.keys(optionItems).map((key) => (
          <div key={key} className={radioContainerClass}>
            <input
              className={`${STYLES.BASIC} ${STYLES.RADIO} ${
                propStyle.control
              } ${
                isDisabled || controlItem?.displayDisabled ? "disabled" : ""
              }`}
              type="radio"
              name={name}
              value={key}
              checked={stateData[name] === key}
              readOnly={controlItem?.readOnly || readOnly}
              onFocus={controlOnFocus}
              onBlur={controlOnLostFocus}
              onChange={controlOnChange}
              autoFocus={autoFocus}
              ref={refItem}
              disabled={
                choice === key ||
                isDisabled ||
                controlItem?.displayDisabled ||
                controlItem?.disabled
              }
            />
            <label
              className={`${STYLES.BASIC} ${STYLES.CHILD} ${propStyle.child}`}
              id={name}
            >
              {t(optionItems[key])}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

Radiobuttons.defaultProps = {
  autoFocus: false,
  choice: '',
  readOnly: false,
};

export {Radiobuttons};
