import {companyLogo, ProcompGreyLogo} from 'assets';
import React from 'react';

const IntroModal16 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <header className="sharedOwnershipHdr flexContainer alignCenter">
          <img
            id="intro-17"
            className=""
            style={{width: '6rem', height: '6rem'}}
            src={ProcompGreyLogo}
            alt=""
          />
          <h3 className="fontHdr1 fontPrimaryColor textAlignCenter">
            Potential Pitfalls or Hurdles of Shared Ownership Concept
          </h3>
        </header>
        <section className="sharedOwnershipContainer paddingLg bgWhite boxShadowAll marginMed noWrap">
          <ul className="textList">
            <li className="fontHdr2">
              The CRA has specified requirements regarding shared ownership arrangements, which
              should be reviewed prior to making a Shared Ownership agreement
            </li>
            <li className="fontHdr2">
              Singleness of contract must be maintained despite separation of components and
              beneficiary designations in the agreement
            </li>
            <li className="fontHdr2">Creditor protection may not be available</li>
            <li className="fontHdr2">General Anti-Avoidance Rules may apply</li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default IntroModal16;
