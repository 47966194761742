import React from 'react';
import {IllustrationDino, procompBlueLogo} from 'assets';

const BrowserUnsupported = () => {
  return (
    <div style={{textAlign: 'center', background: '#F1F2F2', height: '100vh'}}>
      <h1 style={{fontSize: '3.75rem', color: '#333', fontWeight: 800, paddingTop: '2.5rem'}}>
        This browser is extinct
      </h1>
      <div
        style={{
          backgroundImage: `url(${IllustrationDino})`,
          width: '50%',
          paddingTop: '15.85%',
          backgroundSize: '100%',
          marginTop: '3rem',
        }}></div>
      <p style={{fontSize: '1.56rem', color: '#333', width: '35%'}}>
        To enjoy our product please use a modern browser like{' '}
        <span style={{fontSize: '1.56rem', color: '#333', textDecoration: 'underline'}}>
          Chrome
        </span>{' '}
        or{' '}
        <span style={{fontSize: '1.56rem', color: '#333', textDecoration: 'underline'}}>
          Mozilla Firefox
        </span>
      </p>
      <div>
        <div style={{marginTop: '3rem'}}>
          <img className="about-logo" src={procompBlueLogo} alt="" width="170" />
        </div>
        <div>
          <a style={{color: '#1791b0'}} href="procompconsulting.com">
            procompconsulting.com
          </a>
        </div>
      </div>
      <div style={{position: 'fixed', bottom: '0.5rem', width: '100%'}}>
        Icons made by{' '}
        <a href="https://www.flaticons.com/authors/maxions" title="max.icons">
          max.icons{' '}
        </a>
        from{' '}
        <a href="https://www.flaticons.com/" title="Flaticon">
          www.flaticons.com
        </a>
      </div>
    </div>
  );
};

export default BrowserUnsupported;
