import {ActionTypes} from '../Types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  admin: [
    {
      routeId: 'admin01',
      appName: 'Admin',
      subModule: 'dashboard',
      paths: ['/admin/dashboard', '/admin/dashboard/'],
      displayName: 'titleAdmin',
      button: 'btnDashboard',
      isVisible: true,
    },
    {
      routeId: 'admin02',
      appName: 'Admin',
      subModule: 'roles',
      paths: ['/admin/roles', '/admin/roles/'],
      displayName: 'titleAdmin',
      button: 'btnRoles',
      isVisible: true,
    },
    {
      routeId: 'admin03',
      appName: 'Admin',
      subModule: 'users',
      paths: ['/admin/users', '/admin/users/'],
      displayName: 'titleAdmin',
      button: 'btnUsers',
      isVisible: true,
    },
    {
      routeId: 'admin04',
      appName: 'Admin',
      subModule: 'bi',
      paths: ['/admin/bi', '/admin/bi/'],
      displayName: 'titleAdmin',
      button: 'btnWidgetSettings',
      isVisible: true,
    }
  ],
  illustration: [
    {
      routeId: 'illus01',
      appName: 'NewBusiness',
      subModule: 'illustration',
      paths: ['/illustration', '/illustration/'],
      button: 'btnCreateIllustration',
      displayName: 'titleNewBusiness',
      isVisible: true,
    },
    {
      routeId: 'illus02',
      appName: 'NewBusiness',
      subModule: 'savedIllustration',
      paths: ['/illustration/savedIllustration', '/illustration/savedIllustration/'],
      button: 'btnSavedIllustration',
      displayName: 'titleNewBusiness',
      isVisible: true,
    },
  ],
  inforce: [
    {
      routeId: 'inforce01',
      appName: 'Inforce',
      subModule: 'policyLookup',
      paths: ['/inforce/policyLookup', '/inforce/policyLookup/'],
      button: 'btnPolicyLookup',
      displayName: 'titleInforce',
      isVisible: true,
    },
    {
      routeId: 'inforce02',
      appName: 'Inforce',
      subModule: 'alphalookup',
      paths: ['/inforce/alphalookup', '/inforce/alphalookup/'],
      button: 'btnAlphaLookup',
      displayName: 'titleInforce',
      isVisible: true,
    },
    {
      routeId: 'inforce03',
      appName: 'Inforce',
      subModule: 'dataReports',
      paths: ['/inforce/dataReports', '/inforce/dataReports/'],
      displayName: 'titleInforce',
      button: 'btnDataReports',
      isVisible: true,
    },
  ],
  eapplication: [
    {
      routeId: 'eapp01',
      appName: 'EApplication',
      subModule: 'eApplication',
      paths: ['/eapplication', '/eapplication/'],
      displayName: 'titleEApplication',
      button: 'btnEApplication',
      isVisible: true,
    },
  ],
  "eapp-settings": [
    {
      routeId: 'eapp-settings01',
      appName: 'E-Application',
      subModule: 'eapp-settings',
      paths: ['/eapp-settings', '/eapp-settings/'],
      displayName: 'titleEApplication',
      button: 'btnEApplication',
      isVisible: true,
    },
  ],
  proneeds: [
    {
      routeId: 'proneeds01',
      appName: 'ProNeeds',
      subModule: 'proneeds',
      paths: ['/proneeds', '/proneeds/'],
      displayName: 'titleProNeeds',
      button: 'btnProneeds',
      isVisible: true,
    },
  ],
  template: [
    {
      routeId: 'template01',
      appName: 'Template',
      subModule: 'template',
      paths: ['/template', '/template/'],
      displayName: 'titleTemplate',
      button: 'btnTemplate',
      isVisible: true,
    },
  ],
  developer: [
    {
      routeId: 'developer01',
      appName: 'Developer',
      subModule: 'controlItems',
      paths: ['/developer/controlItems', '/developer/controlItems/'],
      displayName: 'titleDeveloper',
      button: 'btnControlItems',
      isVisible: true,
    },
    {
      routeId: 'developer02',
      appName: 'Developer',
      subModule: 'interfaceSetupItems',
      paths: ['/developer/interfaceSetupItems', '/developer/interfaceSetupItems/'],
      displayName: 'titleDeveloper',
      button: 'btnInterfaceSetupItems',
      isVisible: true,
    },
  ],
  bi: [
    {
      routeId: 'bi01',
      appName: 'BI',
      subModule: 'widgets',
      paths: ['/bi/widgets', '/bi/widgets/'],
      displayName: 'titleDeveloper',
      button: 'btnBIDashboard',
      isVisible: true,
    },
    {
      routeId: 'bi02',
      appName: 'BI',
      subModule: 'tracking',
      paths: ['/bi/tracking', '/bi/tracking/'],
      displayName: 'titleDeveloper',
      button: 'btnTracking',
      isVisible: true,
    },
    {
      routeId: 'bi03',
      appName: 'BI',
      subModule: 'biReports',
      paths: ['/bi/biReports', '/bi/biReports/'],
      displayName: 'titleDeveloper',
      button: 'btnBIReports',
      isVisible: true,
    },
  ],
};

const routesReducer = (state = initialState, action) => {
  let routes = {};
  let adminRoutes = [];
  switch (action.type) {
    case ActionTypes.HIDE_BI_DETAILS:
      routes = cloneDeep(state);
      const biRoutes = routes["bi"];
      for (let i = 0; i < biRoutes.length; i++) {
        if (biRoutes[i].subModule === "tracking") {
          biRoutes[i].isVisible = false;
        }
      }
      routes["bi"] = biRoutes;
      return routes;
    case ActionTypes.HIDE_TRACKING:
      routes = cloneDeep(state);
      adminRoutes = routes["admin"];
      for (let i = 0; i < adminRoutes.length; i++) {
        if (
          adminRoutes[i].subModule === "bi" ||
          adminRoutes[i].subModule === "tracking" ||
          adminRoutes[i].subModule === "biReports" ||
          adminRoutes[i].subModule === "widgets"
        ) {
          //For demo, tracking is always set to true
          adminRoutes[i].isVisible = false;
          //adminRoutes[i].isVisible = false;
        }
      }
      routes["admin"] = adminRoutes;
      return routes;

    case ActionTypes.HIDE_USERS:
      routes = cloneDeep(state);
      adminRoutes = routes["admin"];
      for (let i = 0; i < adminRoutes.length; i++) {
        if (
          adminRoutes[i].subModule === "users" ||
          adminRoutes[i].subModule === "dashboard"
        ) {
          //For demo, tracking is always set to true
          adminRoutes[i].isVisible = false;
          //adminRoutes[i].isVisible = false;
        }
      }
      routes["admin"] = adminRoutes;
      return routes;

    case ActionTypes.HIDE_DEV_ROUTE:
      routes = cloneDeep(state);
      const devRoutes = routes["developer"];
      for (let i = 0; i < devRoutes.length; i++) {
        devRoutes[i].isVisible = false;
      }
      routes["developer"] = devRoutes;
      return routes;

    case ActionTypes.SET_ROUTES:
      return initialState;
    default:
      return state;
  }
};

export {routesReducer as routes};
