import {companyLogo, execLogo, ProcompGreyLogo} from 'assets';
import React from 'react';

const IntroModal2 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <header className="">
          <div className="sharedOwnershipHdr flexContainer alignCenter">
            <img
              id="intro-2"
              className=""
              style={{width: '6rem', height: '6rem'}}
              src={ProcompGreyLogo}
              alt=""
            />
            <h3 className="fontHdrCapitalize textAlignCenter">UNDERSTANDING SHARED OWNERSHIP</h3>
          </div>
          <h1 className="fontHdr1 fontPrimaryColor textAlignCenter">
            Who should consider a Shared Ownership arrangement?
          </h1>
        </header>
        <section className="sharedOwnershipContainer flexContainer autoCols justifyCenter alignCenter noWrap">
          <p className="fontHdr2 paddingLg fontBold textAlignCenter">
            Owner or Key Person/Executive of a corporation who
          </p>
          <section className="flexContainer alignCenter">
            <img id="exec-2" className="" src={execLogo} alt="person" />
            <div className="paddingLg bgWhite boxShadowAll">
              <ul>
                <li className="fontHdr2">Is a high income earner</li>
                <li className="fontHdr2">
                  May be eligible for large bonus or other taxable benefit
                </li>
                <li className="fontHdr2">Is seeking opportunities to minimize tax</li>
                <li className="fontHdr2">Has available after-tax income to shelter</li>
              </ul>
            </div>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal2;
