
export const MAX_ARRAY = 120;

export const CoverageTypes = {
    NONE: 0,
    BASE: 1,
    RIDER: 2,
    BENEFIT: 3,
    PUA: 4,
    OYT: 5,
    ENHANCED: 6
};

export const RiderTypes = {
    NONE: 0,
    ADB: 1,
    WAIVER: 2,
    AIB: 3,
    GPO: 4,
    CHILD: 5,
    PIB: 6,
    TERM: 7,
    UL: 8,
    OTHER: 9,
    DMB: 10
};

export const CstatCode = {
    DEATH: "D",
    CALCPREMREFUND: "1"
};

export const PolicyTypes = {
    None: 0,
    UL: 1,
    WholeLife: 2,
    LevelTerm: 3,
    TermWithCV: 4,
    DecreasingIncreasingTerm: 5,
    PaidUpLife: 6,
    Endowment: 7,
    ImmediateAnnuity: 8,
    RRIF: 9,
    LIF: 10,
    ESA_ISA: 11,
    Disability: 12,
    SegregatedFund: 13,
    SegregatedFundRRIF: 14,
    DeferredAnnuity: 15,
    Enhanced: 16,
    CriticalIllness: 17
};
export const BillingMethodTypes = {
    Mail: 0,
    EFT: 1,
    BillError: 2
};
export const DividendPayoutTypes = {
    None: 1,
    PUA: 2,
    DRP: 3,
    DOD: 4,
    Enhanced: 5,
    LRD: 6,
    PIC: 7,
    OYT: 8
};

export const COITypes = {
    None: 0,
    LevelCurrent: 1,
    LevelGuaranteed: 2,
    YRT: 3,
    Increasing: 4,
    YRTToLevel: 5,
    IncreasingToLevel: 6
};

export const DBOptionTypes = {
    None: 0,
    Level: 1,
    Indexed: 2,
    LevelPlusFund: 3
};

export const PartyTypes = {
    Person: 1,
    Company: 2
};
export const SpouseContributorTypes = {
    None: 0,
    Spouse: 1
};

export const SolveInputType = {
    ColumnValue: 1,
    GlobalItemValue: 2,
    RateArray: 3,
    PolicyProperty: 4,
    CoverageProperty: 5,
    FundProperty: 6,
    InsuredProperty: 7
}

export const SolveInputDirectionType = {
    Increase: 1,
    Decrease: 2
}

export const SolveGuessMethodType = {
    FromInput: 1,
    MidPoint: 2,
}

export const SolveTargetType = {
    CashValue: 1,
    ColumnValue: 2,
    GlobalItemValue: 3,
}

export const SolveTargetTolerance = {
    Converge: 1,
    FirstCorrectAnswer: 2,
}

export const SolveMethodType = {
    Secant: 1,
    Bisection: 2
}

export const DepositTypes = {
    FlatAmount: 0,
    Array: 1,
    Minimum: 2,
    LevelMaximum: 3,
    YearlyMaximum: 4
}


//ToDo: This needs to be moved to a core constant file
export const FundTypes = {
    DIA: 1,
    GIA: 2,
    DRP: 3,
    PRM: 4,
    DOD: 5,
    SideFund: 6,
    Loan: 7,
    SegFund: 8
}

export const ConstantValues = {
    DIVIDEND_ALTERNATESCALE: 75,
    ROWHIGHLIGHT_SPACING: 4
};

export const BillingTypeCode = {
    MAIL: '1',
    PREMIUMOFFSET: 'D',
    EFT: '4',
    APL: '9'
}

export const PremiumModeTypes = {
    //Months between payments
    Annual: 12,
    Monthly: 1,
    Quarterly: 3,
    SemiAnnual: 6
}

export const ExemptStatus = {
    ExemptWithTesting: 1,
    ExemptWithoutTesting: 2,
    NonExempt: 3,
    Grandfathered: 4,
    ExemptWithoutTestingNew: 5,
    NonExemptNew: 6
}

export const TaxAccrualRule = {
    None: 1,
    Trienniel: 2,
    CalendarYear: 3,
    PolicyYear: 4
}

export const BILLINGSTATUS_BILLING = 'B';
export const DIVIDEND_ALTERNATESCALE = 85;
export const ALTERNATE_COI_INCREASE = 1.25;
export const CLIENT_INTRATE_MAX_ADDITION = 3;


export const Alternate_IntRate = {
    Reduction: 1,
    StartYear: 2,
    Reduction_UL: 1,
    StartYear_UL: 4
}

export const PayoutInsuredTypes = {
    None: 0,
    Annuitant: 1,
    Spouse: 2
}

export const PayoutTypes = {
    None: 0,
    Minimum: 1,
    Maximum: 2,
    FlatAmount: 3
}

export const AssigneeTypes = {
    None: 0,
    Absolute: 1,
    Collateral: 2
}

export const PolicyLookupResultTypes = {
    PolicyFound: 1,
    PolicyNotFound: 2,
    PolicyNotQuotable: 3,
    PolicyPremiumRestrict: 4,
    PolicyTerminated: 5,
    PolicyPaidUp: 6,
    PolicyRejected: 7,
    PolicyETI: 8,
    PolicyNoFuturePremiums: 9,
    PolicyNotLoadable: 10
}

//The first 5 columns of the calculation engine are hard-coded
export const ReportColumns = {
    Year: 1,
    CalendarYear: 2,
    Age: 3,
    FilterCol: 4,
    IsCalc: 5
}

export const SOSBA_MONTHS_TO_MATURITY = 6;


