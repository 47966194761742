import {ActionTypes} from '../Types';

export const setRoutes = (user) => (dispatch) => {
  dispatch({type: ActionTypes.SET_ROUTES});

  if (user) {
    const {permissions} = user;
    const isAdminSystem =
      user.system?.isAdminSystem &&
      (permissions?.isAdminSystem?.isActive || permissions?.isAdminSystemAdministrator?.isActive);

    if (
      !user.system.isBusinessIntelligence ||
      !(
        user.system.isBusinessIntelligence &&
        (permissions?.isAdmin?.isActive ||
          permissions?.isBusinessIntelligence?.isActive ||
          isAdminSystem)
      )
    ) {
      dispatch({
        type: ActionTypes.HIDE_TRACKING,
      });
    }
    if (!permissions?.isAdmin?.isActive) {
      dispatch({
        type: ActionTypes.HIDE_USERS,
      });
    }
    if (!permissions?.isDeveloper?.isActive) {
      dispatch({
        type: ActionTypes.HIDE_DEV_ROUTE,
      });
    }

    if (
      user.system.isBusinessIntelligence &&
      user.permissions?.isBusinessIntelligence?.isActive &&
      !(user.permissions?.isAdmin?.isActive || isAdminSystem)
    ) {
      dispatch({
        type: ActionTypes.HIDE_BI_DETAILS,
      });
    }
  }
};
