import axios from 'axios';
import {dataStore} from 'core/redux/store';
import {logout} from 'core/redux/actions';
import {tokenRequest} from 'lib/authConfig';
import {InteractionRequiredAuthError} from '@azure/msal-browser';
import {msalInstance} from 'core/hocs/withCoreEvents';

export const setAuthToken = () => {
  const refreshToken = JSON.parse(sessionStorage.getItem('refreshToken'));
  if (refreshToken) {
    axios.defaults.headers['Authorization'] = `Bearer ${refreshToken.jwt}`;
  } else {
    delete axios.defaults.headers['Authorization'];
  }
  axios.defaults.headers['Content-Type'] = 'application/json';
};

export const refreshAccessToken = async () => {
  const account = msalInstance.getActiveAccount();
  try {
    const token = await msalInstance.acquireTokenSilent({
      ...tokenRequest,
      account,
    });
    return token.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance
        .acquireTokenPopup(tokenRequest)
        .then((resp) => {
          return resp.accessToken;
        })
        .catch((err) => {
          dataStore.dispatch(logout());
          return null;
        });
    } else {
      console.error(error);
    }
  }
};

export const setInterceptorAD = () => {
  axios.interceptors.request.use(
    async (config) => {
      config.headers['x-api-challenge'] = sessionStorage.getItem('challenge') || 'no-key-found';
      // refreshAccessToken method is the one which makes acquireTokenSilent call .
      const token = await refreshAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        sessionStorage.setItem('refreshToken', JSON.stringify({jwt: token}));
        setAuthToken();
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};
//For AD Refresh
export const setInterceptorAD1 = () => {
  setAuthToken();
  let isRefreshing = false;
  let failedQueue = [];
  const processQueue = (err, token = null) => {
    failedQueue.forEach((promise) => {
      if (!err) {
        promise.resolve(token);
      } else {
        promise.reject(err);
        // dataStore.dispatch(logout());
      }
    });
    failedQueue = [];
  };

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({resolve, reject});
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = JSON.parse(sessionStorage.getItem('refreshToken'));
        return new Promise(async (resolve, reject) => {
          try {
            const account = msalInstance.getActiveAccount();
            const response = await msalInstance.acquireTokenSilent({
              ...tokenRequest,
              account,
            });
            console.log(response);
            const refreshToken = {jwt: response.accessToken};
            sessionStorage.setItem('refreshToken', JSON.stringify(refreshToken));
            setAuthToken();
            originalRequest.headers['Authorization'] = 'Bearer ' + refreshToken.jwt;
            //Login route uses refresh token as body object. So body object needs to be updated for new refresh token
            if (originalRequest.url === '/users/login') {
              originalRequest.data = JSON.stringify(refreshToken);
            }
            processQueue(null, refreshToken.jwt);
            resolve(axios(originalRequest));
          } catch (error) {
            processQueue(error, null);
            dataStore.dispatch(logout());
          }
          isRefreshing = false;
        });
      }
      return Promise.reject(error);
    }
  );
};

// for multiple requests
export const setInterceptor = () => {
  setAuthToken();
  let isRefreshing = false;
  let failedQueue = [];
  const processQueue = (err, token = null) => {
    failedQueue.forEach((promise) => {
      if (!err) {
        promise.resolve(token);
      } else {
        promise.reject(err);
        // dataStore.dispatch(logout());
      }
    });
    failedQueue = [];
  };

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({resolve, reject});
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = JSON.parse(sessionStorage.getItem('refreshToken'));
        return new Promise((resolve, reject) => {
          axios
            .post('/users/refresh', refreshToken)
            .then(({data}) => {
              sessionStorage.setItem('refreshToken', JSON.stringify(data));
              setAuthToken();
              originalRequest.headers['Authorization'] = 'Bearer ' + data.jwt;
              //Login route uses refresh token as body object. So body object needs to be updated for new refresh token
              if (originalRequest.url === '/users/login') {
                originalRequest.data = JSON.stringify(data);
              }

              processQueue(null, data.jwt);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              dataStore.dispatch(logout());
              // reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
      return Promise.reject(error);
    }
  );
};

//For single request
export const setInterceptor1 = () => {
  setAuthToken();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      console.log(error, error.config, error.response);
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = JSON.parse(sessionStorage.getItem('refreshToken'));
        return axios
          .post('/users/refresh', refreshToken)
          .then(({data}) => {
            sessionStorage.setItem('refreshToken', JSON.stringify(data));
            setAuthToken();
            originalRequest.headers['Authorization'] = 'Bearer ' + data.jwt;

            //Login route uses refresh token as body object. So body object needs to be updated for new refresh token
            if (originalRequest.url === '/users/login') {
              originalRequest.data = JSON.stringify(data);
            }
            return axios(originalRequest);
          })
          .catch((error) => {
            dataStore.dispatch(logout());
          });
      }
      return Promise.reject(error);
    }
  );
};
