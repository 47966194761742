import { ActionTypes } from "custom/common/redux/Types";

const getFirstYearPremium = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_FIRSTYEAR_PREMIUM:
      // let tempArr = payload["dblTableData_Main"].concat(payload["dblTableData_Main"]);
      // dblTableDataMerge(payload["dblTableData_Main"], tempArr);
      // columnItemsMerge(payload["columnItems"], payload["columnItems"], "ALT");
      return {
        ...state,
        ...payload
      };
    case ActionTypes.REMOVE_FIRSTYEAR_PREMIUM:
      return {};
    default:
      return state;
  }
};

// const dblTableDataMerge = (arr1, arr2) => {
//   let mergedArray = [];
//   if (arr1.length > arr2.length) {
//     mergedArray = merge(arr1, arr2);
//   } else {
//     mergedArray = merge(arr2, arr1);
//   }
//   return mergedArray;
// };

// function merge(arr1, arr2) {
//   let new2dArr = [];
//   let temp1dArr = [];
//   let temp0Arr = Array(arr2[0].length).fill(0);

//   for (let idx = 0; idx < arr1.length; idx++) {
//     temp1dArr = [...arr1[idx]];
//     if (idx < arr2.length) {
//       temp1dArr=temp1dArr.concat(arr2[idx]);
//     } else {
//       temp1dArr=temp1dArr.concat(temp0Arr);
//     }
//     new2dArr.push(temp1dArr);
//   }
//   return new2dArr;
// }

export {getFirstYearPremium as firstYearPremium};