import React from 'react';
import {STYLES} from 'core/constants/uiConstants';
import {usePCTranslation} from 'core/hooks';

const Tab = ({activeTab, name, onClick}) => {
  const {t} = usePCTranslation();

  let className = STYLES.TABLISTITEM;
  if (activeTab === name) {
    className = `${className} ${STYLES.TABLISTACTIVE}`;
  }
  const onClickItem = (event) => {
    onClick(event, name, event.target.value);
  };
  return (
    <li className={className} onClick={onClickItem} name={name}>
      {t(`${name}-label`)}
    </li>
  );
};
export default Tab;
