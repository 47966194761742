import {ActionTypes} from 'custom/common/redux/Types';
import axios from 'axios';
import {updateStoreFormData} from 'core/redux/actions';

let cancelToken;
export const getFirstYearPremium = (formData) => async (dispatch) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = axios.CancelToken.source();
  try {
    const res = await axios.post('/api/GetFirstYearPremium', formData, {cancelToken: cancelToken.token});
    dispatch({
      type: ActionTypes.GET_FIRSTYEAR_PREMIUM,
      payload: res['data'],
    });

    dispatch(updateStoreFormData('lblPremium', res['data'].firstYearPremium, 'newBusiness'));
  } catch (error) {
    console.log(error);
  }
};

export const getFullPremium = (formData) => async (dispatch) => {
  try {
    const res = await axios.post('/api/GetFullPremium', formData);
    dispatch({
      type: ActionTypes.GET_FULL_PREMIUM,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
