import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {STYLES} from 'core/constants/uiConstants';
import {TooltipIcon, TooltipText} from '../uicore';
import {usePCTranslation} from 'core/hooks';

const Label = ({text, name, propStyle, useTranslation}) => {
  const {t} = usePCTranslation();
  const {controlItems} = useSelector((state) => ({
    controlItem: state.setupItems.controlItems,
  }));
  let labelText = useTranslation ? t(`${name}-label`) : text;

  const labelbox = () => (
    <label className={`${STYLES.LABEL} ${propStyle.label}`} id={name}>
      {labelText}
    </label>
  );
  return (
    <div id={name} className={`${STYLES.LABELCOMPONENT} ${propStyle.labelcomponent}`}>
      {controlItems && controlItems[name] && controlItems[name].showTooltipIcon ? (
        <Fragment>
          <div className={`${STYLES.TOOLTIP}`}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
          {labelbox()}
        </Fragment>
      ) : (
        <Fragment>
          {controlItems && controlItems[name] && controlItems[name].showTooltip ? (
            <Fragment>
              <div className={`${STYLES.TOOLTIP}`}>
                <TooltipText name={name} />
                {labelbox()}
              </div>
            </Fragment>
          ) : (
            labelbox()
          )}
        </Fragment>
      )}
    </div>
  );
};
Label.defaultProps = {
  useTranslation: true,
};

export {Label};
