import { ActionTypes } from "custom/common/redux/Types";

const initialState = {
  isLoaded: false
};

const dataReportsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.FETCH_DATAREPORTS:
      return { ...state, ...payload, isLoaded: true };
    case ActionTypes.FETCHERROR_DATAREPORTS:
    case ActionTypes.CLEAR_DATAREPORTS:
      return initialState;
    default:
      return state;
  }
};

export { dataReportsReducer as dataReports };
