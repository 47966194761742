import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {STYLES} from 'core/constants/uiConstants';
import {usePCTranslation} from 'core/hooks';

const TabsHeader = ({ children, activeTab, setActiveTab }) => {
  const { t } = usePCTranslation();
  const controlItems = useSelector((state) => state.setupItems.controlItems);
  const onClickTabItem = (event, tab, value) => {
    setActiveTab(event, tab, value);
  };

  const { permissions } = useSelector((state) => state.auth.user);

  return (
    <div className={`${STYLES.TABS}`}>
      <ul className={`${STYLES.TABLIST}`}>
        {children.map((child) => {
          if (
            child === undefined ||
            child?.props?.isHidden ||
            permissions[child?.props?.permissionName]?.isActive ===
              Boolean(false)
          ) {
            return null;
          }
          const { name, disable } = child.props;
          if ((controlItems[name] && controlItems[name].disabled) || disable) {
            return (
              <li
                key={name}
                name={name}
                className={`${STYLES.TABLISTITEM}${
                  activeTab === name ? ` ${STYLES.TABLISTACTIVE}` : " disabled"
                }`}
              >
                {t(`${name}-label`)}
              </li>
            );
          }
          return (
            <li
              key={name}
              name={name}
              className={`${STYLES.TABLISTITEM}${
                activeTab === name ? ` ${STYLES.TABLISTACTIVE}` : ""
              }`}
              onClick={(e) => onClickTabItem(e, name, e.target.value)}
            >
              {t(`${name}-label`)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default TabsHeader;
