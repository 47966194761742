//https://github.com/text-mask/text-mask Reference link for MaskedInput component

import React, {useState, Fragment, useContext} from 'react';
import {useSelector} from 'react-redux';
import MaskedInput from 'react-text-mask';
import {usePCTranslation} from 'core/hooks';
import {STYLES} from 'core/constants/uiConstants';
import {ValidationContext, ValidationMessage} from 'core/formValidation';
import {TooltipText, TooltipIcon} from 'core/uicore';
import {getMaskForInput} from 'core/utils/inputTypeUtils';

const MaskedTextbox = ({getchangeCallback, getfocusCallback, lostfocusCallback, stateData, name, text, type, autoFocus, refItem, propStyle, readOnly, step, styleProp}) => {
  const {t} = usePCTranslation();
  const {errors, formErrors} = useContext(ValidationContext);
  const {controlItem, inputLanguage} = useSelector((state) => ({
    controlItem: state.setupItems.controlItems ? state.setupItems.controlItems[name] : null,
    inputLanguage: state.auth.user ? state.auth.user['inputLanguage'] : 'English',
  }));

  const controlOnChange = async (event) => {
    event.target.value = event.target.value.toUpperCase();
    await getchangeCallback(event, event.target.name, event.target.value);
    event.stopPropagation();
  };
  const controlOnFocus = (event) => {
    event.persist();
    setTimeout(() => {
      getfocusCallback(event, event.target.name, event.target.value);
      //DO NOT propagate this event up as it should only be for the current control
      event.stopPropagation();
    }, 0);
  };
  const controlOnLostFocus = (event) => {
    event.persist();
    // setFocus(false);
    setTimeout(() => {
      // console.log('controlOnLostFocus', event);
      lostfocusCallback(event, event.target.name, event.target.value);
      //DO NOT propagate this event up as it should only be for the current control
      event.stopPropagation();
    }, 0);
  };

  let containerClass = `${STYLES.INPUTCONTAINER} ${propStyle.textcontainer}`;
  if ((errors[name] || formErrors[name]) && controlItem && controlItem.isDirty) {
    containerClass += ' errorState';
  }
  if (controlItem && controlItem.displayDisabled) {
    containerClass += ' disabled';
  }
  if (controlItem && controlItem.displayHidden) {
    containerClass += ' hiddenField';
  }

  const textInput = () => {
    return (
      <MaskedInput
        mask={getMaskForInput(controlItem.fieldType || '')}
        className={`${STYLES.BASIC} ${STYLES.TEXT} ${propStyle.control}`}
        guide={false}
        value={stateData}
        id={name}
        name={name}
        step={step}
        readOnly={(controlItem && controlItem.readOnly) || readOnly}
        onFocus={controlOnFocus}
        onChange={controlOnChange}
        // onInput = {() => {}}
        autoFocus={autoFocus}
        onBlur={controlOnLostFocus}
        ref={refItem}
        maxLength={controlItem.maxLength}
        style={styleProp}
        autoComplete='new_off'
      />
    );
  };
  return (
    <div id={name} className={containerClass}>
      <label className={`${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`} id={name}>
        {controlItem.showLabel && t(`${name}-caption`)}
      </label>
      {!controlItem.showTooltip ? (
        textInput()
      ) : controlItem.showTooltipIcon ? (
        <Fragment>
          <div className={`${STYLES.TOOLTIP}`}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
          {textInput()}
        </Fragment>
      ) : (
        <div className={`${STYLES.TOOLTIP} noIcon`}>
          <TooltipText name={name} />
          {textInput()}
        </div>
      )}

      {controlItem && controlItem.isDirty && <ValidationMessage name={name} field={name} size='10' propStyle={{validationmsg: 'textboxvalidation'}} />}
    </div>
  );
};
MaskedTextbox.defaultProps = {
  autoFocus: false,
  readOnly: false,
  step: 0,
  styleProp: {},
};

export {MaskedTextbox};
