import {ActionTypes} from '../types';

const initialState = {};

const formDataReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.SET_FORMDATA:
      sessionStorage.setItem(payload.dataType, JSON.stringify(payload.formData));
      return {
        ...state,
        [payload.dataType]: payload.formData,
      };

    case ActionTypes.UPDATE_FORMDATA:
      let cacheData = {};
      if (sessionStorage.getItem(payload.dataType)) {
        cacheData = JSON.parse(sessionStorage.getItem(payload.dataType));
        cacheData = {...cacheData, [payload.name]: payload.value};
        sessionStorage.setItem(payload.dataType, JSON.stringify(cacheData));
      }
      return {
        ...state,
        [payload.dataType]: {
          ...state[payload.dataType],
          [payload.name]: payload.value,
        },
      };
    case ActionTypes.CLEAR_FORMDATA:
      console.log('Clear Form Data: ', payload);
      const {dataType, formData} = payload;
      if (dataType) {
        sessionStorage.setItem(dataType, JSON.stringify(formData)); //Initialize with initial values, this applied mainly to alphalookup only
        return {
          ...state,
          [dataType]: formData,
        };
      }
      return initialState;
    default:
      return state;
  }
};

export {formDataReducer as formData};
