import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Spinner} from 'core/uicore';
import {useRouter} from 'core/hooks/useRouter';

export const PrivateRoute = ({
  isAuthorized,
  children,
  isAdmin,
  isInforce,
  isNewBusiness,
  ...rest
}) => {
  const {isAuthenticated, user, controlItems} = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    controlItems: state.setupItems.controlItems,
  }));
  const {location} = useRouter();

  if (!isAuthenticated) {
    return <Redirect to={{pathname: '/login', state: {from: location}}} />;
  }
  if (!controlItems) {
    return <Spinner />;
  }
  let defaultApp = '/login';
  if (isNewBusiness && isNewBusiness() && user?.defaultApplication === 'New Business') {
    defaultApp = '/illustration';
  } else if (isInforce && isInforce() && user?.defaultApplication === 'Inforce') {
    defaultApp = '/inforce';
  } else if (isAdmin && isAdmin() && user?.defaultApplication === 'Admin') {
    defaultApp = '/admin';
  } else if (isNewBusiness && isNewBusiness()) {
    defaultApp = '/illustration';
  } else if (isInforce && isInforce()) {
    defaultApp = '/inforce';
  }
  return (
    <Route
      {...rest}
      render={() =>
        isAuthorized ? children : <Redirect to={{pathname: defaultApp, state: {from: location}}} />
      }
    />
  );
};

export default PrivateRoute;
