function sessionStore(formName, name, value) {
  let cacheData = {};
  if (sessionStorage.getItem(formName)) {
    cacheData = JSON.parse(sessionStorage.getItem(formName));
    cacheData = {...cacheData, [name]: value};
    sessionStorage.setItem(formName, JSON.stringify(cacheData));
  }
}
//this utility will update store as well as session storage
export function updateStoreFormSessionData(formData, name, value = "") {
  switch (formData["formName"]) {
    case "illustration":
      formData[name] = value;
      sessionStore("newBusiness", name, value);
      break;
    case "inforce":
      formData[name] = value;
      sessionStore("inforceFormData", name, value);
      break;
    case "alphalookup":
      break;
    case "eApplication":
      formData[name] = value;
      sessionStore("eAppFormData", name, value);
      break;
    case "dataReports":
      break;

    default:
      break;
  }
}

export const setContentHeight = (delay = 100) => {
  // setTimeout(() => {
  //   let height = 0;
  //   let list = document.querySelectorAll('.fixedPageHdr');
  //   list.forEach((item) => {
  //     height += item.offsetHeight;
  //   });
  //   let contentEl = document.getElementById('content-id');
  //   if (contentEl) {
  //     contentEl.style.marginTop = height + 'px';
  //   }
  // }, 100);
  // setTimeout(() => {
  //   let height = 0;
  //   let list = document.querySelectorAll('.fixedPageHdr');
  //   list.forEach((item) => {
  //     height += item.offsetHeight;
  //   });
  //   let contentEl = document.getElementById('content-id');
  //   if (contentEl) {
  //     contentEl.style.marginTop = height + 'px';
  //   }
  // }, 200);
  // setTimeout(() => {
  //   let height = 0;
  //   let list = document.querySelectorAll('.fixedPageHdr');
  //   list.forEach((item) => {
  //     height += item.offsetHeight;
  //   });
  //   let contentEl = document.getElementById('content-id');
  //   if (contentEl) {
  //     contentEl.style.marginTop = height + 'px';
  //   }
  // }, 300);
};
