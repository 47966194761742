import React from 'react';
import * as globalEvents from 'core/utils/globalEvents';
import * as customEvents from 'custom/common/utils/customEvents';
import {setInterceptor, setInterceptorAD} from 'core/utils/setAuthConfig';
import {fetchUser} from 'core/redux/actions';
import {dataStore} from 'core/redux/store';
import axios from 'axios';

// MSAL imports
import {PublicClientApplication, EventType} from '@azure/msal-browser';
import {loginRequest, msalConfig} from 'lib/authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

export const withCoreEvents = (WrappedComponent) => {
  class ContainerComponent extends React.Component {
    state = {
      isAppLoading: false,
      isAppChanging: false,
    };
    appLoad = async (
      user,
      controlItems,
      formData,
      elementItems,
      interfaceSetupItems,
      interfaceSetupArray,
      allSetupItems,
      updateFormData,
      updateControlItems,
      inforceData
    ) => {
      this.setState({isAppLoading: true});
      await this.appRunAllBusinessRules(
        user,
        controlItems,
        formData,
        elementItems,
        interfaceSetupItems,
        interfaceSetupArray,
        allSetupItems,
        updateFormData,
        updateControlItems,
        inforceData
      );
      this.setState({isAppLoading: false});
    };
    appGetFocus = (
      event,
      controlItems,
      formData,
      elementItems,
      interfaceSetupItems,
      updateFormData,
      updateControlItems
    ) => {
      globalEvents.GlobalGetFocus(
        event.target.name,
        controlItems,
        formData,
        elementItems,
        interfaceSetupItems,
        updateFormData,
        updateControlItems
      );
    };
    appLostFocus = (
      event,
      user,
      controlItems,
      formData,
      elementItems,
      interfaceSetupItems,
      interfaceSetupArray,
      allSetupItems,
      updateFormData,
      updateControlItems,
      inforceData
    ) => {
      globalEvents.GlobalLostFocus(
        event.target.name,
        user,
        controlItems,
        formData,
        elementItems,
        interfaceSetupItems,
        interfaceSetupArray,
        allSetupItems,
        updateFormData,
        updateControlItems,
        inforceData
      );
    };
    appChange = async (
      event,
      user,
      controlItems,
      formData,
      elementItems,
      interfaceSetupItems,
      interfaceSetupArray,
      allSetupItems,
      updateFormData,
      updateControlItems,
      inforceData
    ) => {
      this.setState({isAppLoading: true});
      await globalEvents.GlobalChange(
        event.target.name,
        user,
        controlItems,
        formData,
        elementItems,
        interfaceSetupItems,
        interfaceSetupArray,
        allSetupItems,
        updateFormData,
        updateControlItems,
        inforceData
      );
      this.setState({isAppLoading: false});
    };
    appClick = async (
      name,
      user,
      controlItems,
      formData,
      elementItems,
      interfaceSetupItems,
      allSetupItems,
      updateFormData,
      updateControlItems,
      inforceData
    ) => {
      this.setState({isAppLoading: true});
      await globalEvents.GlobalClick(
        name,
        user,
        controlItems,
        formData,
        elementItems,
        interfaceSetupItems,
        allSetupItems,
        updateFormData,
        updateControlItems,
        inforceData
      );
      this.setState({isAppLoading: false});
    };
    appRunAllBusinessRules = (
      user,
      controlItems,
      formData,
      elementItems,
      interfaceSetupItems,
      interfaceSetupArray,
      allSetupItems,
      updateFormData,
      updateControlItems,
      inforceData
    ) => {
      Object.keys(formData).forEach((item) => {
        customEvents.GlobalCustomBusinessRules(
          item,
          user,
          controlItems,
          formData,
          elementItems,
          interfaceSetupItems,
          interfaceSetupArray,
          allSetupItems,
          updateFormData,
          updateControlItems,
          inforceData,
          true
        );
      });
    };
    async componentDidMount() {
      let tokenMethod;
      try {
        const {data} = await axios.get('/api/getTokenMethod');
        tokenMethod = data;
        sessionStorage.setItem('tokenMethod', tokenMethod);
      } catch (error) {
        console.log('getTokenMethod error: ', error);
      }
      if (tokenMethod === 'AD') {
        try {
          let isRefresh = false;
          if (sessionStorage.getItem('refreshToken')) {
            isRefresh = true;
          }
          await msalInstance.handleRedirectPromise();
          const {account, accessToken} = await msalInstance.acquireTokenPopup(loginRequest);
          const refreshToken = {jwt: accessToken};

          sessionStorage.setItem('refreshToken', JSON.stringify(refreshToken));
          setInterceptorAD();
          dataStore.dispatch(fetchUser(account, isRefresh));
        } catch (error) {
          console.log('error occured: ' + error);
        }
      } else {
        const refreshToken = JSON.parse(sessionStorage.getItem('refreshToken'));
        if (refreshToken) {
          setInterceptor();
          dataStore.dispatch(fetchUser(undefined, true)); // (account, isRefresh)
        }
      }
    }
    render() {
      return (
        <WrappedComponent
          globalGetFocus={this.appGetFocus}
          globalChange={this.appChange}
          globalLostFocus={this.appLostFocus}
          globalClick={this.appClick}
          onLoad={this.appLoad}
          isAppLoading={this.state.isAppLoading}
          pca={msalInstance}
        />
      );
    }
  }
  return ContainerComponent;
};
export default withCoreEvents;
