import {
  hideControl,
  showControl,
  disableControl,
  enableControl,
  showControlBatch,
  hideControlBatch,
  enableControlBatch,
  disableControlBatch,
  lockControl,
  unlockControl,
} from 'core/utils/globalUtils';
import {getCoverageItemByType} from 'core/utils/calcUtils';

import {
  PolicyTypes,
  DividendPayoutTypes,
  BillingTypeCode,
  CoverageTypes,
  PolicyLookupResultTypes,
} from 'core/constants/calcConstants';
import {
  PolicyTypes as PolicyTypesNewbiz,
  interfaceItemsPolicyTypes,
  DivOptions,
  ProdID,
  isTermPolicy,
  isTerm100Policy,
  isWholeLifePolicy,
  isUniversalLifePolicy,
} from 'custom/newbiz/utils/constants';

import {
  isProductHealth,
  fullName,
  calcAgeNearest,
  GetLastAnniversaryDate,
} from 'custom/common/utils/lookupFunctions';
import {updateStoreFormSessionData} from './functions';

let controlFields = {
  cboRateClass: JSON.parse(sessionStorage.getItem('newBusiness'))
    ? JSON.parse(sessionStorage.getItem('newBusiness')).cboRateClass
    : 'P',
  chkTerm10: JSON.parse(sessionStorage.getItem('newBusiness'))
    ? JSON.parse(sessionStorage.getItem('newBusiness')).chkTerm10
    : true,
  chkTerm20: JSON.parse(sessionStorage.getItem('newBusiness'))
    ? JSON.parse(sessionStorage.getItem('newBusiness')).chkTerm20
    : false,
  chkTerm30: JSON.parse(sessionStorage.getItem('newBusiness'))
    ? JSON.parse(sessionStorage.getItem('newBusiness')).chkTerm30
    : false,
  chkADO: JSON.parse(sessionStorage.getItem('newBusiness'))
    ? JSON.parse(sessionStorage.getItem('newBusiness')).chkADO
    : false,
};
export function initializeControlFields(formData, useSession = true) {
  let sessData = JSON.parse(sessionStorage.getItem('controlFields'));
  if (sessData && useSession) {
    controlFields['cboRateClass'] = sessData.cboRateClass;
    controlFields['chkTerm10'] = sessData.chkTerm10;
    controlFields['chkTerm20'] = sessData.chkTerm20;
    controlFields['chkTerm30'] = sessData.chkTerm30;
    controlFields['chkADO'] = sessData.chkADO;
  } else {
    controlFields['cboRateClass'] = formData['cboRateClass'];
    controlFields['chkTerm10'] = formData['chkTerm10'];
    controlFields['chkTerm20'] = formData['chkTerm20'];
    controlFields['chkTerm30'] = formData['chkTerm30'];
    controlFields['chkADO'] = formData['chkADO'];
  }
}
export function updateControlFields(formData, name) {
  controlFields[name] = formData[name];
  let sessContext = JSON.parse(sessionStorage.getItem('controlFields'));
  sessContext = {...sessContext, ...controlFields};
  sessionStorage.setItem('controlFields', JSON.stringify({...sessContext}));
}
let pageSelection = {
  chkCoverPage: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkCoverPage
    : true,
  chkTaxPage: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkTaxPage
    : false,
  chkUnderwritingPage: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkUnderwritingPage
    : false,
  chkComplianceNotice: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkComplianceNotice
    : true,
  chkCoveragePage: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkCoveragePage
    : true,
  chkBenefitsPage: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkBenefitsPage
    : true,
};

let conceptSelection = {
  chkProductCompare: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkProductCompare
    : false,
  chkInternalRoR: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkInternalRoR
    : false,
  chkCharitableGiving: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkCharitableGiving
    : false,
  chkInsuranceInvestment: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkInsuranceInvestment
    : false,
  chkSharedOwnership: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkSharedOwnership
    : false,
};
const incomePayout = {
  chkIncomeStoryboardPage: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkIncomeStoryboardPage
    : false,
  chkIncomeNonRegPage: JSON.parse(sessionStorage.getItem("newBusiness"))
    ? JSON.parse(sessionStorage.getItem("newBusiness")).chkIncomeNonRegPage
    : false,
};
export function getPageConceptSelection() {
  return { pageSelection, conceptSelection, incomePayout };
}
export function initializePageConceptSelection(formData, useSession = true) {
  let sessData = JSON.parse(sessionStorage.getItem("pageConcept"));
  if (sessData && useSession) {
    pageSelection["chkCoverPage"] = sessData.chkCoverPage;
    pageSelection["chkTaxPage"] = sessData.chkTaxPage;
    pageSelection["chkUnderwritingPage"] = sessData.chkUnderwritingPage;
    pageSelection["chkComplianceNotice"] = sessData.chkComplianceNotice;
    conceptSelection["chkProductCompare"] = sessData.chkProductCompare;
    conceptSelection["chkInternalRoR"] = sessData.chkInternalRoR;
    conceptSelection["chkCharitableGiving"] = sessData.chkCharitableGiving;
    conceptSelection["chkInsuranceInvestment"] =
      sessData.chkInsuranceInvestment;
    conceptSelection["chkSharedOwnership"] = sessData.chkSharedOwnership;
    incomePayout["chkIncomeStoryboardPage"] = sessData.chkIncomeStoryboardPage;
    incomePayout["chkIncomeNonRegPage"] = sessData.chkIncomeNonRegPage;
    pageSelection["chkBenefitsPage"] = sessData.chkBenefitsPage;
    pageSelection["chkCoveragePage"] = sessData.chkCoveragePage;
  } else {
    pageSelection["chkCoverPage"] =
      formData["chkCoverPage"] === "true" ? true : false;
    pageSelection["chkTaxPage"] =
      formData["chkTaxPage"] === "true" ? true : false;
    pageSelection["chkUnderwritingPage"] =
      formData["chkUnderwritingPage"] === "true" ? true : false;
    pageSelection["chkComplianceNotice"] =
      formData["chkComplianceNotice"] === "true" ? true : false;
    conceptSelection["chkProductCompare"] =
      formData["chkProductCompare"] === "true" ? true : false;
    conceptSelection["chkInternalRoR"] =
      formData["chkInternalRoR"] === "true" ? true : false;
    conceptSelection["chkCharitableGiving"] =
      formData["chkCharitableGiving"] === "true" ? true : false;
    conceptSelection["chkInsuranceInvestment"] =
      formData["chkInsuranceInvestment"] === "true" ? true : false;
    conceptSelection["chkSharedOwnership"] =
      formData["chkSharedOwnership"] === "true" ? true : false;
    incomePayout["chkIncomeStoryboardPage"] =
      formData["chkIncomeStoryboardPage"] !== "false" ? true : false;
    incomePayout["chkIncomeNonRegPage"] =
      formData["chkIncomeNonRegPage"] !== "false" ? true : false;
    pageSelection["chkCoveragePage"] =
      formData["chkCoveragePage"] === "true" ? true : false;
    pageSelection["chkBenefitsPage"] =
      formData["chkBenefitsPage"] === "true" ? true : false;
    sessionStorage.setItem(
      "pageConcept",
      JSON.stringify({ ...pageSelection, ...conceptSelection, ...incomePayout })
    );
  }
}

export function updateConceptItem(formData, name) {
  conceptSelection[name] = formData[name];
  let sessConcept = JSON.parse(sessionStorage.getItem("pageConcept"));
  sessConcept = { ...sessConcept, ...conceptSelection };
  sessionStorage.setItem("pageConcept", JSON.stringify({ ...sessConcept }));
}

export function updatePageItem(formData, name) {
  pageSelection[name] = formData[name];
  let sessConcept = JSON.parse(sessionStorage.getItem("pageConcept"));
  sessConcept = { ...sessConcept, ...pageSelection };
  sessionStorage.setItem("pageConcept", JSON.stringify({ ...sessConcept }));
}
export function updateIncomePayoutItem(formData, name) {
  incomePayout[name] = formData[name];
  let sessConcept = JSON.parse(sessionStorage.getItem("pageConcept"));
  sessConcept = { ...sessConcept, ...incomePayout };
  sessionStorage.setItem("pageConcept", JSON.stringify({ ...sessConcept }));
}
export function GlobalCustomLostFocus(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  interfaceSetupArray,
  allSetupItems,
  updateFormData,
  updateControlItems,
  inforceData,
  runAll,
  isRecall
) {
  // window.testFunction();
  window.GlobalCustomLostFocus(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    interfaceSetupArray,
    allSetupItems,
    updateFormData,
    updateControlItems,
    inforceData,
    runAll,
    isRecall
  );
}

export function GlobalCustomClickBusinessRules(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  allSetupItems,
  updateFormData,
  updateControlItems,
  inforceData
) {
  window.customClickBusinessRules(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    allSetupItems,
    updateFormData,
    updateControlItems,
    inforceData
  );
}

export function GlobalCustomBusinessRules(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  interfaceSetupArray,
  allSetupItems,
  updateFormData,
  updateControlItems,
  inforceData,
  runAll = false,
  isRecall = false
) {
  window.customBusinessRules(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    interfaceSetupArray,
    allSetupItems,
    updateFormData,
    updateControlItems,
    inforceData,
    runAll,
    isRecall
  );
}

export function GlobalCustomBuildTrackingData(
  name,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  updateFormData,
  inforceData
) {
  // let data = {...formData};
  // let key = '';
  // let val = '';
  // if (formData['formName'] === 'eApplication') {
  //   if (data['fileAttachments'].length > 0) {
  //     for (let attachment of data['fileAttachments']) {
  //       key = attachment.fileName;
  //       val = `${attachment.documentType}|${attachment.size}`;
  //       data[key] = val;
  //     }
  //   }
  //   delete data['fileAttachments'];
  //   return data;
  // }
  return null;
}

//setting window function start
window.initializePageConceptSelection = initializePageConceptSelection;

window.updateStoreFormSessionData = updateStoreFormSessionData;
window.isProductHealth = isProductHealth;
window.isTermPolicy = isTermPolicy;
window.isTerm100Policy = isTerm100Policy;
window.isWholeLifePolicy = isWholeLifePolicy;
window.isUniversalLifePolicy = isUniversalLifePolicy;
window.hideControl = hideControl;
window.showControl = showControl;
window.disableControl = disableControl;
window.enableControl = enableControl;
window.showControlBatch = showControlBatch;
window.hideControlBatch = hideControlBatch;
window.enableControlBatch = enableControlBatch;
window.disableControlBatch = disableControlBatch;
window.lockControl = lockControl;
window.unlockControl = unlockControl;
window.getCoverageItemByType = getCoverageItemByType;
window.fullName = fullName;
window.updateConceptItem = updateConceptItem;
window.updatePageItem = updatePageItem;
window.updateIncomePayoutItem = updateIncomePayoutItem;
window.updateControlFields = updateControlFields;
window.PolicyTypesNewbiz = PolicyTypesNewbiz;
window.interfaceItemsPolicyTypes = interfaceItemsPolicyTypes;
window.DivOptions = DivOptions;
window.ProdID = ProdID;
window.PolicyTypes = PolicyTypes;
window.DividendPayoutTypes = DividendPayoutTypes;
window.BillingTypeCode = BillingTypeCode;
window.CoverageTypes = CoverageTypes;
window.PolicyLookupResultTypes = PolicyLookupResultTypes;
window.pageSelection = pageSelection;
window.conceptSelection = conceptSelection;
window.incomePayout = incomePayout;
window.controlFields = controlFields;
window.calcAgeNearest = calcAgeNearest;
window.GetLastAnniversaryDate = GetLastAnniversaryDate;
//setting window function end
