import { ActionTypes } from "custom/common/redux/Types";
import axios from "axios";
import { setAlert } from "core/redux/actions";

export const updateBIOptionsProfile =
  (biFormData) => async (dispatch, getState) => {
    let user = { ...getState().auth.user };
    const isAuthenticated = getState().auth.isAuthenticated;

    if (isAuthenticated) {
      const userFormData = { ...user["formData"], ...biFormData };

      user["formData"] = biFormData;

      try {
        const res = await axios.post("/users/update", user);
        user["formData"] = userFormData;
        dispatch({
          type: ActionTypes.UPDATE_PROFILE,
          payload: user,
        });
        console.log("Updating reports profile", res.data);
      } catch (error) {
        if (error && error.response) {
          dispatch(
            setAlert(
              error.response.data["message"],
              "Error",
              "updateProfile",
              5000
            )
          );
          console.log(error.response);
        }
      }
    }
  };
