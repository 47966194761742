import React, { Fragment } from "react";
//import Button from "../controls/Button";
import CalcTable from "./CalcTable";

const FullPremiumTable = ({
  controlGetFocus,
  controlLostFocus,
  storeFormData,
  setElement,
  insuranceData,
  freezeColumnCount = 3, //,
  //illustrationState
}) => {
  return (
    <Fragment>
      {/* <Button
        text="Return"
        getfocusCallback={controlGetFocus}
        getclickCallback={GetInput}
        lostfocusCallback={controlLostFocus}
        stateData={storeFormData}
        name="btnReturn"
        refItem={setElement}
        propStyle={{ label: 'buttonlabel', control: 'buttoncontrol' }}
      /> */}
      <CalcTable
        dblTableData={insuranceData?.dblTableData_Main}
        columnItems={insuranceData?.columnItems}
        name="tblCalc"
        propStyle={{
          table: "table tableOverview",
          head: "head",
          row: "row",
          data: "data",
          body: "body",
        }}
        freezeColumnCount={freezeColumnCount}
      />
    </Fragment>
  );
};

export default FullPremiumTable;
