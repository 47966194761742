import React, {Component} from 'react';
import {STYLES} from 'core/constants/uiConstants';
import {withPCTranslation} from 'core/hocs';

class PremiumDisplay extends Component {
  formatValue(n) {
    return '$' + n.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  render() {
    let insuranceData = this.props.insuranceData;
    //let msg_premiumsummary = this.props.t('msg-PremiumSummary');
    let msg_basepremium = this.props.t('msg-BasePremium');
    let msg_annualpremium = this.props.t('msg-AnnualPremium');
    let msg_modalpremium = this.props.t('msg-ModalPremium');
    let msg_nodata = this.props.t('msg-NoData');
    let msg_notApplicable = this.props.t('msg-NotApplicable');
    const {
      propStyle: {container, header, summary, coveragetype, coveragevalue},
    } = this.props;

    if (Object.keys(insuranceData).length === 0) {
      return (
        <div className={`${STYLES.BASIC} ${STYLES.HEADER} ${header} paddingMedWide`}>
          {msg_nodata}
        </div>
      );
    } else {
      //const errors = this.props.stateData['numErrors'] == 0;
      if (this.props.stateData['numErrors'] > 0 || insuranceData.jointAgeError) {
        return (
          <div className={`${STYLES.BASIC} ${STYLES.PREMIUMDISPLAY} ${container}`}>
            {/* <div className={`${STYLES.BASIC} ${STYLES.HEADER} ${header}`}>
              {msg_premiumsummary}
            </div> */}
            <div className={`${STYLES.BASIC} ${STYLES.SUMMARY} ${summary}`}>
              {msg_notApplicable}
            </div>
          </div>
        );
      } else {
        return (
          <div className={`${STYLES.BASIC} ${STYLES.PREMIUMDISPLAY} ${container}`}>
            {/* <div className={`${STYLES.BASIC} ${STYLES.HEADER} ${header}`}>
              {msg_premiumsummary}
            </div> */}
            <div className={`${STYLES.SUMMARYCONTAINER}`}>
              <div className={`${STYLES.BASIC} ${STYLES.SUMMARY} ${summary}`}>
                <div className="base-premium">{msg_basepremium}</div>
                <div id="base-premium-value" className="base-premium-value evenLetterSpacing">
                  {this.formatValue(this.getInitialPremiumByID('001'))}
                </div>
              </div>
              {Object.keys(insuranceData.coverageListItems).map((i) => (
                <div
                  className={`${STYLES.BASIC} ${STYLES.SUMMARY} ${summary} paddingMedLeftIndent`}
                  key={insuranceData.coverageListItems[i].name}>
                  <div className={`${STYLES.COVERAGETYPE} ${coveragetype}`}>
                    {insuranceData.coverageListItems[i].coverageType}
                  </div>
                  <div className={`${STYLES.COVERAGEVALUE} ${coveragevalue} evenLetterSpacing`}>
                    {this.formatValue(insuranceData.coverageListItems[i].annualPremium)}
                  </div>
                </div>
              ))}
              <div className={`${STYLES.BASIC} ${STYLES.SUMMARY} ${summary}`}>
                <div className="annual-premium">{msg_annualpremium}</div>
                <div id="annual-premium-value" className="annual-premium-value evenLetterSpacing">
                            {this.formatValue(insuranceData.firstYearPremiumAnnual)}
                </div>
              </div>
              <div className={`${STYLES.BASIC} ${STYLES.SUMMARY} ${summary}`}>
                <div className="modal-premium">{msg_modalpremium}</div>
                <div id="modal-premium-value" className="modal-premium-value evenLetterSpacing">
                            {this.formatValue(insuranceData.firstYearPremium)}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  getInitialPremiumByID(strCoverageID) {
    for (let columnItem of this.props.insuranceData.columnItems) {
      if (columnItem.name === 'AnnualPremium_' + strCoverageID) {
        //console.log("FOUND");
        //Object.keys(aColumnItem).map(i => console.log(`${i} = ${aColumnItem[i]}`));
        //console.log(this.props.insuranceData.dblTableData_Main[1][aColumnItem.columnNumber]);
        return this.props.insuranceData.dblTableData_Main[1][columnItem.columnNumber];
      }
    }

    return 0;
  }

  getInitialPremiumByName(strName) {
    for (let columnItem of this.props.insuranceData.columnItems) {
      if (columnItem.name === strName) {
        //console.log("FOUND");
        return this.getInitialPremiumByID(columnItem.coverageID);
      }
    }

    return 0;
  }

  getInitialCalcValueByName(strName) {
    for (let columnItem of this.props.insuranceData.columnItems) {
      if (columnItem.name === strName) {
        //console.log("FOUND");
        return this.props.insuranceData.dblTableData_Main[1][columnItem.columnNumber];
      }
    }

    return 0;
  }
}

export default withPCTranslation(PremiumDisplay);
