import {ActionTypes} from 'custom/common/redux/Types';

const alphaLookupReducer = (state = [], action) => {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.FETCH_ALPHALOOKUP:
      let data = payload.slice();
      for (let item in data) {
        data[item] = {...data[item], expanded: true};
      }
      return data;
    case ActionTypes.FETCHERROR_ALPHALOOKUP:
    case ActionTypes.CLEAR_ALPHALOOKUP:
      return [];
    default:
      return state;
  }
};

export {alphaLookupReducer as alphaLookupData};
