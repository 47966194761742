import {ActionTypes} from '../types';

export const setStoreFormData = (
  formData,
  dataType,
  useSessionStorage = true
) => {
  // console.log(formData);
  let initialFormData = formData;

  //Session store will
  if (sessionStorage.getItem(dataType) && useSessionStorage) {
    initialFormData = JSON.parse(sessionStorage.getItem(dataType));
  }
  //Custom code for Date components. Either, will move state to Component level state or handle it differently.
  Object.keys(initialFormData).forEach((item) => {
    // controlItems[item].fieldType === 'input_Date';
    if (item.startsWith("dt")) {
      if (initialFormData[item] === "") {
        initialFormData[item] = new Date();
      } else {
        initialFormData[item] = new Date(initialFormData[item]);
      }
    }
  });
  return {
    type: ActionTypes.SET_FORMDATA,
    payload: { formData: initialFormData, dataType },
  };
};
export const updateWholeFormData = (updatedFormData, dataType) => {
  return {
    type: ActionTypes.SET_FORMDATA,
    payload: { formData: updatedFormData, dataType },
  };
};


export const updateStoreFormData = (name, value, dataType) => {
  return {
    type: ActionTypes.UPDATE_FORMDATA,
    payload: {name, value, dataType},
  };
};

export const clearStoreFormData = (dataType, formData /*Initializing with default values */) => {
  return {
    type: ActionTypes.CLEAR_FORMDATA,
    payload: {dataType, formData},
  };
};
