import {companyLogo, ProcompGreyLogo} from 'assets';
import React from 'react';

const IntroModal11 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <header className="">
          <div className="sharedOwnershipHdr flexContainer alignCenter">
            <img
              id="intro-11"
              className=""
              style={{width: '6rem', height: '6rem'}}
              src={ProcompGreyLogo}
              alt=""
            />
            <h3 className="fontHdrCapitalize textAlignCenter">THE SHARED OWNERSHIP AGREEMENT</h3>
          </div>
          <h1 className="fontHdr1 textAlignCenter marginMed fontAltColorB">
            Main Issues to Negotiate
          </h1>
        </header>
        <section className="sharedOwnershipContainer flexContainer threeCol noWrap">
          <ul className="textList">
            <li className="fontLg">Initial transfer of ownership</li>
            <li className="fontLg">Rights of ownership</li>
            <li className="fontLg">Allocation of premiums</li>
            <li className="fontLg">Additional deposits or withdrawals</li>
          </ul>
          <ul className="textList">
            <li className="fontLg">Beneficiary designations by both parties</li>
            <li className="fontLg">Death benefit entitlments</li>
            <li className="fontLg">Consequences of Executive's retirement</li>
            <li className="fontLg">Termination of agreement</li>
          </ul>
          <ul className="textList">
            <li className="fontLg">Establishment of Employer Benefit Period Term of Agreement</li>
            <li className="fontLg">
              Cost Recovery to Corporation
              <ul>
                <li className="fontLg">Definition</li>
                <li className="fontLg">Rights and circumstances of cost recovery</li>
              </ul>
            </li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default IntroModal11;
