export const styleTypes = {
  NORMAL: 0,
  POLICYTYPE: 1,
  HEAD: 2,
  BOLD: 3,
  BACKGROUNDCOLOR: 5,
  BOLDANDRED: 6,
  BOLDANDINDENT: 7,
  WHITE: 8,
  GREY: 9,
  INDENT: 10,
  EMPTY: 15,// temporary
  RED: 20
};

export const phoneTypes = {
    Beeper: 1,
    Business: 2,
    BusinessFax: 3,
    Cellular: 4,
    Mailing: 5,
    Other: 6,
    Pager: 7,
    Residence: 8,
    ResidenceFax: 9,
    Residence2: 10,
    SelectPhone: 11
}

export const addressTypes = {
    Billing: 1,
    Business: 2,
    Disbursement: 3,
    Mailing: 4,
    Other: 5,
    Residence: 6,
    Vacation: 7
}
