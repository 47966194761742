import React, {useState, useEffect} from 'react';
import ModalTitle from 'core/uicore/ModalTitle';
import {Modal} from 'core/uicore';
import {Button} from 'core/controls';
import {tickLogo, closeIcon} from 'assets';
import {usePCTranslation} from 'core/hooks';
import QuestionModalFooter from 'core/uicore/QuestionModalFooter';

const ComboModal = ({
  name,
  controlItem,
  optionItems,
  controlClick,
  controlGetFocus,
  onSelectProduct,
  onCancel,
  controlLostFocus,
  setElement,
  btnX,
  btnCancel,
  btnChoose,
  modalTitle,
  value,
  isUseInformation,
}) => {
  const {t} = usePCTranslation();
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    setSelectedItem(value);
  }, []);

  // This is for clicking the product, item is index of the product
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };

  //This is for clicking Choose button and closing modal
  const handleChoose = () => {
    let event = {target: {name, value: selectedItem}, persist: () => {}, stopPropagation: () => {}};
    onSelectProduct(event);
  };

  return (
    <Modal onClick={(e) => e.stopPropagation()} propStyle="modalDark">
      <div className="modalcontainerMed paddingLg borderRadiusAllLg">
        <Button
          text="Cancel"
          getfocusCallback={controlGetFocus}
          getclickCallback={(event, name, value) => {
            controlClick(event, name, value);
            onCancel();
          }}
          lostfocusCallback={controlLostFocus}
          name={btnX}
          refItem={setElement}
          propStyle={{
            label: "buttonlabel",
            control: `modalClose`,
          }}
          logo={{
            srcLogo: closeIcon,
            altLogo: "",
            classLogo: "branding-image-filter",
          }}
          showIcon={true}
        />
        <div className="OPENSAVEMODALCONTENTS modalWrapper">
          <div className="modalHeaderSm">
            <ModalTitle title={modalTitle} t={t} fontSize="fontHdr2" />
          </div>
          <div className="modal-content">
            {Object.keys(optionItems).map((key) => (
              <div
                key={key}
                onClick={() => handleSelectItem(key)}
                className={`PAGELINK flexContainer alignCenter paddingMed cursorPointer borderRadiusAll ${
                  selectedItem === key ? "activeLink" : ""
                }`}
              >
                <div className="flexContainer noCols alignCenter fullWidth justifyBetween noWrap">
                  <div className="marginSm">
                    <label className="PAGETITLE borderNone fontMed">
                      {controlItem.isTranslateOptions === true
                        ? t(optionItems[key])
                        : optionItems[key]}
                    </label>
                    {isUseInformation && <p>{t(`${key}-information`)}</p>}
                  </div>
                  <img
                    className="tick-icon"
                    name="tick"
                    src={tickLogo}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <QuestionModalFooter
          controlGetFocus={controlGetFocus}
          controlLostFocus={controlLostFocus}
          controlClick={controlClick}
          setElement={setElement}
          onSelectOK={handleChoose}
          onSelectCancel={onCancel}
          btnCancel={btnCancel}
          btnOK={btnChoose}
          // isDisable={disabled}
        />
      </div>
    </Modal>
  );
};

export {ComboModal};
