import React, {Fragment, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {aboutLogo, userLogo, switchLogo, signOffLogo} from 'assets';
import {ButtonBar, Logo} from 'core/uicore';
import {Button} from 'core/controls';
import {useRouter, usePCTranslation} from 'core/hooks';
import {logout} from 'core/redux/actions';
import {updateReportOptionsProfile} from 'custom/newbiz/redux/actions';
import {STYLES} from 'core/constants/uiConstants';
import SwitchProductModal from './SwitchProductModal';
import TabbedHeader from './TabbedHeader';
import ProfileModal from './ProfileModal';
import SharedOwnershipModal from 'custom/sharedOwnership/SharedOwnershipModal';
import AboutModal from './AboutModal';
import {
  NEW_BUSINESS,
  INFORCE,
  SHARED_OWNERSHIP,
  SWITCH_PRODUCTS,
  PROFILE,
  ABOUT,
  NONE,
  ADMIN,
} from '../utils/constants';
import {useMsal} from '@azure/msal-react';

const PageHeader = ({tabHeaderSticky, ...rest}) => {
  const {push, pathname} = useRouter();
  const dispatch = useDispatch();
  const {t} = usePCTranslation();
  const {instance, accounts} = useMsal();

  const {user, routes, storeFormData} = useSelector((state) => ({
    user: state.auth.user,
    storeFormData: state.formData['newBusiness'],
    routes: state.routes,
  }));
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [isAboutModal, setIsAboutModal] = useState(false);
  const [isSharedOwnershipModal, setIsSharedOwnershipModal] = useState(false);
  const [isSwitchProductModalOpen, setIsSwitchProductModalOpen] = useState(false);
  const [active, setActive] = useState(NONE);
  const [pageheaderData, setPageheaderData] = useState({});

  useEffect(() => {
    let headerData = {...pageheaderData};
    if (pathname) {
      let appName = pathname.split('/')[pathname.split('/').length - 1].toLowerCase();
      headerData['formName'] = appName;
      setPageheaderData(headerData);
    }
  }, [pathname]);

  const elementItems = {};
  const setElement = (element) => {
    if (element !== null) {
      elementItems[element.name] = element;
    }
  };
  const updatePageheaderData = (name, value) => {
    let pageHeader = {...pageheaderData};
    pageHeader[name] = value;
    setPageheaderData(pageHeader);
  };
  const controlClick = (event, name, value) => {
    event.persist();
    rest.globalClick(name, pageheaderData, elementItems, updatePageheaderData);
  };

  const controlLostFocus = (event, name, value) => {
    rest.globalLostFocus(event, pageheaderData, elementItems, updatePageheaderData);
  };
  const controlGetFocus = (event, name, value) => {
    rest.globalGetFocus(event, pageheaderData, elementItems, updatePageheaderData);
  };

  /**
   * Example for below line of code:
   * location.pathname -> /inforce/alphalookup
   * page -> inforce
   * if invalid page, redirect to login, login redirects user to default app if logged in
   */
  let pathArray = pathname.split('/');
  const page = pathArray[1].toLowerCase();

  const logOff = async () => {
    const tokenMethod = sessionStorage.getItem('tokenMethod');
    if (tokenMethod === 'AD') {
      dispatch(logout());
      try {
        await instance.logoutRedirect({
          account: accounts[0],
          // mainWindowRedirectUri: 'http://localhost:44376/login',
          postLogoutRedirectUri: 'https://localhost:5001/',
        });
      } catch (error) {
        console.log(error);
        // dispatch(logout());
      }
    } else {
      dispatch(logout());
      push('/login');
    }
  };

  const handleClick = async (event, name, value) => {
    controlClick(event, name, value);
    switch (name) {
      case 'btnSwitchProducts':
        setIsSwitchProductModalOpen(!isSwitchProductModalOpen);
        setActive(SWITCH_PRODUCTS);
        break;
      case 'btnProfile':
        setActive(PROFILE);
        //onAbout(); // About modal window
        setIsProfileModal(true);
        // if (!isProfile) {
        //   controlClick(event, name, value);
        //   // setActivePage("profile");
        //   push("/profile");
        // }
        break;
      case 'btnLogout':
        if (page === NEW_BUSINESS && !pathArray[2] && storeFormData) {
          await dispatch(updateReportOptionsProfile(storeFormData));
        }
        logOff();
        document.documentElement.classList.remove(
          ...document.documentElement.classList
        );
        break;
      case 'btnAbout':
        setActive(ABOUT);
        setIsAboutModal(true);
      default:
        break;
    }
  };

  const handleSelectProduct = (product) => {
    setIsSwitchProductModalOpen(!isSwitchProductModalOpen);
    if (page === NEW_BUSINESS && product === SHARED_OWNERSHIP) {
      setIsSharedOwnershipModal(true);
    } else if (page !== product) {
      // push(`/${prod}`);
      push({pathname: `/${product}`, state: {from: SHARED_OWNERSHIP}}); // Why from = sharedOwnership?
    }
    setActive(NONE);
  };
  const handleSwitchProductCancel = () => {
    setIsSwitchProductModalOpen(!isSwitchProductModalOpen);
    setActive(NONE);
  };

  const handleCancelAbout = () => {
    setIsAboutModal(false);
    setActive(NONE);
  };

  const handleCancelProfile = () => {
    setIsProfileModal(false);
    setActive(NONE);
  };
  const handleSaveProfile = () => {
    setIsProfileModal(false);
    setActive(NONE);
  };
  const handleCancelSharedOwnership = (formData) => {
    rest.updateIllusForm(formData, false);
    setIsSharedOwnershipModal(false);
    setActive(NONE);
  };
  const handleFinishSharedOwnership = (formData) => {
    rest.updateIllusForm(formData, true);
    setIsSharedOwnershipModal(false);
    setActive(NONE);
  };
  return (
    <Fragment>
      <div className={`${STYLES.PAGEHEADER} mobileHide header-container`}>
        <Logo companyName="PROCOMP LIFE" name="companyName" propStyle={{logo: 'paddingMed'}} />
        <ButtonBar name="Profile Header" buttonbar="flexContainer autoCols justifyEnd">
          <Button
            text="Switch Products"
            getfocusCallback={controlGetFocus}
            getclickCallback={handleClick}
            lostfocusCallback={controlLostFocus}
            //stateData={storeFormData}
            name="btnSwitchProducts"
            refItem={setElement}
            propStyle={{
              label: 'buttonlabel',
              control: 'buttoncontrol buttonHdr',
            }}
            logo={{
              srcLogo: switchLogo,
              altLogo: '',
              classLogo: 'help-logo',
            }}
            showIcon={true}
            isActive={active === SWITCH_PRODUCTS}
          />
          <Button
            text="About"
            getfocusCallback={controlGetFocus}
            getclickCallback={handleClick}
            lostfocusCallback={controlLostFocus}
            //stateData={storeFormData}
            name="btnAbout"
            refItem={setElement}
            propStyle={{
              label: 'buttonlabel',
              control: 'buttoncontrol buttonHdr',
            }}
            logo={{
              srcLogo: aboutLogo,
              altLogo: '',
              classLogo: 'help-logo',
            }}
            showIcon={true}
            isActive={active === ABOUT}
          />
          <Button
            text="Your Profile"
            getfocusCallback={controlGetFocus}
            getclickCallback={handleClick}
            lostfocusCallback={controlLostFocus}
            //stateData={storeFormData}
            name="btnProfile"
            refItem={setElement}
            propStyle={{
              label: 'buttonlabel',
              control: 'buttoncontrol buttonHdr borderPartialRight',
            }}
            logo={{
              srcLogo: userLogo,
              altLogo: '',
              classLogo: 'profile-logo',
            }}
            showIcon={true}
            isActive={active === PROFILE}
          />
          <Button
            text="Sign Out"
            getfocusCallback={controlGetFocus}
            getclickCallback={handleClick}
            lostfocusCallback={controlLostFocus}
            //stateData={storeFormData}
            name="btnLogout"
            refItem={setElement}
            propStyle={{
              label: 'buttonlabel',
              control: 'buttoncontrol buttonHdr borderPartialRight',
            }}
            logo={{
              srcLogo: signOffLogo,
              altLogo: '',
              classLogo: 'help-logo',
            }}
            showIcon={true}
          />
        </ButtonBar>
      </div>
      <TabbedHeader
        sticky={tabHeaderSticky}
        isUserAdmin={rest.isUserAdmin}
        controlClick={controlClick}
        controlGetFocus={controlGetFocus}
        controlLostFocus={controlLostFocus}
        setElement={setElement}
      />
      {isSwitchProductModalOpen && (
        <SwitchProductModal
          controlGetFocus={controlGetFocus}
          controlClick={controlClick}
          controlLostFocus={controlLostFocus}
          setElement={setElement}
          onCancel={handleSwitchProductCancel}
          onSelectProduct={handleSelectProduct}
          route={page}
          isMobile={false}
        />
      )}
      {isProfileModal && (
        <ProfileModal {...rest} onCancel={handleCancelProfile} onSaveProfile={handleSaveProfile} />
      )}
      {isSharedOwnershipModal && (
        <SharedOwnershipModal
          onCancel={handleCancelSharedOwnership}
          onFinish={handleFinishSharedOwnership}
          {...rest}
        />
      )}
      {isAboutModal && (
        <AboutModal
          controlGetFocus={controlGetFocus}
          controlClick={controlClick}
          controlLostFocus={controlLostFocus}
          setElement={setElement}
          onCancel={handleCancelAbout}
          route={page}
        />
      )}
    </Fragment>
  );
};

export default PageHeader;
