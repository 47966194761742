import React, {Component} from 'react';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import {ProfileHeader, UserInformation, ProfileSettings} from 'custom/profile/ui';
import {Button} from 'core/controls';
import {profileFormData} from 'custom/profile/utils/data';
import {FieldContainer, Alert, Modal, ModalTitle, TabLayout, TabContent} from 'core/uicore';
import {FormValidator, FormStatus, ValidationMessage} from 'core/formValidation';
import ValidateForm from 'custom/common/utils/customFormValidation';
import TabsHeader from 'core/uicore/TabsHeader';
import QuestionModalFooter from 'core/uicore/QuestionModalFooter';
import {closeIcon} from 'assets';
import ProfileWidgets from './ProfileWidgets';

class ProfileModal extends Component {
  state = {
    profileData: profileFormData, // Load static data before componentDidMount
    isLoading: false,
    sticky: '',
    activeTab: 'tabProfileInformation',
    widgetsData: [],
  };

  elementItems = {};
  updateProfileFormData = async (name, value) => {
    this.setState({
      profileData: {...this.state.profileData, [name]: value},
    });
    //this.props.updateStoreFormData(name, value, "profileFormData");
  };
  setElement = (element) => {
    if (element !== null) {
      this.elementItems[element.name] = element;
    }
  };
  controlChange = async (event, name, value) => {
    event.persist();
    await this.updateProfileFormData(name, value);
    this.props.globalChange(
      event,
      this.state.profileData,
      this.elementItems,
      this.updateProfileFormData
    );
  };
  controlClick = (event, name, value) => {
    event.persist();
    event.preventDefault();
    this.props.globalClick(
      name,
      this.state.profileData,
      this.elementItems,
      this.updateProfileFormData
    );
  };
  controlGetFocus = (event, name, value) => {
    this.props.globalGetFocus(
      event,
      this.state.profileData,
      this.elementItems,
      this.updateProfileFormData
    );
  };
  controlLostFocus = (event, name, value) => {
    this.props.globalLostFocus(
      event,
      this.state.profileData,
      this.elementItems,
      this.updateProfileFormData
    );
  };

  updateWidgetsData = (widgetsData) => {
    this.setState({widgetsData});
  };
  // saveWidgetsData = async () => {
  //   try {
  //     const {data} = axios.post('/api/BI/GetAllUserWidgets', this.state.widgetsData);
  //     console.log('Post widgets success: ', data);
  //   } catch (error) {
  //     console.log('Post widgets fail: ', error);
  //   }
  // };

  submitProfile = async () => {
    // this.setState({ disableSaveButton: true });
    // setTimeout(() => this.setState({ disableSaveButton: false }), 2000);

    const {profileData, widgetsData} = this.state;
    if (profileData['rdoFontSize'] === 'Large') {
      document.body.classList.add('altFont');
    } else if (profileData['rdoFontSize'] === 'Small') {
      document.body.classList.remove('altFont');
    }
    const widgets = {};
    const cloneWidgets = cloneDeep(widgetsData);
    cloneWidgets.forEach((widget, idx) => {
      widget.displayOrder = idx + 1;
      widgets[widget.name] = widget;
    });

    await this.props.updateUserProfile({...profileData, widgetsData: widgets}, this.props.t);

    if (this.props.inforceData && this.props.inforceData.inforcePolicy) {
      this.props.populateTreeAction(this.props.inforceData.inforcePolicy, this.props.t);
    }
    this.props.onSaveProfile();
  };

  handleCancel = () => {
    // this.setState({ disableCancelButton: true });
    // setTimeout(() => this.setState({ disableCancelButton: false }), 2000);

    // this.loadProfileData();
    this.props.setAlert(this.props.t('msg-InfoReverted'), 'Secondary', 'updateProfile', 5000);
    this.props.onCancel();
    this.updateWidgetsData([]);
  };
  setActiveTab = (activeTab) => {
    this.setState({activeTab});
  };

  fetchUserWidgets = async () => {
    try {
      const {data} = await axios.post('/api/BI/GetAllUserWidgets', this.props.user);
      const widgetsData = data.sort(
        (widget1, widget2) => widget1.displayOrder - widget2.displayOrder
      );
      this.setState({widgetsData});
    } catch (error) {
      console.log('fetch users widget error', error);
    }
  };

  //Initialize profile data from auth User data
  loadProfileData = () => {
    const {user} = this.props.auth;
    // const tempWidgets = user['widgets'];
    // let widgetsData = [];
    // const widgetKeys = Object.keys(tempWidgets).sort(
    //   (key1, key2) => tempWidgets[key1].isDisplayOrder - tempWidgets[key2].isDisplayOrder
    // );

    // widgetKeys.forEach((key) => {
    //   widgetsData.push(tempWidgets[key]);
    // });

    this.setState({
      profileData: {
        ...this.state.profileData,
        txtUserName: user['profileName'],
        txtEmailAddress: user['emailAddress'],
        txtPrimaryPhone: user['phoneNumberPrimary'],
        txtSecondaryPhone: user['phoneNumberSecondary'],
        txtFaxNumber: user['faxNumber'],
        chkPrimaryPhone: user['isMobilePrimary'],
        chkSecondaryPhone: user['isMobileSecondary'],
        imgProfilePicture: '',
        rdoInputLanguage: user['inputLanguage'],
        rdoReportLanguage: user['reportLanguage'],
        rdoFontSize: user['fontSize'],
        rdoDefaultApp: user['defaultApplication'],
      },
      // widgetsData,
    });
    this.props.onLoad(this.state.profileData, this.elementItems, this.updateProfileFormData);
  };

  submitData = () => {};

  componentDidMount() {
    this.loadProfileData();
    this.fetchUserWidgets();
    // console.log("Profile Data", this.state.profileData);
  }
  componentDidUpdate() {
    // console.log("In Profile ComponentDidUpdate: ", this.state);
  }

  render() {
    const {
      controlChange,
      controlClick,
      controlGetFocus,
      controlLostFocus,
      setElement,
      handleCancel,
      submitProfile,
      submitData,
      setActiveTab,
      updateWidgetsData,
      state: {profileData, activeTab, widgetsData},
      props: {auth, onCancel, t},
    } = this;
    return (
      <Modal onClick={(e) => e.stopPropagation()} propStyle="modalDark">
        <div className="modalcontainer paddingLg borderRadiusAllLg">
          <Button
            text="Cancel"
            getfocusCallback={controlGetFocus}
            getclickCallback={(event, name, value) => {
              controlClick(event, name, value);
              onCancel();
            }}
            lostfocusCallback={controlLostFocus}
            name="btnXProfileCancel"
            refItem={setElement}
            propStyle={{
              label: "buttonlabel",
              control: `modalClose`,
            }}
            logo={{
              srcLogo: closeIcon,
              altLogo: "",
              classLogo: "new-logo branding-image-filter",
            }}
            showIcon={true}
          />
          <FormStatus data={profileData} styling="FORMSTATUS modalWrapper">
            <FormValidator
              data={profileData}
              submit={submitData}
              validateForm={ValidateForm}
              styling="FORMVALIDATOR modalWrapper"
            >
              <div className="PROFILE modalWrapper">
                <div className="modalWrapper">
                  <div className="modalHeaderSm">
                    <ModalTitle
                      title={"ProfileModal"}
                      t={t}
                      fontSize="fontHdr2"
                      showUserName={true}
                    />
                  </div>

                  <div className="modal-content">
                    <TabsHeader
                      styling="tabs"
                      setActiveTab={(event, name, value) => {
                        controlClick(event, name, value);
                        setActiveTab(name);
                      }}
                    >
                      <div
                        key="profileInformation"
                        name="tabProfileInformation"
                      ></div>
                      <div key="profileSettings" name="tabProfileSetings"></div>
                      <div
                        key="profileWidgets"
                        name="tabProfileWidgets"
                        isHidden={
                          !auth?.user?.system?.isBusinessIntelligence ||
                          !auth?.user?.permissions?.isBusinessIntelligence
                            ?.isDisplayed ||
                          !auth?.user?.permissions?.isBusinessIntelligence
                            ?.isActive
                        }
                      ></div>
                    </TabsHeader>
                    <TabContent activeTab={activeTab}>
                      <TabLayout
                        key="profileInformation"
                        name="tabProfileInformation"
                        styling="tab1"
                      >
                        <UserInformation
                          controlGetFocus={controlGetFocus}
                          controlChange={controlChange}
                          controlLostFocus={controlLostFocus}
                          setElement={setElement}
                          formData={profileData}
                          user={auth.user}
                        />
                      </TabLayout>
                      <TabLayout
                        key="profileSettings"
                        name="tabProfileSetings"
                        styling="tab2"
                      >
                        <ProfileSettings
                          controlGetFocus={controlGetFocus}
                          controlChange={controlChange}
                          controlLostFocus={controlLostFocus}
                          setElement={setElement}
                          formData={profileData}
                          auth={auth}
                        />
                      </TabLayout>
                      <TabLayout
                        key="profileWidgets"
                        name="tabProfileWidgets"
                        styling="tab3"
                      >
                        <ProfileWidgets
                          controlGetFocus={controlGetFocus}
                          controlChange={controlChange}
                          controlLostFocus={controlLostFocus}
                          setElement={setElement}
                          formData={profileData}
                          auth={auth}
                          widgetsData={widgetsData}
                          updateWidgetsData={updateWidgetsData}
                        />
                      </TabLayout>
                    </TabContent>
                  </div>
                </div>
                <QuestionModalFooter
                  controlGetFocus={controlGetFocus}
                  controlLostFocus={controlLostFocus}
                  controlClick={controlClick}
                  setElement={setElement}
                  onSelectOK={submitProfile}
                  onSelectCancel={handleCancel}
                  btnCancel={"btnProfileCancel"}
                  btnOK={"btnProfileSave"}
                  // isDisable={disabled}
                />
              </div>
            </FormValidator>
          </FormStatus>
        </div>
      </Modal>
    );
  }
}

// export default AutoLogout(withRouter(Profile));

// export default withRouter(Profile);
export default ProfileModal;
// export default Profile;
