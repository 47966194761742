import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
// import common_en from './locale/en/common.json';
// import common_fr from './locale/fr/common.json';

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    // resources: {
    //   en: {
    //     translations: common_en
    //   },

    //   fr: {
    //     translations: common_fr
    //   }
    // },
    lng: 'en',
    fallbackLng: false,
    debug: false,

    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    returnEmptyString: true,

    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
