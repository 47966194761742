import React, {Fragment, useEffect, useState} from 'react';
// import {useSelector} from "react-redux";
import {Textbox, Radiobuttons} from 'core/controls';
import {FormStatus, FormValidator} from 'core/formValidation';
import ValidateForm from 'custom/common/utils/customFormValidation';
import {PERSONAL_INFO} from '../SharedOwnershipModal';
import {companyLogo, ProcompGreyLogo} from 'assets';

const BasicInfoModal = ({
  controlGetFocus,
  controlChange,
  controlLostFocus,
  setElement,
  elementItems,
  updateFormData,
  onLoad,
  formData,
  modalIndex,
}) => {
  useEffect(() => {
    console.log(formData);
    onLoad(formData.personalInfo, elementItems, updateFormData);
  }, []);

  const submitData = () => {};
  return (
    <FormStatus data={formData.personalInfo}>
      <FormValidator data={formData.personalInfo} submit={submitData} validateForm={ValidateForm}>
        <div className="slideshowContainer">
          <main className="slideshowContents fullWidth">
            <header className="sharedOwnershipHdr title-1 marginSm flexContainer alignCenter fullWidth noCols">
              <img
                id="basic-info"
                className=""
                style={{width: '6rem', height: '6rem'}}
                src={ProcompGreyLogo}
                alt=""
              />
            </header>
            <section className="sharedOwnershipContainer bgWhite boxShadowAll noWrap">
              <h2 className="bgPrimaryColor paddingMed fontHdr2 fontBold">
                Let's start with some basic information
              </h2>
              <div className="paddingLg">
                <div className="flexContainer noCols">
                  <Textbox
                    text="Insured Name"
                    getfocusCallback={controlGetFocus}
                    getchangeCallback={controlChange}
                    lostfocusCallback={controlLostFocus}
                    stateData={formData.personalInfo['txtSharedInsuredName']}
                    name="txtSharedInsuredName"
                    refItem={setElement}
                    propStyle={{
                      label: 'textlabel',
                      control: 'textcontrol',
                      textcontainer: 'textcontainer',
                    }}
                  />
                  <Textbox
                    text="Age"
                    getfocusCallback={controlGetFocus}
                    getchangeCallback={controlChange}
                    lostfocusCallback={controlLostFocus}
                    stateData={formData.personalInfo['txtSharedAge']}
                    name="txtSharedAge"
                    refItem={setElement}
                    propStyle={{
                      label: 'textlabel',
                      control: 'textcontrol inputSm',
                      textcontainer: 'textcontainer',
                    }}
                  />
                  <Textbox
                    text="Age"
                    getfocusCallback={controlGetFocus}
                    getchangeCallback={controlChange}
                    lostfocusCallback={controlLostFocus}
                    stateData={formData.personalInfo['txtSharedRetirementAge']}
                    name="txtSharedRetirementAge"
                    refItem={setElement}
                    propStyle={{
                      label: 'textlabel',
                      control: 'textcontrol inputSm',
                      textcontainer: 'textcontainer',
                    }}
                  />
                </div>
                <div className="flexContainer noCols">
                  <Radiobuttons
                    text="Gender"
                    getfocusCallback={controlGetFocus}
                    getchangeCallback={controlChange}
                    lostfocusCallback={controlLostFocus}
                    stateData={formData.personalInfo}
                    name="rdoSharedSex"
                    refItem={setElement}
                    propStyle={{
                      label: 'fontHdr3',
                      control: 'radiocontrol',
                      radiocontainer: 'radiocontainer marginSm',
                      inputcontainer: 'radiocontainer marginSm',
                      child: 'childcontrol',
                    }}
                  />
                  <Radiobuttons
                    text="SmokeClass"
                    getfocusCallback={controlGetFocus}
                    getchangeCallback={controlChange}
                    lostfocusCallback={controlLostFocus}
                    stateData={formData.personalInfo}
                    name="rdoSharedSmokeClass"
                    refItem={setElement}
                    propStyle={{
                      label: 'fontHdr3',
                      control: 'radiocontrol',
                      radiocontainer: 'radiocontainer marginSm',
                      inputcontainer: 'radiocontainer marginSm',
                      child: 'childcontrol',
                    }}
                  />
                </div>
                <div className="flexContainer noCols">
                  <Textbox
                    text="Company Name"
                    getfocusCallback={controlGetFocus}
                    getchangeCallback={controlChange}
                    lostfocusCallback={controlLostFocus}
                    stateData={formData.personalInfo['txtSharedCompanyName']}
                    name="txtSharedCompanyName"
                    refItem={setElement}
                    propStyle={{
                      label: 'textlabel',
                      control: 'textcontrol',
                      textcontainer: 'textcontainer',
                    }}
                  />

                  <Textbox
                    text="Payment Years"
                    getfocusCallback={controlGetFocus}
                    getchangeCallback={controlChange}
                    lostfocusCallback={controlLostFocus}
                    stateData={formData.personalInfo['txtSharedEmployerPayPeriod']}
                    name="txtSharedEmployerPayPeriod"
                    refItem={setElement}
                    propStyle={{
                      label: 'textlabel',
                      control: 'textcontrol inputSm',
                      textcontainer: 'textcontainer',
                    }}
                  />
                  <Textbox
                    text="Benefit Years"
                    getfocusCallback={controlGetFocus}
                    getchangeCallback={controlChange}
                    lostfocusCallback={controlLostFocus}
                    stateData={formData.personalInfo['txtSharedEmployerBenefitPeriod']}
                    name="txtSharedEmployerBenefitPeriod"
                    refItem={setElement}
                    propStyle={{
                      label: 'textlabel',
                      control: 'textcontrol inputSm',
                      textcontainer: 'textcontainer',
                    }}
                  />
                </div>
              </div>
            </section>
          </main>
        </div>
      </FormValidator>
    </FormStatus>
  );
};

export default BasicInfoModal;
