//Note that these plancodes match the ProdIDs from the interfaceSetup
export const ProdID = {
  TERM10: "0131",
  TERM20: "0132",
  TERM30: "0133",
  TERM100JOINT: "0562",
  TERM100: "0563",
  WHOLELIFE: "0900",
  WHOLELIFE20PAY: "0902",
  UL: "0602",
  UL2: "0603"
};

export const DivOptions = {
  RAP: 'RAP',
  PUA: 'PUA',
  DOD: 'DOD',
  PIC: 'PIC',
  ENH: 'ENH',
  NONE: 'NONE',
};

export const PolicyTypes = {
  SINGLE: 'SINGLE',
  JOINTFIRST: 'JOINTFIRST',
  JOINTLAST: 'JOINTLAST',
};

export const PaymentOptions = {
  ANNUAL: '012',
  MONTHLY: '01',
  SEMIANNUAL: '06',
  QUARTERLY: '03',
};

export const RatingOptions = {
  STANDARD: '100',
  RATING125: '125',
  RATING150: '150',
  RATING175: '175',
  RATING200: '200',
};

export const interfaceItemsPolicyTypes = {
  TERM100: 'TERM100',
  LEVELTERM: 'LEVELTERM',
  WHOLELIFE: 'WHOLELIFE',
  UL: 'UL',
};

//term 10, term 20 or term 30
export const isTermPolicy = (prodId) => {
  return (
    prodId === ProdID.TERM10 ||
    prodId === ProdID.TERM20 ||
    prodId === ProdID.TERM30 ||
    prodId === ProdID.TERM
  );
};

//term 100
export const isTerm100Policy = (prodId) => {
  return prodId === ProdID.TERM100;
};

//whole life policy
export const isWholeLifePolicy = (prodId) => {
  return prodId === ProdID.WHOLELIFE || prodId === ProdID.WHOLELIFE20PAY;
};

//universal life policy
export const isUniversalLifePolicy = (prodId) => {
  return (prodId === ProdID.UL) || (prodId === ProdID.UL2);
};