import React, {useState} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {Modal, ModalTitle, QuestionModalFooter} from 'core/uicore';
import {Button} from 'core/controls';
import {tickLogo, closeIcon} from 'assets';
import {INFORCE, NEW_BUSINESS, SHARED_OWNERSHIP, EAPPLICATION, ADMIN, BI, EAPP_SETTINGS} from '../utils/constants';
import {usePCTranslation} from 'core/hooks';

const SwitchProductModal = ({
  controlClick,
  controlGetFocus,
  onSelectProduct,
  onCancel,
  controlLostFocus,
  setElement,
  route,
  isMobile,
}) => {
  const {user, controlItems} = useSelector(
    (state) => ({
      user: state.auth.user,
      controlItems: state.setupItems.controlItems,
    }),
    shallowEqual
  );
  const {t} = usePCTranslation();
  const [selectedItem, setSelectedItem] = useState(route);
  // const [disabled, setDisabled] = useState(true);

  // useEffect(() => {
  //   setSelectedItem(route);
  // }, [])

  // This is for clicking the product, item is index of the product
  const handleSelectItem = (e, item) => {
    controlClick(e, `${item}Switch`, item);
    setSelectedItem(item);
  };

  //This is for clicking Choose button and closing modal
  const handleChoose = () => {
    onSelectProduct(selectedItem);
  };
  if (!user) {
    return null;
  }

  const switchButton = (switchBtnName, itemId, isPermitted, logoName='logoHdrNewBusiness') => (
    <button
      id={switchBtnName}
      onClick={(e) => handleSelectItem(e, itemId)}
      className={`PAGELINK fullWidth justifyStart flexContainer alignCenter paddingMed ${
        isPermitted ? 'cursorPointer' : 'disabled'
      } ${logoName} borderRadiusAll ${
        selectedItem === itemId && isPermitted ? 'activeLink' : ''
      }`}
      disabled={!isPermitted}>
      <div className="flexContainer noCols alignCenter noWrap flexGrow justifyBetween">
        <label className="PAGETITLE borderNone fontMed">{t(switchBtnName)}</label>
        <img className="tick-icon" name="tick" src={tickLogo} alt="" />
      </div>
    </button>
  )

  return (
    <Modal onClick={(e) => e.stopPropagation()} propStyle="modalDark">
      <div className="modalcontainerSm paddingLg borderRadiusAllLg">
        <Button
          text="Cancel"
          getfocusCallback={controlGetFocus}
          getclickCallback={(event, name, value) => {
            controlClick(event, name, value);
            onCancel();
          }}
          lostfocusCallback={controlLostFocus}
          name="btnXSwitchProductCancel"
          refItem={setElement}
          propStyle={{
            label: "buttonlabel",
            control: `modalClose`,
          }}
          logo={{
            srcLogo: closeIcon,
            altLogo: "",
            classLogo: "new-logo branding-image-filter",
          }}
          showIcon={true}
        />
        <div className="OPENSAVEMODALCONTENTS modalWrapper">
          <div className="modalHeaderSm">
            <ModalTitle title={"SwitchProducts"} t={t} fontSize="fontHdr2" />
          </div>
          <div className="modal-content">
            {user.system.isInforce &&
              user?.permissions?.isInforce?.isDisplayed &&
              switchButton(
                "inforceSwitch",
                INFORCE,
                user?.permissions?.isInforce?.isActive,
                "logoHdrInforce"
              )}

            {user.system.isNewBusiness &&
              user?.permissions?.isNewBusiness?.isDisplayed &&
              switchButton(
                "illustrationSwitch",
                NEW_BUSINESS,
                user?.permissions?.isNewBusiness?.isActive,
                "logoHdrNewBusiness"
              )}

            {route === NEW_BUSINESS &&
              !isMobile &&
              user.system.isConcepts &&
              user?.permissions?.isNewBusiness?.isDisplayed &&
              switchButton(
                "sharedOwnershipSwitch",
                SHARED_OWNERSHIP,
                user?.permissions?.isNewBusiness?.isActive
              )}

            {/* {(route === NEW_BUSINESS || route === EAPPLICATION) &&
              user?.permissions?.isEapplicationAdministrator &&
              user.system.isEapplication && switchButton('eapplicationSwitch',EAPPLICATION,  user?.permissions?.isEapplication,'logoHdrEApplication')} */}

            {
              // (route === NEW_BUSINESS || route === EAPP_SETTINGS) &&
              // user?.permissions?.isEapplicationAdministrator &&
              user.system.isEapplication &&
                user?.permissions?.isEapplication?.isDisplayed &&
                switchButton(
                  "eappSettingSwitch",
                  EAPP_SETTINGS,
                  user?.permissions?.isEapplication?.isActive,
                  "logoHdrEApplication"
                )
            }

            {user.system.isBusinessIntelligence &&
              user?.permissions?.isBusinessIntelligence?.isDisplayed &&
              switchButton(
                "biSwitch",
                BI,
                user?.permissions?.isBusinessIntelligence?.isActive
              )}

            {(user?.permissions?.isAdmin?.isDisplayed ||
              ((user?.permissions?.isAdminSystem?.isDisplayed ||
                user?.permissions?.isAdminSystemAdministrator?.isDisplayed) &&
                user.system.isAdminSystem)) && (
              <button
                id={`${ADMIN}Switch`}
                onClick={(e) => handleSelectItem(e, ADMIN)}
                className={`PAGELINK fullWidth justifyStart flexContainer alignCenter paddingMed logoHdrNewBusiness borderRadiusAll ${
                  !user?.permissions?.isAdmin?.isActive
                    ? "disabled"
                    : "cursorPointer"
                } ${
                  selectedItem === ADMIN &&
                  (user?.permissions?.isAdmin?.isActive ||
                    ((user?.permissions?.isAdminSystem?.isActive ||
                      user?.permissions?.isAdminSystemAdministrator
                        ?.isActive) &&
                      user.system.isAdminSystem))
                    ? "activeLink"
                    : ""
                }`}
                disabled={!user?.permissions?.isAdmin?.isActive}
              >
                <div className="flexContainer noCols alignCenter noWrap flexGrow justifyBetween">
                  <label className="PAGETITLE borderNone fontMed">
                    {t("adminSwitch")}
                  </label>
                  <img
                    className="tick-icon"
                    name="tick"
                    src={tickLogo}
                    alt=""
                  />
                </div>
              </button>
            )}
          </div>
        </div>
        <QuestionModalFooter
          controlGetFocus={controlGetFocus}
          controlLostFocus={controlLostFocus}
          controlClick={controlClick}
          setElement={setElement}
          onSelectOK={handleChoose}
          onSelectCancel={onCancel}
          btnCancel={"btnSwitchProductCancel"}
          btnOK={"btnSwitchProductChoose"}
          // isDisable={disabled}
        />
      </div>
    </Modal>
  );
};

export default SwitchProductModal;
