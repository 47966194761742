import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {isIE} from 'react-device-detect';
import axios from "axios";
import App from "custom/common/App";
import * as serviceWorker from "./serviceWorker";
import "core/utils/i18n";
import BrowserUnsupported from "BrowserUnsupported";
// console.log(process.env.NODE_ENV);
// if (process.env.NODE_ENV !== 'development') {
//   console.log = (...messages) => {};
// }
//console.log('REACT_APP_VERSION: ', process.env.REACT_APP_VERSION);

const RenderApp = () => {
  if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = process.env.REACT_APP_DEV_URL;
  }

  if (isIE) {
    return <BrowserUnsupported />;
  }
  return <App />;
};

ReactDOM.render(RenderApp(), document.getElementById('app-root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
