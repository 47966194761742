import React from 'react';
import {buildBICardList, ReportGroupsName} from '../utils/data';
import {useSelector} from 'react-redux';

const queryList = buildBICardList();
const QueryListDisplay = ({onClick: handleQueryClick, t}) => {
  const {user} = useSelector((state) => ({
    user: state.auth.user,
  }));

  return (
    <div className='flexContainer threeCol'>
      {queryList.map((card, cardIndex) => {
        if((!user?.permissions[ReportGroupsName[cardIndex]?.permission].isActive || !user?.permissions[ReportGroupsName[cardIndex]?.permission].isDisplayed) && !user?.system[ReportGroupsName[cardIndex]]){
          return 
        }
        return (
          <div key={cardIndex} className='paddingLg borderThin borderNeutral borderRadiusAllLg'>
            <div className='fontHdr2 fontAllCaps'>{t(ReportGroupsName[cardIndex]?.name)}</div>
            {card.map((cardItem) => {
              return (
                <div
                  className="fontPrimaryColor cursorPointer"
                  id={cardItem.key}
                  key={cardItem.key}
                  onClick={(e) =>
                    handleQueryClick(
                      e,
                      cardItem,
                      ReportGroupsName[cardIndex].reportAPIPath
                    )
                  }
                >
                  {cardItem.value}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default QueryListDisplay;
