import React from 'react';
import {STYLES} from 'core/constants/uiConstants';

const TabLayout = ({children, label, styling, active}) => {
  let classNames = `${STYLES.TABLAYOUT} ${styling}`;
  if (label === active) classNames = classNames + ' active-tab';
  return (
    <div className={classNames} label={label}>
      {children}
    </div>
  );
};

export default TabLayout;
