import React, {Fragment, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {checkLogo, warningLogo, flagLogo} from 'assets';

const alertLogo = {
  Success: checkLogo,
  Error: warningLogo,
  Secondary: flagLogo,
};

const Alert = ({name, styling}) => {
  const {alert} = useSelector((state) => ({
    alert: state.alert[name] || null,
  }));
  return (
    <Fragment>
      {alert && (
        <div key={alert.id} className={`ALERT ${styling} bg${alert.alertType}Color`}>
          <img className='alert-icon' src={alertLogo[alert.alertType]} alt='' />
          {alert.msg}
        </div>
      )}
    </Fragment>
  );
};

export default Alert;
