import React, {useState, Fragment, memo, useEffect, useMemo, useContext} from 'react';
import {useSelector} from 'react-redux';
import {ValidationMessage, ValidationContext} from '../formValidation';
import {TooltipIcon, TooltipText} from '../uicore';
import {formatDisplay, parseInput, decimalToComma, commaToDecimal} from 'core/utils/inputTypeUtils';
import {INPUTTYPES, STYLES} from 'core/constants/uiConstants';
import {usePCTranslation} from 'core/hooks';

const Textareabox = ({getchangeCallback, getfocusCallback, lostfocusCallback, stateData, name, text, type, autoFocus, refItem, propStyle, readOnly, step, placeholder, styleProp, cols, rows}) => {
  const {t} = usePCTranslation();
  const {errors, formErrors} = useContext(ValidationContext);
  const {controlItem, inputLanguage} = useSelector((state) => ({
    controlItem: state.setupItems.controlItems ? state.setupItems.controlItems[name] : null,
    inputLanguage: state.auth.user ? state.auth.user['inputLanguage'] : 'English',
  }));
  const [textValue, setTextValue] = useState('');
  let currentState = stateData ? stateData : '';
  let formattedState = formatDisplay(inputLanguage, currentState, controlItem.fieldType, controlItem.decimalPlaces);

  if (
    controlItem &&
    (controlItem.fieldType === INPUTTYPES.INPUT_REAL || controlItem.fieldType === INPUTTYPES.INPUT_DOLLAR || controlItem.fieldType === INPUTTYPES.INPUT_PERCENT) &&
    inputLanguage === 'French'
  ) {
    currentState = decimalToComma(currentState);
  }
  const [focus, setFocus] = useState(false);

  // let isFocus = (stateData && stateData["CurrentField"]===name) || false;
  let isReadOnly = controlItem.readOnly || readOnly;
  useEffect(() => {
    if (focus && !isReadOnly) {
      setTextValue(currentState);
    } else {
      setTextValue(formattedState);
    }
    // console.log(currentState, "Use Effect testing");
  }, [currentState, focus, formattedState]);

  //Label text
  // const [labelText, setLabelText] = useState("");

  // useMemo(() => {setLabelText(t(`${name}-caption`))}, [inputLanguage]);
  const controlOnChange = async (event) => {
    //Cursor position was resetting to end of text box, as we are formatting the typed text in "parseInput" to meet certain input types criteria
    const cursorPos = event.target.selectionStart;

    // console.log(event);
    event.persist();
    let value = parseInput(inputLanguage, stateData, event, controlItem);
    // console.log(value);
    if ((controlItem.fieldType === INPUTTYPES.INPUT_REAL || controlItem.fieldType === INPUTTYPES.INPUT_DOLLAR || controlItem.fieldType === INPUTTYPES.INPUT_PERCENT) && inputLanguage === 'French') {
      value = commaToDecimal(value);
    }
    let lengthOffset = lengthDifference(event.target.value, value);

    if (value !== textValue) {
      //setTextValue(value);
      await getchangeCallback(event, event.target.name, value);
    }
    event.target.setSelectionRange(cursorPos + lengthOffset, cursorPos + lengthOffset);

    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };
  const lengthDifference = (oldVal, newVal) => {
    return newVal.length - oldVal.length;
  };
  const controlOnFocus = (event) => {
    event.persist();
    setFocus(true);
    setTimeout(() => {
      getfocusCallback(event, event.target.name, event.target.value);
      //DO NOT propagate this event up as it should only be for the current control
      event.stopPropagation();
    }, 0);
  };
  const controlOnLostFocus = (event) => {
    event.persist();
    setFocus(false);
    setTimeout(() => {
      // console.log('controlOnLostFocus', event);
      lostfocusCallback(event, event.target.name, event.target.value);
      //DO NOT propagate this event up as it should only be for the current control
      event.stopPropagation();
    }, 0);
  };

  let containerClass = `${STYLES.INPUTCONTAINER} ${propStyle.textcontainer}`;
  if ((errors[name] || formErrors[name]) && controlItem && controlItem.isDirty) {
    containerClass += ' errorState';
  }
  if (controlItem && controlItem.displayDisabled) {
    containerClass += ' disabled';
  }
  if (controlItem && controlItem.displayHidden) {
    containerClass += ' hiddenField';
  }
  // console.logarea("Textareabox render test");
  //utility internal function
  const textInput = () => {
    return (
      <Fragment>
        <textarea
          className={`${STYLES.BASIC} ${STYLES.TEXT} ${propStyle.control}`}
          value={textValue}
          id={name}
          name={name}
          step={step}
          rows={rows}
          cols={cols}
          readOnly={(controlItem && controlItem.readOnly) || readOnly}
          onFocus={controlOnFocus}
          // onChange={controlOnChange}
          onChange={() => {}}
          onInput={controlOnChange}
          autoFocus={autoFocus}
          onBlur={controlOnLostFocus}
          ref={refItem}
          maxLength={controlItem.maxLength}
          style={styleProp}
          autoComplete='new_off'
          placeholder={placeholder}
        />
      </Fragment>
    );
  };
  return (
    <div id={name} className={containerClass}>
      {/* {controlItems[name].showLabel && ( */}
      <label className={`${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`} id={name}>
        {controlItem.showLabel && t(`${name}-caption`)}
      </label>
      {/* )} */}
      {!controlItem.showTooltip ? (
        textInput()
      ) : controlItem.showTooltipIcon ? (
        <Fragment>
          <div className={`${STYLES.TOOLTIP}`}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
          {textInput()}
        </Fragment>
      ) : (
        <div className={`${STYLES.TOOLTIP} noIcon`}>
          <TooltipText name={name} />
          {textInput()}
        </div>
      )}

      <ValidationMessage name={name} field={name} size='10' propStyle={{validationmsg: 'textboxvalidation'}} />
    </div>
  );
};
Textareabox.defaultProps = {
  autoFocus: false,
  readOnly: false,
  step: 0,
  styleProp: {},
  placeholder: '',
};
export {Textareabox};
