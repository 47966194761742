import React, {useState} from 'react';
import {usePCTranslation} from 'core/hooks';
import {Modal, QuestionModalFooter, ModalTitle} from 'core/uicore';
import {Checkbox} from 'core/controls';

const WelcomeModal = ({
  globalChange,
  globalClick,
  globalGetFocus,
  onWelcomeModalClose,
  globalLostFocus,
  updateUserProfileField,
  auth,
}) => {
  const [formData, setFormData] = useState({
    chkWelcomeMessageRepeat: false,
    btnWelcomeOK: '',
  });
  const {t} = usePCTranslation();
  const updateWelcomeModalFormData = async (name, value) => {
    let data = {...formData};
    data[name] = value;
    setFormData(data);
  };
  const elementItems = {};
  const setElement = (element) => {
    if (element !== null) {
      elementItems[element.name] = element;
    }
  };
  const controlChange = async (event, name, value) => {
    event.persist();
    await updateWelcomeModalFormData(name, value);
    // let data = {...formData};
    // data[name] = value;
    globalChange(event, formData, elementItems, updateWelcomeModalFormData);
  };
  const controlClick = (event, name, value) => {
    event.persist();
    event.preventDefault();
    globalClick(name, formData, elementItems, updateWelcomeModalFormData);
  };
  const controlGetFocus = (event, name, value) => {
    globalGetFocus(event, formData, elementItems, updateWelcomeModalFormData);
  };
  const controlLostFocus = (event, name, value) => {
    globalLostFocus(event, formData, elementItems, updateWelcomeModalFormData);
  };
  const handleFinish = () => {
    if (!formData['chkWelcomeMessageRepeat']) {
      updateUserProfileField('isFirstRun', false);
    }
    onWelcomeModalClose();
  };
  return (
    <Modal onClick={(e) => e.stopPropagation()} propStyle="modalDark">
      <div className="modalcontainerSm paddingLg borderRadiusAllLg">
        <div className="OPENSAVEMODALCONTENTS modalWrapper">
          <div className="modalHeaderSm">
            <ModalTitle title={'title-WelcomeMessage'} t={t} fontSize="fontHdr2" />
          </div>
          <div className="modal-content">
            <p>{`${t('msg-WelcomeMessage1')} ${auth?.user?.firstName}!!!`}</p>
            <p>{t('msg-WelcomeMessage2')}</p>
            <Checkbox
              text="Show Again"
              getfocusCallback={controlGetFocus}
              getchangeCallback={controlChange}
              lostfocusCallback={controlLostFocus}
              stateData={formData}
              name="chkWelcomeMessageRepeat"
              refItem={setElement}
              propStyle={{
                label: 'checklabel',
                control: `checkcontrol`,
                checkcontainer: 'checkcontainer',
              }}
            />
            <QuestionModalFooter
              controlGetFocus={controlGetFocus}
              controlLostFocus={controlLostFocus}
              controlClick={controlClick}
              setElement={setElement}
              onSelectOK={handleFinish}
              onSelectCancel={() => {}}
              btnCancel={''}
              btnOK={'btnWelcomeOK'}
              isInfo={true}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
