import React from 'react';
//const { PropTypes } = React;
import PropTypes from 'prop-types';

//ToDo: get icon color working!
export const Icon = (props) => {
  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '10px',
      float: 'right',
      color: 'purple',
    },
    path: {
      fill: props.color,
    },
  };
  let newStyle = {};
  Object.assign(newStyle, styles.svg);
  newStyle['float'] = props.float;

  return (
    <svg
      //style={newStyle}
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox='0 0 1024 1024'>
      <path style={styles.path} d={props.icon}></path>
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

Icon.defaultProps = {
  size: 16,
  float: 'right',
};

export default Icon;
