import {companyLogo, procompMagentaLogo} from 'assets';
import React from 'react';

const IntroModal1 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <h3 className="fontHdrCapitalize textAlignCenter marginMed fontLg">
          BEFORE GETTING STARTED
        </h3>
        <header className="sharedOwnershipHdr">
          <h1 className="fontHdr1 textAlignCenter fontPrimaryColor fontLg">
            Shared Ownership is a concept
          </h1>
          <h2 className="fontHdr1 textAlignCenter">not a life insurance product.</h2>
          <div className="flexContainer alignCenter justifyCenter">
            <img
              id="intro-1"
              className=""
              style={{width: '6rem', height: '6rem'}}
              src={procompMagentaLogo}
              alt=""
            />
            {/* <span className='fontHdr2 fontAltColor logo-title'>PROCOMP</span> */}
          </div>
        </header>
        <section className="sharedOwnershipContainer noWrap">
          <section className="paddingLg borderBottom borderThin borderNeutral">
            <p className="fontHdr2 textAlignCenter">
              This concept must be implemented by way of a separate legal agreement prepared by, and
              on the advice of, qualified legal counsel for the parties involved. A Shared ownership
              arrangement is complex, technical and not without risk.
            </p>
          </section>
          <section className="paddingLg">
            <p className="marginMed">
              The following material is for informational purposes only. This material is not
              intended to be, not should it be constructed as legal, tax or financial advice to the
              viewer. It should be only used as a guideline for the parties involved and their
              respective advisors. Competent independent legal and tax advice from qualified
              professionals is essential.
            </p>
            <p>
              While every effort has been made to ensure the accuracy of this information, errors
              and omissions are possible.
            </p>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal1;
