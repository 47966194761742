import uuid from 'uuid';
//Core - Report engine
export class ReportBuilder {
  constructor() {
    this.ReportData = {};
  }

  buildDataReport(user, reportInputItems, reportSettings, reportNumber, reportName) {
    this.ReportData.user = user;
    this.ReportData.reportID = uuid();
    this.ReportData.reportName = reportName;
    this.ReportData.reportNumber = reportNumber;
    this.ReportData.reportSettings = this.buildSettingsOutput(reportSettings);
    this.ReportData.reportInputItems = reportInputItems;
    return this.ReportData;
  }

  buildReport(
    reportListItems,
    reportScalarItems,
    controlItems,
    formData,
    insuranceData,
    reportSettings,
    columnStringItems,
    reportDataArrayString,
    isRunExcel,
    isRunPDF,
    xlsFileName,
    pdfFileName,
    isOverridePath,
    pathType,
    xlsOverridePath,
    pdfOverridePath,
    isDeleteWhenDone
  ) {
    this.ReportData.reportID = uuid();
    this.ReportData.isRunExcel = isRunExcel;
    this.ReportData.isRunPDF = isRunPDF;
    this.ReportData.pdfFileName = pdfFileName;
    this.ReportData.xlsFileName = xlsFileName;
    this.ReportData.isOverridePath = isOverridePath;
    this.ReportData.pathType = pathType;
    this.ReportData.pdfOverridePath = pdfOverridePath;
    this.ReportData.xlsOverridePath = xlsOverridePath;
    this.ReportData.isDeleteWhenDone = isDeleteWhenDone;

    this.ReportData.reportListItems = this.buildListItemsOutput(reportListItems);
    this.ReportData.reportSettings = this.buildSettingsOutput(reportSettings);
    this.ReportData.reportScalarItems = reportScalarItems;

    this.ReportData.reportColumnItems = this.buildColumnItemsOutput(insuranceData);
    this.ReportData.reportDataArray = this.buildDataArrayOutput(insuranceData);
    this.ReportData.reportColumnStringItems = this.buildColumnStringItemsOutput(columnStringItems);
    this.ReportData.reportDataArrayString = this.buildDataArrayStringOutput(reportDataArrayString);
    return this.ReportData;
  }

    buildNewBusinessReport(
        controlItems,
        formData,
        insuranceData,
        isRunExcel,
        isRunPDF,
        xlsFileName,
        pdfFileName,
        isOverridePath,
        pathType,
        xlsOverridePath,
        pdfOverridePath,
        isDeleteWhenDone,
        reportLanguage
    ) {
        console.log("insuranceData = ", insuranceData);

        //The API for globalVariables.booleanValue is an integer (1 or 0)
        //but the value in the field returned from the calculation API is boolean ( true or false)
        //so we must convert it before sending it back.
        insuranceData.globalVariables.forEach(function (arrayItem) {
            arrayItem.booleanValue = JSON.parse(arrayItem.booleanValue) ? 1 : 0;
        });

        this.ReportData.reportID = uuid();
        this.ReportData.isRunExcel = isRunExcel;
        this.ReportData.isRunPDF = isRunPDF;
        this.ReportData.pdfFileName = pdfFileName;
        this.ReportData.xlsFileName = xlsFileName;
        this.ReportData.isOverridePath = isOverridePath;
        this.ReportData.pathType = pathType;
        this.ReportData.pdfOverridePath = pdfOverridePath;
        this.ReportData.xlsOverridePath = xlsOverridePath;
        this.ReportData.isDeleteWhenDone = isDeleteWhenDone;
        this.ReportData.reportLanguage = reportLanguage;
        this.ReportData.insuranceData = insuranceData;
        this.ReportData.formData = formData;
        ////this.ReportData.controlItems = controlItems; //not currently in the ReportData object
        return this.ReportData;
    }

  //Report List items data generation
  addReportListItem(name, reportFile, custom, required, pageBreak, custColFile) {
    return {name, reportFile, custom, required, pageBreak, custColFile};
  }
  buildListItemsOutput(reportListItems) {
    let ListItemsArray = [];
    reportListItems.forEach((item) => {
      ListItemsArray.push({...item});
    });
    return ListItemsArray;
  }
  //Report List End

  buildDataArrayOutput = (insuranceData) => {
    let dataArray = [];
    insuranceData.dblTableData_Main.forEach((item) => {
      dataArray.push(item);
    });
    //console.log('DataArray: ', dataArray);
    return dataArray;
  };

  buildColumnItemsOutput = (insuranceData) => {
    let columnItems = [];
    insuranceData.columnItems.forEach((item) => {
      const {name, displayName, columnNumber} = item;
      columnItems.push({name, displayName, columnNumber});
    });
    return columnItems;
  };
  //Insurance End

  buildSettingsOutput = (reportSettings) => {
    let reportSettingsArray = [];
    reportSettings.forEach((item) => {
      reportSettingsArray.push({...item});
    });
    return reportSettingsArray;
  };
  //Settings End

  //Column String Items
  buildColumnStringItemsOutput = (columnStringItems) => {
    let columnStringItemsObj = [];
    columnStringItems.forEach((item) => {
      let {name, displayName, columnNumber} = item;
      columnStringItemsObj.push({name, displayName, columnNumber});
    });

    return columnStringItemsObj;
  };
  //Column String End

  //Data Array String
  buildDataArrayStringOutput = (dataArrayStringItems) => {
    let dataArrayStringObj = [];
    dataArrayStringItems.forEach((item) => dataArrayStringObj.push(item));

    return dataArrayStringObj;
  };
  // Data Array String End
}
