import {isLaterDate} from 'custom/dataReports/utils/functions';
import {PolicyTypes} from 'custom/newbiz/utils/constants';

export default function ValidateForm(formData, rules) {
  //console.log("Inside ValidateForm");
  let errors = {};
  console.log(formData);
  if (
    formData["rdoPostalOrCity"] === "Postal" &&
    formData["txtPostalCode"] === ""
  ) {
    errors["txtPostalCode"] = "Postal Code cannot be empty";
  }
  if (formData["rdoPostalOrCity"] === "City" && formData["txtCity"] === "") {
    errors["City"] = "City cannot be empty";
  }

  // if (formData.chkBirthday === true || formData.rdoRange === "Date") {
  //   // if (!isLaterDate(formData.dtToDate, formData.dtFromDate)) {
  //   if (formData.dtToDate.getTime() < formData.dtFromDate.getTime()) {
  //     errors.push("'From' date must be less or equal to 'To' date");
  //   }
  // } else
  if (formData["chkBirthday"] === false || formData["rdoRange"] === "Age") {
  } else if (
    formData["dtToDate"] !== undefined &&
    formData["dtFromDate"] !== undefined
  ) {
    // if (!isLaterDate(formData.dtToDate, formData.dtFromDate)) {
    if (formData["dtToDate"].getTime() < formData["dtFromDate"].getTime()) {
      errors["dtFromDate"] = "'From' date must be less or equal to 'To' date";
    }
  }

  if (formData["txtOwnerName"] === "") {
    console.log("custom Form Validation error");
    errors["txtOwnerName"] =
      "You must enter the name of the policy owner and the insured before you can generate the illustration report.";
  } else if (formData["txtInsuredName"] === "") {
    console.log("custom Form Validation error");
    errors["txtInsuredName"] =
      "You must enter the name of the policy owner and the insured before you can generate the illustration report.";
  } else if (
    formData["cboPolicyType"] !== PolicyTypes.SINGLE &&
    formData["txtInsuredName2"] === ""
  ) {
    console.log("custom Form Validation error");
    errors["txtInsuredName2"] =
      "You must enter the name of all insureds before you can generate the illustration report.";
  }

  if (formData["formName"] === "eApplication") {
    if (!formData["chkPaymentDeclaration"]) {
      errors["chkPaymentDeclaration"] = "You must check payment declaration";
    }
    if (!formData["chkDeclarationAuthorization"]) {
      errors["chkDeclarationAuthorization"] =
        "You must check declaration and authorization";
    }
    if (
      formData["txtInsuredHomePhone"].length !==
      rules["txtInsuredHomePhone"].maxLength
    ) {
      errors["txtInsuredHomePhone"] =
        "You must enter valid Insured Home Phone Number";
    }
    // if (formData['txtInsuredCellPhone'].length !== rules['txtInsuredCellPhone'].maxLength) {
    //   errors['txtInsuredCellPhone'] = 'You must enter valid Insured Cell Phone Number';
    // }
    // if (formData['txtInsuredPostalZipCode'].length !== rules['txtInsuredPostalZipCode'].maxLength) {
    //   errors['txtInsuredPostalZipCode'] = 'You must enter valid postal/zip code';
    // }
    if (formData["rdoOwnerType"] !== "Company") {
      if (
        formData["txtOwnerHomePhone"].length !==
        rules["txtOwnerHomePhone"].maxLength
      ) {
        errors["txtOwnerHomePhone"] =
          "You must enter valid Owner Home phone number";
      }
      // if (
      //   formData["txtOwnerCellPhone"].length !==
      //   rules["txtOwnerCellPhone"].maxLength
      // ) {
      //   errors["txtOwnerCellPhone"] =
      //     "You must enter valid Owner Cell phone number";
      // }
      if (formData["txtOwnerSIN"].length !== rules["txtOwnerSIN"].maxLength) {
        errors["txtOwnerSIN"] = "You must enter valid SIN number";
      }
    }

    if (formData["rdoOwnerType"] === "Company") {
      if (
        formData["txtOwnerBusinessNumber3"].length !==
        rules["txtOwnerBusinessNumber3"].maxLength
      ) {
        errors["txtOwnerBusinessNumber3"] = "You must enter valid Business Number";
      }
      if (
          formData["txtOwnerHomePhone3"].length !==
          rules["txtOwnerHomePhone3"].maxLength
      ) {
          errors["txtOwnerHomePhone3"] =
          "You must enter valid Business Phone Number";
      }
    }
    //   if (
    //     formData["txtOwnerPostalZipCode"].length !==
    //     rules["txtOwnerPostalZipCode"].maxLength
    //   ) {
    //     errors["txtOwnerPostalZipCode"] =
    //       "You must enter valid postal/zip code for Owner";
    //   }
    // }

    if (
      formData["rdoPaymentFrequency"] === "M" &&
      formData["rdoAccountType"] === "B"
    ) {
      if (
        formData["txtTransitNumber"].length !==
        rules["txtTransitNumber"].maxLength
      ) {
        errors["txtTransitNumber"] = "You must enter valid Transit Number";
      }
      if (
        formData["txtInstitutionNumber"].length !==
        rules["txtInstitutionNumber"].maxLength
      ) {
        errors["txtInstitutionNumber"] =
          "You must enter valid Institution Number";
      }
      if (
        formData["txtAccountNumber"].length !==
        rules["txtAccountNumber"].maxLength
      ) {
        errors["txtAccountNumber"] = "You must enter valid Account Number";
      }
    }
    // if (
    //   formData["rdoWeightLoss"] === "Y" &&
    //   (!formData["txtWeightLoss"] ||
    //     formData["txtWeightLoss"].trim().length === 0)
    // ) {
    //   errors["txtWeightLoss"] = "Please enter reason for weight loss";
    // }

    if (
      formData["rdo2aCrimeQuestion"] === "Y" &&
      (!formData["txt2aCrimeDetails"] ||
        formData["txt2aCrimeDetails"].trim().length === 0)
    ) {
      errors["txt2aCrimeDetails"] =
        "Please enter asked details for Health Questions";
    }
    if (
      formData["rdo2bCrimeQuestion"] === "Y" &&
      (!formData["txt2bCrimeDetails"] ||
        formData["txt2bCrimeDetails"].trim().length === 0)
    ) {
      errors["txt2bCrimeDetails"] =
        "Please enter asked details for Health Questions";
    }
    // if (formData['rdo2cCrimeQuestion'] === 'Y' && (!formData['txt2cCrimeDetails'] || formData['txt2cCrimeDetails'].trim().length === 0)) {
    //   errors['txt2cCrimeDetails'] = 'Please enter asked details for Health Questions';
    // }
    if (
      formData["rdo3aValidateSymptom"] === "Y" &&
      (!formData["txt3aIllnessNature"] ||
        formData["txt3aIllnessNature"].trim().length === 0)
    ) {
      errors["txt3aIllnessNature"] = "You must provide nature of illness";
    }
    if (
      formData["rdo3bValidateSymptom"] === "Y" &&
      (!formData["txt3bIllnessNature"] ||
        formData["txt3bIllnessNature"].trim().length === 0)
    ) {
      errors["txt3bIllnessNature"] = "You must provide nature of illness";
    }
    if (
      formData["rdo3cValidateSymptom"] === "Y" &&
      (!formData["txt3cIllnessNature"] ||
        formData["txt3cIllnessNature"].trim().length === 0)
    ) {
      errors["txt3cIllnessNature"] = "You must provide nature of illness";
    }
  }

  // Beneficiary share% form validation
  let primaryBene = 0;
  let primaryBeneficiaryField;
  let contingentBeneficiaryField;
  let contingentBene = 0;
  for (let i = 1; i <= 3; i++) {
    if (formData[`chkBeneBase${i}`] === true) {
      if (formData[`rdoBeneBaseType${i}`] === "P") {
        primaryBene += +formData[`txtBeneBaseShare${i}`];
        primaryBeneficiaryField = `rdoBeneBaseType${i}`;
      } else {
        contingentBene += +formData[`txtBeneBaseShare${i}`];
        contingentBeneficiaryField = `rdoBeneBaseType${i}`;
      }
    }
  }
  if (primaryBene > 100) {
    errors[primaryBeneficiaryField] =
        "Primary beneficiary percentage share for Base Coverage should be less than or equal to 100";
  }
  if (contingentBene > 100) {
    errors[contingentBeneficiaryField] =
        "Contingent beneficiary percentage share for Base Coverage should be less than or equal to 100";
  }
  primaryBene = 0;
  contingentBene = 0;

  if (formData["chkEAppChildRider1"] === true) {
    for (let i = 1; i <= 3; i++) {
      if (formData[`chkBeneChildRider1${i}`] === true) {
        if (formData[`rdoBeneChildRider1Type${i}`] === "P") {
          primaryBene += +formData[`txtBeneChildRider1Share${i}`];
          primaryBeneficiaryField = `rdoBeneChildRider1Type${i}`;
        } else {
          contingentBene += +formData[`txtBeneChildRider1Share${i}`];
          contingentBeneficiaryField = `rdoBeneChildRider1Type${i}`;
        }
      }
      if (primaryBene > 100) {
          errors[primaryBeneficiaryField] =
              "Primary beneficiary percentage share for Child Rider1 Coverage should be less than or equal to 100";
      }
      if (contingentBene > 100) {
          errors[contingentBeneficiaryField] =
              "Contingent beneficiary percentage share for Child Rider1 Coverage should be less than or equal to 100";
      }
    }
  }
  primaryBene = 0;
  contingentBene = 0;

  if (formData["chkEAppChildRider2"] === true) {
    for (let i = 1; i <= 3; i++) {
      if (formData[`chkBeneChildRider2${i}`] === true) {
        if (formData[`rdoBeneChildRider2Type${i}`] === "P") {
          primaryBene += +formData[`txtBeneChildRider2Share${i}`];
          primaryBeneficiaryField = `rdoBeneChildRider2Type${i}`;
        } else {
          contingentBene += +formData[`txtBeneChildRider2Share${i}`];
          contingentBeneficiaryField = `rdoBeneChildRider2Type${i}`;
        }
      }
    }
    if (primaryBene > 100) {
      errors[primaryBeneficiaryField] =
        "Primary beneficiary percentage share for ChildRider2 Coverage should be less than or equal to 100";
    }
    if (contingentBene > 100) {
      errors[contingentBeneficiaryField] =
        "Contingent beneficiary percentage share for ChildRider2 Coverage should be less than or equal to 100";
    }
  }
  primaryBene = 0;
  contingentBene = 0;

  if (formData["chkEAppTermRider1"] === true) {
    for (let i = 1; i <= 3; i++) {
      if (formData[`chkBeneTermRider1${i}`] === true) {
        if (formData[`rdoBeneTermRider1Type${i}`] === "P") {
          primaryBene += +formData[`txtBeneTermRider1Share${i}`];
          primaryBeneficiaryField = `rdoBeneTermRider1Type${i}`;
        } else {
          contingentBene += +formData[`txtBeneTermRider1Share${i}`];
          contingentBeneficiaryField = `rdoBeneTermRider1Type${i}`;
        }
      }
    }
    if (primaryBene > 100) {
      errors[primaryBeneficiaryField] =
        "Primary beneficiary percentage share for TermRider1 Coverage should be less than or equal to 100";
    }
    if (contingentBene > 100) {
      errors[contingentBeneficiaryField] =
        "Contingent beneficiary percentage share for TermRider1 Coverage should be less than or equal to 100";
    }
  }
    primaryBene = 0;
    contingentBene = 0;

  if (formData["chkEAppTermRider2"] === true) {
    for (let i = 1; i <= 3; i++) {
      if (formData[`chkBeneTermRider2${i}`] === true) {
        if (formData[`rdoBeneTermRider2Type${i}`] === "P") {
          primaryBene += +formData[`txtBeneTermRider2Share${i}`];
          primaryBeneficiaryField = `rdoBeneTermRider2Type${i}`;
        } else {
          contingentBene += +formData[`txtBeneTermRider2Share${i}`];
          contingentBeneficiaryField = `rdoBeneTermRider2Type${i}`;
        }
      }
    }
    if (primaryBene > 100) {
      errors[primaryBeneficiaryField] =
        "Primary beneficiary percentage share for TermRider2 Coverage should be less than or equal to 100";
    }
    if (contingentBene > 100) {
      errors[contingentBeneficiaryField] =
        "Contingent beneficiary percentage share for TermRider2 Coverage should be less than or equal to 100";
    }
  }

  
  return errors;
}
