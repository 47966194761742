import React, { Component, Fragment } from 'react';
import { Modal, Spinner, Alert } from 'core/uicore';
import { addScalarItem } from 'core/report/reportUtils';
import { ScalarTypes } from 'core/constants/reportConstants';
import { ReportBuilder } from 'core/report/buildOutput';
import { buildReportSettings } from 'custom/inforce/report/buildReportFunctions';
import {
  postBIReportsData,
  exportDownload,
} from "custom/common/api/postReportWebServices";
// import {buildAgentName} from '../utils/functions';
import QueryListDisplay from "./QueryListDisplay";
import { biReportsFormData } from "../utils/data";
import { Checkbox, Datebox } from "core/controls";

class BIReports extends Component {
  state = {
    isLoadingReport: false,
    formData: biReportsFormData,
  };
  elementItems = {};
  updatBIReportFormData = (name, value) => {
    this.setState({
      formData: { ...this.state.formData, [name]: value },
    });
  };

  updateBIReportFormDataWithMultipleFields = (fields, values) => {
    const formData = { ...this.state.formData };

    fields.forEach((field, idx) => {
      formData[field] = values[idx];
    });
    this.setState({ formData });
  };

  setElement = (element) => {
    if (element !== null) {
      this.elementItems[element.name] = element;
    }
  };
  componentDidMount() {
    const userFormData = this.props.auth?.user?.formData;
    let startDate = new Date();
    let endDate = new Date();

    if (userFormData?.["dtBIReportsStartDate"]) {
      startDate = new Date(userFormData["dtBIReportsStartDate"]);
    } else {
      startDate.setMonth(0);
      startDate.setDate(1);
    }

    if (userFormData?.["dtBIReportsEndDate"]) {
      endDate = new Date(userFormData["dtBIReportsEndDate"]);
    }
    this.updateBIReportFormDataWithMultipleFields(
      ["dtBIReportsStartDate", "dtBIReportsEndDate"],
      [startDate, endDate]
    );
  }

  controlChange = async (event, name, value) => {
    event.persist();
    await this.updatBIReportFormData(name, value);
    if (name === "dtBIReportsStartDate" || name === "dtBIReportsEndDate") {
      const data = {
        [name]: value,
      };
      await this.props.updateBIOptionsProfile(data);
    }
    this.props.globalChange(
      event,
      this.state.formData,
      this.elementItems,
      this.updatBIReportFormData
    );
  };

  controlClick = (event, name, value) => {
    event.persist();
    event.preventDefault();
    this.props.globalClick(
      name,
      this.state.formData,
      this.elementItems,
      this.updatBIReportFormData
    );
  };

  controlGetFocus = (event, name, value) => {
    this.props.globalGetFocus(
      event,
      this.state.formData,
      this.elementItems,
      this.updatBIReportFormData
    );
  };

  controlLostFocus = (event, name, value) => {
    this.props.globalLostFocus(
      event,
      this.state.formData,
      this.elementItems,
      this.updatBIReportFormData
    );
  };

  handleCreateReport = async ({ reportNumber, reportName, reportAPIPath }) => {
    this.setState({ isLoadingReport: true });

    let reportInputItems = [];
    reportInputItems.push(
      addScalarItem(
        "PQWizard_StartDate",
        this.state.formData["dtBIReportsStartDate"],
        ScalarTypes.SCALAR_DATE
      )
    );
    reportInputItems.push(
      addScalarItem(
        "PQWizard_EndDate",
        this.state.formData["dtBIReportsEndDate"],
        ScalarTypes.SCALAR_DATE
      )
    );
    reportInputItems.push(
      addScalarItem(
        "PQWizard_chkStoreInExcel",
        this.state.formData["chkStoreInExcel"],
        ScalarTypes.SCALAR_BOOLEAN
      )
    );

    let reportSettings = buildReportSettings();

    const repoBuild = new ReportBuilder();

    let finalReport = repoBuild.buildDataReport(
      this.props.auth.user,
      reportInputItems,
      reportSettings,
      reportNumber,
      reportName
    );

    let resReportsData = await postBIReportsData(finalReport, reportAPIPath);

    if (resReportsData.resultCode === 2) {
      this.props.setAlert(resReportsData.errorText, "Error", "dataReports");
    } else {
      if (this.state.formData["chkStoreInExcel"] === true) {
        await exportDownload(
          finalReport.reportID,
          resReportsData.reportOutputFile
        );
      } else {
        //Note: content=viewer will change it back from pdf to the default viewer
        let url = `mywebtierparams/mywebtierparams.dll?content=pdf&volume=ProComp&name=main&folder=v0&ReportID=${finalReport.reportID}`;
        console.log("url", url);
        window.open(url);
      }
    }
    this.setState({ isLoadingReport: false });
  };

  handleReportClick = (e, card, reportAPIPath) => {
    this.setState({
      formData: {
        ...this.state.formData,
        reportNumber: card.key,
        reportName: card.value,
      },
      isModal: true,
    });
    this.handleCreateReport({
      reportNumber: card.key,
      reportName: card.value,
      reportAPIPath,
    });
    this.controlClick(e, "btnBIReportSelection", card.value);
  };

  render() {
    const {
      controlChange,
      setElement,
      controlLostFocus,
      controlGetFocus,
      handleReportClick,
      props: { tInput, t },
      state: { formData, isLoadingReport },
    } = this;
    return (
      <Fragment>
        <div className="pageContents fixedPageHdr">
          <Alert name="dataReports" styling="paddingLg fullWidth" />
          <div
            style={{ display: "flex", columnGap: "4rem", marginBottom: "2rem" }}
          >
            {" "}
            <div id="reportheader-id" className="fontHdr1">
              {t("DataReports")}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "3rem",
              }}
            >
              <Datebox
                name="dtBIReportsStartDate"
                text="From Date"
                getfocusCallback={controlGetFocus}
                getchangeCallback={controlChange}
                lostfocusCallback={controlLostFocus}
                stateData={formData}
                refItem={setElement}
                maxDate={new Date()}
                propStyle={{
                  label: "textlabel",
                  control: "datecontrol borderRadiusLeft marginNone",
                  datecontainer: `datecontainer`,
                }}
              />
              <Datebox
                name="dtBIReportsEndDate"
                text="To Date"
                getfocusCallback={controlGetFocus}
                getchangeCallback={controlChange}
                lostfocusCallback={controlLostFocus}
                stateData={formData}
                refItem={setElement}
                minDate={""}
                maxDate={new Date()}
                propStyle={{
                  label: "textlabel",
                  control: "datecontrol borderRadiusLeft marginNone",
                  datecontainer: `datecontainer`,
                }}
              />
              <Checkbox
                text="Store in Excel"
                getfocusCallback={controlGetFocus}
                getchangeCallback={controlChange}
                lostfocusCallback={controlLostFocus}
                stateData={formData}
                name="chkStoreInExcel"
                refItem={setElement}
                propStyle={{
                  label: "checklabel",
                  control: "checkcontrol",
                  checkcontainer: "checkcontainer stemQuestion fullWidth",
                }}
              />
            </div>
          </div>
          <QueryListDisplay onClick={handleReportClick} t={tInput} />
        </div>
        {isLoadingReport && (
          <Modal onClick={(e) => e.stopPropagation()}>
            <Spinner styling="modalDark" />
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default BIReports;
