import {INPUTTYPES} from 'core/constants/uiConstants';
import {ScalarTypes, REPORT_SETTING} from 'core/constants/reportConstants';
// import {REPORT_SETTING} from './constants';

export function getOptionText(item, controlItems, formData, t) {
  return controlItems[item] && controlItems[item].optionItems
    ? t(controlItems[item].optionItems[formData[item]])
    : null;
}

export function formatRatingText(fieldValue, displayText) {
  //If it is rated above standard then remove the letters at the front
  let newText = displayText;
  if (parseInt(fieldValue) > 100) {
    let blankLocation = displayText.indexOf(' ');
    if (blankLocation >= 0) {
      newText = displayText.substring(blankLocation + 1);
    }
    console.log(`newtext = ${newText}`);
    return newText;
  }
}

export const getScalarItem = (scalarItemsArray, name) => {
  let aScalarItem = {};
  let filterArray = scalarItemsArray.filter(
    (scalarItem) => scalarItem.name.toUpperCase() === name.toUpperCase()
  );
  if (filterArray.length === 0) {
    return aScalarItem;
  } else {
    return filterArray[0];
  }
};

export const buildScalarFromControlItems = (controlItems, scalarReportArray, formData, user, t) => {
    const optionsProduct = {
        131: 'Term 10',
        132: 'Term 20',
        133: 'Term 30',
    };

    Object.keys(controlItems).forEach((item) => {
        //We cannot use !formData[item] to test if the item is in formData because for booleans this will return false (ie checkboxes that are off)  use !(item in formData) instead
        //if(!formData[item]){
        //  return;
        //  }

        if (!(item in formData)) {
            return;
        }

        //Null items break it so don't process them
        if (formData[item] === null) {
            console.log("null item in formData: ${item}", item);
        }
        else {
            let desc = `${item}-description`;
            let temp = t(desc);
            let key = '';
            let value = '';

            //Extracting optionItems
            let optionItems = controlItems[item].useOptions ? controlItems[item].optionItems : {};
            //Static data

            // if (user["reportLanguage"] === "English") {
            //   desc = desc + "-en";
            // } else if (user["reportLanguage"] === "French") {
            //   desc = desc + "-fr";
            // }

            let name = temp.length > 0 && temp !== '' ? temp : item;

            let searchString = 'chkEappFundSelection1_String,chkEappFundSelection1,cboEappFundSelection1,cboEappFundSelection1_Desc,txtEappFundSelectionInterestRate1,txtEappFundSelectionAllocationPercent1,chkEappFundSelection2_String,chkEappFundSelection2,cboEappFundSelection2,cboEappFundSelection2_Desc,txtEappFundSelectionInterestRate2,txtEappFundSelectionAllocationPercent2,chkEappFundSelection3_String,chkEappFundSelection3,cboEappFundSelection3,cboEappFundSelection3_Desc,txtEappFundSelectionInterestRate3,txtEappFundSelectionAllocationPercent3,chkEappFundSelection4_String,chkEappFundSelection4,cboEappFundSelection4, cboEappFundSelection4_Desc,txtEappFundSelectionInterestRate4,txtEappFundSelectionAllocationPercent4,Medical_String,Medical,ECG_String,ECG,Urine_String,Urine,Saliva_String,Saliva,BloodProfile_String,BloodProfile,Inspection_String,Inspection,MVR_String,MVR,ParaMedical_String,ParaMedical,Eapp Multi Owner Election,chkDeclarationAuthorization,chkPaymentDeclaration,Eapp WPD,Eapp GPO,Eapp AIB,Eapp AIB Owner Age,'
            let remove_String = 'chkEappFundSelection1_String, chkEappFundSelection1, cboEappFundSelection1, cboEappFundSelection1_Desc, txtEappFundSelectionInterestRate1, txtEappFundSelectionAllocationPercent1, chkEappFundSelection2_String, chkEappFundSelection2, cboEappFundSelection2, cboEappFundSelection2_Desc, txtEappFundSelectionInterestRate2, txtEappFundSelectionAllocationPercent2, chkEappFundSelection3_String, chkEappFundSelection3, cboEappFundSelection3, cboEappFundSelection3_Desc, txtEappFundSelectionInterestRate3, txtEappFundSelectionAllocationPercent3, chkEappFundSelection4_String, chkEappFundSelection4, cboEappFundSelection4, cboEappFundSelection4_Desc, txtEappFundSelectionInterestRate4, txtEappFundSelectionAllocationPercent4,Medical_String,Medical,ECG_String,ECG,Urine_String,Urine,Saliva_String,Saliva,BloodProfile_String,BloodProfile,Inspection_String,Inspection,MVR_String,MVR,ParaMedical_String,ParaMedical,Eapp Multi Owner Election,chkDeclarationAuthorization,chkPaymentDeclaration,Eapp WPD,Eapp GPO,Eapp AIB,Eapp AIB Owner Age,'
            //For the demo we seem to be hitting the maximum number of scalars allowed
            //so I am removing some that we do not need for demo
            if (searchString.includes(','+name)) {
                console.log("Report Scalar Skip = ", name);
                return;
            } else {

                switch (controlItems[item].fieldType) {
                    case INPUTTYPES.INPUT_UPPER:
                    case INPUTTYPES.INPUT_TEXT:
                    case INPUTTYPES.INPUT_NAME:
                    case INPUTTYPES.INPUT_INTEGER:
                    case INPUTTYPES.INPUT_NUMBER: //This type is different from Integer in a way how number will be displayed on the page (without money formatting)
                    case INPUTTYPES.INPUT_LONGINT:
                    case INPUTTYPES.INPUT_REAL:
                    case INPUTTYPES.INPUT_DOLLAR:
                    case INPUTTYPES.INPUT_PERCENT:
                    case INPUTTYPES.INPUT_DOLLAR_INTEGER:
                    case INPUTTYPES.INPUT_DATE:
                    case INPUTTYPES.INPUT_PHONE:
                    case INPUTTYPES.INPUT_POSTALCODE:
                    case INPUTTYPES.INPUT_EMAIL:
                        if (formData[item].length > 100) {
                            let strArray = formData[item].match(/(.|[\r\n]){1,100}/g);
                            strArray.forEach((str, idx) => {
                                /**
                                * Below condition is to make sure that existing reports do not break in case their text is more than 100 chars
                                * For reports with more than 100 chars. variables be like:
                                * txt2aCrimeDetails, txt2aCrimeDetails1, txt2aCrimeDetails2 etc.
                                * Also, if text length is less than 100 for these fields. It works, because first variable is txt2aCrimeDetails in both cases
                                */
                                if (idx === 0) {
                                    scalarReportArray.push(addScalarItem(name, str, controlItems[item].fieldType));
                                } else {
                                    scalarReportArray.push(
                                        addScalarItem(`${name}${idx}`, str, controlItems[item].fieldType)
                                    );
                                }
                            });
                        } else {
                            //console.log("field name = ", name);
                            //console.log("formData[item] = ", formData[item]);
                            scalarReportArray.push(addScalarItem(name, formData[item], controlItems[item].fieldType));
                        }
                        // scalarReportArray.push(addScalarItem(name, formData[item], controlItems[item].fieldType));
                        break;
                    case INPUTTYPES.INPUT_COMBO:
                    case INPUTTYPES.INPUT_RADIO:
                        scalarReportArray.push(addScalarItem(item, formData[item], INPUTTYPES.INPUT_UPPER));
                        let nameDesc = name;
                        if (controlItems[item].useOptions && name === item) {
                            nameDesc = `${name}_Desc`;
                        }
                        if (controlItems[item].useOptions) {
                            for (key of Object.keys(optionItems)) {
                                if (key === formData[item]) {
                                    value = t(optionItems[key]);
                                    value = value.slice(0, 125);
                                    scalarReportArray.push(addScalarItem(nameDesc, value, INPUTTYPES.INPUT_UPPER)); // Add NAME(or NAME_DESC) | VALUE | STRING TYPE
                                    break;
                                }
                            }
                        }
                        break;
                    case INPUTTYPES.INPUT_CHECK:
                        let name_String = `${item}_String`;
                        let value_String = formData[item] ? '1' : '0';
                        if (controlItems[item].formName.toUpperCase() == "EAPPLICATION") {
                            console.log("Report Scalar_String Skip = ", item);
                        } else {
                            scalarReportArray.push(addScalarItem(name_String, value_String, INPUTTYPES.INPUT_UPPER));
                        }
                        scalarReportArray.push(addScalarItem(name, formData[item], controlItems[item].fieldType));
                        break;
                    case INPUTTYPES.INPUT_MULTICHECK:
                        scalarReportArray.push(addScalarItem(name, formData[item], INPUTTYPES.INPUT_UPPER));
                        // let tempArr = [];
                        // for (key of Object.keys(optionItems)) {
                        //   if (key === formData[item]) {
                        //     tempArr.push(t(optionItems[key]));
                        //   }
                        // }
                        // value = tempArr.join(",");
                        // scalarReportArray.push(
                        //   addScalarItem(name, value, INPUTTYPES.INPUT_UPPER)
                        // );
                        break;

                    default:
                        break;
                }
            }
        }
    });
};

export function buildScalarsFromCalculationGlobalVariables(globalVariables, scalarReportArray) {
  let aScalarReportItem = {};
  for (let aGlobalVariable of globalVariables) {
    //console.log(`aGlobalVariable.name = ${aGlobalVariable.name}`, aGlobalVariable);
    aScalarReportItem = copyGlobalToReportItem(aGlobalVariable);
    scalarReportArray.push(aScalarReportItem);
  }
}

function copyGlobalToReportItem(aGlobalVariable) {
  //It is necessary to put this into a separate function to create a new variable
  let aScalarReportItem = {};
  aScalarReportItem.name = aGlobalVariable.name;
  if (aGlobalVariable.booleanValue === true) {
    aScalarReportItem.booleanValue = 1;
  } else {
    aScalarReportItem.booleanValue = 0;
  }
  aScalarReportItem.dataType = aGlobalVariable.dataType;
  aScalarReportItem.dateValue = aGlobalVariable.dateValue;
  aScalarReportItem.doubleValue = aGlobalVariable.doubleValue;
  aScalarReportItem.integerValue = aGlobalVariable.integerValue;
  aScalarReportItem.longIntValue = aGlobalVariable.longIntValue;
  aScalarReportItem.singleValue = aGlobalVariable.singleValue;
  aScalarReportItem.format = aGlobalVariable.format;
  aScalarReportItem.stringValue = aGlobalVariable.stringValue;
  return aScalarReportItem;
}

export function addScalarItem(name, value, dataType) {
  let scalarItemObject = {};
  scalarItemObject.name = name;
  switch (dataType) {
    case INPUTTYPES.INPUT_NAME:
    case INPUTTYPES.INPUT_TEXT:
    case INPUTTYPES.INPUT_UPPER:
    case INPUTTYPES.INPUT_COMBO:
    case INPUTTYPES.INPUT_RADIO:
    case ScalarTypes.SCALAR_STRING:
    case INPUTTYPES.INPUT_POSTALCODE:
    case INPUTTYPES.INPUT_EMAIL:
      scalarItemObject.stringValue = value;
      scalarItemObject.dataType = REPORT_SETTING.STRING;
      break;
    case INPUTTYPES.INPUT_INTEGER:
    case ScalarTypes.SCALAR_INTEGER:
      if (value === '') {
        value = '0';
      }
      scalarItemObject.integerValue = parseInt(value);
      scalarItemObject.longIntValue = parseInt(value);
      scalarItemObject.dataType = REPORT_SETTING.INTEGER;

      break;
    case INPUTTYPES.INPUT_LONGINT:
    case ScalarTypes.SCALAR_LONGINT:
    case INPUTTYPES.INPUT_DOLLAR_INTEGER:
    case INPUTTYPES.INPUT_NUMBER:
      if (value === '') {
        value = '0';
      }
      scalarItemObject.longIntValue = parseInt(value);
      scalarItemObject.dataType = REPORT_SETTING.LONGINT;

      break;
    case INPUTTYPES.INPUT_REAL:
    case INPUTTYPES.INPUT_DOLLAR:
    case INPUTTYPES.INPUT_PERCENT:
    case ScalarTypes.SCALAR_DOUBLE:
      if (value === '') {
        value = '0';
      }
      scalarItemObject.doubleValue = parseFloat(value);
      scalarItemObject.dataType = REPORT_SETTING.DOUBLE;

      break;
    case INPUTTYPES.INPUT_CHECK:
    case ScalarTypes.SCALAR_BOOLEAN:
      
      if (value === '') {
          value = 'false';
          console.log(`build scalar: ${name} had blank was updated to false`);
      }
      scalarItemObject.booleanValue = JSON.parse(value) ? 1 : 0;
      scalarItemObject.dataType = REPORT_SETTING.BOOLEAN;
      break;
    case INPUTTYPES.INPUT_PHONE:
      scalarItemObject.stringValue = value;
      scalarItemObject.dataType = REPORT_SETTING.STRING;
      break;
    case ScalarTypes.SCALAR_DATE:
    case INPUTTYPES.INPUT_DATE:
      let date = formattedDate(value);
      //console.log(typeof date, parseDate(date));
      scalarItemObject.stringValue = date; // Parse to DD/MM/YYYY
      scalarItemObject.dateValue = parseDate(date); // Parse to YYYY-MM-DDT12:00:00.000+00:00
      scalarItemObject.dataType = REPORT_SETTING.DATE; //ToDo - Make Date format work for Report
      // scalarItemObject.dataType = REPORT_SETTING.STRING;
      break;
    default:
      break;
  }
  return scalarItemObject;
}

export function getIllustrationNumber() {
  const today = new Date();
  //We had to add .toString() on the first one so that the result was treated as a string and not numbers that would get added together
  return (
    parseDay(today.getDate()) +
    parseDay(parseInt(today.getMonth() + 1)) +
    today.getFullYear() +
    parseDay(today.getHours()) +
    parseDay(today.getMinutes()) +
    parseDay(today.getSeconds())
  );
}

const parseDay = (day) => {
  let temp = '00' + day;
  return temp.substring(temp.length - 2, temp.length);
};

const parseDate = (date) => {
  const dd = date.substring(0, 2);
  const mm = date.substring(3, 5);
  const yyyy = date.substring(6);
  let returnDate = `${yyyy}-${mm}-${dd}T12:00:00.000+00:00`;

  return returnDate;
};
const formattedDate = (date) => {
  let formatDate = date;
  // if (typeof date === "string") {
  //   formatDate = new Date(date);

  //   console.log("format Date", formatDate);
  // }
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(formatDate);
};
