import React, {Component} from 'react';
import StatusContext from './StatusContext';
import {ICONS, STYLES} from 'core/constants/uiConstants';
import {Icon} from 'core/uicore';

class StatusMessage extends Component {
  static contextType = StatusContext;

  render() {
    //When the field is blank then we display nothing
    if (this.props.field === '') {
      let testObject = this.context.getStatusForField('');

      return Object.keys(testObject).map((item) => (
        <div
          className={`${STYLES.BASIC} ${STYLES.SUMMARY} ${this.props.propStyle.message}`}
          key={`${item}: ${testObject[item]}`}>
          {this.props.useIcon && <Icon icon={ICONS.DASH} size={5} float="left" />}
          {testObject[item]}
        </div>
      ));
    }
  }
}

//This is setting the property to true even when it is being passed in?
StatusMessage.defaultProps = {
  useIcon: true,
};

export default StatusMessage;
