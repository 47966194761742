import React, {Component} from 'react';
import {StatusMessage, StatusContext} from '.';
//import { StatusContext } from "../formValidation";
import {STYLES} from 'core/constants/uiConstants';
import {withPCTranslation} from 'core/hocs';

class StatusDisplay extends Component {
  static contextType = StatusContext;

  formatValue = (data) => (Array.isArray(data) ? data.join(', ') : data.toString());

  render() {
    let testObject = this.context.getStatusForField('');
    let msg_status = this.props.t('msg-Status');
    const {
      propStyle: {container, header, summary, message},
    } = this.props;
    return (
      <div className={`${STYLES.BASIC} ${STYLES.STATUSDISPLAY} ${container}`}>
        <div className={`${STYLES.BASIC} ${STYLES.HEADER} ${header}`}>{msg_status}</div>
        {Object.keys(testObject).length === 0 ? (
          <div className={`${STYLES.SUMMARYCONTAINER}`}>
            <div className={`${STYLES.BASIC} ${STYLES.SUMMARY} ${summary}`}></div>
          </div>
        ) : (
          <div className={`${STYLES.SUMMARYCONTAINER}`}>
            <StatusMessage field='' useIcon={this.props.useIcon} icon={this.props.icon} propStyle={{message: `${message}`}} />
          </div>
        )}
      </div>
    );
  }
}

export default withPCTranslation(StatusDisplay);
