import React, {useEffect, useState} from 'react';
import {Textbox, Checkbox, Radiobuttons, Selectbox} from 'core/controls';
import {FormStatus, FormValidator} from 'core/formValidation';
import ValidateForm from 'custom/common/utils/customFormValidation';
import {FUND_SELECTION} from '../SharedOwnershipModal';
import {companyLogo, ProcompGreyLogo} from 'assets';

const ProductSelectionModal = ({
  controlGetFocus,
  controlChange,
  controlLostFocus,
  setElement,
  elementItems,
  updateFormData,
  onLoad,
  formData,
  modalIndex,
  t,
}) => {
  useEffect(() => {
    onLoad(formData.productSelection, elementItems, updateFormData);
  }, []);
  const submitData = () => {};
  return (
    <FormStatus data={formData.productSelection}>
      <FormValidator
        data={formData.productSelection}
        submit={submitData}
        validateForm={ValidateForm}>
        <div className="slideshowContainer">
          <main className="slideshowContents">
            <header className="sharedOwnershipHdr">
              <div className="flexContainer alignCenter">
                <img
                  id="product-selection"
                  className=""
                  style={{width: '6rem', height: '6rem'}}
                  src={ProcompGreyLogo}
                  alt=""
                />
                <h2 className="fontHdrCapitalize textAlignCenter">
                  THE SHARED OWNERSHIP AGREEMENT
                </h2>
              </div>
              <h1 className="fontHdr1 textAlignCenter fontAltColorB">Product Selection</h1>
            </header>
            <section
              id="product-selection"
              className="sharedOwnershipContainer flexContainer noCols noWrap">
              <section>
                <Textbox
                  text="Employer Death Benefit"
                  getfocusCallback={controlGetFocus}
                  getchangeCallback={controlChange}
                  lostfocusCallback={controlLostFocus}
                  stateData={formData.productSelection['txtSharedDeathBenefitAmount']}
                  name="txtSharedDeathBenefitAmount"
                  refItem={setElement}
                  propStyle={{
                    label: 'textlabel',
                    control: 'textcontrol inputMed',
                    textcontainer: 'textcontainer',
                  }}
                />
                <Radiobuttons
                  text="COI Type"
                  getfocusCallback={controlGetFocus}
                  getchangeCallback={controlChange}
                  lostfocusCallback={controlLostFocus}
                  stateData={formData.productSelection}
                  //options={optionsPaymentMode}
                  name="rdoSharedCOIType"
                  refItem={setElement}
                  propStyle={{
                    label: 'fontHdr3',
                    control: 'radiocontrol',
                    radiocontainer: 'radiocontainer marginSm',
                    inputcontainer: 'radiocontainer marginSm',
                    child: 'childcontrol',
                  }}
                />
                <Radiobuttons
                  text="Death Benefit Type"
                  getfocusCallback={controlGetFocus}
                  getchangeCallback={controlChange}
                  lostfocusCallback={controlLostFocus}
                  stateData={formData.productSelection}
                  //options={optionsPaymentMode}
                  name="rdoSharedDeathBenefitType"
                  refItem={setElement}
                  propStyle={{
                    label: 'fontHdr3',
                    control: 'radiocontrol',
                    radiocontainer: 'radiocontainer marginSm',
                    inputcontainer: 'radiocontainer marginSm',
                    child: 'childcontrol',
                  }}
                  isDisabled={true}
                />
              </section>
              <section className="paddingSm bgWhite boxShadowAll">
                <table className="products-table" id="products-table">
                  <thead>
                    <tr className="title-row">
                      <th></th>
                      <th className="title-header fund-header">{t('msg-Fund')}</th>
                      <th className="title-header amount-header">{t('msg-InterestRate')}</th>
                      <th className="title-header rating-header">{t('msg-AllocationPercent')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="row-data data1"></td>
                      <td className="row-data data2">
                        <Selectbox
                          text="Fund Selection"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.productSelection}
                          // options={optionsRating}
                          name="cboSharedFundSelection1"
                          refItem={setElement}
                          propStyle={{
                            label: 'selectlabel',
                            control: 'selectcontrol',
                            selectcontainer: `selectcontainer followUpQuestion`,
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data3">
                        <Textbox
                          text="Interest Rate"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={
                            formData.productSelection['txtSharedFundSelectionInterestRate1']
                          }
                          name="txtSharedFundSelectionInterestRate1"
                          step="0.001"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: `selectcontainer`,
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data4">
                        <Textbox
                          text="Allocation Percent"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={
                            formData.productSelection['txtSharedFundSelectionAllocationPercent1']
                          }
                          name="txtSharedFundSelectionAllocationPercent1"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: `selectcontainer`,
                          }}
                          showIcon={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="row-data data1">
                        <Checkbox
                          text="Fund Selection"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.productSelection}
                          name="chkSharedFundSelection2"
                          refItem={setElement}
                          propStyle={{
                            label: 'checklabel',
                            control: 'checkcontrol',
                            checkcontainer: 'checkcontainer stemQuestion fullWidth',
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data2">
                        <Selectbox
                          text="Fund Selection"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.productSelection}
                          // options={optionsRating}
                          name="cboSharedFundSelection2"
                          refItem={setElement}
                          propStyle={{
                            label: 'selectlabel',
                            control: 'selectcontrol',
                            selectcontainer: `selectcontainer followUpQuestion`,
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data3">
                        <Textbox
                          text="Interest Rate"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={
                            formData.productSelection['txtSharedFundSelectionInterestRate2']
                          }
                          name="txtSharedFundSelectionInterestRate2"
                          step="0.001"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: `selectcontainer`,
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data4">
                        <Textbox
                          text="Allocation Percent"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={
                            formData.productSelection['txtSharedFundSelectionAllocationPercent2']
                          }
                          name="txtSharedFundSelectionAllocationPercent2"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: `selectcontainer`,
                          }}
                          showIcon={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="row-data data1">
                        <Checkbox
                          text="Fund Selection"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.productSelection}
                          name="chkSharedFundSelection3"
                          refItem={setElement}
                          propStyle={{
                            label: 'checklabel',
                            control: 'checkcontrol',
                            checkcontainer: 'checkcontainer stemQuestion fullWidth',
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data2">
                        <Selectbox
                          text="Fund Selection"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.productSelection}
                          // options={optionsRating}
                          name="cboSharedFundSelection3"
                          refItem={setElement}
                          propStyle={{
                            label: 'selectlabel',
                            control: 'selectcontrol',
                            selectcontainer: `selectcontainer followUpQuestion`,
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data3">
                        <Textbox
                          text="Interest Rate"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={
                            formData.productSelection['txtSharedFundSelectionInterestRate3']
                          }
                          name="txtSharedFundSelectionInterestRate3"
                          step="0.001"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: `selectcontainer`,
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data4">
                        <Textbox
                          text="Allocation Percent"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={
                            formData.productSelection['txtSharedFundSelectionAllocationPercent3']
                          }
                          name="txtSharedFundSelectionAllocationPercent3"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: `selectcontainer`,
                          }}
                          showIcon={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="row-data data1">
                        <Checkbox
                          text="Fund Selection"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.productSelection}
                          name="chkSharedFundSelection4"
                          refItem={setElement}
                          propStyle={{
                            label: 'checklabel',
                            control: 'checkcontrol',
                            checkcontainer: 'checkcontainer stemQuestion fullWidth',
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data2">
                        <Selectbox
                          text="Fund Selection"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={formData.productSelection}
                          // options={optionsRating}
                          name="cboSharedFundSelection4"
                          refItem={setElement}
                          propStyle={{
                            label: 'selectlabel',
                            control: 'selectcontrol',
                            selectcontainer: `selectcontainer followUpQuestion`,
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data3">
                        <Textbox
                          text="Interest Rate"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={
                            formData.productSelection['txtSharedFundSelectionInterestRate4']
                          }
                          name="txtSharedFundSelectionInterestRate4"
                          step="0.001"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: `selectcontainer`,
                          }}
                          showIcon={true}
                        />
                      </td>
                      <td className="row-data data4">
                        <Textbox
                          text="Allocation Percent"
                          getfocusCallback={controlGetFocus}
                          getchangeCallback={controlChange}
                          lostfocusCallback={controlLostFocus}
                          stateData={
                            formData.productSelection['txtSharedFundSelectionAllocationPercent4']
                          }
                          name="txtSharedFundSelectionAllocationPercent4"
                          refItem={setElement}
                          propStyle={{
                            label: 'textlabel',
                            control: 'textcontrol',
                            textcontainer: `selectcontainer`,
                          }}
                          showIcon={true}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </section>
          </main>
        </div>
      </FormValidator>
    </FormStatus>
  );
};

export default ProductSelectionModal;
