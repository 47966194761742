import React, {useState} from 'react';
import BasicInfoModal from './BasicInfoModal_';
import PremiumAllocationModal from './PremiumAllocationModal_';
import ReadonlyModal from './ReadonlyModal';
import BeneficiaryDesignationsModal from './BeneficiaryDesignationsModal_';
import ProductSelectionModal from './ProductSelectionModal_';

const ModalSelection = ({
  controlChange,
  controlClick,
  controlGetFocus,
  controlLostFocus,
  setElement,
  elementItems,
  controlItems,
  updateFormData,
  onLoad,
  formData,
  modalIndex,
  t,
}) => {
  const [isVisited, setIsVisited] = useState(false);

  if (modalIndex === 4) {
    return (
      <BasicInfoModal
        formData={formData}
        controlChange={controlChange}
        controlClick={controlClick}
        controlGetFocus={controlGetFocus}
        controlLostFocus={controlLostFocus}
        setElement={setElement}
        elementItems={elementItems}
        onLoad={onLoad}
        updateFormData={updateFormData}
        modalIndex={modalIndex}
        // imgArray={imgArray}
      />
    );
  }
  if (modalIndex === 9) {
    return (
      <BeneficiaryDesignationsModal
        formData={formData}
        controlChange={controlChange}
        controlGetFocus={controlGetFocus}
        controlLostFocus={controlLostFocus}
        setElement={setElement}
        elementItems={elementItems}
        onLoad={onLoad}
        updateFormData={updateFormData}
        modalIndex={modalIndex}
        // imgArray={imgArray}
        t={t}
      />
    );
  }
  if (modalIndex === 12) {
    return (
      <ProductSelectionModal
        formData={formData}
        controlChange={controlChange}
        controlClick={controlClick}
        controlGetFocus={controlGetFocus}
        controlLostFocus={controlLostFocus}
        setElement={setElement}
        elementItems={elementItems}
        onLoad={onLoad}
        updateFormData={updateFormData}
        modalIndex={modalIndex}
        // imgArray={imgArray}
        isVisited={isVisited}
        setIsVisited={setIsVisited}
        t={t}
      />
    );
  }
  if (modalIndex === 13) {
    return (
      <PremiumAllocationModal
        formData={formData}
        controlChange={controlChange}
        controlClick={controlClick}
        controlGetFocus={controlGetFocus}
        controlLostFocus={controlLostFocus}
        setElement={setElement}
        elementItems={elementItems}
        controlItems={controlItems}
        onLoad={onLoad}
        updateFormData={updateFormData}
        modalIndex={modalIndex}
        // imgArray={imgArray}
      />
    );
  }

  return <ReadonlyModal modalIndex={modalIndex} /*imgArray={imgArray}*/ />;
};

export default ModalSelection;
