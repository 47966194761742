import format from 'number-format.js';
import {INPUTTYPES} from 'core/constants/uiConstants';

export const getMaskForInput = (inputType) => {
  switch (inputType) {
    case INPUTTYPES.INPUT_PHONE:
      return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    case INPUTTYPES.INPUT_POSTALCODE:
      return [/[a-zA-Z]/, /[0-9]/, /[a-zA-Z]/, ' ', /[0-9]/, /[a-zA-Z]/, /[0-9]/];
    case INPUTTYPES.INPUT_ZIPCODE:
      return [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[1-9]/, /[1-9]/, /[1-9]/, /[1-9]/];
    case INPUTTYPES.INPUT_CREDITCARD:
      return;
    case INPUTTYPES.INPUT_SIN:
      return [
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ];
    case INPUTTYPES.INPUT_SSN:
      return [
        /[1-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        '-',
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
        /[1-9]/,
      ];
    case INPUTTYPES.INPUT_TEXT:
      return false;
    default:
      return false;
  }
};

export const decimalToComma = (value) => {
  let str = value.split('.').join(',');
  // console.log(str);
  return str;
};
export const commaToDecimal = (value) => {
  let str = value.split(',').join('.');
  console.log(str);
  return str;
};
const emailMask = (email) => {
  let maskedEmail = email.replace(/([^@\.])/g, '*').split('');
  let previous = '';
  for (let i = 0; i < maskedEmail.length; i++) {
    if (i <= 1 || previous === '.' || previous === '@') {
      maskedEmail[i] = email[i];
    }
    previous = email[i];
  }
  return maskedEmail.join('');
};

export const parseInput = (inputLanguage, oldVal, event, controlItem) => {
  let regex = /./;
  let strVal = event.target.value.slice();
  // console.log(event.target.value)
  if (event.target.value !== '') {
    if (event.target.value.length > controlItem.maxLength) {
      strVal = event.target.value.slice(0, controlItem.maxLength);
    }
    switch (controlItem.fieldType) {
      case INPUTTYPES.INPUT_TEXT:
        regex = /./;
        break;
      case INPUTTYPES.INPUT_UPPER:
        // regex = /^[A-Z0-9.' ]*$/;
        // strVal=strVal.toUpperCase();
        break;
      case INPUTTYPES.INPUT_EMAIL:
        // emailMask(strVal);
        // regex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        break;
      case INPUTTYPES.INPUT_POSTALCODE:
        let reg = '^';
        for (let i = 1; i <= strVal.length; i++) {
          if (i % 2 === 1) {
            reg = reg + '[a-zA-Z]';
          } else {
            reg = reg + '[0-9]';
          }
        }
        reg += '$';
        regex = new RegExp(reg);
        strVal = strVal.toUpperCase();
        break;
      case INPUTTYPES.INPUT_PHONE:
        let val = strVal.split('');
        let regexPhone = '^';
        if (strVal.length === 1 && strVal.split('')[0] !== '(') {
          strVal = '(' + strVal;
        }
        if (strVal.length === 5 && strVal.split('')[4] !== ')') {
          strVal = strVal.slice(0, strVal.length - 1) + ')' + strVal.slice(strVal.length - 1);
        }
        if (strVal.length === 6 && strVal.split('')[5] !== ' ') {
          strVal = strVal.slice(0, strVal.length - 1) + ' ' + strVal.slice(strVal.length - 1);
        }
        if (strVal.length === 10 && strVal.split('')[9] !== '-') {
          strVal = strVal.slice(0, strVal.length - 1) + '-' + strVal.slice(strVal.length - 1);
        }
        for (let i = 0; i < strVal.length; i++) {
          if (i === 0) {
            regexPhone += '[(]';
          } else if ((i > 0 && i < 4) || (i > 5 && i < 9) || (i > 9 && i < 14)) {
            regexPhone += '[0-9]';
          }
          if (i === 4) {
            regexPhone += '[)]';
          }
          if (i === 5) {
            regexPhone += '[ ]';
          }
          if (i === 9) {
            regexPhone += '[-]';
          }
        }
        regexPhone += '$';
        regex = new RegExp(regexPhone);
        break;
      case INPUTTYPES.INPUT_NAME:
        regex = /^[a-zA-Z0-9.' ]*$/;
        strVal = toTitleCase(strVal);
        break;
      case INPUTTYPES.INPUT_INTEGER:
      case INPUTTYPES.INPUT_DOLLAR_INTEGER:
      case INPUTTYPES.INPUT_NUMBER:
      case INPUTTYPES.INPUT_ZIPCODE:
      case INPUTTYPES.INPUT_SIN:
      case INPUTTYPES.INPUT_SSN:
        regex = /^\d*$/;
        break;
      //   strVal = removeDecimal(strVal);
      //   returnVal = parseInt(strVal);
      case INPUTTYPES.INPUT_REAL:
      case INPUTTYPES.INPUT_DOLLAR:
      case INPUTTYPES.INPUT_PERCENT:
        if (inputLanguage === 'English') {
          regex = new RegExp(`^\\d+\\.{0,1}(\\d{0,${controlItem.decimalPlaces}})$`);
        } else {
          regex = new RegExp(`^\\d+\\,{0,1}(\\d{0,${controlItem.decimalPlaces}})$`);
        }

        break;
      default:
        break;
    }
    if (regex.test(strVal)) {
      return strVal;
    } else {
      return oldVal || '';
    }
  }
  return strVal;
};

const decimalHashes = (decimalPlaces, isForced = false) => {
  // isForced means - all decimal places must be used. Reference: http://mottie.github.io/javascript-number-formatter/
  let str = '';
  if (isForced === true) {
    for (let i = 0; i < decimalPlaces - 1; i++) {
      str += '#';
    }
    str += '0'; //forcing display till last place
  } else {
    for (let i = 0; i < decimalPlaces; i++) {
      str += '#';
    }
  }
  return str;
};
export const formatDisplay = (inputLanguage, value, fieldType, decimalPlaces = 0) => {
  let decimals;
  switch (fieldType) {
    case INPUTTYPES.INPUT_UPPER:
    case INPUTTYPES.INPUT_NUMBER:
      break;
    case INPUTTYPES.INPUT_PHONE:
      // let formattedPhone = "";
      // let tempPhone = value.split("");
      // for(let i =0; i< tempPhone.length; i++){
      //   if(i===0) formattedPhone += "(";
      //   formattedPhone += tempPhone[i];
      //   if(i===2) formattedPhone += ") ";
      //   if(i===5) formattedPhone += "-"
      // }
      // return formattedPhone;
      break;
    case INPUTTYPES.INPUT_POSTALCODE: //CA
      let formattedStr = '';
      let tempPostalCode = value.split('');
      for (let i = 0; i < tempPostalCode.length; i++) {
        formattedStr += tempPostalCode[i];
        if (i === 2) {
          formattedStr += ' ';
        }
      }
      return formattedStr;
    case INPUTTYPES.INPUT_ZIPCODE: //US
      let formattedCode = '';
      let tempCode = value.split('');
      for (let i = 0; i < tempCode.length; i++) {
        formattedCode += tempCode[i];
        if (i === 4) {
          formattedCode += '-';
        }
      }
      return formattedCode;
    case INPUTTYPES.INPUT_SIN:
      let sin = '';
      let tempSIN = value.split('');
      for (let i = 0; i < tempSIN.length; i++) {
        sin += tempSIN[i];
        if (i === 2 || i === 5) {
          sin += ' ';
        }
      }
      return sin;

    case INPUTTYPES.INPUT_SSN:
      return;
    case INPUTTYPES.INPUT_INTEGER:
      if (inputLanguage === 'English') {
        return format('#,##0.', value);
      } else {
        return format('# ##0.', value);
      }

    case INPUTTYPES.INPUT_REAL:
      if (inputLanguage === 'English') {
        decimals = decimalHashes(decimalPlaces, true);
        return format(`#,##0.${decimals}`, value);
      } else {
        //French
        decimals = decimalHashes(decimalPlaces, true);
        return format(`# ##0,${decimals}`, value);
      }
    case INPUTTYPES.INPUT_PERCENT:
      if (inputLanguage === 'English') {
        decimals = decimalHashes(decimalPlaces, true);
        return format(`#0.${decimals} %`, value);
      } else {
        decimals = decimalHashes(decimalPlaces, true);
        return format(`#0,${decimals} %`, value);
      }
    case INPUTTYPES.INPUT_DOLLAR:
      if (inputLanguage === 'English') {
        return format('$#,##0.00', value);
      } else {
        return format('$# ##0,00', value);
      }
    case INPUTTYPES.INPUT_DOLLAR_INTEGER:
      if (inputLanguage === 'English') {
        return format('$#,##0.', value);
      } else {
        return format('$# ##0.', value);
      }
    default:
      break;
  }
  return value;
};

const removeDecimal = (val) => {
  let ret = '';
  for (let i = 0; i < val.length; i++) {
    if (val[i] !== '.') {
      ret += val[i];
    }
  }
  return ret;
};

export const camelCase = (str) => {
  return str
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function (b) {
      return b.toLowerCase();
    });
};

const capitalizeApostrophe = (str) => {
  let retStr = str.split('');

  for (let i = 0; i < retStr.length; i++) {
    if (retStr[i] === "'" && i + 1 < retStr.length) {
      retStr[i + 1] = retStr[i + 1].toUpperCase();
    }
  }
  return retStr.join('');
};

export const toTitleCase = (str) => {
  let str1 = str.replace(/\w\S*/g, function (txt) {
    // return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
  return capitalizeApostrophe(str1);
};

export const onFocusInputPattern = (controlItem) => {
  switch (controlItem['fieldType']) {
    case INPUTTYPES.INPUT_UPPER:
      break;
    case INPUTTYPES.INPUT_REAL:
      return '/^([0-9]+(.[0-9]+)?)/';
    case INPUTTYPES.INPUT_INTEGER:
      return '[0-9]';
    case INPUTTYPES.INPUT_PERCENT:
      break;
    case INPUTTYPES.INPUT_DOLLAR:
      break;
    default:
      break;
  }
};
