import React, {Fragment, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {STYLES} from 'core/constants/uiConstants';
import {TooltipIcon, TooltipText} from '../uicore';
import {usePCTranslation} from 'core/hooks';
//ToDo: Problem with this implemenation is that if the name of the group of checkboxes is defaulted to a string, it does not automatically populate the control

const MultiCheckbox = ({name, text, stateData, options, refItem, autoFocus, getfocusCallback, lostfocusCallback, getchangeCallback, propStyle}) => {
  const [values, setValues] = useState([]);
  //Initialize the multi check values from StateData in case of refresh page.
  useEffect(() => {
    if (stateData && stateData[name]) {
      setValues(stateData[name].split('|'));
    }
  }, [stateData && stateData[name], name]);
  const {t} = usePCTranslation();
  const {controlItem} = useSelector((state) => ({
    controlItem: state.setupItems.controlItems ? state.setupItems.controlItems[name] : null,
  }));

  let optionItems = {};
  if (controlItem && controlItem.useOptions) {
    optionItems = controlItem.optionItems;
  } else {
    optionItems = options;
  }
  const controlOnChange = (event) => {
    event.persist();
    // let valArray = [];
    // valArray = stateData[name].split("|");
    let tempVal = [...values];
    if (!tempVal.includes(event.target.value)) {
      tempVal.push(event.target.value);
    } else {
      for (let i = 0; i < tempVal.length; i++) {
        if (tempVal[i] === event.target.value) {
          tempVal.splice(i, 1);
          break;
        }
      }
    }
    setValues(tempVal);
    // console.log(values);
    getchangeCallback(event, event.target.name, tempVal.join('|'));
    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();

    // this.setState(
    //   state => {
    //     if (event.target.checked) {
    //       state[this.props.name].push(event.target.value);
    //     } else {
    //       let index = state[this.props.name].indexOf(event.target.value);
    //       state[this.props.name].splice(index, 1);
    //     }
    //   },
    //   () => {
    //     this.props.getchangeCallback(event, this.state, this.props.name);
    //   }
    // );
    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    //event.stopPropagation();
  };

  const controlOnFocus = (event) => {
    getfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnLostFocus = (event) => {
    lostfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const checkLabel = () => {
    return (
      <label className={`${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`} id={name}>
        {t(`${name}-caption`)}
      </label>
    );
  };

  return (
    <div id={name} className={`${STYLES.INPUTCONTAINER} ${propStyle.multicheckcontainer}`}>
      {!controlItem.showTooltip ? (
        checkLabel()
      ) : controlItem.showTooltipIcon ? (
        <Fragment>
          {checkLabel()}
          <div className={`${STYLES.TOOLTIP}`}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
        </Fragment>
      ) : (
        <div className={`${STYLES.TOOLTIP}`}>
          <TooltipText name={name} />
          {checkLabel()}
        </div>
      )}
      <div className={`checkGroup ${propStyle.checkGroup}`}>
        {Object.keys(optionItems).map((key) => (
          <div key={key} className={propStyle.child}>
            <input
              className={`${STYLES.BASIC} ${propStyle.control}`}
              type='checkbox'
              name={name}
              checked={values.includes(key) ? true : false}
              value={key}
              onBlur={controlOnLostFocus}
              onFocus={controlOnFocus}
              onChange={controlOnChange}
              autoFocus={autoFocus}
              ref={refItem}
            />
            <label className='form-check-label'>{t(optionItems[key])}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

MultiCheckbox.defaultProps = {
  autoFocus: false,
};
export {MultiCheckbox};
