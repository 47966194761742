export const NEW_BUSINESS = 'illustration';
export const INFORCE = 'inforce';
export const SHARED_OWNERSHIP = 'sharedOwnership';
export const EAPPLICATION = 'eapplication';
export const EAPP_SETTINGS = 'eapp-settings';
export const SWITCH_PRODUCTS = 'switchProducts';
export const PROFILE = 'profile';
export const ABOUT = 'about';
export const ADMIN = 'admin';
export const BI = 'bi';
export const NONE = '';

export const routes = {
  admin: [
    {
      routeId: 'admin01',
      appName: 'Admin',
      subModule: 'dashboard',
      path: '/admin/dashboard',
      displayName: 'titleAdmin',
      button: 'btnDashboard',
      isActive: false,
    },
    {
      routeId: 'admin02',
      appName: 'Admin',
      subModule: 'users',
      path: '/admin/users',
      displayName: 'titleAdmin',
      button: 'btnUsers',
      isActive: false,
    },
    {
      routeId: 'admin03',
      appName: 'Admin',
      subModule: 'bi',
      path: '/admin/bi',
      displayName: 'titleAdmin',
      button: 'btnTracking',
      isActive: false,
    },
  ],
  illustration: [
    {
      routeId: 'illus01',
      appName: 'NewBusiness',
      subModule: 'illustration',
      path: '/illustration',
      button: 'btnCreateIllustration',
      displayName: 'titleNewBusiness',
      isActive: true,
    },
    {
      routeId: 'illus02',
      appName: 'NewBusiness',
      subModule: 'savedIllustration',
      path: '/illustration/savedIllustration',
      button: 'btnSavedIllustration',
      displayName: 'titleNewBusiness',
      isActive: false,
    },
  ],
  inforce: [
    {
      routeId: 'inforce01',
      appName: 'Inforce',
      subModule: 'policyLookup',
      path: '/inforce/policyLookup',
      button: 'btnPolicyLookup',
      displayName: 'titleInforce',
      isActive: true,
    },
    {
      routeId: 'inforce02',
      appName: 'Inforce',
      subModule: 'alphalookup',
      path: '/inforce/alphalookup',
      button: 'btnAlphaLookup',
      displayName: 'titleInforce',
      isActive: false,
    },
    {
      routeId: 'inforce03',
      appName: 'Inforce',
      subModule: 'dataReports',
      path: '/inforce/dataReports',
      displayName: 'titleInforce',
      button: 'btnDataReports',
      isActive: false,
    },
  ],
  eapplication: [
    {
      routeId: 'eapp01',
      appName: 'EApplication',
      subModule: 'eApplication',
      path: '/eapplication',
      displayName: 'titleEApplication',
      button: 'btnEApplication',
      isActive: false,
    },
  ],
  proneeds: [
    {
      routeId: 'proneeds01',
      appName: 'ProNeeds',
      subModule: 'proneeds',
      path: '/proneeds',
      displayName: 'titleProNeeds',
      button: 'btnProneeds',
      isActive: false,
    },
  ],
};
