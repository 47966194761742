export const INPUTTYPES = {
  INPUT_TEXT: 'input_Text',
  INPUT_UPPER: 'input_Upper',
  INPUT_NAME: 'input_Name',
  INPUT_DOLLAR: 'input_Dollar', //Includes floating point dollar
  INPUT_NUMBER: 'input_Number', // Inclues floating point numers
  INPUT_DOLLAR_INTEGER: 'input_Dollar_Integer', // Only integer dollar
  INPUT_INTEGER: 'input_Integer', //Only integers
  INPUT_BUTTON: 'input_Button',
  INPUT_COMBO: 'input_Combo',
  INPUT_CHECK: 'input_Check',
  INPUT_RADIO: 'input_Radio',
  INPUT_REAL: 'input_Real',
  INPUT_DATE: 'input_Date',
  INPUT_MULTICHECK: 'input_MultiCheck',
  INPUT_MULTISELECT: 'input_MultiSelect',
  INPUT_PERCENT: 'input_Percent',
  INPUT_PHONE: 'input_Phone',
  INPUT_LABEL: 'input_Label',
  INPUT_TAB: 'input_Tab',
  INPUT_PAGE: 'input_Page',
  INPUT_EMAIL: 'input_Email',
  INPUT_POSTALCODE: 'input_PostalCode',
  INPUT_ZIPCODE: 'input_ZipCode',
  INPUT_SIN: 'input_SIN',
  INPUT_SSN: 'input_SSN',
  INPUT_CREDITCARD: 'input_CreditCard',
  INPUT_LONGINT: 'input_LongInt',
  INPUT_OPTION: 'input_Option',
  INPUT_DEV: 'input_Dev',
  INPUT_ARRAY: 'input_Array',
};

export const ICONS = {
  BUBBLE:
    'M512 64c282.77 0 512 186.25 512 416 0 229.752-229.23 416-512 416-27.156 0-53.81-1.734-79.824-5.044-109.978 109.978-241.25 129.7-368.176 132.596v-26.916c68.536-33.578 128-94.74 128-164.636 0-9.754-0.758-19.33-2.164-28.696-115.796-76.264-189.836-192.754-189.836-323.304 0-229.75 229.23-416 512-416z',
  ENLARGE2:
    'M1024 0v416l-160-160-192 192-96-96 192-192-160-160zM448 672l-192 192 160 160h-416v-416l160 160 192-192z',
  SHRINK2:
    'M448 576v416l-160-160-192 192-96-96 192-192-160-160zM1024 96l-192 192 160 160h-416v-416l160 160 192-192z',
  BIN2:
    'M192 1024h640l64-704h-768zM640 128v-128h-256v128h-320v192l64-64h768l64 64v-192h-320zM576 128h-128v-64h128v64z',
  CLOUD_CHECK:
    'M892.268 514.49c2.442-11.108 3.732-22.646 3.732-34.49 0-88.366-71.634-160-160-160-14.224 0-28.014 1.868-41.134 5.352-24.796-77.352-97.288-133.352-182.866-133.352-87.348 0-161.054 58.336-184.326 138.17-22.742-6.62-46.792-10.17-71.674-10.17-141.384 0-256 114.616-256 256 0 141.382 114.616 256 256 256h608c88.366 0 160-71.632 160-160 0-78.718-56.854-144.16-131.732-157.51zM416 768l-160-160 64-64 96 96 224-224 64 64-288 288z',
  BOLD:
    'M707.88 484.652c37.498-44.542 60.12-102.008 60.12-164.652 0-141.16-114.842-256-256-256h-320v896h384c141.158 0 256-114.842 256-256 0-92.956-49.798-174.496-124.12-219.348zM384 192h101.5c55.968 0 101.5 57.42 101.5 128s-45.532 128-101.5 128h-101.5v-256zM543 832h-159v-256h159c58.45 0 106 57.42 106 128s-47.55 128-106 128z',
  UNDERLINE:
    'M704 64h128v416c0 159.058-143.268 288-320 288-176.73 0-320-128.942-320-288v-416h128v416c0 40.166 18.238 78.704 51.354 108.506 36.896 33.204 86.846 51.494 140.646 51.494s103.75-18.29 140.646-51.494c33.116-29.802 51.354-68.34 51.354-108.506v-416zM192 832h640v128h-640z',
  ITALIC: 'M896 64v64h-128l-320 768h128v64h-448v-64h128l320-768h-128v-64z',
  PARAGRAPH_LEFT:
    'M0 64h1024v128h-1024zM0 256h640v128h-640zM0 640h640v128h-640zM0 448h1024v128h-1024zM0 832h1024v128h-1024z',
  PARAGRAPH_CENTER:
    'M0 64h1024v128h-1024zM192 256h640v128h-640zM192 640h640v128h-640zM0 448h1024v128h-1024zM0 832h1024v128h-1024z',
  PARAGRAPH_RIGHT:
    'M0 64h1024v128h-1024zM384 256h640v128h-640zM384 640h640v128h-640zM0 448h1024v128h-1024zM0 832h1024v128h-1024z',
  GOOGLE:
    'M522.2 438.8v175.6h290.4c-11.8 75.4-87.8 220.8-290.4 220.8-174.8 0-317.4-144.8-317.4-323.2s142.6-323.2 317.4-323.2c99.4 0 166 42.4 204 79l139-133.8c-89.2-83.6-204.8-134-343-134-283 0-512 229-512 512s229 512 512 512c295.4 0 491.6-207.8 491.6-500.2 0-33.6-3.6-59.2-8-84.8l-483.6-0.2z',
  FACEBOOK:
    'M608 192h160v-192h-160c-123.514 0-224 100.486-224 224v96h-128v192h128v512h192v-512h160l32-192h-192v-96c0-17.346 14.654-32 32-32z',
  TWITTER:
    'M1024 226.4c-37.6 16.8-78.2 28-120.6 33 43.4-26 76.6-67.2 92.4-116.2-40.6 24-85.6 41.6-133.4 51-38.4-40.8-93-66.2-153.4-66.2-116 0-210 94-210 210 0 16.4 1.8 32.4 5.4 47.8-174.6-8.8-329.4-92.4-433-219.6-18 31-28.4 67.2-28.4 105.6 0 72.8 37 137.2 93.4 174.8-34.4-1-66.8-10.6-95.2-26.2 0 0.8 0 1.8 0 2.6 0 101.8 72.4 186.8 168.6 206-17.6 4.8-36.2 7.4-55.4 7.4-13.6 0-26.6-1.4-39.6-3.8 26.8 83.4 104.4 144.2 196.2 146-72 56.4-162.4 90-261 90-17 0-33.6-1-50.2-3 93.2 59.8 203.6 94.4 322.2 94.4 386.4 0 597.8-320.2 597.8-597.8 0-9.2-0.2-18.2-0.6-27.2 41-29.4 76.6-66.4 104.8-108.6z',
  LINKEDIN2:
    'M384 384h177.106v90.782h2.532c24.64-44.194 84.958-90.782 174.842-90.782 186.946 0 221.52 116.376 221.52 267.734v308.266h-184.61v-273.278c0-65.184-1.334-149.026-96.028-149.026-96.148 0-110.82 70.986-110.82 144.292v278.012h-184.542v-576z M64 384h192v576h-192v-576z M256 224c0 53.019-42.981 96-96 96s-96-42.981-96-96c0-53.019 42.981-96 96-96s96 42.981 96 96z',
  ERROR:
    'M512 92.774l429.102 855.226h-858.206l429.104-855.226zM512 0c-22.070 0-44.14 14.882-60.884 44.648l-437.074 871.112c-33.486 59.532-5 108.24 63.304 108.24h869.308c68.3 0 96.792-48.708 63.3-108.24h0.002l-437.074-871.112c-16.742-29.766-38.812-44.648-60.882-44.648v0z M576 832c0 35.346-28.654 64-64 64s-64-28.654-64-64c0-35.346 28.654-64 64-64s64 28.654 64 64z M512 704c-35.346 0-64-28.654-64-64v-192c0-35.346 28.654-64 64-64s64 28.654 64 64v192c0 35.346-28.654 64-64 64z',
  DASH:
    'M0 416v192c0 17.672 14.328 32 32 32h960c17.672 0 32-14.328 32-32v-192c0-17.672-14.328-32-32-32h-960c-17.672 0-32 14.328-32 32z',
  BULLET:
    'M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 640c-70.692 0-128-57.306-128-128 0-70.692 57.308-128 128-128 70.694 0 128 57.308 128 128 0 70.694-57.306 128-128 128z',
};
export const STYLES = {
  BASIC: '',
  LOGO: 'LOGO flexContainer alignCenter bgPrimaryShade',
  SELECT: 'SELECT borderThin borderNeutral borderRadiusAll',
  TEXT: 'TEXT borderThin borderNeutral borderRadiusAll marginMed',
  CHECK: 'CHECK opacityNone',
  DATE: 'DATE',
  TOOLTIP: 'tooltip',
  TOOLTIPTEXT: 'tooltiptext bgDark fontSm borderRadiusAll paddingMed opacityNone',
  TOOLTIPICON: 'tooltip-icon',
  BUTTON: 'BUTTON paddingMed marginSm borderNone',
  MULTISELECT: 'MULTISELECT',
  MULTICHECK: 'MULTICHECK opacityNone',
  RADIO: 'RADIO opacityNone',
  PILL: 'PILL opacityNone',
  LABEL: 'LABEL',
  CHILD: 'CHILD',
  LABELCOMPONENT: 'LABELCOMPONENT',
  INPUTBOX: 'INPUTBOX',
  INPUT: 'INPUT TEXT borderThin borderNeutral borderRadiusAll',
  INPUTSUBMIT: 'INPUTSUBMIT flexContainer',
  PRODUCT: 'PRODUCT',
  INFORCEPRODUCT: 'INFORCEPRODUCT',
  INFORCEPOLICYHEADER: 'INFORCEPOLICYHEADER alignStretch',
  ALPHALOOKUPHEADER: 'ALPHALOOKUPHEADER alignCenter',
  BUTTONS: 'BUTTONS',
  TABS: 'TABS',
  TABLIST: 'TABLIST flexContainer paddingNone noCols',
  TABLISTITEM: 'TABLISTITEM fontPrimaryColor paddingMedWide borderRadiusTop cursorPointer',
  TABLISTACTIVE: 'TABLISTACTIVE bgPrimaryColor',
  TABCONTENTACTIVE: 'TABCONTENTACTIVE',
  TABCONTENT: 'TABCONTENT',
  CARD: 'CARD',
  GROUP: 'GROUP',
  TABLE: 'TABLE',
  THEAD: 'THEAD',
  TROW: 'TROW',
  TDATA: 'TDATA',
  TBODY: 'TBODY',
  SUMMARYFIELDSET: 'SUMMARYFIELDSET',
  SUMMARY: 'SUMMARY borderThin borderNeutral borderBottom flexContainer justifyBetween',
  SUMMARYCONTAINER: 'SUMMARYCONTAINER',
  HEADER: 'HEADER fontSubHeader fontHdr3',
  PAGEHEADER: 'PAGEHEADER bgPrimaryShade justifyBetween flexContainer alignStretch',
  PAGEHEADERM: 'PAGEHEADERM bgPrimaryShade justifyBetween flexContainer alignStretch',
  BUTTONBAR: 'BUTTONBAR',
  POLICYHEADER: 'POLICYHEADER bgWhite',
  PREMIUMDISPLAY: 'PREMIUMDISPLAY',
  PROFILEBUTTONS: 'PROFILEBUTTONS',
  PAGEFOOTER: 'PAGEFOOTER',
  VALIDATION: 'VALIDATION',
  ERRORICON: 'ERRORICON errorIcon',
  INPUTCONTAINER: 'INPUTCONTAINER marginSm',
  RADIOCONTAINER: 'RADIOCONTAINER',
  PILLCONTAINER: 'PILLCONTAINER',
  TABLAYOUT: 'TABLAYOUT paddingLg borderThin borderColor',
  TABCONTAINER: 'TABCONTAINER',
  LAYOUT: 'LAYOUT flexContainer',
  SIDEBARCONTAINER: 'SIDEBARCONTAINER bgNeutral',
  FIELDCONTAINER: 'FIELDCONTAINER borderThin borderNeutral borderBottom',
  FIELDCONTAINERHEADER: 'FIELDCONTAINERHEADER fontHdr2 flexContainer alignCenter marginNone',
  FIELDCONTENT: 'FIELDCONTENT toggleableField',
  SUBFIELDSET: 'SUBFIELDSET sub-fieldset fullWidth flexContainer',
  STATUSDISPLAY: 'STATUSDISPLAY',
  COVERAGETYPE: 'COVERAGETYPE',
  COVERAGEVALUE: 'COVERAGEVALUE',

  INFORCEPOLICYSUMMARY: 'INFORCEPOLICYSUMMARY',
  PANELITEMGROUP: 'PANELITEMGROUP paddingLgWide',
};
