import {ActionTypes} from 'custom/common/redux/Types';
import axios from 'axios';
import {setAlert} from 'core/redux/actions';
//Api for only Admin user
export const getAllRoles = (isUpdating = false) => async (dispatch) => {
  try {
    const {data} = await axios.get('/users/GetAllRoles');
    dispatch({type: ActionTypes.ALL_ROLES, payload: data});
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }
};

export const getRoleByName = (roleName) => async (dispatch) => {
  try {
    const {data} = await axios.get(`/users/GetRoleByName/${roleName}`);
    dispatch({type: ActionTypes.GET_ROLE_BY_NAME, payload: data});
  } catch (error) {
    console.log(error);
  }
};
