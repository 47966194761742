import {
  GetColumnNumber,
  getCoverageByID,
  getCoverageItemByType
} from "core/utils/calcUtils";
import {
  RiderTypes,
  ReportColumns,
  PolicyTypes,
  CoverageTypes,
  FundTypes
} from "core/constants/calcConstants";
import { isPolicyPaidUp } from "custom/common/utils/lookupFunctions";
import { globalVariables, isEmpty } from "core/utils/util";
import { ScalarTypes } from "core/constants/reportConstants";
import { addScalarItem, getScalarItem } from "core/report/reportUtils";

export function GetPhoneItem(phoneItems, phoneType){
    for (let aPhoneItem of phoneItems) {
        if (aPhoneItem.phoneType === phoneType) {
            return aPhoneItem;
        }
    }
    return null;
}

export function GetAddressItem(addressItems, addressType) {
    for (let aAddressItem of addressItems) {
        if (aAddressItem.addressType === addressType) {
            return aAddressItem;
        }
    }
    return null;
}


export function GetSkipPaymentYear(skipPUAPaymentName, insuranceData) {
  let intSkipPUAPayment = GetColumnNumber(
    skipPUAPaymentName,
    insuranceData.columnItems
  );
  let i;
  for (i = 1; i === insuranceData.dblTableData_Main.length; i++) {
    if (insuranceData.dblTableData_Main[i][intSkipPUAPayment] === 1) {
      return i;
    }
  }
  return -1;
}

//Custom - inforce
export function BuildSummaryColumnText(
  coverageItem,
  inforceData,
  insuranceData
) {
  //We need to get the calc CoverageItem becuase the calc engine calculated the PremiumDurationYear which we need for this
  //For non-benefits we don't need to get the parent since they are unique coverageIDs per coverage.  Benefits however
  //can have multiples per policy (ie WaiverOfPremium 035 can be on the base plus riders)
  let ignoreParentID = coverageItem.coverageType !== CoverageTypes.BENEFIT;
  let aCoverageItem = getCoverageByID(
    insuranceData.policy.coverages,
    coverageItem.coverageID,
    coverageItem.parentCoverageID,
    ignoreParentID
  );
  if (coverageItem.riderType === RiderTypes.CHILD) {
    return (
      ("Year " +
        (
          insuranceData.dblTableData_Main[1][ReportColumns.CalendarYear] +
          Math.min(
            aCoverageItem.premiumDurationYear,
            aCoverageItem.expiryDuration
          ) -
          1
        ).toString() +
        ": " +
        coverageItem.name +
        " last premium (age ") +
      ((
        Math.min(
          aCoverageItem.premiumDurationYear,
          aCoverageItem.expiryDuration
        ) +
            inforceData.policy.baseCoverage.insured.attainedAge -
        1
      ).toString() +
        ").")
    );
  } else {
    //if this is an off-anniversary rider then add a year to the display
    let coverageIssueDate = new Date(aCoverageItem.issueDate);
    let policyIssueDate = new Date(inforceData.policy.issueDate);
    let intAddYear = 0;
    let strReturn = "";

    if (coverageIssueDate.getMonth !== policyIssueDate.getMonth) {
      intAddYear = 1;
    }

    if (inforceData.inforcePolicy.policyType === PolicyTypes.UL) {
      if (coverageItem.coverageType !== CoverageTypes.BASE) {
          intAddYear = intAddYear + 1;
          //console.log(`insuranceData.dblTableData_Main.length = ${insuranceData.dblTableData_Main.length}`, insuranceData.dblTableData_Main);
          //We insuranceData.dblTableData_Main.length is 0 even if the policy lapses in year 1 (because index 0 counts)
        if (insuranceData.dblTableData_Main.length > 1) {
          strReturn =
            "Year " +
            (
              insuranceData.dblTableData_Main[1][ReportColumns.CalendarYear] +
              aCoverageItem.premiumDurationYear -
              1 +
              intAddYear
            ).toString() +
            ": " +
            coverageItem.name +
            " Cost of Insurance ceases ";
        } else {
          strReturn = "";
        }
      }
    } else {
      if (isPolicyPaidUp(inforceData.inforcePolicy)) {
          strReturn = "";
      } else {
          let intTemp = insuranceData.dblTableData_Main[1][ReportColumns.CalendarYear] + aCoverageItem.premiumDurationYear - 1 + intAddYear;
          let strTemp = intTemp.toString();
        strReturn =
          ("Year " +
            (
              insuranceData.dblTableData_Main[1][ReportColumns.CalendarYear] +
              aCoverageItem.premiumDurationYear -
              1 +
              intAddYear
            ).toString() +
            ": ") +
          coverageItem.name +
          " last premium ";
      }
    }

    let aBaseCoverageItem = getCoverageItemByType(
      inforceData.inforcePolicy.coverages,
      CoverageTypes.BASE
    );
    if (!isPolicyPaidUp(inforceData.inforcePolicy)) {
      if (
        (coverageItem.insured.clientID === aBaseCoverageItem.insured.clientID)
      ) {
        if (
          !(
            inforceData.inforcePolicy.policyType === PolicyTypes.UL &&
            coverageItem.coverageType === CoverageTypes.BASE
          )
        ) {
          strReturn =
            (strReturn + "(age ") +
            ((
              aCoverageItem.premiumDurationYear +
              coverageItem.insured.currentAttainedAge -
              1 +
              intAddYear
            ).toString() +
              ").");
        }
      }
    }
    return strReturn;
  }
}

//Custom - inforce
export function SetSelectedDurations(insuranceData, formData) {
  let FilterCol = insuranceData.columnItems[3].columnNumber;
  if (formData["chkSelectDuration"]) {
    //if (formData['rdoDuration'] === 'SEL') {
    let rowCount = insuranceData.dblTableData_Main.length - 1;
    //ToDo: These are hard-coded for now.  This algorithm is intended to optimize to a one-pager
    let firstYears = 10;
    let everyYears = 5;
    var i;
    for (i = 1; i <= rowCount; i++) {
      if (i <= firstYears) {
        insuranceData.dblTableData_Main[i][FilterCol] = 1;
      } else {
        if ((i - firstYears) % everyYears === 0 || i === rowCount) {
          insuranceData.dblTableData_Main[i][FilterCol] = 1;
        } else {
          insuranceData.dblTableData_Main[i][FilterCol] = 0;
        }
      }
    }
  }
}

//Custom - inforce
export function BuildSummaryPolicyExpiryColumnText(
  insuranceData,
  coverageItem
) {
  return (
    "Year " +
    (
      insuranceData.dblTableData_Main[1][ReportColumns.CalendarYear] +
      coverageItem.expiryDuration
    ).toString() +
    ": Policy Expires."
  );
}

export function BuildInvestmentAllocationScalars(
    scalarReportArray,
    calcPolicy,
    inforceFormData,
    insuranceData
) {
    //console.log('BuildInvestmentAllocationScalars.insuranceData = ', insuranceData);
    //console.log('BuildInvestmentAllocationScalars.calcPolicy.baseCoverage.funds = ', calcPolicy.baseCoverage.funds);
    
    //This sub is going to perform a loop to gather.
    //One entry for each Seg Fund
    //A single entry to sum up all DIA/GIA
    //It will also fill in the Total Line
    let dblDIAGIATotal = 0;
    let dblDIAGIARate = 0;
    let intCounter = 0;
    let fundName;
    let aGlobalVariable;
    let globalVariablesInsuranceData
    globalVariablesInsuranceData = insuranceData.globalVariables;

    //console.log('BuildInvestmentAllocationScalars.globalVariablesData = ', globalVariablesInsuranceData);
    //console.log('BuildInvestmentAllocationScalars.globalVariable = ', globalVariables);

    
    for (let aFundItem of calcPolicy.baseCoverage.funds) {
        if (aFundItem.fundType === FundTypes.SegFund) {
            intCounter++;
            scalarReportArray.push(
                addScalarItem(
                    "AllocFundName" + intCounter.toString(),
                    aFundItem.fundName,
                    ScalarTypes.SCALAR_STRING
                )
            );
            scalarReportArray.push(
                addScalarItem(
                    "AllocValue" + intCounter.toString(),
                    aFundItem.chargeAllocation.allocationPercent,
                    ScalarTypes.SCALAR_DOUBLE
                )
            );
            fundName = "InterestRate_" + aFundItem.fundID;
            fundName = fundName.toUpperCase();
            aGlobalVariable = getGlobalVariablesInsuranceDataItem(globalVariablesInsuranceData, fundName);
            if (aGlobalVariable) {
                scalarReportArray.push(
                    addScalarItem(
                        "AllocInitialRate" + intCounter.toString(),
                        aGlobalVariable.value * 100,
                        ScalarTypes.SCALAR_DOUBLE
                    )
                );

                scalarReportArray.push(
                    addScalarItem(
                        "AllocRateYears" + intCounter.toString(),
                        "LIFE",
                        ScalarTypes.SCALAR_STRING
                    )
                );
                scalarReportArray.push(
                    addScalarItem(
                        "AllocSubsequentRate" + intCounter.toString(),
                        aGlobalVariable.value * 100,
                        ScalarTypes.SCALAR_DOUBLE
                    )
                );
                scalarReportArray.push(
                    addScalarItem(
                        "AllocScenarioRate" + intCounter.toString(),
                        aGlobalVariable.value * 100,
                        ScalarTypes.SCALAR_DOUBLE
                    )
                );
            }
        } else if (
            (aFundItem.fundType === FundTypes.DIA ||
                aFundItem.fundType === FundTypes.GIA) &&
            !aFundItem.isExternalFund
        ) {
            dblDIAGIATotal =
                dblDIAGIATotal + aFundItem.chargeAllocation.allocationPercent;
            fundName = "InterestRate_" + aFundItem.fundID;
            fundName = fundName.toUpperCase();
            aGlobalVariable = getGlobalVariablesInsuranceDataItem(globalVariablesInsuranceData, fundName);
            if (aGlobalVariable) {
                dblDIAGIARate = aGlobalVariable.value * 100;
            }
        }
    }

    if (dblDIAGIATotal > 0) {
        intCounter++;
        scalarReportArray.push(
            addScalarItem(
                "AllocFundName" + intCounter.toString(),
                "Daily Int. Acct/Guar. Int. Acct.",
                ScalarTypes.SCALAR_STRING
            )
        );
        scalarReportArray.push(
            addScalarItem(
                "AllocValue" + intCounter.toString(),
                dblDIAGIATotal,
                ScalarTypes.SCALAR_DOUBLE
            )
        );
        scalarReportArray.push(
            addScalarItem(
                "AllocInitialRate" + intCounter.toString(),
                dblDIAGIARate,
                ScalarTypes.SCALAR_DOUBLE
            )
        );
        scalarReportArray.push(
            addScalarItem(
                "AllocRateYears" + intCounter.toString(),
                "LIFE",
                ScalarTypes.SCALAR_STRING
            )
        );
        scalarReportArray.push(
            addScalarItem(
                "AllocSubsequentRate" + intCounter.toString(),
                dblDIAGIARate,
                ScalarTypes.SCALAR_DOUBLE
            )
        );
        scalarReportArray.push(
            addScalarItem(
                "AllocScenarioRate" + intCounter.toString(),
                dblDIAGIARate,
                ScalarTypes.SCALAR_DOUBLE
            )
        );
    }

    let dblAverageInterestRate;
    aGlobalVariable = getGlobalVariablesInsuranceDataItem(globalVariablesInsuranceData, "AVERAGEINTERESTRATE");
    if (aGlobalVariable) {
        dblAverageInterestRate = aGlobalVariable.value;
    }else {
        dblAverageInterestRate = 0;
    }

    if ((inforceFormData["chkFutureScenarios"] === true) && (inforceFormData["chkUseClientRates"] === true)) {
        scalarReportArray.push(
            addScalarItem(
                "AllocInitialRateTotal",
                inforceFormData["txtClientRate"],
                ScalarTypes.SCALAR_DOUBLE
            )
        );
        scalarReportArray.push(
            addScalarItem("AllocRateYearsTotal", inforceFormData["txtClientRateToYear"], ScalarTypes.SCALAR_STRING)
        );
        scalarReportArray.push(
            addScalarItem(
                "AllocSubsequentRateTotal",
                inforceFormData["txtClientRateAfter"],
                ScalarTypes.SCALAR_DOUBLE
            )
        );

    } else {
        scalarReportArray.push(
            addScalarItem(
                "AllocInitialRateTotal",
                dblAverageInterestRate * 100,
                ScalarTypes.SCALAR_DOUBLE
            )
        );
        scalarReportArray.push(
            addScalarItem("AllocRateYearsTotal", "LIFE", ScalarTypes.SCALAR_STRING)
        );
        scalarReportArray.push(
            addScalarItem(
                "AllocSubsequentRateTotal",
                dblAverageInterestRate * 100,
                ScalarTypes.SCALAR_DOUBLE
            )
        );
    }

    scalarReportArray.push(
        addScalarItem(
            "AllocScenarioRateTotal",
            dblAverageInterestRate * 100,
            ScalarTypes.SCALAR_DOUBLE
        )
    );
    scalarReportArray.push(
        addScalarItem("AllocNumberOfFunds", intCounter, ScalarTypes.SCALAR_INTEGER)
    );
}

export function getGlobalVariablesInsuranceDataItem(globalVariables, itemName)
{
    const globalVariable = globalVariables.find(variable => variable.name === itemName);
    return globalVariable;
}



