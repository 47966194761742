import React from 'react';
import {Button} from 'core/controls';

const SharedConceptModalFooter = ({
  controlGetFocus,
  controlLostFocus,
  controlClick,
  setElement,
  onSelectOK,
  onSelectCancel,
  onSelectBack,
  btnOK,
  btnCancel,
  btnBack,
  modalIndex,
  modalName,
  totalPages,
  formData,
  isDisable,
}) => {
  return (
    <div className='flexContainer modalControls noMargins justifyBetween paddingMed borderTop borderNeutral borderThin noCols alignCenter bgWhite borderRadiusBottomLg'>
      <div className='cancel-button'>
        <Button
          text=''
          getfocusCallback={controlGetFocus}
          getclickCallback={(event, name, value) => {
            controlClick(event, name, value);
            onSelectCancel();
          }}
          lostfocusCallback={controlLostFocus}
          name={btnCancel}
          refItem={setElement}
          propStyle={{
            label: 'buttonlabel',
            control: `buttoncontrol bgPrimaryColor borderRadiusAll paddingMedWide marginNone buttonOutline`,
          }}
        />
      </div>
      <label> {`Step ${modalIndex} of ${totalPages}`}</label>
      <div className='two-buttons flexContainer noCols noWrap justifyEnd'>
        <Button
          text=''
          getfocusCallback={controlGetFocus}
          getclickCallback={(event, name, value) => {
            controlClick(event, name, value);
            onSelectBack();
          }}
          lostfocusCallback={controlLostFocus}
          name={btnBack}
          refItem={setElement}
          propStyle={{
            label: 'buttonlabel',
            control: `buttoncontrol bgPrimaryColor borderRadiusAll paddingMedWide marginNone buttonOutline`,
          }}
          disableButton={isDisable}
        />
        <Button
          text={''}
          getfocusCallback={controlGetFocus}
          getclickCallback={(event, name, value) => {
            controlClick(event, name, value);
            onSelectOK();
          }}
          lostfocusCallback={controlLostFocus}
          name={btnOK}
          refItem={setElement}
          propStyle={{
            label: 'buttonlabel',
            control: `buttoncontrol bgPrimaryColor borderRadiusAll paddingMedWide marginNone`,
          }}
          disableButton={formData[modalName]['numErrors'] > 0}
        />
      </div>
    </div>
  );
};
SharedConceptModalFooter.defaultProps = {
  isDisable: false,
};

export default SharedConceptModalFooter;
