import React, {createContext} from "react";

interface ContextProps {
  errors: {
    [field: string]: string[]
  };
  formErrors: {
    [field: string]: string
  };
  dirty: {
    [field: string]: boolean
  };
  formSubmitted: boolean;
  getMessagesForField(field: string): string[];
}

const initialContextValue = {
    formSubmitted: false,
    errors: {},
    formErrors: {},
    dirty: {},
    getMessagesForField: (field: string) => [],
}

export default createContext<ContextProps>(initialContextValue);
