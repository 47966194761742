import React from 'react';
import IntroModal1 from './IntroModal1';
import IntroModal2 from './IntroModal2';
import IntroModal3 from './IntroModal3';
import IntroModal5 from './IntroModal5';
import IntroModal6 from './IntroModal6';
import IntroModal7 from './IntroModal7';
import IntroModal8 from './IntroModal8';
import IntroModal10 from './IntroModal10';
import IntroModal11 from './IntroModal11';
import IntroModal14 from './IntroModal14';
import IntroModal15 from './IntroModal15';
import IntroModal16 from './IntroModal16';
import IntroModal17 from './IntroModal17';

const ReadonlyModal = ({modalIndex, imgArray}) => {
  switch (modalIndex) {
    case 1:
      return <IntroModal1 />;
    case 2:
      return <IntroModal2 />;
    case 3:
      return <IntroModal3 />;
    case 5:
      return <IntroModal5 />;
    case 6:
      return <IntroModal6 />;
    case 7:
      return <IntroModal7 />;
    case 8:
      return <IntroModal8 />;
    case 10:
      return <IntroModal10 />;
    case 11:
      return <IntroModal11 />;
    case 14:
      return <IntroModal14 />;
    case 15:
      return <IntroModal15 />;
    case 16:
      return <IntroModal16 />;
    case 17:
      return <IntroModal17 />;
    default:
      break;
  }
  return (
    <div className='slideshowContainer'>
      <div className='slideshowContents'>
        <img src={imgArray[modalIndex - 1]} alt='' />
      </div>
    </div>
  );
};

export default ReadonlyModal;
