// import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
// import translation from 'assets/translation.json';
// import {useMemo, useCallback} from 'react';

interface ITransProps {
  t?: (key: string) => string;
  tInput?: (key: string) => string;
  tReport?: (key: string) => string;
}
export const usePCTranslation = () => {
  const {t} = useTranslation();
  const user = useSelector((state) => state.auth.user);

  // const data = useMemo(() => JSON.parse(JSON.stringify(translation)), []);
  // const t = useCallback((key) => data[key], [data]);

  const tInput = (key: string, options) => {
    let value: string;
    if (user && user['inputLanguage'] === 'French') {
      value = t(`${key}-fr`, options);
      if (value === `${key}-fr`) {
        return '';
      } else {
        return value;
      }
    } else {
      value = t(`${key}-en`, options);
      if (value === `${key}-en`) {
        return '';
      } else {
        return value;
      }
    }
  };
  const tReport = (key: string, options) => {
    let value: string;
    if (user && user['reportLanguage'] === 'French') {
      value = t(`${key}-fr`, options);
      if (value === `${key}-fr`) {
        return '';
      } else {
        return value;
      }
    } else {
      value = t(`${key}-en`, options);
      if (value === `${key}-en`) {
        return '';
      } else {
        return value;
      }
    }
  };

  return {t: tInput, tInput, tReport};
};

export default usePCTranslation;
