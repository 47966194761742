import {
  PolicyTypes,
  DividendPayoutTypes,
  PremiumModeTypes,
  BILLINGSTATUS_BILLING,
  PayoutTypes,
  BillingTypeCode,
  ExemptStatus,
  TaxAccrualRule,
  PartyTypes,
} from 'core/constants/calcConstants';

//ToDo: This should be moved to a core report utils module

//ToDo: Move this to a central utlity module
//Custom - Inforce
export function isProductLIF(prodID) {
  return (
    prodID === 658 ||
    prodID === 659 ||
    prodID === 667 ||
    prodID === 668 ||
    prodID === 669 ||
    prodID === 670 ||
    prodID === 671
  );
}
//Custom - Inforce
//ToDo: Move this to a central utlity module
export function isProdIDSOSBA(prodID) {
  return (
    prodID === 800 ||
    prodID === 801 ||
    prodID === 802 ||
    prodID === 803 ||
    prodID === 804 ||
    prodID === 805 ||
    prodID === 806 ||
    prodID === 807
  );
}

//Custom - Inforce
//ToDo: Move this to a central utlity module
export function isProductEssentialLife(prodID) {
  return (
    prodID === 370 ||
    prodID === 371 ||
    prodID === 372 ||
    prodID === 373 ||
    prodID === 374 ||
    prodID === 375 ||
    prodID === 376 ||
    prodID === 377 ||
    prodID === 378 ||
    prodID === 379
  );
}
//Custom - Inforce
//ToDo: Move this to a central utlity module
function isProductParticipatingTerm(prodID) {
  return (
    prodID === 114 ||
    prodID === 116 ||
    prodID === 117 ||
    prodID === 118 ||
    prodID === 119 ||
    prodID === 121 ||
    prodID === 122 ||
    prodID === 123
  );
}
//Custom - Inforce
//ToDo: Move this to a central utlity module
function isProductParticipatingT100(prodID) {
  return (
    prodID === 553 ||
    prodID === 554 ||
    prodID === 556 ||
    prodID === 557 ||
    prodID === 558 ||
    prodID === 559
  );
}

export function isProductPar(policyType, prodID) {
  return (
    policyType === PolicyTypes.Endowment ||
    policyType === PolicyTypes.Enhanced ||
    policyType === PolicyTypes.WholeLife ||
    policyType === PolicyTypes.DeferredAnnuity ||
    isProductParticipatingTerm(prodID) ||
    isProductParticipatingT100(prodID)
  );
}
//Custom - Inforce
export function IsProductPremOffset(inforcePolicy, controlItems, inforceFormData) {
  if (
    inforcePolicy.dividendPayoutType === DividendPayoutTypes.PUA ||
    inforcePolicy.dividendPayoutType === DividendPayoutTypes.DOD
  ) {
    if (
      isInPremiumOffset(inforcePolicy) ||
      isPremiumOffsetSelected(controlItems, inforceFormData)
    ) {
      return true;
    }
  }
  return false;
}

//Custom - Inforce
export function isPremiumOffsetSelected(controlItems, inforceFormData) {
  return !controlItems['chkPremiumOffset'].disabled && inforceFormData['chkPremiumOffset'];
}

//Custom - Inforce
export function IsProdIDFlexLife2(prodID) {
  return prodID === 602 || prodID === 603;
}
//Custom - Inforce
export function isProductADBPlus(prodID) {
  return prodID === 790 || prodID === 791;
}
//Custom - Inforce
export function IsProductExcludeRPU(prodID) {
  return prodID === 805 || prodID === 807;
}

export function IsProductTruncateRPU(prodID) {
  return (
    prodID === 370 ||
    prodID === 371 ||
    prodID === 372 ||
    prodID === 373 ||
    prodID === 374 ||
    prodID === 375 ||
    prodID === 376 ||
    prodID === 377 ||
    prodID === 378 ||
    prodID === 379 ||
    prodID === 545 ||
    prodID === 546 ||
    prodID === 547 ||
    prodID === 548
  );
}
//Custom - Inforce
export function IsProductParticipatingTerm(prodID) {
  return (
    prodID === 114 ||
    prodID === 116 ||
    prodID === 117 ||
    prodID === 118 ||
    prodID === 119 ||
    prodID === 121 ||
    prodID === 122 ||
    prodID === 123
  );
}

export function IsProductNonParticipatingTerm(prodID) {
  //T100s that do not 'participate'.  Used for determining output report
  return (
    prodID === 101 ||
    prodID === 102 ||
    prodID === 103 ||
    prodID === 104 ||
    prodID === 105 ||
    prodID === 107 ||
    prodID === 108 ||
    prodID === 109 ||
    prodID === 110 ||
    prodID === 111 ||
    prodID === 113 ||
    prodID === 115 ||
    prodID === 120 ||
    prodID === 124 ||
    prodID === 125 ||
    prodID === 126 ||
    prodID === 127 ||
    prodID === 128 ||
    prodID === 129 ||
    prodID === 130 ||
    prodID === 131 ||
    prodID === 132 ||
    prodID === 133 ||
    prodID === 790 ||
    prodID === 791
  );
  //Include 790 and 791 (ADB Plus)
}

export function IsProductParticipatingT100(prodID) {
  //T100s that do not 'participate'.  Used for determining output report
  return (
    prodID === 553 ||
    prodID === 554 ||
    prodID === 556 ||
    prodID === 557 ||
    prodID === 558 ||
    prodID === 559
  );
}

export function IsProductNonParticipatingT100(prodID) {
  //T100s that do not 'participate'.  Used for determining output report
  return (
    prodID === 545 ||
    prodID === 546 ||
    prodID === 547 ||
    prodID === 548 ||
    prodID === 549 ||
    prodID === 550 ||
    prodID === 551 ||
    prodID === 552 ||
    prodID === 805 ||
    prodID === 806 ||
    prodID === 807
  );
}

export function IsProductCurrentPremiumT100(prodID) {
  //T100s that do not 'participate'.  Used for determining output report
  return prodID === 560 || prodID === 561 || prodID === 562 || prodID === 563;
}

export function isProdIDGradedPremium(prodID) {
  return prodID === 341 || prodID === 343 || prodID === 330;
}

//Custom - Inforce
export function isProdIDProPlan(prodID) {
  return (
    prodID === 553 ||
    prodID === 554 ||
    prodID === 556 ||
    prodID === 557 ||
    prodID === 558 ||
    prodID === 559
  );
}

export function isProdIDSOSBAEndowment(prodID) {
  return prodID === 801 || prodID === 802;
}

export function isProductHealth(prodID) {
  return (
    prodID === 200 ||
    prodID === 201 ||
    prodID === 202 ||
    prodID === 203 ||
    prodID === 204 ||
    prodID === 205 ||
    prodID === 206 ||
    prodID === 207 ||
    prodID === 208 ||
    prodID === 209
  );
}

export function isPolicyPaidUp(inforcePolicy) {
  let aDate = new Date(inforcePolicy.paidUpDate);
  return (
    aDate <= inforcePolicy.currentDate ||
    inforcePolicy.policyCStatCode === '3' ||
    inforcePolicy.policyCStatCode === '4'
  );
  return true;
}

export function isPlanExcludeHealthMatch(PlanCode) {
  let strTemp = PlanCode.toUpperCase();
  return (
    strTemp === '88W  1' ||
    strTemp === '88W  J' ||
    strTemp === '88P651' ||
    strTemp === '88P652' ||
    strTemp === '11L1 1' ||
    strTemp === '11L1 J' ||
    strTemp === '11L201' ||
    strTemp === '11L20J'
  );
}

export function isPolicyMaturing(inforcePolicy, checkMonthsToMaturity) {
  let dtExpiryDate = new Date(inforcePolicy.expiryDate);
  let dtCurrentDate = new Date(inforcePolicy.currentDate);

  let monthsToMaturity = monthDiff(dtCurrentDate, dtExpiryDate);
  return monthsToMaturity <= checkMonthsToMaturity;
}

export function isFundLoan(fundID) {
  return fundID.toUpperCase() === 'LOAN' || fundID.toUpperCase() === 'ULCF';
}

export function modalFactor(mode) {
  return 12 / mode;
}

//Custom - Inforce
//This is a duplicate from Tree.js. This needs to be moved to a core module
function TextLookup_tEdit(typeID, valueID, useProperCase, t) {
  let outText = '';
  let lookupText = '';
  lookupText = t(`tEdit-${typeID}-${valueID}`);
  outText = lookupText.trim();
  if (useProperCase) {
    outText = toProperCase(outText);
  }
  return outText;
}

//UTILITY Function for trimming planCode: FACFMR ==>> FACFM
function getPlanCode(planCode) {
  let code = planCode;
  if (code.length > 0 && code[code.length - 1] === 'R') {
    code = code.substring(0, code.length - 1);
  }
  return code;
}
export function TextLookup_ProductName(planCode, t) {
  return TextLookup_tEdit('PROD', getPlanCode(planCode), false, t);
}

export function TextLookup_PolicyName(planCode, t) {
  //console.log(`PlanCode = **${planCode.trim()}**`);
  //console.log(`lookup = ${TextLookup_tEdit('PNAME', planCode.trim(), false)}`);
  return TextLookup_tEdit('PNAME', getPlanCode(planCode.trim()), false, t);
}

export function TextLookup_PolicyCStatCode(cStatCode, t) {
  return TextLookup_tEdit('POLST', cStatCode.toUpperCase(), false, t);
}

export function TextLookup_PayoutMethodCode(payoutMethodCode, t) {
  return TextLookup_tEdit('APOT', payoutMethodCode, false, t);
}

export function TextLookup_SubTable1(strCode, isUseProperCase, t) {
  return TextLookup_tEdit('STB1', strCode, isUseProperCase, t);
}

export function TextLookup_SubTable2(strCode, isUseProperCase, t) {
  return TextLookup_tEdit('STB2', strCode, isUseProperCase, t);
}

export function TextLookup_SubTable3(strCode, isUseProperCase, t) {
  return TextLookup_tEdit('STB3', strCode, isUseProperCase, t);
}

export function TextLookup_SubTable4(strCode, isUseProperCase, t) {
  return TextLookup_tEdit('STB4', strCode, isUseProperCase, t);
}

export function TextLookup_Relationship(strCode, t) {
  return TextLookup_tEdit('RELCD', strCode, false, t);
}

export function TextLookup_Consent(strCode, t) {
  return TextLookup_tEdit('PRIV', strCode, false, t);
}

export function TextLookup_AccountType(accountType, t) {
  switch (accountType.toUpperCase()) {
    case 'DIA':
      return t('FL Daily Interest Account');
    case 'GIA':
      return t('FL Guar Interest Accounts');
    case 'SEGFUND':
      return t('FL Equity-Linked Accounts');
    default:
      return '';
  }
}

//Custom - Inforce
export function Translate_RRIF_Code(prodID) {
  switch (prodID) {
    case 666:
      return 'RRIF 3';
    case 652:
    case 657:
      return 'RRIF 4';
    case 731:
      return 'RRIF 6';
    case 753:
      return 'RRIF 1';
    case 754:
      return 'RRIF 2';
    case 658:
    case 667:
      return 'LIFFA';
    case 659:
    case 669:
      return 'LIFFC';
    case 668:
      return 'LIFFB';
    case 670:
      return 'LIFFM';
    case 671:
      return 'LIFFO';
    case 672:
      return 'LIFFS';
    case 680:
      return 'LIFFS2';
    case 734:
      return 'LIFEA';
    case 736:
      return 'LIFEC';
    case 737:
      return 'LIFEC1';
    case 747:
      return 'LIFEM';
    case 748:
      return 'LIFEM1';
    case 751:
      return 'LIFEO';
    case 752:
      return 'LIFEO1';
    case 756:
      return 'LIFES';
    case 757:
      return 'LIFES2';
    default:
      // Error condition
      return 'unkwn';
  }
}
//Core - format data
//Todo this is a duplicate from Tree.js.  Should be moved to a core module
export function format_Display_Date(dateString) {
  let aDate = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(aDate);
}

//Todo this is a duplicate from Tree.js.  Should be moved to a core module
export function format_Display_Date_Short(dateString) {
  let aDate = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(aDate);
}

//Todo this is a duplicate from Tree.js.  Should be moved to a core module
export function getCurrentDate() {
    //Note that the date.getDate() function actually gets the day of the month (.getDay() gives the day of the week)
  return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
}

//ToDo: This needs to be moved to a core utils module
export function format_Display_Date_Object(dateObject) {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(dateObject);
}

export function getCurrentDateFormatted() {
    //Note that the date.getDate() function actually gets the day of the month (.getDay() gives the day of the week)
  let aDateObject = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  return format_Display_Date_Object(aDateObject);
}

export function formatDollar(amount) {
  let dollarValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
  //For negative numbers we remove the negative symbol and replace with brackets around the number
  if (dollarValue.substring(0, 1) === '-') {
    dollarValue = '(' + dollarValue.substring(1) + ')';
  }
  return dollarValue;
}

export function formatAllocationPercent(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  }).format(amount / 100);
}

export function formatInterestRate(amount) {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
  }).format(amount);
}

export function booleanToString(boolValue) {
  if (boolValue) {
    return '1';
  } else {
    return '0';
  }
}

export function Format0Decimals(value) {
  return Number(value).toFixed(0);
}

export function Format2Decimals(value) {
  return Number(value).toFixed(2);
}

export function Format5Decimals(value) {
  return Number(value).toFixed(5);
}

export function FormatnDecimals(value, n) {
  return Number(value).toFixed(n);
}

export function formatSIN(sin) {
  if (sin.trim() === '' || sin === '000000000') {
    return 'Not Available';
  } else {
    let strTemp = '000000000' + sin;
    strTemp = strTemp.substring(strTemp.length - 9);
    let strTemp2 =
      strTemp.substring(0, 3) + '- ' + strTemp.substring(3, 6) + '-' + strTemp.substring(6, 9);
    return strTemp2;
  }
}

//Core - format data
export function formatIsAmountAboveZero(amount, output) {
  if (amount > 0) {
    return output;
  } else {
    return 'Not Selected';
  }
}

//Core - format data
export function toProperCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
//Custom Inforce
export function isInPayout(policy) {
  return (
    policy.policyType !== PolicyTypes.UL &&
    policy.policyType !== PolicyTypes.RRIF &&
    policy.annualPayoutAmount > 0
  );
}

export function TextLookup_PremiumModeULReport(premiumMode, billingTypeCode, t) {
  let temp = TextLookup_PremiumModeReport(premiumMode, t).toLowerCase();
  if (billingTypeCode === '4') {
    temp = temp + ' by pre-authorized debit';
  }
  return temp;
}

export function TextLookup_PremiumModeReport(premiumMode, t) {
  switch (premiumMode) {
    case PremiumModeTypes.Monthly:
      return t('msg-ReportMonthly');
    case PremiumModeTypes.Annual:
      return t('msg-ReportAnnual');
    case PremiumModeTypes.Quarterly:
      return t('msg-ReportQuarterly');
    case PremiumModeTypes.SemiAnnual:
      return t('msg-ReportSemiAnnual');
    default:
      break;
  }
}

export function TextLookup_PremiumModePolicy(policy, t) {
  let temp = '';
  if (
    policy.policyCStatCode !== '3' &&
    policy.policyCStatCode !== '4' &&
    policy.billingStatus !== BILLINGSTATUS_BILLING
  ) {
    return 'N/A';
  }

  switch (policy.policyCStatCode) {
    case '3':
    case '4':
      return t('Paid Up');
    case '2':
      temp = t('Waived');
      break;
    default:
      break;
  }
  temp = temp + TextLookup_PremiumMode(policy.premiumMode, t);
  if (policy.policyCstatCode !== '2') {
    temp = temp + TextLookup_BillingTypeCode(policy.billingTypeCode, policy, t);
  }
  return temp;
}

export function TextLookup_PremiumMode(premiumMode, t) {
  let valueID = '';
  switch (premiumMode) {
    case PremiumModeTypes.Monthly:
      valueID = '1';
      break;
    case PremiumModeTypes.Quarterly:
      valueID = '3';
      break;
    case PremiumModeTypes.SemiAnnual:
      valueID = '6';
      break;
    case PremiumModeTypes.Annual:
      valueID = '12';
      break;
    default:
      return '';
  }
  return TextLookup_tEdit('PAYM', valueID, false, t);
}

function TextLookup_BillingTypeCode(billingTypeCode, policy, t) {
  switch (billingTypeCode) {
    case '4':
      return ` ${t('PAD (Draw Day')}  ${policy.drawDay} )`;
    case '5':
      return `  ${t('Military Pay')}`;
    case 'S':
      return `  ${t('Salary Deduction')}`;
    case 'D':
      return `  ${t('Premium Offset')}`;
    default:
      return '';
  }
}

export function TextLookup_DividendPayout(dividendPayout, t) {
  let valueID = '';

  switch (dividendPayout) {
    case DividendPayoutTypes.DOD:
      valueID = '4';
      break;
    case DividendPayoutTypes.DRP:
      valueID = '2';
      break;
    case DividendPayoutTypes.Enhanced:
      valueID = 'E';
      break;
    case DividendPayoutTypes.LRD:
      valueID = '6';
      break;
    case DividendPayoutTypes.None:
      valueID = '0';
      break;
    case DividendPayoutTypes.OYT:
      valueID = '5';
      break;
    case DividendPayoutTypes.PIC:
      valueID = '1';
      break;
    case DividendPayoutTypes.PUA:
      valueID = '3';
      break;
    default:
      return 'N/A';
  }
  return TextLookup_tEdit('DIV', valueID, false, t);
}

export function TextLookup_PayoutFrequency(policy, t) {
  //Annually is not in tEdit so leaving it for now
  switch (policy.annualPayoutMode) {
    case PremiumModeTypes.Monthly:
      return t('Monthly');
    case PremiumModeTypes.Quarterly:
      return t('Quarterly');
    case PremiumModeTypes.SemiAnnual:
      return t('Semi-Annual');
    case PremiumModeTypes.Annual:
      return t('Annually');
    default:
      return '';
  }
}

export function TextLookup_PayoutType(payoutType, t) {
  switch (payoutType) {
    case PayoutTypes.Minimum:
      return t('Minimum');
    case PayoutTypes.Maximum:
      return t('Maximum');
    case PayoutTypes.FlatAmount:
    default:
      return t('Level');
  }
}

export function TextLookup_NFOption(nfOption, t) {
  let valueID = '';
  let result = '';

  switch (nfOption) {
    case '':
      return t('N/A');
    case 'APL':
      valueID = '4';
      break;
    case 'ETI':
      valueID = '0';
      break;
    case 'PIC':
      valueID = '3';
      break;
    case 'RPU':
      valueID = '2';
      break;
    default:
  }
  result = TextLookup_tEdit('NFO', valueID, false, t);
  //some NF Options have a value in brackets that we do not want to display
  let index = result.indexOf('(', 0);
  if (index > -1) {
    result = result.substr(0, index);
  }
  return result;
}

export function TextLookup_SmokeClass(smokeClass, t) {
  //For some reason this is not in tEdit so it is hard-coded
  switch (smokeClass.toUpperCase()) {
    case 'S':
      return t('Regular');
    case 'N':
      return t('Non-Smoker');
    case 'R':
      return t('Regular');
    default:
      return t('N/A');
  }
}
//Custom Inforce
export function TextLookup_Sex(sex, t) {
  //For some reason this is not in tEdit so it is hard-coded
  switch (sex.toUpperCase()) {
    case 'F':
      return t('Female');
    case 'M':
      return t('Male');
    case 'J':
      return t('Joint');
    default:
      return '';
  }
}
//Core - Inforce
export function Translate_NextPaymentDate(payoutPaymentDate, payoutEffectiveDate, currentDate) {
  let paymentDate = new Date(payoutPaymentDate);
  let effectiveDate = new Date(payoutEffectiveDate);
  let currDate = new Date(currentDate);
  if (paymentDate >= currDate) {
    return format_Display_Date(payoutPaymentDate);
  } else if (effectiveDate >= currDate) {
    return format_Display_Date(payoutEffectiveDate);
  } else {
    return 'Not Selected';
  }
}
//Custom - formatting data
function addressItemRemoveNulls(addressItem) {
  addressItem.address1 = stringRemoveNull(addressItem.address1);
  addressItem.address2 = stringRemoveNull(addressItem.address2);
  addressItem.address3 = stringRemoveNull(addressItem.address3);
  addressItem.address4 = stringRemoveNull(addressItem.address4);
  return addressItem;
}

function stringRemoveNull(aString) {
  if (aString === null) {
    aString = '';
  }
  return aString;
}

export function FormatMailingAddress1(addressItem) {
  //First remove nulls from address items and convert them to blanks
  addressItem = addressItemRemoveNulls(addressItem);
  return (addressItem.address1 + ' ' + addressItem.address3).trim() + ' ' + addressItem.address4;
}

export function FormatMailingAddress2(addressItem) {
  return (addressItem.address2 + ' ' + addressItem.address3).trim() + ' ' + addressItem.address4;
}

export function FormatMailingAddress3(addressItem) {
  return (
    (addressItem.city + ' ' + addressItem.province).trim() +
    ' ' +
    addressItem.postalCode
  ).trim();
}
//Custom - formatting data
export function FormatChurchName(insuredItem) {
  let temp =
    toProperCase((insuredItem.churchName.trim() + ', ' + insuredItem.churchCity).trim()) +
    ' ' +
    insuredItem.churchProvince;
  if (temp === ', ') {
    return 'NAME NOT ON FILE';
  }
  return temp;
}
//Core - formatting data
export function FormatPhoneNumber(phoneNumber) {
  if (phoneNumber === '') {
    return '';
  } else {
    //let temp = phoneNumber.padStart(10, "0");
    //This clears all non digit characters and returns only digits
    let temp = phoneNumber.replace(/\D/g, ''); //"(905) 640-2667" => "9056402667"
    temp.padStart(10, '0');
    return '(' + temp.substr(0, 3) + ')' + ' ' + temp.substr(3, 3) + '-' + temp.slice(-4);
  }
}
//Custom - inforce
export function GetBeneficaryString(coverageItem, t) {
  let temp = '';

  if (coverageItem.beneficiaries.length > 0) {
    for (let insuredItem of coverageItem.beneficiaries) {
      temp =
        temp +
        fullName(insuredItem) +
        ', ' +
        insuredItem.beneficiaryRelationship +
        ' ' +
        formatAllocationPercent(insuredItem.beneficiaryPercent) +
        ' ';
    }

    if (temp !== '') {
      temp = temp.substr(0, temp.length - 1);
    }
    let beneficiary = coverageItem.beneficiaries[coverageItem.beneficiaries.length - 1];
    temp =
      temp +
      ' Class: ' +
      toProperCase(TextLookup_BeneficiaryCode(beneficiary.beneficiaryTypeCode, t));
  } else {
    return '';
  }
  return temp;
}
//Custom - inforce
export function TextLookup_BeneficiaryCode(beneficiaryTypeCode, t) {
  let valueID = '';
  switch (beneficiaryTypeCode.toUpperCase()) {
    case 'R':
      valueID = 'O';
      break;
    default:
      valueID = beneficiaryTypeCode.toUpperCase();
  }
  return TextLookup_tEdit('BTYPE', valueID, false, t);
}

export function GetFullName(insuredItem) {
  return (insuredItem.firstName + ' ' + insuredItem.middleName).trim() + ' ' + insuredItem.lastName;
}

export function GetFullNameWithSalutation(insuredItem) {
  //why is salutation null?
  return (insuredItem.salutation + ' ' + GetFullName(insuredItem)).trim();
  return GetFullName(insuredItem);
}

export function fullName(insuredItem) {
  let salutation = '';
  let outName = '';

  if (insuredItem.partyType === PartyTypes.Person) {
    salutation = insuredItem.salutation.toUpperCase();
    if (
      salutation !== '' &&
      salutation !== 'MR' &&
      salutation !== 'MRS' &&
      salutation !== 'MISS' &&
      salutation !== 'MS'
    ) {
      outName =
        (
          (insuredItem.salutation + ' ' + insuredItem.firstName).trim() +
          ' ' +
          insuredItem.middleName
        ).trim() +
        ' ' +
        insuredItem.lastName;
    } else {
      outName =
        (insuredItem.firstName + ' ' + insuredItem.middleName).trim() + ' ' + insuredItem.lastName;
    }

    let deathDate = new Date(insuredItem.dateOfDeath);
    let blankDate = new Date(1900, 1, 1);

    if (deathDate > blankDate) {
      outName = outName + ' (Deceased)';
    }
    return outName;
  } else {
    return insuredItem.companyName;
  }
}

//Custom - inforce
export function isInPremiumOffset(inforcePolicy) {
  return inforcePolicy.billingTypeCode === BillingTypeCode.PREMIUMOFFSET;
}

//Custom - inforce
export function isNonExemptNew(inforcePolicy) {
  return (
    inforcePolicy.exemptStatus === ExemptStatus.NonExemptNew &&
    inforcePolicy.taxAccrualRule !== TaxAccrualRule.None
  );
}

export function TranslatePaymentModeReport(item, formData, t) {
  switch (formData[item]) {
    case '012':
      return t('msg-Annual');
    case '06':
      return t('msg-SemiAnnual');
    case '03':
      return t('msg-Quarterly');
    case '01':
      return t('msg-Monthly');
    default:
      return t('msg-Monthly');
  }
}

export function calculateAgeFromJan1(insured, currentDate) {
  //ToDo create this function
  return calcAge(insured.dateOfBirth, currentDate, -1);
}

function calcAge(birthdate, today, calcType) {
  //calcType -1 = age last, 0 = age nearest, 1 = age next
  let date1 = new Date(birthdate);
  let date2 = new Date(today);

  let Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  if (Difference_In_Days < 0) {
    return 0;
  }

  let age = Difference_In_Days / 365;

  switch (calcType) {
    case -1: //age last
      return Math.trunc(age);
    case 0: //age nearest
      return Math.round(age);
    case 1: //age next
      return Math.trunc(age) + 1;
    default:
      //age last
      return Math.trunc(age);
  }
}

export function calcAgeNearest(dtBirthDate, dtToday) {
    //Note that the date.getDate() function actually gets the day of the month (.getDay() gives the day of the week)
  let birthDate = new Date(dtBirthDate);
  let today = new Date(dtToday);
  let lastBirthDay = GetLastAnniversaryDate(birthDate, today);

  //complete years since birth date
  let intYears = dateDiffMS('Year', birthDate, today);
  if (
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() == birthDate.getMonth() && today.getDate() < birthDate.getDate())
  ) {
    intYears = intYears - 1;
  }
  //Complete Months since last birthday
  let intMonths = dateDiffMS('Month', lastBirthDay, today);
  if (today.getDate() < lastBirthDay.getDate()) {
    intMonths = intMonths - 1;
  }

  //Days since last birthday plus months
  let intDays = dateDiffMS(
    'Day',
    new Date(
      lastBirthDay.getFullYear(),
      lastBirthDay.getMonth() + intMonths,
      lastBirthDay.getDate()
    ),
    today
  );
  let intAge = intYears;
    if ((intMonths > 6) || (intMonths == 6 && intDays > 0)) {
    intAge = intAge + 1;
  }
  return intAge;
}

export function GetLastAnniversaryDate(policyIssueDate, currentDate) {
    //Note that the date.getDate() function actually gets the day of the month (.getDay() gives the day of the week)
  let issueDate = new Date(policyIssueDate);
    let today = new Date(currentDate);
  //This function will return the date of the last anniversary

  if (issueDate.getMonth() < today.getMonth()) {
    //use current year
    return new Date(
        today.getFullYear(),
        issueDate.getMonth(),
        issueDate.getDate()
    );
  } else if (issueDate.getMonth() > today.getMonth()) {
    //use previous year
    return new Date(
        today.getFullYear() - 1,
        issueDate.getMonth(),
        issueDate.getDate()
    );
  } else {
    //month is the same
    if (issueDate.getDate() <= today.getDate()) {
      //'use the current year
      return new Date(
          today.getFullYear(),
          issueDate.getMonth(),
          issueDate.getDate()
      );
    } else {
      //use the previous year
      return new Date(
          today.getFullYear() - 1,
          issueDate.getMonth(),
          issueDate.getDate()
      );
    }
  }
}

//This function gets full months between two dates. The first date, d1, is the earliest of the two
function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

function dateDiffMS(interval, startingDate, endingDate) {
  //This function is meant to mimic the MicroSoft DateDiff which is expected in our age nearest calculation and matches the desktop calculation
    var yearDiff = endingDate.getFullYear() - startingDate.getFullYear();
    if (interval.toUpperCase() == 'YEAR') {
        return yearDiff;
    } else if (interval.toUpperCase() == 'MONTH') {
        var monthDiff = endingDate.getMonth() - startingDate.getMonth();
        monthDiff += yearDiff * 12;
        return monthDiff;
    } else {
        return dateDiff(interval, startingDate, endingDate);
    }
}

function dateDiff(interval, startingDate, endingDate) {
  //Note that the date.getDate() function actually gets the day of the month (.getDay() gives the day of the week)
  //Returns the difference between two dates.
  //Interval should be "Year", "Month", "Day".  Will default to Day if interval is incorrect.
    //var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
    var startDate = new Date(startingDate);
  if (!endingDate) {
    endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
  }
  var endDate = new Date(endingDate);
  if (startDate > endDate) {
    var swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  var startYear = startDate.getFullYear();
  var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  var yearDiff = endDate.getFullYear() - startYear;
  var monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  if (interval.toUpperCase() == 'YEAR') {
    return yearDiff;
  } else if (interval.toUpperCase() == 'MONTH') {
    return monthDiff;
  } else {
    return dayDiff;
  }
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
