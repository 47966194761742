import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { dragIcon } from 'assets';
import { usePCTranslation } from 'core/hooks';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    padding: '0.5rem 0.5rem',
    margin: '.5rem',
    cursor: 'move',
    borderRadius: '16px',
  },
  content: {
    padding: '0.5rem 0.5rem',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function OutlinedCard({ widget, onToggle, moveCard, index }) {
  const { t } = usePCTranslation();
  const classes = useStyles();
  const { name, displayName, isActive, widgetID } = widget;
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'card ',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card ',
    item: () => {
      return { widgetID, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const formatLongText = (longText) => {
    const parts = longText.split("<br/>");
    const len = parts.length;
    return parts.map((part, idx) => {
      return (
        <React.Fragment key={idx}>
          {part}
          {idx !== len && <br />}
        </React.Fragment>
      );
    });
  };
  return (
    <Card
      className={classes.root}
      variant="outlined"
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}>
    
    
      <CardContent className={classes.content}>
        <div className="flexContainer justifyBetween">
                  <p className="fontBold fontMed">{t(`widget-${name}-title`)}</p>
          <div>
            <img width={20} src={dragIcon} alt="dragIcon" />
          </div>
        </div>
        <p className="fontSm"></p>
        <Typography className={classes.pos} color="textSecondary">
          {t(`widget-${name}-shortText`)}
        </Typography>
        <Typography variant="body2" component="p">
          {formatLongText(t(`widget-${name}-longText`))}
        </Typography>
      </CardContent>
      <div className="checkcontainer">
        <input
          className="checkcontrol toggle"
          name="toggleVisibility"
          type="checkbox"
          checked={isActive}
          onChange={(e) => onToggle(widgetID, e.target.checked)}
        />
        <label className="checklabel">{isActive === true ? "ON" : "OFF"}</label>
      </div>
    </Card>
  );
}
