import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {useRouter, usePCTranslation} from 'core/hooks';
import {Button} from 'core/controls';

const TabbedHeader = ({controlClick, controlGetFocus, controlLostFocus, setElement, isUserAdmin, sticky}) => {
  const {match, push, location} = useRouter();
  const {t} = usePCTranslation();
  const {routes} = useSelector((state) => ({
    routes: state.routes,
  }));
  const page = location.pathname.split('/')[1].toLowerCase();

  const handleClickRoute = (event, name, value, route) => {
    const tempArr = location.pathname.split('/');
    if (route.subModule.toLowerCase() !== tempArr[tempArr.length - 1].toLowerCase()) {
      controlClick(event, name, value);
      push(route.paths[0]);
    }
  };
  const isActive = (route) => {
    const tempArr = location.pathname.split('/');
    if (route.subModule.toLowerCase() !== tempArr[tempArr.length - 1].toLowerCase()) {
      return false;
    }
    return true;
  };
  if (!routes[page] || routes[page].length === 0) {
    return <></>;
  }
  return (
    <div id='tabHeader-id' className={`PRODUCTHEADER flexContainer alignStretch logoHdr${routes[page][0].appName} ${sticky} bgWhite borderThin borderBottom borderNeutral paddingLgHoriz`}>
      <div id={routes[page][0].appName} className='flexContainer autoCols justifyEnd alignCenter'>
        <label id={routes[page][0].appName} className='PAGETITLE borderNone fontMed'>
          {t(routes[page][0].displayName)}
        </label>
      </div>
      {routes[page].length > 1 && (
        <div className='LINKS flexContainer'>
          {routes[page].map(
            (route) =>
              route.isVisible && (
                <Fragment key={route.routeId}>
                  <div className={`${isActive(route) ? 'activeHeader' : ''}`}>
                    <Button
                      text=''
                      getfocusCallback={controlGetFocus}
                      getclickCallback={(event, name, value) => handleClickRoute(event, name, value, route)}
                      lostfocusCallback={controlLostFocus}
                      //stateData={storeFormData}
                      name={route.button}
                      refItem={setElement}
                      propStyle={{
                        label: 'buttonlabel',
                        control: 'buttoncontrol buttonHdr',
                      }}
                    />
                  </div>
                </Fragment>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default TabbedHeader;
