import {ActionTypes} from 'custom/common/redux/Types';
import axios from 'axios';
import {setAlert, removeAlert} from 'core/redux/actions';

function sleep(sec) {
  return new Promise((resolve) => setTimeout(resolve, sec * 1000));
}
let result = {
  data: {
    agentID: '000005967',
    agentList: [
      {agentID: 'All', agentName: 'All Listed Below', isActive: true},
      {agentID: '000005968', agentName: 'Jo Agent', isActive: true},
      {agentID: '000005969', agentName: 'J Agent', isActive: true},
      {agentID: '000005970', agentName: 'Joe', isActive: false},
    ],
    productList: [
      {productID: 0, productName: 'All'},
      {productID: 1, productName: 'Accident & Sickness'},
      {productID: 2, productName: 'ADB'},
      {productID: 3, productName: 'Annuity'},
      {productID: 5, productName: 'APRA'},
      {productID: 6, productName: 'Critical Illness'},
      {productID: 7, productName: 'Essential Life'},
      {productID: 8, productName: 'Life - Permament'},
      {productID: 9, productName: 'Life - Term'},
      {productID: 10, productName: 'Life - UL'},
      {productID: 11, productName: 'Reduced Paid-Up'},
      {productID: 12, productName: 'Segregated Funds'},
      {productID: 13, productName: 'Term 100'},
    ],
    planList: [
      {planID: 'All', planName: 'All'},
      {planID: 'S8124', planName: '10 Yr Level Term Rider S8124'},
      {planID: 'S7074', planName: '10 Yr Level Term S7074'},
      {planID: 'S8071', planName: '10 Yr Level Term S8071'},
      {planID: '15Yr', planName: '15 Yr Monthly Income Rider'},
      {planID: '1911', planName: '1911 Paid-Up Life'},
      {planID: '1929', planName: '1929 Whole Life'},
    ],
  },
};
const policyStatusListOptionItems = (policyStatusList) => {
  let policyStatusOptions = {};

  policyStatusList.map(({policyStatusCode, policyStatusDescription}) => {
    policyStatusOptions[policyStatusCode] = policyStatusDescription;
    return policyStatusCode;
  });
  return policyStatusOptions;
};
const churchListOptionItems = (churchList) => {
  let churchOptions = {};

  churchList.map(({churchNumber, churchName, churchCity, churchProvince}) => {
    churchOptions[churchNumber] = `${churchName}, ${churchCity} ${churchProvince}, ${churchNumber}`;
    return churchNumber;
  });
  return churchOptions;
};
const denominationListOptionItems = (denominationList) => {
  let denominationOptions = {};
  console.log('denominationListOptionItems', denominationList);
  denominationList.map((val) => {
    denominationOptions[val] = val;
    return val;
  });
  return denominationOptions;
};

const loadDataReportsData = () => async (dispatch, getState) => {
  //let res;
  try {
    let res1 = await axios.post('/api/DataReports', getState().auth.user);
    //await sleep(1);
    //res = result;
    let res2 = await axios.post('/api/DataReportLists');
    console.log('loadDataReportsData', res1.data, res2.data);
    console.log("res2.data['denominationList']", res2.data['denominationList']);

    //For testing, adding 2nd Agent to avoid skipping Agent page. Agent page is not completed for designing and feature wise
    // let agentList = res1.data['agentList'].slice();
    // agentList.push({
    //   agentID: '000005968',
    //   agentName: 'J A Agent',
    //   isActive: false,
    // });
    // agentList.push({
    //   agentID: '000005969',
    //   agentName: 'J Agent',
    //   isActive: false,
    // });
    // console.log(agentList, res1.data.agentList);

    let churchOptionItems = churchListOptionItems(res2.data['churchList']);
    let policyStatusOptionItems = policyStatusListOptionItems(res2.data['policyStatusList']);
    let denominationOptionItems = denominationListOptionItems(res2.data['denominationList']);
    console.log('DenominationOptionItems', denominationOptionItems);
    dispatch({
      type: ActionTypes.FETCH_DATAREPORTS,
      payload: {
        ...res1.data,
        ...res2.data,
        churchOptionItems,
        policyStatusOptionItems,
        // agentList,
        denominationOptionItems,
      }, //spread all objects of both api call results
    });
  } catch (error) {
    console.log('Data Reports Error: ', error.response);
    dispatch({
      type: ActionTypes.FETCHERROR_DATAREPORTS,
    });
    // dispatch(
    //   setAlert(
    //     error.response.data[0].errorMessage,
    //     "Error",
    //     "alphaLookup",
    //     10000
    //   )
    // );
  }
};
const clearDataReportsData = () => (dispatch, getState) => {
  // This is to remove alert if any existing on data fetch
  if (getState().alert['alphaLookup']) {
    dispatch(removeAlert('alphaLookup'));
  }
  dispatch({type: ActionTypes.CLEAR_DATAREPORTS});
};

export {loadDataReportsData, clearDataReportsData};
