import React, {Fragment} from 'react';
import {STYLES} from 'core/constants/uiConstants';

const tableDisplay = (headRow, grid, propStyle, freeze = false) => {
  return (
    <table className={`${STYLES.BASIC} ${STYLES.TABLE} ${propStyle.table}`}>
      <thead className={`${STYLES.BASIC} ${STYLES.THEAD} ${propStyle.head}`}>
        <tr className={`${STYLES.BASIC} ${STYLES.TROW} ${propStyle.row}`}>
          {headRow.map((item) => (
            <td
              className={`${STYLES.BASIC} ${STYLES.TDATA} ${propStyle.data} ${
                freeze ? 'headerColumn' : ''
              }`}
              key={item.name}>
              {item.displayName}
            </td>
          ))}
        </tr>
      </thead>
      <tbody className={`${STYLES.BASIC} ${STYLES.TBODY} ${propStyle.body}`}>
        {grid.map((row, index) => (
          <tr key={index}>
            {row.map((val, idx) => (
              <td key={idx} className={`${freeze ? 'headerColumn' : ''}`}>
                {val}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const CalcTable = ({
  dblTableData,
  columnItems,
  propStyle,
  freezeColumnCount,
}) => {
  if (!dblTableData) {
    return null;
  }

  // let headRow1 = insuranceData.columnItems.slice(0,3);
  // let headRow2 = insuranceData.columnItems.slice(3);

  // let grid1 = [];
  // let grid2= [];

  // tblMain.slice(1).map(row=>{
  //   grid1.push(row.slice(1,4));
  //   grid2.push(row.slice(4));
  // });
  // return (
  //   <div className="flexContainer twoCol">
  //     {tableDisplay(headRow1, grid1, propStyle, true)}
  //     {tableDisplay(headRow2, grid2, propStyle, false)}
  //   </div>
  // )

  //We slice off the first row and column as they are index '0' and not used
  const grid = Object.keys(dblTableData)
    .slice(1)
    .map((item, index) => (
      <tr key={index}>
        {dblTableData[item].slice(1).map((col, idx) => (
          <td
            key={idx}
            className={`${idx < freezeColumnCount ? "headerColumn" : ""}`}
          >
            {col}
          </td>
        ))}
      </tr>
    ));

  return (
    <table className={`${STYLES.BASIC} ${STYLES.TABLE} ${propStyle.table}`}>
      <thead className={`${STYLES.BASIC} ${STYLES.THEAD} ${propStyle.head}`}>
        <tr className={`${STYLES.BASIC} ${STYLES.TROW} ${propStyle.row}`}>
          {columnItems.map((item, idx) => (
            <td
              className={`${STYLES.BASIC} ${STYLES.TDATA} ${propStyle.data} ${
                idx < freezeColumnCount ? "headerColumn" : ""
              }`}
              key={item.name}
            >
              {item.displayName}
            </td>
          ))}
        </tr>
      </thead>

      <tbody className={`${STYLES.BASIC} ${STYLES.TBODY} ${propStyle.body}`}>
        {grid}
      </tbody>
    </table>
  );
};

// CalcTable.defaultProps = {};

export default CalcTable;
