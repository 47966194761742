import React from 'react';
import PageHeader from './PageHeader';
import PageHeaderM from './PageHeaderM';

const Header = ({...props}) => {
  // console.log(props);
  return (
    <div id='header-bars' className={`header-bars`}>
      {props.screenWidth > 825 ? <PageHeader {...props} /> : <PageHeaderM {...props} />}
    </div>
  );
};

export default Header;
