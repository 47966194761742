import React, {Fragment} from 'react';
import {Checkbox, Label, Textbox, MaskedTextbox} from 'core/controls';
import {STYLES} from 'core/constants/uiConstants';

const UserInformation = ({controlLostFocus, controlChange, controlGetFocus, setElement, formData, user}) => {
  //This is to prevent user['agentID'] undefined error on session logout
  if (!user) {
    return null;
  }
  return (
    <Fragment>
      {/* <label className="HEADER-TITLE fontHdr2 paddingMed">Your Information</label> */}
      <div className='flexContainer noCols fullWidth'>
        <Textbox
          text='Name'
          getfocusCallback={controlGetFocus}
          getchangeCallback={controlChange}
          lostfocusCallback={controlLostFocus}
          stateData={formData['txtUserName']}
          name='txtUserName'
          type='text'
          refItem={setElement}
          propStyle={{
            label: 'textlabel',
            control: 'textcontrol inputLarge',
            textcontainer: 'textcontainer',
          }}
        />
        <Textbox
          text='Email Address'
          getfocusCallback={controlGetFocus}
          getchangeCallback={controlChange}
          lostfocusCallback={controlLostFocus}
          stateData={formData['txtEmailAddress']}
          name='txtEmailAddress'
          type='text'
          refItem={setElement}
          propStyle={{
            label: 'textlabel',
            control: 'textcontrol inputLarge',
            textcontainer: 'textcontainer',
          }}
        />
        <div className={`${STYLES.INPUTCONTAINER} premcontainer`}>
          <Label
            text='Agent ID'
            name='lblAgentIDTitle'
            propStyle={{
              label: ' textlabel premtitle',
            }}
          />
          <Label
            text={user['agentID']}
            name='lblAgentIDValue'
            propStyle={{
              label: ' textlabel premvalue fontHdr2',
            }}
            useTranslation={false}
          />
        </div>
      </div>
      <div className='flexContainer noCols fullWidth'>
        <div className='PHONEGROUP'>
          <MaskedTextbox
            text='Primary Phone Number'
            getfocusCallback={controlGetFocus}
            getchangeCallback={controlChange}
            lostfocusCallback={controlLostFocus}
            stateData={formData['txtPrimaryPhone']}
            name='txtPrimaryPhone'
            type='text'
            refItem={setElement}
            propStyle={{
              label: 'textlabel',
              control: 'textcontrol inputLarge',
              textcontainer: 'textcontainer',
            }}
          />
          <Checkbox
            text='This is a mobile number'
            getfocusCallback={controlGetFocus}
            getchangeCallback={controlChange}
            lostfocusCallback={controlLostFocus}
            stateData={formData}
            name='chkPrimaryPhone'
            refItem={setElement}
            propStyle={{
              label: 'checklabel fontPrimaryColor',
              control: 'checkcontrol toggle',
              checkcontainer: 'checkcontainer',
            }}
          />
        </div>
        <div className='PHONEGROUP'>
          <MaskedTextbox
            text='Secondary Phone Number'
            getfocusCallback={controlGetFocus}
            getchangeCallback={controlChange}
            lostfocusCallback={controlLostFocus}
            stateData={formData['txtSecondaryPhone']}
            name='txtSecondaryPhone'
            type='text'
            refItem={setElement}
            propStyle={{
              label: 'textlabel',
              control: 'textcontrol inputLarge',
              textcontainer: 'textcontainer',
            }}
          />
          <Checkbox
            text='This is a mobile number'
            getfocusCallback={controlGetFocus}
            getchangeCallback={controlChange}
            lostfocusCallback={controlLostFocus}
            stateData={formData}
            name='chkSecondaryPhone'
            refItem={setElement}
            propStyle={{
              label: 'checklabel fontPrimaryColor',
              control: 'checkcontrol toggle',
              checkcontainer: 'checkcontainer',
            }}
          />
        </div>
        <Textbox
          text='Fax Number'
          getfocusCallback={controlGetFocus}
          getchangeCallback={controlChange}
          lostfocusCallback={controlLostFocus}
          stateData={formData['txtFaxNumber']}
          name='txtFaxNumber'
          type='text'
          refItem={setElement}
          propStyle={{
            label: 'textlabel',
            control: 'textcontrol inputLarge',
            textcontainer: 'textcontainer',
          }}
        />
      </div>
    </Fragment>
  );
};

export default UserInformation;
