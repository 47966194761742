import {ActionTypes} from 'custom/common/redux/Types';
import axios from 'axios';
import {updateInforce, populateTree} from '../../utils/Tree';
import {setAlert} from 'core/redux/actions';
import {globalVariables} from 'core/utils/util';

export const loadInforceData = (policyNum, t) => async (dispatch, getState) => {
  try {
    let user = getState().auth.user;
    let isStatusReport = false;
    console.log('loadInforceData: user', user);
    //let res2 = await axios.get(`/api/GetReportStatusInfo/${policyNum}`, { params: { user, isStatusReport } });
    //console.log("GetReportStatusInfo data", res2.data);

    let GetPolicyObject = {
      policyNumber: policyNum,
      user: user
    };

    let res = await axios.post('/api/getpolicy', GetPolicyObject);
    dispatch({
      type: ActionTypes.INFORCE_LOAD,
      payload: res.data,
    });
    console.log('inforce Data', res.data);
    if (res.data.errorCode === 1 || res.data.errorCode === 3 || res.data.errorCode === 5 || res.data.errorCode === 9) {
      //If error do not populate tree
      dispatch(populateTreeAction(res.data.inforcePolicy, t));
    }
  } catch (error) {
    console.log('Inforce Load Error', error.response);
    dispatch({
      type: ActionTypes.INFORCE_LOAD_ERROR,
      payload: error.response,
    });
  }
};

export const populateTreeAction = (inforcePolicy, t) => async (dispatch) => {
  let result = populateTree(inforcePolicy, t);
  dispatch({
    type: ActionTypes.TREE_BUILD,
    payload: result,
  });
};
export const updateInforcePolicy = (formData, t) => async (dispatch, getState) => {
  let result = await updateInforce(getState().inforceData, formData);
  dispatch({
    type: ActionTypes.INFORCE_LOAD,
    payload: result,
  });
  if (result['errorCode'] === 1 || result['errorCode'] === 3 || result['errorCode'] === 5 || result['errorCode'] === 9) {
    //If error do not populate tree
    dispatch(populateTreeAction(result.inforcePolicy, t));
    if (globalVariables.isAddRiderWP) {
      dispatch(setAlert(t('msg-AddedTermRiderWithWP'), 'Success', 'addTermRider', 1000000));
    } else {
      dispatch(setAlert(t('msg-AddedTermRider'), 'Success', 'addTermRider', 1000000));
    }
  }
};

export const postInforceReport = ({formData}) => async (dispatch) => {
  try {
    let res = await axios.post('/api/inforcereport', {params: formData});
    dispatch({
      type: ActionTypes.INFORCE_POST,
      payload: res.data,
    });
  } catch (error) {
    console.log('Inforce Post Error: ', error);
  }
};
