import {ActionTypes} from '../Types';

export const createGlobalVariableArray = (key, value) => {
  return {
    type: ActionTypes.CREATE_GLOBAL_ARRAY,
    payload: {key, value},
  };
};

//This is hook to the core implementation from where it is called for clearing redux on logout
export const clearCustomStore = () => (dispatch) => {
  dispatch({type: ActionTypes.INFORCE_REMOVE});
  dispatch({type: ActionTypes.CLEAR_ALPHALOOKUP});
  dispatch({type: ActionTypes.CLEAR_DATAREPORTS});
  dispatch({type: ActionTypes.TREE_REMOVE});
  dispatch({type: ActionTypes.REMOVE_FIRSTYEAR_PREMIUM});
};
