//https://reactdatepicker.com/
import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
import {STYLES} from 'core/constants/uiConstants';
import {usePCTranslation} from 'core/hooks';
import {TooltipText, TooltipIcon} from 'core/uicore';
import moment from 'moment';

const Datebox = ({
  getchangeCallback,
  getfocusCallback,
  lostfocusCallback,
  stateData,
  name,
  text,
  type,
  autoFocus,
  refItem,
  propStyle,
  format,
  ageFieldName,
  minDate = null,
  maxDate = null,
  disable = false,
}) => {
  const { t } = usePCTranslation();
  const { controlItem } = useSelector((state) => ({
    controlItem: state.setupItems.controlItems
      ? state.setupItems.controlItems[name]
      : null,
  }));
  let rawValue = "";
  let rawChange = false;
  let blnDateCheck = false;
  const controlOnChange = (date) => {
    //event.persist();
    //let value = formattedDate(date);
    //console.log(value);
    let event = {
      persist: () => {},
      preventDefault: () => {},
      stopPropagation: () => {},
      target: {
        name,
        date,
      },
    };

    if (rawChange) {
      blnDateCheck = moment(rawValue, "YYYY-MM-DD", true).isValid();
    } else {
      blnDateCheck = true;
    }

    console.log("inside controlOnChange");
    console.log("inside controlOnChange rawChange = ", rawChange);
    //If the rawValue from the date component is in the format yyyy-mm-dd then call the change callback or if we clicked the calendar
    if (blnDateCheck) {
      getchangeCallback(event, name, date);
    }
    rawChange = false;
  };

  const controlOnFocus = () => {
    let event = {
      target: {
        name,
        date: new Date(),
      },
    };
    getfocusCallback(event, event.target.name, event.target.date);
  };
  const controlOnLostFocus = () => {
    let event = {
      target: {
        name,
        date: new Date(),
      },
    };
    lostfocusCallback(event, event.target.name, event.target.date);
  };

  let containerClass = `${STYLES.INPUTCONTAINER} ${STYLES.DATE} ${propStyle.datecontainer}`;
  if (controlItem?.displayDisabled) containerClass += " disabled";
  if (controlItem?.displayHidden) containerClass += " hiddenField";
  const openToDate =
    stateData[name] ||
    new Date().setFullYear(new Date().getFullYear() - +stateData[ageFieldName]);
  const handleChangeRaw = (e) => {
    console.log("Raw value: ", e.target.value);
    rawValue = e.target.value;
    rawChange = true;
  };
  const dateInput = () => (
    <div className="DATEPICKER" id={name}>
      <DatePicker
        selected={stateData[name]}
        onChange={(date) => controlOnChange(date)}
        onChangeRaw={handleChangeRaw}
        openToDate={openToDate}
        onInputClick={controlOnFocus}
        onClickOutside={controlOnLostFocus}
        disabled={controlItem?.displayDisabled || disable}
        dateFormat={format}
        minDate={minDate}
        maxDate={maxDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        ref={refItem}
        placeholderText={format.toLowerCase()}
        name={name}
        className={propStyle?.dateInput || ""}
      />
    </div>
  );
  return (
    <div id={name} className={`DATEPICKERCONTAINER ${containerClass}`}>
      {!controlItem?.showTooltip ? (
        <Fragment>
          <label
            className={`DATEPICKERLABEL ${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`}
            id={name}
          >
            {t(`${name}-caption`)}
          </label>
          {dateInput()}
        </Fragment>
      ) : controlItem.showTooltipIcon ? (
        <Fragment>
          <label
            className={`DATEPICKERLABEL ${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`}
            id={name}
          >
            {t(`${name}-caption`)}
          </label>
          <div className={`${STYLES.TOOLTIP}`} id={name}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
          {dateInput()}
        </Fragment>
      ) : (
        <div className={`${STYLES.TOOLTIP}`} id={name}>
          <label
            className={`DATEPICKERLABEL ${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`}
            id={name}
          >
            {t(`${name}-caption`)}
          </label>
          {dateInput()}
          <TooltipText name={name} />
        </div>
      )}
    </div>
  );
};
Datebox.defaultProps = {
  format: 'dd/MM/yyyy',
};
export {Datebox};
