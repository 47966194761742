//import { CONTROLITEMS_LOAD, CONTROLITEMS_LOAD_SUCCESS, CONTROLITEMS_LOAD_FAIL, CONTROLITEMS_STORE } from '../actions/controlItemsActionCreators';
import {ActionTypes} from '../types';
//import { data as initialData } from "../initialData.js";

//import { GET_DATA } from "../../webservice/RestMiddleware";

// const initialState = {};
const CONTROL_ITEMS = 'controlItems';
const INTERFACE_SETUP_ARRAY = 'interfaceSetupArray';
const INTERFACE_SETUP_ITEMS = 'interfaceSetupItems';

const setupReducer = (state = {}, action) => {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.DATA_LOADING:
      return {...state, isDataLoading: true};
    case ActionTypes.CONTROLITEMS_LOAD:
      return {
        ...state,
        isDataLoading: false,
        controlItems: payload,
      };
    case ActionTypes.INTERFACESETUPITEMS_LOAD:
      return {
        ...state,
        isDataLoading: false,
        interfaceSetupArray: payload,
      };
    case ActionTypes.INTERFACESETUPITEMS_UPDATE:
      return {isDataLoading: false, ...state, interfaceSetupItems: payload};
    case ActionTypes.ALLSETUPS_LOAD:
      return {...state, allSetupItems: payload};
    case ActionTypes.DATA_REMOVE:
      return {};
    case ActionTypes.UPDATE_CONTROLITEMS:
      const {name, parameter, value} = action.payload;
      return {
        ...state,
        controlItems: {
          ...state[CONTROL_ITEMS],
          [name]: {...state.controlItems[name], [parameter]: value},
        },
      };
    default:
      return state;
  }
};

export {setupReducer as setupItems};
