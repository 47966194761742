import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useDropzone} from 'react-dropzone';
import {Button, Radiobuttons} from 'core/controls';
import axios from 'axios';
import {setAlert} from 'core/redux/actions';

const FileUpload = ({name, formData, controlClick, controlGetFocus, controlLostFocus, controlChange, setElement}) => {
  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // accept: 'application/vnd.ms-excel',
    noClick: true,
    noKeyboard: true,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(acceptedFiles);
    if (acceptedFiles.length > 0) {
      // if (formData[name].length > 0) {
      //   newFile = formData[name].filter((file) => file.fileName === acceptedFiles[0].name);
      // }
      if (acceptedFiles[0].name !== formData['fileUserUploadName']) {
        let event = {
          target: {
            value: acceptedFiles[0].name,
            name: 'fileUserUploadName',
          },
          persist: () => {}, //To fix persist not found issue in Main Page component file
        };
        controlChange(event, 'fileUserUploadName', acceptedFiles[0].name);
        event = {
          target: {
            value: acceptedFiles[0].size,
            name: 'fileUserUploadSize',
          },
          persist: () => {}, //To fix persist not found issue in Main Page component file
        };
        controlChange(event, 'fileUserUploadSize', acceptedFiles[0].size);
      }
      // if (newFile.length === 0) {
      //   let tempFiles = [];
      //   tempFiles.push({
      //     fileName: acceptedFiles[0].name,
      //     size: acceptedFiles[0].size,
      //   });
      //   let event = {
      //     target: {
      //       value: tempFiles,
      //       name,
      //     },
      //   };
      //   controlChange(event, name, tempFiles);
      // }
    }
  }, [acceptedFiles]);

  const handleImportUser = async () => {
    let f = new FormData();
    f.append('File', acceptedFiles[0]);
    f.append('FileDirectory', '');
    f.append('isFullUpdate', formData['rdoFileImportType'] !== 'Full' ? false : true);
    try {
      let result = await axios.post('/users/Uploadusers', f, {headers: {'Content-Type': 'multipart/form-data'}});
      if (result) {
        console.log(result);
        dispatch(setAlert('Users imported successfully', 'Success', 'importUsers', 5000));
      }
    } catch (error) {
      console.log('upload error = ', error);
      dispatch(setAlert('Users import error', 'Error', 'importUsers', 5000));
    }
  };

  // const files = formData[name].map((file) => (
  //   <li key={file.fileName}>
  //     {file.fileName} - {file.size} bytes
  //   </li>
  // ));

  return (
    <div className='container'>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <Button
          text='Select File'
          getfocusCallback={controlGetFocus}
          getclickCallback={(event, name, value) => {
            open(event);
            controlClick(event, name, value);
          }}
          lostfocusCallback={controlLostFocus}
          stateData={formData}
          name='btnSelectUserFile'
          refItem={setElement}
          propStyle={{
            label: 'buttonlabel',
            control: `buttoncontrol bgPrimaryColor borderRadiusAll`,
          }}
          allowPropagation={true}
        />
      </div>
      {formData['fileUserUploadSize'] > 0 && (
        <aside>
          <h4>Filename</h4>
          <ul>
            <li key={formData['fileUserUploadName']}>
              {formData['fileUserUploadName']} - {formData['fileUserUploadSize']} bytes
            </li>
          </ul>
        </aside>
      )}
      <Radiobuttons
        text=''
        getfocusCallback={controlGetFocus}
        getchangeCallback={controlChange}
        lostfocusCallback={controlLostFocus}
        stateData={formData}
        name='rdoFileImportType'
        refItem={setElement}
        propStyle={{
          label: '',
          control: 'radiocontrol',
          radiocontainer: 'radiocontainer marginSm',
          inputcontainer: 'radiocontainer marginSm',
          radioGroup: 'flexContainer noCols',
          child: 'childcontrol',
        }}
      />
      <Button
        text='Import User'
        getfocusCallback={controlGetFocus}
        getclickCallback={(event, name, value) => {
          handleImportUser();
          controlClick(event, name, value);
        }}
        lostfocusCallback={controlLostFocus}
        stateData={formData}
        name='btnImportUser'
        refItem={setElement}
        propStyle={{
          label: 'buttonlabel',
          control: `buttoncontrol bgPrimaryColor borderRadiusAll`,
        }}
        allowPropagation={true}
      />
    </div>
  );
};

export {FileUpload};
