import React, {Component} from 'react';
import {connect} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {
  loadControlItems,
  loadInterfaceSetupItems,
  updateControlItems,
  setStoreFormData,
  updateStoreFormData,
  updateWholeFormData,
  clearStoreFormData,
  login,
  logout,
  sessionTimeout,
  updateUserProfile,
  updateUserProfileField,
  getAllUsers,
  setAlert,
  updateControlItemsDB,
  deleteControlItemDB,
  updateInterfaceSetupItemsDB,
  deleteInterfaceSetupItemDB,
} from "core/redux/actions";
import {
  getFirstYearPremium,
  updateReportOptionsProfile,
} from "custom/newbiz/redux/actions";
import {
  loadInforceData,
  populateTreeAction,
} from "custom/inforce/redux/actions";
import {
  loadAlphaLookupData,
  clearAlphaLookupData,
} from "custom/alphaLookup/redux/actions";
import {
  loadDataReportsData,
  clearDataReportsData,
} from "custom/dataReports/redux/actions";
import { getAllRoles } from "custom/admin/redux/actions";
import { updateBIOptionsProfile } from "custom/bi/redux/actions";
// import {login, logout, sessionTimeout, updateUserProfile, getAllUsers} from 'core/redux/actions/authActions';
// import {setAlert} from 'core/redux/actions/alertActions';
import Login from "../auth/Login";

import { withPCTranslation } from "core/hocs";

import Routes from "./Routes";
import { MsalProvider } from "@azure/msal-react";

const mapStateToProps = (dataStore) => ({
  controlItems: dataStore.setupItems.controlItems,
  interfaceSetupItems: dataStore.setupItems.interfaceSetupItems,
  allSetupItems: dataStore.setupItems.allSetupItems,
  interfaceSetupArray: dataStore.setupItems.interfaceSetupArray,
  setupItems: dataStore.setupItems,
  storeFormData: dataStore.formData["newBusiness"],
  eAppFormData: dataStore.formData["eAppFormData"],
  inforceFormData: dataStore.formData["inforceFormData"],
  firstYearPremium: dataStore.firstYearPremium,
  inforceData: dataStore.inforceData,
  alphaLookupFormData: dataStore.formData["alphaLookupFormData"],
  alphaLookupData: dataStore.alphaLookupData,
  dataReports: dataStore.dataReports,
  auth: dataStore.auth,
  routes: dataStore.routes,
  roles: dataStore.roles,
  alertList: dataStore.alert,
});

const mapDispatchToProps = {
  // loadData,
  loadControlItems,
  updateControlItemsDB,
  deleteControlItemDB,
  updateInterfaceSetupItemsDB,
  deleteInterfaceSetupItemDB,
  loadInterfaceSetupItems,
  setAlert,
  updateControlItems,
  setStoreFormData,
  clearStoreFormData,
  updateStoreFormData,
  updateWholeFormData,
  getFirstYearPremium,
  loadInforceData,
  populateTreeAction,
  loadAlphaLookupData,
  clearAlphaLookupData,
  loadDataReportsData,
  clearDataReportsData,
  login,
  logout,
  sessionTimeout,
  updateUserProfile,
  updateUserProfileField,
  updateReportOptionsProfile,
  getAllUsers,
  getAllRoles,
  updateBIOptionsProfile,
};

export const StoreConnector = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withPCTranslation(
    class extends Component {
      constructor() {
        super();
        this.state = {
          screenWidth: window.innerWidth || 0,
          sticky: 'scrollToTop',
          tabHeaderSticky: 'scrollToTop',
          policyHeaderSticky: 'scrollToTop',
          tabsSticky: 'scrollToTop',
        };
      }
      setScreenWidth = () => {
        this.setState({screenWidth: window.innerWidth});
        // setContentHeight();
      };
      toggleSticky = (e) => {
        console.log('ToggleSticky');
        const currentScrollPos = window.pageYOffset;
        let sticky = currentScrollPos > 0 ? 'sticky' : 'scrollToTop';
        if (this.state.sticky !== sticky) {
          this.setState({sticky});
          // setContentHeight();
        }

        /**
         * Tab Header sticky logic
         */
        let tabHeaderOffset = document.getElementById('tabHeader-id')
          ? document.getElementById('tabHeader-id').offsetHeight
          : 0;
        sticky = currentScrollPos > tabHeaderOffset ? 'sticky' : 'scrollToTop';
        if (this.state.tabHeaderSticky !== sticky) {
          this.setState({tabHeaderSticky: sticky});
          // setContentHeight();
        }

        /**
         * Policy Header sticky logic
         */
        let policyHeaderOffset = document.getElementById('policyheader-id')
          ? document.getElementById('policyheader-id').offsetHeight
          : 0;

        let policyHeaderSticky = currentScrollPos > tabHeaderOffset ? 'sticky' : 'scrollToTop';
        // }
        if (this.state.policyHeaderSticky !== policyHeaderSticky) {
          this.setState({policyHeaderSticky});
        }

        /**
         * Tabs sticky logic
         */
        let tabsheaderOffset = document.getElementById('tabheader-id')
          ? document.getElementById('tabheader-id').offsetTop
          : 0;
        // let tabsOffsetTop = document.getElementById('tabsheader-id') ? document.getElementById('tabsheader-id').offsetHeight().top : 0;
        // console.log('Tabs Distance from top: ', tabsOffsetTop - window.scrollTop);
        // console.log('Tabs Header offset:', tabHeaderOffset);
        // console.log('Policy Header Offset: ', policyHeaderOffset);
        // console.log('Tabs Offset: ', tabsheaderOffset);
        let tabsSticky =
          currentScrollPos > policyHeaderOffset + tabHeaderOffset ? 'sticky' : 'scrollToTop';

        if (this.state.tabsSticky !== tabsSticky) {
          this.setState({tabsSticky});
        }
      };

      globalGetFocus = (event, storeFormData, elementItems, updateFormData) => {
        let {
          controlItems,
          interfaceSetupItems,
          updateControlItems,
          auth: {user},
        } = this.props;
        this.props.globalGetFocus(
          event,
          controlItems,
          storeFormData,
          elementItems,
          interfaceSetupItems,
          updateFormData,
          updateControlItems
        );
      };
      globalChange = (event, storeFormData, elementItems, updateFormData) => {
        let {
          controlItems,
          interfaceSetupItems,
          allSetupItems,
          inforceData,
          auth: {user},
          updateControlItems,
          interfaceSetupArray,
        } = this.props;
        this.props.globalChange(
          event,
          user,
          controlItems,
          storeFormData,
          elementItems,
          interfaceSetupItems,
          interfaceSetupArray,
          allSetupItems,
          updateFormData,
          updateControlItems,
          inforceData
        );
      };
      globalClick = (name, storeFormData, elementItems, updateFormData) => {
        let {
          controlItems,
          interfaceSetupItems,
          allSetupItems,
          inforceData,
          auth: {user},
          updateControlItems,
        } = this.props;
        this.props.globalClick(
          name,
          user,
          controlItems,
          storeFormData,
          elementItems,
          interfaceSetupItems,
          allSetupItems,
          updateFormData,
          updateControlItems,
          inforceData
        );
      };
      globalLostFocus = (event, storeFormData, elementItems, updateFormData) => {
        let {
          controlItems,
          interfaceSetupItems,
          allSetupItems,
          inforceData,
          auth: {user},
          updateControlItems,
          interfaceSetupArray,
        } = this.props;
        this.props.globalLostFocus(
          event,
          user,
          controlItems,
          storeFormData,
          elementItems,
          interfaceSetupItems,
          interfaceSetupArray,
          allSetupItems,
          updateFormData,
          updateControlItems,
          inforceData
        );
      };
      onLoad = (storeFormData, elementItems, updateFormData) => {
        let {
          controlItems,
          interfaceSetupItems,
          interfaceSetupArray,
          allSetupItems,
          inforceData,
          updateControlItems,
          auth: {user},
        } = this.props;
        this.props.onLoad(
          user,
          controlItems,
          storeFormData,
          elementItems,
          interfaceSetupItems,
          interfaceSetupArray,
          allSetupItems,
          updateFormData,
          updateControlItems,
          inforceData
        );
      };
      isEapplicationAvailable = () => {
        const {user} = this.props.auth;
        return user?.system?.isEapplication;
      };
      isEapplicationAccessible = () => {
        const {user} = this.props.auth;
          return this.isEapplicationAvailable() && user?.permissions?.isEapplication?.isActive;
      };
      isEapplicationAdministrator = () => {
        const {user} = this.props.auth;
          return this.isEapplicationAvailable() && user?.permissions?.isEapplicationAdministrator?.isActive;
      };
      isConceptsAvailable = () => {
        const {user} = this.props.auth;
        return user?.system?.isConcepts;
      };
      isConceptsAccessible = () => {
        const {user} = this.props.auth;
          return this.isConceptsAvailable() && user?.permissions?.isConcepts?.isActive;
      };
      isNewBusinessAvailable = () => {
        const {user} = this.props.auth;
          return user?.system?.isNewBusiness;
      };
      isNewBusinessAccessible = () => {
        const {user} = this.props.auth;
          return this.isNewBusinessAvailable() && user?.permissions?.isNewBusiness?.isActive;
      };
      isInforceAvailable = () => {
        const {user} = this.props.auth;
        return user?.system?.isInforce;
      };
      isInforceAccessible = () => {
        const {user} = this.props.auth;
          return this.isInforceAvailable() && user?.permissions?.isInforce?.isActive;
      };
      isHeadOffice = () => {
        const {user} = this.props.auth;
        return user?.permissions?.isHeadOffice?.isActive;
      };
      isUserAdmin = () => {
        const {user} = this.props.auth;
        return user?.permissions?.isAdmin?.isActive;
      };
      isUserNewBusinessBatch = () => {
          const { user } = this.props.auth;
          return user?.permissions?.isNewBusinessBatch?.isActive;
      };
      isUserInforceBatch = () => {
          const { user } = this.props.auth;
          return user?.permissions?.isInforceBatch?.isActive;
      };
      isAdminAccessible = () => {
        const { user } = this.props.auth;
        if (!user) return false;

        const { permissions, system } = user;
        return (
          permissions?.isAdmin ||
          (system?.isBusinessIntelligence &&
            permissions?.isBusinessIntelligence?.isActive) ||
          (system?.isAdminSystem &&
            (permissions?.isAdminSystem?.isActive ||
              permissions?.isAdminSystemAdministrator?.isActive))
        );
      };
      isAdminUserManagementAccessible = () => {
        const { user } = this.props.auth;
        if (!user) return false;

        const { permissions, system } = user;

        return (
          permissions?.isAdmin ||
          (system?.isAdminSystem &&
            (permissions?.isAdminSystem?.isActive ||
              permissions?.isAdminSystemAdministrator?.isActive))
        );
      };

      isBusinessIntelligenceAccesible = () => {
        const { user } = this.props.auth;
        if (!user) return false;

        const { permissions, system } = user;
        return (
          system?.isBusinessIntelligence &&
          (permissions?.isAdmin?.isActive ||
            permissions?.isBusinessIntelligence?.isActive ||
            (system?.isAdminSystem &&
              (permissions?.isAdminSystem?.isActive ||
                permissions?.isAdminSystemAdministrator?.isActive)))
        );
      };
      isNewBusinessBatchRunAllowed = () => {
        const { user } = this.props.auth;
        return (
          this.isUserNewBusinessBatch() && user?.system?.isNewBusinessBatch
        );
      };
      isInforceBatchRunAllowed = () => {
        const { user } = this.props.auth;
        return this.isUserInforceBatch() && user?.system?.isInforceBatch;
      };
      isDeveloper = () => {
        const { user } = this.props.auth;
        return user?.permisions?.isDeveloper?.isActive;
      };
      isNeedsAnalysisLite = () => {
        const {user} = this.props.auth;
          return user?.system?.isNeedsAnalysisLite && user?.permissions?.isNeedsAnalysisLite?.isActive;
      };
      isNeedsAnalysisFull = () => {
        const {user} = this.props.auth;
          return user?.system?.isNeedsAnalysisFull && user?.permissions?.isNeedsAnalysisLite?.isActive;
      };
      render() {
        return (
          <MsalProvider instance={this.props.pca}>
            <Router>
              {/* <ScrollToTop /> */}
              {/* <ScrollToTopController /> */}
              <Switch>
                {/* <Route path='/fileviewer' component={FileView} /> */}
                <Route path="/login">
                  <Login
                    isInforce={this.isInforceAccessible}
                    isNewBusiness={this.isNewBusinessAccessible}
                    isHeadOffice={this.isHeadOffice}
                    isAdmin={this.isAdminAccessible}
                  />
                </Route>
                <Route
                  path="/"
                  render={(routeProps) => (
                    <Routes
                      {...this.props}
                      {...routeProps}
                      globalGetFocus={this.globalGetFocus}
                      globalChange={this.globalChange}
                      globalClick={this.globalClick}
                      globalLostFocus={this.globalLostFocus}
                      onLoad={this.onLoad}
                      screenWidth={this.state.screenWidth}
                      sticky={this.state.sticky}
                      isEapplicationAvailable={this.isEapplicationAvailable}
                      isEapplicationAccessible={this.isEapplicationAccessible}
                      isEapplicationAdministrator={this.isEapplicationAdministrator}
                      isInforceAvailable={this.isInforceAvailable}
                      isInforceAccessible={this.isInforceAccessible}
                      isConceptsAvailable={this.isConceptsAvailable}
                      isConceptsAccessible={this.isConceptsAccessible}
                      isNewBusinessAvailable={this.isNewBusinessAvailable}
                      isNewBusinessAccessible={this.isNewBusinessAccessible}
                      isHeadOffice={this.isHeadOffice}
                      isUserAdmin={this.isUserAdmin}
                      isAdminAccessible={this.isAdminAccessible}
                      isAdminUserManagementAccessible={this.isAdminUserManagementAccessible}
                      isBusinessIntelligenceAccesible={this.isBusinessIntelligenceAccesible}
                      isNewBusinessBatchRunAllowed={this.isNewBusinessBatchRunAllowed}
                      isInforceBatchRunAllowed={this.isInforceBatchRunAllowed}
                      isDeveloper={this.isDeveloper}
                      isNeedsAnalysisFull={this.isNeedsAnalysisFull}
                      isNeedsAnalysisLite={this.isNeedsAnalysisLite}
                      policyHeaderSticky={this.state.policyHeaderSticky}
                      tabHeaderSticky={this.state.tabHeaderSticky}
                      tabsSticky={this.state.tabsSticky}
                    />
                  )}
                />
              </Switch>
            </Router>
          </MsalProvider>
        );
      }
      componentDidMount() {
        window.addEventListener('resize', this.setScreenWidth);
        window.addEventListener('scroll', this.toggleSticky);
      }
      componentWillUnmount() {
        window.removeEventListener('resize', this.setScreenWidth);
        window.removeEventListener('scroll', this.toggleSticky);
      }
      // componentDidUpdate(prevProps, prevState) {
      //   if (!this.props.controlItems && this.props.auth.isAuthenticated) {
      //     let {user} = this.props.auth;

      //     //Temporary fix. It gets called multiple times due to change in language and classes on body element.
      //     // if (this.props.setupItems && !this.props.setupItems.isDataLoading) {
      //     //   this.props.loadControlItems();
      //     // }

      //     //console.log("user:", user);
      //     if (user && user.loadProfile === true) {
      //       user.loadProfile = false;
      //       if (user['fontSize'] === 'Large') {
      //         document.body.classList.add('altFont');
      //       } else if (user['fontSize'] === 'Small') {
      //         document.body.classList.remove('altFont');
      //       }
      //     }
      //   }
      // }
    }
  )
);
