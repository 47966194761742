import validator from 'validator';
import {formatDisplay} from '../utils/inputTypeUtils';

//The 'rules' fields will match the ControlItem fields:
//FieldType, UseLimits, Min, Max, Disabled, ShowStatus, LoadText, ShowToolTip, IsError, UseChoiceItems, UseRecallItems, UseOptionItems, UseLinkItems
//ChoiceItems, ToolTipItems, RecallItems, OptionItems, LinkItems, AllowZero, SkipBusinessRules, IncludeInReporting, DecimalPlaces
//---- Extra fields added for React:  true, required
//let temp = 0;
export function ValidateData(data, rules, t, user /*, updateData*/) {
  let errors = {};
  if (!user) {
    return errors;
  }
  let errorCount = 0;
  let msg_range = t('msg-Range');
  let msg_textLength = t('msg-TextLength');
  let msg_checked = t('msg-checked');
  let msg_required = t('msg-required');

  let msg_min = t('msg-min');
  let msg_max = t('msg-max');
  let msg_option = t('msg-Option');
  let msg_validEmail = t('msg-ValidEmail');
  let msg_validPhone = t('msg-ValidPhone');
  //return errors;
  Object.keys(data).forEach((field) => {
    //console.log(`validate field = ${field}`);
    //console.log(`inside custom validation`);
    if (rules.hasOwnProperty(field)) {
      let fielderrors = [];
      //console.log(data);
      let val = data[field];

      //To avoid running runRecalls in case of error for a field
      rules[field].isError = false;
      // if(field==='txtTermRiderAge'){
      //   console.log(temp++)
      // }
      //Checkboxes cause things to blow up so we ignore them if not required
      if (
        // (rules[field].fieldType === "input_Check" ||
        //   rules[field].fieldType === "input_MultiCheck" ||
        //   rules[field].fieldType === "input_List" ||
        //   rules[field].fieldType === "input_Radio" ||
        //   rules[field].fieldType === "input_Group" ||
        //   rules[field].fieldType === "input_Date" ||
        //   rules[field].fieldType === "input_Label" ||
        //   rules[field].fieldType === "input_Combo" ||
        //   rules[field].fieldType === "input_Text" ||
        //   rules[field].fieldType === "input_Number" ||
        //   rules[field].fieldType === "input_Email" ||
        //   rules[field].fieldType === "input_Phone" ||
        //   rules[field].fieldType === "input_Name") &&
        // !rules[field].required ||
        rules[field].disabled ||
        rules[field].displayHidden ||
        rules[field].fieldType === 'input_Date' ||
        rules[field].fieldType === 'input_Files' ||
        rules[field].fieldType === 'input_List' ||
        rules[field].fieldType === 'input_Button'
      ) {
        //do nothing
      } else {
        if (rules[field].fieldType === 'input_Email') {
          if (data['CurrentField'] !== field && rules[field].required) {
            if (validator.isEmpty(val)) {
              fielderrors.push(msg_required);
              rules[field].isError = true;
            } else if (!validator.isEmail(val)) {
              fielderrors.push(msg_validEmail);
              rules[field].isError = true;
            }
          }
        } else if (rules[field].fieldType === 'input_Check') {
          if (!val && rules[field].required) {
            //fielderrors.push("Must be checked");
            fielderrors.push(msg_checked);
            rules[field].isError = true;
          }
        } else if (rules[field].fieldType === 'input_Combo') {
          if (validator.isEmpty(val) && rules[field].required) {
            fielderrors.push(msg_option);
            rules[field].isError = true;
          }
        } else if (rules[field].fieldType === 'input_Radio') {
          if (validator.isEmpty(val) && rules[field].required) {
            fielderrors.push(msg_option);
            rules[field].isError = true;
          }
        } else if (rules[field].fieldType === 'input_Phone') {
          if (data['CurrentField'] !== field) {
            if (validator.isEmpty(val) && rules[field].required) {
              fielderrors.push(msg_required);
              rules[field].isError = true;
            } else if (data[field].length !== rules[field].maxLength && data[field].length > 0) {
              fielderrors.push(msg_validPhone);
              rules[field].isError = true;
            }
          }
        } else {
          if (rules[field].required && validator.isEmpty(val)) {
            fielderrors.push(msg_required);
            rules[field].isError = true;
          }
          if (!validator.isEmpty(val)) {
            if (rules[field].useMinLength) {
              if (rules[field].fieldType === 'input_Text') {
                if (data[field].length < rules[field].minLength && data['CurrentField'] !== field) {
                  fielderrors.push(`${msg_textLength}: ${msg_min}: ${rules[field].minLength} ${msg_max}: ${rules[field].maxLength}`);
                  rules[field].isError = true;
                }
              }
            }
            if (rules[field].useLimits) {
              let minLimit = formatDisplay(user.inputLanguage, rules[field].min, rules[field].fieldType, rules[field].decimalPlaces);
              let maxLimit = formatDisplay(user.inputLanguage, rules[field].max, rules[field].fieldType, rules[field].decimalPlaces);
              if (rules[field].fieldType === 'input_Integer' || rules[field].fieldType === 'input_Dollar_Integer' || rules[field].fieldType === 'input_Number') {
                if (rules[field].allowZero && parseInt(val) === 0) {
                  //Do Nothing
                } else if (!validator.isInt(val, {min: rules[field].min, max: rules[field].max})) {
                  fielderrors.push(`${msg_range}: ${msg_min}: ${minLimit} ${msg_max}: ${maxLimit}`);
                  rules[field].isError = true;
                }
              } else {
                if (rules[field].fieldType === 'input_Real' || rules[field].fieldType === 'input_Dollar' || rules[field].fieldType === 'input_Percent') {
                  if (rules[field].allowZero && parseFloat(val) === 0) {
                    //Do Nothing
                  } else if (!validator.isFloat(val, {min: rules[field].min, max: rules[field].max, decimal_digits: rules[field].decimalPlaces})) {
                    fielderrors.push(`${msg_range}: ${msg_min}: ${minLimit} ${msg_max}: ${maxLimit}`);
                    rules[field].isError = true;
                  }
                }
              }
            }
          }
        }
        if (fielderrors.length > 0) {
          errors[field] = fielderrors;
        }
      }
      errorCount = errorCount + fielderrors.length;
    }
  });
  data['numErrors'] = errorCount;
  if (errorCount > 0) {
    data['lblPremium'] = 'Not Applicable';
  }
  //errors['numErrors'] = errorCount;
  if (sessionStorage.getItem('newBusiness')) {
    let cache = JSON.parse(sessionStorage.getItem('newBusiness'));
    cache['numErrors'] = errorCount;
    sessionStorage.setItem('newBusiness', JSON.stringify(cache));
  }
  //updateData('numErrors', errorCount, 'newBusiness');
  return errors;
}
