import React, {Fragment} from 'react';
import {PillButtons} from 'core/controls';

const ProfileSettings = ({
  controlLostFocus,
  controlChange,
  controlGetFocus,
  setElement,
  formData,
  auth,
}) => {
  const disableApplication = () => {
    if (!auth || !auth.user) {
      return '';
    }

    const {user} = auth;
    if (!user?.permissions?.isNewBusiness?.isActive) {
      return "New Business";
    } else if (!user?.permissions?.isInforce?.isActive) {
      return "Inforce";
    } else {
      return "";
    }
  };

  return (
    <div className="flexContainer twoCol">
      {/* <label className="HEADER-TITLE fontHdr2 paddingMed">Settings</label>
            <div className="flexContainer noCols fullWidth"> */}

      <PillButtons
        text="Input Language"
        getfocusCallback={controlGetFocus}
        getchangeCallback={controlChange}
        lostfocusCallback={controlLostFocus}
        stateData={formData}
        name="rdoInputLanguage"
        refItem={setElement}
        propStyle={{
          label: "fontHdr3",
          control: "radiocontrol buttonPill",
          pillcontainer: "pillcontainer marginSm flexContainer noCols",
          inputcontainer: "inputcontainer marginSm",
          child: "childcontrol",
          pills: "flexContainer",
        }}
      />
      <PillButtons
        text="Report Language"
        getfocusCallback={controlGetFocus}
        getchangeCallback={controlChange}
        lostfocusCallback={controlLostFocus}
        stateData={formData}
        name="rdoReportLanguage"
        refItem={setElement}
        propStyle={{
          label: "fontHdr3",
          control: "radiocontrol buttonPill",
          pillcontainer: "pillcontainer marginSm flexContainer noCols",
          inputcontainer: "inputcontainer marginSm",
          child: "childcontrol",
          pills: "flexContainer",
        }}
      />
      <PillButtons
        text="Font Size"
        getfocusCallback={controlGetFocus}
        getchangeCallback={controlChange}
        lostfocusCallback={controlLostFocus}
        stateData={formData}
        name="rdoFontSize"
        refItem={setElement}
        propStyle={{
          label: "fontHdr3",
          control: "radiocontrol buttonPill",
          pillcontainer: "pillcontainer marginSm flexContainer noCols",
          inputcontainer: "inputcontainer marginSm",
          child: "childcontrol",
          pills: "flexContainer",
        }}
      />
      <PillButtons
        text="Default application at startup"
        getfocusCallback={controlGetFocus}
        getchangeCallback={controlChange}
        lostfocusCallback={controlLostFocus}
        stateData={formData}
        name="rdoDefaultApp"
        refItem={setElement}
        propStyle={{
          label: "fontHdr3",
          control: "radiocontrol buttonPill",
          pillcontainer: "pillcontainer marginSm flexContainer noCols",
          inputcontainer: "inputcontainer marginSm",
          child: "childcontrol",
          pills: "flexContainer",
        }}
        disableChoice={disableApplication()}
      />
    </div>
  );
};

export default ProfileSettings;
