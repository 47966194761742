import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';
import {STYLES} from 'core/constants/uiConstants';
import TabContent from './TabContent';

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.name,
    };
  }

  onClickTabItem = (event, tab, value) => {
    this.setState({activeTab: tab});
    this.props.setActiveTab(event, tab, value);
  };

  render() {
    const {
      onClickTabItem,
      props: {children},
      state: {activeTab},
    } = this;

    return (
      <div className={`${STYLES.TABS}`}>
        <ul className={`${STYLES.TABLIST}`}>
          {children.map((child) => {
            if (child === undefined) {
              return undefined;
            }
            const {name} = child.props;

            return <Tab activeTab={activeTab} key={name} name={name} onClick={onClickTabItem} />;
          })}
        </ul>
        {children.map((child) => {
          if (child === undefined) {
            return undefined;
          }
          const {name, children, styling} = child.props;
          let classNames = `${STYLES.TABCONTENT} ${styling} `;
          if (name === activeTab) classNames += STYLES.TABCONTENTACTIVE;
          return (
            // <TabContent key={label} classes={classNames} content={children}/>
            <div key={name} className={classNames} name={name}>
              {children}
            </div>
          );
        })}
      </div>
    );
  }
}
Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
};

export default Tabs;
