import React from 'react';
import {Provider} from 'react-redux';
import {dataStore} from 'core/redux/store';
import withCoreEvents from 'core/hocs/withCoreEvents';
import {StoreConnector} from './ui/StoreConnector';
const App = (props) => {
  return (
    <Provider store={dataStore}>
      <StoreConnector {...props} />
    </Provider>
  );
};
export default withCoreEvents(App);
