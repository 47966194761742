import {companyLogo, ProcompGreyLogo} from 'assets';
import React from 'react';

const IntroModal3 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <header className="sharedOwnershipHdr">
          <div className="flexContainer alignCenter">
            <img
              id="intro-3"
              className=""
              style={{width: '6rem', height: '6rem'}}
              src={ProcompGreyLogo}
              alt=""
            />
            <h3 className="fontHdrCapitalize textAlignCenter">UNDERSTANDING SHARED OWNERSHIP</h3>
          </div>
          <h1 className="fontHdr1 fontPrimaryColor textAlignCenter">
            Shared Ownership of a Universal Life Policy
          </h1>
        </header>
        <section className="sharedOwnershipContainer noWrap">
          <section className="flexContainer threeCol marginLg">
            <p className="paddingLg boxShadowAll textAlignCenter flexContainer justifyCenter alignCenter fontMed">
              This concept is available to all Cost of Insurance options with Increasing Death
              Benefit
            </p>
            <p className="paddingLg boxShadowAll textAlignCenter flexContainer justifyCenter alignCenter fontMed">
              Policy should be maximum-funded
            </p>
            <p className="paddingLg boxShadowAll textAlignCenter flexContainer justifyCenter alignCenter fontMed">
              Premiums and the death benefit are split during expected term of agreement (Employer
              Benefit Period)
            </p>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal3;
