import React, {Component} from 'react';
import StatusContext from './StatusContext';
import {withPCTranslation} from 'core/hocs';
import {formatDisplay} from 'core/utils/inputTypeUtils';

class FormStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {},
      dirty: {},
      formSubmitted: true,
      getStatusForField: this.getStatusForField,
    };
  }

  //This runs when FormValidator is instantiated and rendered
  static getDerivedStateFromProps(props, state) {
    state.status = {};

    //This condition prevents unusual crash occurs due to session timeout. Example: "Cannot read property 'inputLanguage' of null"
    if (!props.user) {
      return state;
    }
    if (props.data && props.data['CurrentField'] === '') {
      //do nothing
    } else {
      if (!(props.controlItems === undefined)) {
        let rulesData = props.controlItems[props.data['CurrentField']];
        if (!(rulesData === undefined)) {
          //In case rulesData has not loaded yet
          state.status['current'] = props.t(`${props.data['CurrentField']}-status`);
          //state.status['current'] = rulesData.statusText;
          // if (rulesData.useLimits) {
          //   state.status['current'] = `${state.status['current']}  -  Limits: ${
          //     rulesData.min
          //   } - ${rulesData.max}  `;
          // }

          if (rulesData.useLimits) {
            //props.user need to be checked for case when session logs out.
            const status = props.t(`${props.data['CurrentField']}-status`);
            const limits = props.t('msg-Limits');
            const field = `${props.data['CurrentField']}`;
            let minLimit = formatDisplay(
              props.user.inputLanguage,
              props.controlItems[field].min,
              props.controlItems[field].fieldType,
              props.controlItems[field].decimalPlaces
            );
            let maxLimit = formatDisplay(
              props.user.inputLanguage,
              props.controlItems[field].max,
              props.controlItems[field].fieldType,
              props.controlItems[field].decimalPlaces
            );

            state.status['current'] = `${status} - ${limits}: ${minLimit} - ${maxLimit}  `;
          }
        }
      }
    }

    return state;
  }

  get formValid() {
    return Object.keys(this.state.errors).length === 0;
  }

  handleGetFocus = (ev) => {
    ev.persist();
    let name = ev.target.name;
    this.setState((state) => (state.dirty[name] = true));
    //this.setState(state => state.status = {});
  };

  handleLostFocus = (ev) => {
    ev.persist();
    let name = ev.target.name;
    this.setState((state) => (state.status[name] = ''));
  };

  getStatusForField = (field) => {
    if (field === '') {
      return this.state.status;
    } else {
      return this.state.formSubmitted || this.state.dirty[field] || true ? this.state.status[field] || [] : [];
    }
  };

  render() {
    return (
      <React.Fragment>
        <StatusContext.Provider value={this.state}>
          <div className={this.props.styling} onFocus={this.handleGetFocus} onBlur={this.handleLostFocus}>
            {this.props.children}
          </div>
        </StatusContext.Provider>
      </React.Fragment>
    );
  }
}

export default withPCTranslation(FormStatus);
