import {
  arrow45MoneyLogo,
  arrowStraightMoneyLogo,
  companyLogo,
  corpLogo,
  execLogo,
  ProcompGreyLogo,
  purpleExecCorpLogo,
} from 'assets';
import React from 'react';

const IntroModal7 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <header className="sharedOwnershipHdr">
          <div className="flexContainer alignCenter">
            <img
              id="intro-7"
              className=""
              style={{width: '6rem', height: '6rem'}}
              src={ProcompGreyLogo}
              alt=""
            />
            <h3 className="fontHdrCapitalize textAlignCenter">HOW THE CONCEPT WORKS</h3>
          </div>
          <h1 className="fontHdr1 fontPrimaryColor textAlignCenter">
            Death Benefit Rights - Pre-retirement
          </h1>
        </header>

        <section className="sharedOwnershipContainer flexContainer threeCol noWrap">
          <section className="">
            <h3 className="fontHdr2 textAlignCenter">Executive dies</h3>
            <p className="fontHdr2 textAlignCenter fontBold">During Benefit Period</p>
            <img id="purple-exec-corp-7" src={purpleExecCorpLogo} alt="RetireBrief" />
            <h3 className="fontHdr2 textAlignCenter bgWhite boxShadowAll paddingLg">
              Universal Life Policy
            </h3>
          </section>
          <section className="flexContainerColumn justifyCenter">
            <div
              className="flexContainer noCols paddingMed marginMed"
              style={{marginBottom: '4rem'}}>
              <img id="arrow-45-money" src={arrow45MoneyLogo} alt="arrow45Money" />
              <div className="fontBold">
                <p className="fontBold fontPrimaryColor">Executive's Heirs</p>
                <p className="fontBold fontPrimaryColor">(or Estate)</p>
              </div>
            </div>
            <div className="flexContainer noCols paddingMed marginMed">
              <img
                id="arrow-straight-money"
                src={arrowStraightMoneyLogo}
                alt="arrowStraightMoney"
              />
              <p className="fontBold fontErrorColor">Corporation</p>
            </div>
          </section>
          <section className="flexContainerColumn justifyCenter">
            <div className="flexContainer noCols alignCenter">
              <img id="exec-7" src={execLogo} alt="RetireBrief" />
              <h3 className="fontHdr2 fontPrimaryColor textAlignCenter bgWhite boxShadowAll paddingLg">
                Account Value LESS any Cost Recovery
              </h3>
            </div>
            <section>
              <div className="flexContainer noCols alignCenter">
                <img id="corp-7" src={corpLogo} alt="RetireBrief" />
                <h3 className="fontHdr2 fontErrorColor textAlignCenter bgWhite boxShadowAll paddingLg">
                  Amount of Insurance at Risk PLUS any Cost Recovery
                </h3>
              </div>
            </section>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal7;
