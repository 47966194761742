import React, {Fragment, useContext} from 'react';
import {useSelector} from 'react-redux';
import {usePCTranslation} from 'core/hooks';
import ValidationContext from './ValidationContext';
import {Icon} from 'core/uicore';
import {ICONS, STYLES} from 'core/constants/uiConstants';
import ValidationMessage from './ValidationMessage';

const ErrorDisplay = ({propStyle: {container, header, summary}, useIcon, icon}) => {
  const {errors} = useContext(ValidationContext);
  const {t} = usePCTranslation();
  const controlItems = useSelector((state) => state.setupItems.controlItems);
  let dirtyCount = 0;

  Object.keys(errors).forEach((obj) => {
    if (controlItems[obj].isDirty) {
      dirtyCount++;
    }
  });
  if (dirtyCount <= 0) {
    return <Fragment></Fragment>;
  }
  return (
    <div className={container}>
      <div className={`${STYLES.BASIC} ${STYLES.HEADER} ${header}`}>
        <Icon icon={ICONS.ERROR} size={18} />{' '}
        <span dataseleniumid='errorheader'>
          {dirtyCount} {dirtyCount === 1 ? t('msg-Error') : t('msg-Errors')}
        </span>
      </div>

      <div>{dirtyCount === 1 ? t('msg-ErrormessageSingular') : t('msg-ErrormessagePlural')}</div>

      {dirtyCount === 0 ? (
        <div className={`${STYLES.SUMMARYCONTAINER}`}>
          <div className={`${STYLES.BASIC} ${STYLES.SUMMARY} ${summary}`}>{t('msg-NoErrors')}</div>
        </div>
      ) : (
        <div className={`${STYLES.SUMMARYCONTAINER}`}>
          <ValidationMessage
            id='errorSummary'
            field=''
            useIcon={useIcon}
            icon={icon}
            propStyle={{
              validationmsg: 'errordisplayvalidation',
              erroricon: 'erroricon',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ErrorDisplay;
