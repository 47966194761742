import React, {useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {STYLES} from 'core/constants/uiConstants';
import {ValidationContext} from 'core/formValidation';
import {usePCTranslation} from 'core/hooks';

const Button = ({
  getclickCallback,
  getfocusCallback,
  lostfocusCallback,
  autoFocus,
  name,
  refItem,
  propStyle,
  logo,
  logoRight,
  showIcon,
  showIconRight,
  disableButton,
  isActive,
  type,
  allowPropagation,
  //t
}) => {
  const {errors} = useContext(ValidationContext);
  const {t} = usePCTranslation();
  const {controlItem} = useSelector((state) => ({
    controlItem: state.setupItems.controlItems ? state.setupItems.controlItems[name] : null,
  }));
  // const {tInput} = useTranslation();
  // const [buttonText, setButtonText] = useState("")
  // useEffect(() => {
  //   setButtonText(t(`${name}-label`));
  //   console.log(buttonText)
  // }, []);
  // console.log(buttonText);
  let isDisabled = false;
  //This condition is to disable buttons(like viewReport) in case of field level validation failed.
  if (controlItem && controlItem.disableOnError && Object.keys(errors).length > 0) {
    isDisabled = true;
  } else {
    isDisabled = disableButton;
  }
  // temp local variable
  if (controlItem && controlItem.displayDisabled) {
    isDisabled = controlItem.displayDisabled;
  }
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setDisabled(isDisabled);
  }, [isDisabled]);

  const controlOnClick = (event) => {
    event.persist();
    //the event.target.value is the after-change value here but...
    getclickCallback(event, name, event.target.value);
    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    if (controlItem && controlItem.delayTime > 0 && !isDisabled) {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, controlItem.delayTime);
    }
  };
  const controlOnFocus = (event) => {
    getfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    if (!allowPropagation) {
      event.stopPropagation();
    }
  };

  const controlOnLostFocus = (event) => {
    lostfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    if (!allowPropagation) {
      event.stopPropagation();
    }
  };

  let containerClass = `${STYLES.BASIC} ${STYLES.BUTTON} ${propStyle.control}`;
  if (disabled) {
    containerClass += ' button-disable';
  }
  if (controlItem && controlItem.displayHidden) {
    containerClass += ' hiddenField';
  }
  if (isActive) {
    containerClass += ' activeButton';
  }

  const convertUnicode = (input) => {
    return input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
      var charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });
  };

  return (
    <button
      className={containerClass}
      autoFocus={autoFocus}
      onFocus={controlOnFocus}
      onBlur={controlOnLostFocus}
      onClick={controlOnClick}
      name={name}
      id={name}
      ref={refItem}
      type={type}
      disabled={disabled || isActive}>
      {showIcon && (
        <img
          className={logo.classLogo}
          name={name} // Cause of report button not working issue
          src={logo.srcLogo}
          alt={logo.altLogo}
          // onClick={controlOnClick}
          type="button"
        />
      )}
      {t(`${name}-label`)}
      {showIconRight && (
        <img
          className={logoRight.classLogo}
          name={name}
          src={logoRight.srcLogo}
          alt={logoRight.altLogo}
          // onClick={controlOnClick}
          type="button"
          disabled={disabled || isActive}
        />
      )}
      {/* <span name={name}>{t(`${name}-label`)}</span> */}
    </button>
  );
};

Button.defaultProps = {
  autoFocus: false,
  showIcon: false,
  showIconRight: false,
  disableButton: false,
  type: 'button',
  isActive: false,
  allowPropagation: false,
};
export {Button};
