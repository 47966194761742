import {addScalarItem, buildScalarFromControlItems, getIllustrationNumber, getScalarItem} from 'core/report/reportUtils';
import {globalVariables, toProperCase, isEmpty} from 'core/utils/util';
import {
  isProductPar,
  IsProductParticipatingTerm,
  IsProductNonParticipatingTerm,
  IsProductParticipatingT100,
  IsProductNonParticipatingT100,
  IsProductCurrentPremiumT100,
  Translate_RRIF_Code,
  TextLookup_PolicyName,
  format_Display_Date,
  formatDollar,
  TextLookup_PolicyCStatCode,
  TextLookup_PremiumModePolicy,
  booleanToString,
  IsProductPremOffset,
  isProdIDProPlan,
  IsProdIDFlexLife2,
  FormatPhoneNumber,
  FormatMailingAddress1,
  FormatMailingAddress2,
  FormatMailingAddress3,
  formatIsAmountAboveZero,
  GetFullName,
  fullName,
  getCurrentDate,
  isInPayout,
  format_Display_Date_Short,
  isProductHealth,
  isProdIDSOSBAEndowment,
  isPolicyMaturing,
  Format0Decimals,
  Format2Decimals,
  TextLookup_PremiumModeULReport,
  format_Display_Date_Object,
  TextLookup_PremiumModeReport,
  isPolicyPaidUp,
  formatInterestRate,
  TextLookup_NFOption,
  TextLookup_DividendPayout,
  TextLookup_PayoutFrequency,
  TextLookup_PayoutMethodCode,
  TextLookup_PayoutType,
  isProductLIF,
  Translate_NextPaymentDate,
  FormatChurchName,
  GetFullNameWithSalutation,
  GetBeneficaryString,
  isProductADBPlus,
  isInPremiumOffset,
  isPremiumOffsetSelected,
  IsProductExcludeRPU,
  isProdIDSOSBA,
  isProductEssentialLife,
  modalFactor,
  calculateAgeFromJan1,
} from 'custom/common/utils/lookupFunctions';
import {getCoverageItemByType, GetColumnNumber, getCoverageByID} from 'core/utils/calcUtils';
import {
  CoverageTypes,
  ExemptStatus,
  DividendPayoutTypes,
  PolicyTypes,
  TaxAccrualRule,
  BillingMethodTypes,
  AssigneeTypes,
  SpouseContributorTypes,
  PayoutTypes,
  PayoutInsuredTypes,
  DIVIDEND_ALTERNATESCALE,
  Alternate_IntRate,
  ALTERNATE_COI_INCREASE,
  ReportColumns,
  SOSBA_MONTHS_TO_MATURITY,
} from 'core/constants/calcConstants';
// import {REPORT_SETTING} from 'core/report/constants';
import {GetPhoneItem, GetAddressItem, BuildSummaryPolicyExpiryColumnText, BuildSummaryColumnText, GetSkipPaymentYear, BuildInvestmentAllocationScalars} from '../utils/inforceUtils';
import {ScalarTypes, REPORT_SETTING} from 'core/constants/reportConstants';
import {phoneTypes, addressTypes} from '../utils/constants';

//Custom - inforce
export const buildScalarOutput = (controlItems, formData, insuranceData, inforceData, isStatus, user, t, reportBuildModel) => {
  let scalarReportArray = [];
  let aGlobalVariable;
  let intYear;
  let intYearCol;

  console.log('inforceData', inforceData);
  console.log('formData', formData);

  // //New Business Scalars
  // scalarReportArray.push(this.addScalarItem("Policy Type Choice", 1, ScalarTypes.SCALAR_LONGINT));
  // return scalarReportArray;

  //Core - Move this to core function for Report
  buildScalarFromControlItems(controlItems, scalarReportArray, formData, user, t);

  //Adding Custom Scalars
  //ToDo separate out core code above from this custom code
  console.log('starting custom inforce scalars');
  console.log('globalVariables', globalVariables);
  console.log('insuranceData.globalVariables', insuranceData.globalVariables);
  console.log('formData', formData);

  //Inforce Scalars
  let inforcePolicy = inforceData.inforcePolicy;
  let aBaseCoverage = getCoverageItemByType(inforcePolicy.coverages, CoverageTypes.BASE);

  scalarReportArray.push(addScalarItem('Report All Years', !formData['chkSelectDuration'], ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('Illustration Number', 'PPR# ' + inforcePolicy.policyNumber + formData['txtIllustrationNumber'], ScalarTypes.SCALAR_STRING)); //ToDo: add format(now, yyyyMMddHHmmss")
  scalarReportArray.push(addScalarItem('Policy Number', inforcePolicy.policyNumber, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Policy Type Footer', 'Life - PPR', ScalarTypes.SCALAR_STRING));

    if (!isEmpty(insuranceData.globalVariables))
    {
        aGlobalVariable = getScalarItem(insuranceData.globalVariables, 'ISLOANLAPSE');
        if (!isEmpty(aGlobalVariable)) {
            scalarReportArray.push(addScalarItem('Is Loan Lapse', aGlobalVariable.booleanValue, ScalarTypes.SCALAR_BOOLEAN));
        }
    }

  scalarReportArray.push(
    addScalarItem('Is Par', booleanToString(isProductPar(inforcePolicy.policyType, inforcePolicy.prodID) && inforcePolicy.dividendPayoutType !== DividendPayoutTypes.None), ScalarTypes.SCALAR_BOOLEAN)
  );

  console.log('inforcepolicy = ', inforcePolicy);
  scalarReportArray.push(
    addScalarItem('Is Premium Offset', booleanToString(IsProductPremOffset(inforcePolicy, controlItems, formData) && inforcePolicy.premiumOffsetPluginName != ''), ScalarTypes.SCALAR_BOOLEAN)
    );

    scalarReportArray.push(
        addScalarItem('Is In Premium Offset', booleanToString(isInPremiumOffset(inforcePolicy)) , ScalarTypes.SCALAR_BOOLEAN)
    );

    
  scalarReportArray.push(addScalarItem('Plan Title', aBaseCoverage.name, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Is FlexLife2', IsProdIDFlexLife2(inforcePolicy.prodID), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('Is ProPlan', booleanToString(isProdIDProPlan(inforcePolicy.prodID)), ScalarTypes.SCALAR_BOOLEAN));

  scalarReportArray.push(addScalarItem('Agent Name', user.profileName, ScalarTypes.SCALAR_STRING));

  scalarReportArray.push(addScalarItem('Agent Phone', FormatPhoneNumber(user.phoneNumberPrimary), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Agent Email', user.emailAddress, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Agent Fax', user.faxNumber, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Agent Phone(Secondary)', FormatPhoneNumber(user.phoneNumberSecondary), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Owner', inforcePolicy.owners[0].name, ScalarTypes.SCALAR_STRING));
  if (IsProdIDFlexLife2(inforcePolicy.prodID)) {
      if (!isEmpty(insuranceData.globalVariables)) {
          BuildInvestmentAllocationScalars(scalarReportArray, inforceData.policy, formData, insuranceData);
      }
  }
  let strInPayout = '';
  if (inforcePolicy.policyType === PolicyTypes.RRIF || inforcePolicy.policyType === PolicyTypes.SegregatedFundRRIF) {
    scalarReportArray.push(addScalarItem('Policy Type', TextLookup_PolicyName(Translate_RRIF_Code(inforcePolicy.prodID), t), ScalarTypes.SCALAR_STRING));
  } else {
    if (isInPayout(inforcePolicy)) {
      strInPayout = '    (In Payout)';
    }
    scalarReportArray.push(addScalarItem('Policy Type', TextLookup_PolicyName(inforcePolicy.planCode, t) + strInPayout, ScalarTypes.SCALAR_STRING));
  }

  scalarReportArray.push(addScalarItem('Illustration Date', format_Display_Date(inforcePolicy.illustrationDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Illustration Date Short', format_Display_Date_Short(inforcePolicy.illustrationDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Current Data Date', format_Display_Date(inforceData.currentDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Current Data Date Short', format_Display_Date_Short(inforceData.currentDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Current Date', format_Display_Date(getCurrentDate()), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Transaction Start Date', format_Display_Date(inforcePolicy.transactionStartDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Version', '1.0.0', ScalarTypes.SCALAR_STRING));
  let aDec21982Date = new Date(1982, 12, 2);
  let aIssueDate = new Date(inforcePolicy.issueDate);
  scalarReportArray.push(addScalarItem('Is Before Dec 2 1982', aIssueDate < aDec21982Date, ScalarTypes.SCALAR_BOOLEAN));

  //For the tax comments on the PPR_Status report we are going to break it down to 2 switches.
  //  1. IsShowTaxText (true/false).  This will hide/show the tax text memo field
  //  2. TaxTextID (numeric). This will dictate which text will show using report logic
  let isShowTaxText = false;

  if (inforcePolicy.isRegistered) {
    isShowTaxText = true;
    scalarReportArray.push(addScalarItem('TaxTextID', 1, ScalarTypes.SCALAR_INTEGER));
  } else {
    if (isProductHealth(inforcePolicy.prodID)) {
      isShowTaxText = true;
      scalarReportArray.push(addScalarItem('TaxTextID', 0, ScalarTypes.SCALAR_INTEGER));
    } else {
      switch (inforcePolicy.exemptStatus) {
        case ExemptStatus.Grandfathered:
          isShowTaxText = true;
          scalarReportArray.push(addScalarItem('TaxTextID', 2, ScalarTypes.SCALAR_INTEGER));
          break;
        case ExemptStatus.ExemptWithTesting:
        case ExemptStatus.ExemptWithoutTesting:
        case ExemptStatus.ExemptWithoutTestingNew:
          isShowTaxText = false;
          break;
        case ExemptStatus.NonExempt:
        case ExemptStatus.NonExemptNew:
          switch (inforcePolicy.taxAccrualRule) {
            case TaxAccrualRule.None:
              isShowTaxText = true;
              scalarReportArray.push(addScalarItem('TaxTextID', 3, ScalarTypes.SCALAR_INTEGER));
              break;
            case TaxAccrualRule.Trienniel:
              isShowTaxText = true;
              scalarReportArray.push(addScalarItem('TaxTextID', 4, ScalarTypes.SCALAR_INTEGER));
              break;
            case TaxAccrualRule.CalendarYear:
            case TaxAccrualRule.PolicyYear:
              isShowTaxText = true;
              scalarReportArray.push(addScalarItem('TaxTextID', 5, ScalarTypes.SCALAR_INTEGER));
              break;
            default:
              isShowTaxText = true;
              scalarReportArray.push(addScalarItem('TaxTextID', 3, ScalarTypes.SCALAR_INTEGER));
              break;
          }
          break;
        default:
          isShowTaxText = true;
          scalarReportArray.push(addScalarItem('TaxTextID', 2, ScalarTypes.SCALAR_INTEGER));
          break;
      }
    }
  }

  scalarReportArray.push(addScalarItem('isShowTaxText', booleanToString(isShowTaxText), ScalarTypes.SCALAR_BOOLEAN));

  let showSumInsured = (aBaseCoverage.isUseDeathBenefitRateTable || (isProdIDSOSBAEndowment(aBaseCoverage.prodID)) && !isPolicyMaturing(inforcePolicy, SOSBA_MONTHS_TO_MATURITY)) && aBaseCoverage.sumInsured !== aBaseCoverage.faceAmount;
  scalarReportArray.push(addScalarItem('is show sum insured', booleanToString(showSumInsured), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('Dividend - Alternate Scale', DIVIDEND_ALTERNATESCALE, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend - Int Rate Reduction', Format0Decimals(Alternate_IntRate.Reduction), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Dividend - Int Rate Red 2 Dec', Format2Decimals(Alternate_IntRate.Reduction), ScalarTypes.SCALAR_STRING));

  //UL Scalars
  if (inforcePolicy.policyType === PolicyTypes.UL) {
    if (!isStatus) {
      intYearCol = GetColumnNumber('CalendarYear', insuranceData.columnItems);
      aGlobalVariable = getScalarItem(insuranceData.globalVariables, 'BP');
      if (!isEmpty(aGlobalVariable)) {
        intYear = insuranceData.dblTableData_Main[aGlobalVariable.integerValue][intYearCol];
        scalarReportArray.push(addScalarItem('UL - Policy End Year', intYear, ScalarTypes.SCALAR_INTEGER));
      }
      intYearCol = GetColumnNumber('ALT_CalendarYear', insuranceData.columnItems);

      aGlobalVariable = getScalarItem(insuranceData.globalVariables, 'ALT_BP');
      if (!isEmpty(aGlobalVariable)) {
        intYear = insuranceData.dblTableData_Main[aGlobalVariable.integerValue][intYearCol];
        scalarReportArray.push(addScalarItem('UL - Policy End Year ALT', intYear, ScalarTypes.SCALAR_INTEGER));
      }

      if (formData['chkUseClientRates'] === true) {
        aGlobalVariable = getScalarItem(insuranceData.globalVariables, 'ALT2_BP');
        if (!isEmpty(aGlobalVariable)) {
          intYearCol = GetColumnNumber('ALT2_CalendarYear', insuranceData.columnItems);
          intYear = insuranceData.dblTableData_Main[aGlobalVariable.integerValue][intYearCol];
          scalarReportArray.push(addScalarItem('UL - Policy End Year ALT2', intYear, ScalarTypes.SCALAR_INTEGER));
        }
      }
    }

    scalarReportArray.push(addScalarItem('UL - Int Rate Reduction', Format0Decimals(Alternate_IntRate.Reduction_UL), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('UL - Int Rate Red 2 Dec', Format2Decimals(Alternate_IntRate.Reduction_UL), ScalarTypes.SCALAR_STRING));

    scalarReportArray.push(addScalarItem('UL - COI Increase', Format0Decimals(ALTERNATE_COI_INCREASE * 100), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('Premium Frequency UL Report', TextLookup_PremiumModeULReport(inforcePolicy.premiumMode, inforcePolicy.billingTypeCode, t), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('Mode Deposit Amount', formatDollar(inforcePolicy.totalModalPremium.toFixed(2)), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('UL - Deposits This Year', formatDollar(inforcePolicy.ulDepositsThisYear), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('UL - Paid To Date', format_Display_Date(inforcePolicy.ulPaidToDate), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('UL - Last Deposit Date', format_Display_Date(inforcePolicy.ulLastDepositDate), ScalarTypes.SCALAR_STRING));
    //ToDo: Check to see if this is supposed to be current date or current data date
    let currentDate = getCurrentDate();
    let lastDepositDate = new Date(inforcePolicy.ulLastDepositDate);
    let isLastDate = lastDepositDate < currentDate;
    scalarReportArray.push(addScalarItem('UL - Last Deposit Date Passed', booleanToString(isLastDate), ScalarTypes.SCALAR_BOOLEAN));

    scalarReportArray.push(addScalarItem('Last Anniversary Date', format_Display_Date(inforcePolicy.illustrationDate), ScalarTypes.SCALAR_STRING));

    let nextAnniversary = new Date(inforcePolicy.illustrationDate);
    nextAnniversary.setFullYear(nextAnniversary.getFullYear() + 1);
    scalarReportArray.push(addScalarItem('Next Anniversary Date', format_Display_Date_Object(nextAnniversary), ScalarTypes.SCALAR_STRING));

    scalarReportArray.push(addScalarItem('UL - Max Deposit This Year', formatDollar(inforcePolicy.ulMaxDepositThisYear), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('UL - Max Deposit Next Year', formatDollar(inforcePolicy.ulMaxDepositThisYear), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('UL - Level Exempt Deposit', formatDollar(0), ScalarTypes.SCALAR_STRING));
    //Remaining Max Deposit = Maximum Deposit for the year - FPPRPolicy.ulDepositsThisYear
    scalarReportArray.push(addScalarItem('UL - Remaining Max Deposit', formatDollar(inforcePolicy.ulMaxDepositThisYear - inforcePolicy.ulDepositsThisYear), ScalarTypes.SCALAR_STRING));
  }

  scalarReportArray.push(addScalarItem('ProdName', inforcePolicy.prodName.trim(), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('ProdID', inforcePolicy.prodID, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Policy Status Code', inforcePolicy.policyStatusCode, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Policy Status CStatCode', inforcePolicy.policyCStatCode, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Policy Status', TextLookup_PolicyCStatCode(inforcePolicy.policyCStatCode, t), ScalarTypes.SCALAR_STRING));

  scalarReportArray.push(addScalarItem('Total Annualized Premium', inforcePolicy.totalAnnualizedPremium, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Total Modal Premium', inforcePolicy.totalModalPremium, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Premium Frequency', TextLookup_PremiumModePolicy(inforcePolicy, t), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Premium Mode', TextLookup_PremiumModeReport(inforcePolicy.premiumMode, t), ScalarTypes.SCALAR_STRING));

  scalarReportArray.push(addScalarItem('Expiry Date', format_Display_Date(inforcePolicy.expiryDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Terminated Date', format_Display_Date(inforcePolicy.terminatedDate), ScalarTypes.SCALAR_STRING));

  if (isPolicyPaidUp(inforcePolicy)) {
    scalarReportArray.push(addScalarItem('Paid To Date', 'N/A', ScalarTypes.SCALAR_STRING));
  } else {
    scalarReportArray.push(addScalarItem('Paid To Date', format_Display_Date(inforcePolicy.paidToDate), ScalarTypes.SCALAR_STRING));
  }

  scalarReportArray.push(addScalarItem('Bill To Date', format_Display_Date(inforcePolicy.billToDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Next Monthiversary Date', format_Display_Date(inforcePolicy.nextMonthiversaryDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Policy Value', inforcePolicy.policyValue, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Is Loan Available', booleanToString(inforcePolicy.isLoanAvailable), ScalarTypes.SCALAR_BOOLEAN));

  if (inforcePolicy.isRegistered || !inforcePolicy.isLoanAvailable) {
    scalarReportArray.push(addScalarItem('Available Loan', 'Not Permitted on this Policy', ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('Available Loan Amount', formatDollar(0), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('Available Loan Value', 0, ScalarTypes.SCALAR_DOUBLE));
  } else {
    scalarReportArray.push(
      addScalarItem('Available Loan', formatDollar(inforcePolicy.availableLoan) + '     Int Rate: ' + formatInterestRate(inforcePolicy.availableLoanInterestRate), ScalarTypes.SCALAR_STRING)
    );
    scalarReportArray.push(addScalarItem('Available Loan Amount', formatDollar(inforcePolicy.availableLoan), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('Available Loan Value', inforcePolicy.availableLoan, ScalarTypes.SCALAR_DOUBLE));
  }

  scalarReportArray.push(addScalarItem('Available Loan Interest Rate', inforcePolicy.availableLoanInterestRate, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Non Forfeiture Option', TextLookup_NFOption(inforcePolicy.nonForfeitureOption || '', t), ScalarTypes.SCALAR_STRING));

  if (inforcePolicy.dividendPayoutType === DividendPayoutTypes.Enhanced) {
    scalarReportArray.push(
      addScalarItem('Dividend Option', TextLookup_DividendPayout(inforcePolicy.dividendPayoutType, t) + '  ' + formatDollar(inforcePolicy.dividendEnhancedAmount), ScalarTypes.SCALAR_STRING)
    );
  } else {
    scalarReportArray.push(addScalarItem('Dividend Option', TextLookup_DividendPayout(inforcePolicy.dividendPayoutType, t), ScalarTypes.SCALAR_STRING));
  }

  scalarReportArray.push(addScalarItem('Dividend Payout Type', inforcePolicy.dividendPayoutType, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend Type - None', DividendPayoutTypes.None, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend Type - PUA', DividendPayoutTypes.PUA, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend Type - DRP', DividendPayoutTypes.DRP, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend Type - DOD', DividendPayoutTypes.DOD, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend Type - Enhanced', DividendPayoutTypes.Enhanced, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend Type - LRD', DividendPayoutTypes.LRD, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend Type - PIC', DividendPayoutTypes.PIC, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend Type - OYT', DividendPayoutTypes.OYT, ScalarTypes.SCALAR_INTEGER));

  scalarReportArray.push(addScalarItem('Premium Refund', inforcePolicy.premiumRefund, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Draw Day', inforcePolicy.drawDay, ScalarTypes.SCALAR_INTEGER));

  scalarReportArray.push(addScalarItem('Billing Type Code', inforcePolicy.billingTypeCode, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Billing Method', inforcePolicy.billingMethod, ScalarTypes.SCALAR_INTEGER));

  scalarReportArray.push(addScalarItem('Billing Method - EFT', BillingMethodTypes.EFT, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Billing Method - Mail', BillingMethodTypes.Mail, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Billing Method - Bill Error', BillingMethodTypes.BillError, ScalarTypes.SCALAR_INTEGER));

  switch (inforcePolicy.billingMethod) {
    case BillingMethodTypes.EFT:
      scalarReportArray.push(addScalarItem('Billing Method String', 'pre-authorized debit', ScalarTypes.SCALAR_STRING));
      break;
    case BillingMethodTypes.Mail:
      scalarReportArray.push(addScalarItem('Billing Method String', 'cheque', ScalarTypes.SCALAR_STRING));
      break;
    case BillingMethodTypes.BillError:
      scalarReportArray.push(addScalarItem('Billing Method String', t('unknown'), ScalarTypes.SCALAR_STRING));
      break;
    default:
      scalarReportArray.push(addScalarItem('Billing Method String', 'pre-authorized debit', ScalarTypes.SCALAR_STRING));
      break;
  }

  scalarReportArray.push(addScalarItem('Billing Status', inforcePolicy.billingStatus, ScalarTypes.SCALAR_STRING));

  scalarReportArray.push(addScalarItem('Minimum Premium', inforcePolicy.minPrem, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Is Active', booleanToString(inforcePolicy.isActive), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('Is Vanish Prem', booleanToString(inforcePolicy.isVanishPrem), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('Is Registered', booleanToString(inforcePolicy.isRegistered), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('Is Restrict Premium', booleanToString(inforcePolicy.isRestrictPremium), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('DIA Value', inforcePolicy.diaValue, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('DIA Interest Rate', inforcePolicy.diaInterestRate * 100, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('DIA Interest Rate - ALT', inforcePolicy.diaInterestRate * 100 - Alternate_IntRate.Reduction_UL, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('GIA Value', inforcePolicy.giaValue, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Indexed Fund Value', inforcePolicy.indexedFundValue + inforcePolicy.segFundValue, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Indexed Fund Value Date', format_Display_Date_Short(inforcePolicy.indexedFundValueDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('SegFund Value', inforcePolicy.segFundValue, ScalarTypes.SCALAR_DOUBLE));

  scalarReportArray.push(addScalarItem('PDF Balance', inforcePolicy.pdfBalance, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('PDF Balance Text', formatDollar(inforcePolicy.pdfBalance), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('PDF Interest Rate', inforcePolicy.pdfInterestRate * 100, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('DOD Balance', inforcePolicy.dodBalance, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('DOD Interest Rate', inforcePolicy.dodInterestRate * 100, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Side Fund Balance', inforcePolicy.sideFundBalance, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Side Fund Interest Rate', inforcePolicy.sideFundInterestRate * 100, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Loan Balance', inforcePolicy.loanBalance, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Loan Balance Text', formatDollar(inforcePolicy.loanBalance), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Loan Interest Rate', inforcePolicy.loanInterestRate * 100, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Loan Interest Rate Text', formatInterestRate(inforcePolicy.loanInterestRate), ScalarTypes.SCALAR_STRING));
  //why is inforcePolicy.loanInterestRateType coming in as null??
  //scalarReportArray.push(addScalarItem("Loan Interest Rate Type", inforcePolicy.loanInterestRateType, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('MVA', inforcePolicy.mva, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Surrender Charge', inforcePolicy.surrenderCharge, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Cash Surrender Value', inforcePolicy.cashSurrenderValue, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('PUA Cash Value', inforcePolicy.puaCashValue, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('PUA Face Amount', inforcePolicy.puaFaceAmount, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Term Additions Face Amount', Math.max(inforcePolicy.dividendEnhancedAmount - inforcePolicy.puaFaceAmount, 0), ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Basic Cash Value', inforcePolicy.basicCashValue, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Dividend This Year', inforcePolicy.dividendThisYear, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Annual Payout Amount', inforcePolicy.annualPayoutAmount, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Payout Frequency', TextLookup_PayoutFrequency(inforcePolicy, t), ScalarTypes.SCALAR_STRING));
  //sometimes inforcePolicy.annualPayoutModeCode is null
  if (inforcePolicy.annualPayoutModeCode === null) {
    scalarReportArray.push(addScalarItem('Annual Payout Mode Code', '', ScalarTypes.SCALAR_STRING));
  } else {
    scalarReportArray.push(addScalarItem('Annual Payout Mode Code', inforcePolicy.annualPayoutModeCode, ScalarTypes.SCALAR_STRING));
  }
  scalarReportArray.push(addScalarItem('Payout Method Code', inforcePolicy.payoutMethodCode, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Payout Method', toProperCase(TextLookup_PayoutMethodCode(inforcePolicy.payoutMethodCode, t)), ScalarTypes.SCALAR_STRING));

  scalarReportArray.push(addScalarItem('Payout Payment Date', format_Display_Date(inforcePolicy.payoutPaymentDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Payout Effective Date', format_Display_Date(inforcePolicy.payoutEffectiveDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Payout End Date', format_Display_Date(inforcePolicy.payoutEndDate), ScalarTypes.SCALAR_STRING));
  //sometimes inforcePolicy.scheduledWithdrawalMethod is null
  if (inforcePolicy.scheduledWithdrawalMethod === null) {
    scalarReportArray.push(addScalarItem('Scheduled Withdrawal Method', '', ScalarTypes.SCALAR_STRING));
  } else {
    scalarReportArray.push(addScalarItem('Scheduled Withdrawal Method', inforcePolicy.scheduledWithdrawalMethod, ScalarTypes.SCALAR_STRING));
  }

  scalarReportArray.push(addScalarItem('Payout Tax Withheld', inforcePolicy.payoutTaxWithheld, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Payout Tax Calculation Percent', inforcePolicy.payoutTaxCalculationPercent, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Payout Additional Tax Withheld', inforcePolicy.payoutAdditionalTaxWithheld, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Payout Insured Type', inforcePolicy.payoutInsuredType, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Payout Insured Type - Annuitant', PayoutInsuredTypes.Annuitant, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Payout Insured Type - Spouse', PayoutInsuredTypes.Spouse, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Payout Type Code', inforcePolicy.payoutType, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Payout Type', TextLookup_PayoutType(inforcePolicy.payoutType, t), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Payout Type - Minimum', PayoutTypes.Minimum, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Payout Type - Maximum', PayoutTypes.Maximum, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Payout Type - Flat Amount', PayoutTypes.FlatAmount, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Min Payout This Year', inforcePolicy.minPayoutThisYear, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Max Payout This Year', inforcePolicy.maxPayoutThisYear, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('IsLIF', booleanToString(isProductLIF(inforcePolicy.prodID)), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('Funds Locked In', booleanToString(inforcePolicy.fundsLockedIn), ScalarTypes.SCALAR_BOOLEAN));

  scalarReportArray.push(
    addScalarItem(
      'Payout - Gross Payout Amount',
      formatIsAmountAboveZero(
        inforcePolicy.annualPayoutAmount,
        formatDollar(inforcePolicy.annualPayoutAmount) + ' (Annualized ' + formatDollar(inforcePolicy.annualPayoutAmount * modalFactor(inforcePolicy.annualPayoutMode)) + ')'
      ),
      ScalarTypes.SCALAR_STRING
    )
  );

  scalarReportArray.push(
    addScalarItem(
      'Payout - Net Payout Amt',
      formatIsAmountAboveZero(
        inforcePolicy.annualPayoutAmount,
        formatDollar(inforcePolicy.annualPayoutAmount - inforcePolicy.payoutTaxWithheld - inforcePolicy.payoutAdditionalTaxWithheld) +
          ' (Annualized ' +
          formatDollar((inforcePolicy.annualPayoutAmount - inforcePolicy.payoutTaxWithheld - inforcePolicy.payoutAdditionalTaxWithheld) * modalFactor(inforcePolicy.annualPayoutMode)) +
          ')'
      ),
      ScalarTypes.SCALAR_STRING
    )
  );
  scalarReportArray.push(
    addScalarItem(
      'Payout - Next Payment Date',
      formatIsAmountAboveZero(inforcePolicy.annualPayoutAmount, Translate_NextPaymentDate(inforcePolicy.payoutPaymentDate, inforcePolicy.payoutEffectiveDate, inforcePolicy.currentDate)),
      ScalarTypes.SCALAR_STRING
    )
  );

  //ToDo create age calculation routines
  //let intAge = CalculateAgeFromJan1(FPPRPolicy.PayoutInsured, FCurrentDate)
  if (inforcePolicy.payoutInsuredType === PayoutInsuredTypes.Annuitant) {
    scalarReportArray.push(
      addScalarItem('Payout - Minimum Payout Based On', 'Annuitant, aged ' + calculateAgeFromJan1(inforcePolicy.payoutInsured, inforcePolicy.currentDate), ScalarTypes.SCALAR_STRING)
    );
  } else {
    scalarReportArray.push(
      addScalarItem('Payout - Minimum Payout Based On', 'Spouse, aged ' + calculateAgeFromJan1(inforcePolicy.payoutInsured, inforcePolicy.currentDate), ScalarTypes.SCALAR_STRING)
    );
  }

  scalarReportArray.push(addScalarItem('Guaranteed Maturity Date', format_Display_Date(inforcePolicy.guaranteedMaturityDate), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Guaranteed Maturity Amount', inforcePolicy.guaranteedMaturityAmount, ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('Guaranteed Death Amount', inforcePolicy.guaranteedDeathAmount, ScalarTypes.SCALAR_DOUBLE));

  scalarReportArray.push(addScalarItem('Assignee Type', inforcePolicy.assigneeType, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Assignee Type - Absolute', AssigneeTypes.Absolute, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Assignee Type - Collateral', AssigneeTypes.Collateral, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Assignee Type - None', AssigneeTypes.None, ScalarTypes.SCALAR_INTEGER));

  let insuredItem = inforcePolicy.owners[0];
  scalarReportArray.push(addScalarItem('Owner Name', fullName(insuredItem), ScalarTypes.SCALAR_STRING));

  let ownerAddressItem = GetAddressItem(insuredItem.addresses, addressTypes.Mailing);
  if (!ownerAddressItem) {
    ownerAddressItem = GetAddressItem(insuredItem.addresses, addressTypes.Residence);
  }

  if (!ownerAddressItem) {
    scalarReportArray.push(addScalarItem('Owner Address1', '', ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('Owner Address2', '', ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('Owner Address3', '', ScalarTypes.SCALAR_STRING));
  } else {
    scalarReportArray.push(addScalarItem('Owner Address1', FormatMailingAddress1(ownerAddressItem), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('Owner Address2', FormatMailingAddress2(ownerAddressItem), ScalarTypes.SCALAR_STRING));
    scalarReportArray.push(addScalarItem('Owner Address3', FormatMailingAddress3(ownerAddressItem), ScalarTypes.SCALAR_STRING));
  }

  scalarReportArray.push(addScalarItem('Annuitant Name', fullName(aBaseCoverage.insured), ScalarTypes.SCALAR_STRING));

  if (inforcePolicy.planCode.slice(-1) === 'J' && aBaseCoverage.insureds.length > 1) {
    scalarReportArray.push(addScalarItem('IsJoint', '1', ScalarTypes.SCALAR_BOOLEAN));
    scalarReportArray.push(addScalarItem('Annuitant Name 2', fullName(aBaseCoverage.insureds[1]), ScalarTypes.SCALAR_STRING));
  } else {
    scalarReportArray.push(addScalarItem('IsJoint', '0', ScalarTypes.SCALAR_BOOLEAN));
  }

  scalarReportArray.push(addScalarItem('Church ID', insuredItem.churchID, ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Church Name', FormatChurchName(insuredItem), ScalarTypes.SCALAR_STRING));

  let ownerPhone = '';
  if (insuredItem.phoneNumbers.length === 0) {
    ownerPhone = 'R: Not on File, ';
  } else {
    let ownerPhoneItem = GetPhoneItem(insuredItem.phoneNumbers, phoneTypes.Residence);
    if (!ownerPhoneItem) {
      //do nothing if null
    } else {
      ownerPhone = 'R: ' + FormatPhoneNumber(insuredItem.phoneNumbers[0].phoneNumber) + ', ';
    }
  }

  let aBusinessPhoneItem = GetPhoneItem(insuredItem.phoneNumbers, phoneTypes.Business);
  if (!aBusinessPhoneItem) {
    ownerPhone = ownerPhone + 'B: Not on File';
  } else {
    ownerPhone = ownerPhone + 'B: ' + FormatPhoneNumber(aBusinessPhoneItem.phoneNumber);
  }

  scalarReportArray.push(addScalarItem('Owner Phone', ownerPhone, ScalarTypes.SCALAR_STRING));

  scalarReportArray.push(addScalarItem('Servicing Agent', fullName(inforcePolicy.agent), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Servicing Agent Phone', FormatPhoneNumber(inforcePolicy.agent.phoneNumber), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Writing Agent', fullName(inforcePolicy.writingAgent), ScalarTypes.SCALAR_STRING));
  scalarReportArray.push(addScalarItem('Policy Issue Date', format_Display_Date_Object(aIssueDate), ScalarTypes.SCALAR_STRING));

  scalarReportArray.push(addScalarItem('Spouse Contributor Type', inforcePolicy.spouseContributorType, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Spouse Contributor Type - Spouse', SpouseContributorTypes.Spouse, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Spouse Contributor Type - None', SpouseContributorTypes.None, ScalarTypes.SCALAR_INTEGER));

  if (inforcePolicy.isRegistered) {
    if (inforcePolicy.spouseContributorType === SpouseContributorTypes.Spouse) {
      scalarReportArray.push(addScalarItem('Spousal Contributor', GetFullNameWithSalutation(inforcePolicy.spouseContributor), ScalarTypes.SCALAR_STRING));
    } else {
      scalarReportArray.push(addScalarItem('Spousal Contributor', 'None', ScalarTypes.SCALAR_STRING));
    }
  }

  if (controlItems['btnViewReportInforce'].displayDisabled) {
    scalarReportArray.push(addScalarItem('Policy Progress Report Text', 'Not Available', ScalarTypes.SCALAR_STRING));
  } else {
    scalarReportArray.push(addScalarItem('Policy Progress Report Text', 'Available', ScalarTypes.SCALAR_STRING));
  }

  let beneText = GetBeneficaryString(aBaseCoverage, t);
  //To be able to pass these as variables, they need to be broken up into 100 character chunks
  let beneTemp = beneText;
  let intLength = 0;
  let beneArray = [];
  let i = 0;

  for (i = 1; i <= Math.trunc(beneText.length / 100) + 1; i++) {
    intLength = Math.min(100, beneTemp.length);
    beneArray[i] = beneTemp.slice(0, intLength);
    if (intLength < 100) {
      break;
    } else {
      beneTemp = beneTemp.slice(intLength);
    }
  }

  for (i = 1; i <= beneArray.length; i++) {
    scalarReportArray.push(addScalarItem('Beneficiary Text ' + i.toString(), beneArray[i], ScalarTypes.SCALAR_STRING));
  }
  scalarReportArray.push(addScalarItem('Beneficiary Count', aBaseCoverage.beneficiaries.length, ScalarTypes.SCALAR_INTEGER));

  if (!isStatus) {
    //if (true) {
    let lstSummaryItems = [];
    //Build Summary
    for (let aSummaryCoverageItem of inforcePolicy.coverages) {
      if (aSummaryCoverageItem.coverageType === CoverageTypes.BASE) {
        //For now we do not show this for UL or LevelTerm. Testing will try to bear out all the rules for this
        if (
          !(
            inforcePolicy.policyType === PolicyTypes.UL ||
            inforcePolicy.policyType === PolicyTypes.LevelTerm ||
            inforcePolicy.policyType === PolicyTypes.TermWithCV ||
            inforcePolicy.policyType === PolicyTypes.WholeLife ||
            inforcePolicy.policyType === PolicyTypes.Enhanced ||
            inforcePolicy.policyType === PolicyTypes.CriticalIllness ||
            inforcePolicy.policyType === PolicyTypes.DeferredAnnuity
          )
        ) {
          lstSummaryItems.push(BuildSummaryPolicyExpiryColumnText(insuranceData, aSummaryCoverageItem));
        }

        if (isProductADBPlus(inforcePolicy.prodID)) {
          let strSummaryText = BuildSummaryColumnText(aSummaryCoverageItem, inforceData, insuranceData);
          if (strSummaryText !== '') {
            lstSummaryItems.push(strSummaryText);
          }
        }

        let strSummaryText = '';
        //This is not displayed for enhanced
        if (!(inforcePolicy.policyType === PolicyTypes.Enhanced || inforcePolicy.policyType === PolicyTypes.CriticalIllness)) {
          //We need to get the calc CoverageItem becuase the calc engine calculated the PremiumDurationYear which we need for this
          let aCoverageItem = getCoverageByID(insuranceData.policy.coverages, aSummaryCoverageItem.coverageID, aSummaryCoverageItem.parentCoverageID, false);
          if (aCoverageItem.premiumDurationYear < aCoverageItem.expiryDuration - 1) {
            strSummaryText = BuildSummaryColumnText(aSummaryCoverageItem, inforceData, insuranceData);
            if (strSummaryText !== '') {
              lstSummaryItems.push(strSummaryText);
            }
          }
        }

        for (let aBenefitSummaryCoverageItem of aSummaryCoverageItem.coverages) {
          if (aBenefitSummaryCoverageItem.coverageType === CoverageTypes.BENEFIT || aBenefitSummaryCoverageItem.coverageType === CoverageTypes.RIDER) {
            lstSummaryItems.push(BuildSummaryColumnText(aBenefitSummaryCoverageItem, inforceData, insuranceData));
          }
        }
      } else {
        if (aSummaryCoverageItem.coverageType === CoverageTypes.BENEFIT || aSummaryCoverageItem.coverageType === CoverageTypes.RIDER) {
          lstSummaryItems.push(BuildSummaryColumnText(aSummaryCoverageItem, inforceData, insuranceData));
        }
      }
    }

    lstSummaryItems.sort();

    //Add a final blank line to separate it from the next section
    lstSummaryItems.push('');

    let summaryColumnCount = 0;
    for (let strSummaryItem of lstSummaryItems) {
      summaryColumnCount = summaryColumnCount + 1;
      scalarReportArray.push(addScalarItem('SummaryColumn' + summaryColumnCount.toString(), strSummaryItem, ScalarTypes.SCALAR_STRING));
    }

    scalarReportArray.push(addScalarItem('SummaryColumnCount', summaryColumnCount, ScalarTypes.SCALAR_INTEGER));
    let skipYear = 0;
    let iSkip = 0;
    let strDate = '';
    if (isInPremiumOffset(inforcePolicy)) {
      iSkip = GetSkipPaymentYear('SkipPUAPayment', insuranceData);
      if (iSkip !== -1) {
        skipYear = insuranceData.dblTableData_Main[iSkip][ReportColumns.CalendarYear];
        strDate = format_Display_Date_Object(new Date(skipYear, aIssueDate.getMonth(), aIssueDate.getDate()));
        scalarReportArray.push(addScalarItem('PremOff - Skip PUA Pymt', '1', ScalarTypes.SCALAR_BOOLEAN));

        scalarReportArray.push(addScalarItem('PremOff - Skip Date', strDate, ScalarTypes.SCALAR_STRING));
      } else {
        scalarReportArray.push(addScalarItem('PremOff - Skip PUA Pymnt', '0', ScalarTypes.SCALAR_BOOLEAN));
      }

      iSkip = GetSkipPaymentYear('ALT_SkipPUAPayment', insuranceData);
      if (iSkip !== -1) {
        skipYear = insuranceData.dblTableData_Main[iSkip][ReportColumns.CalendarYear];
        strDate = format_Display_Date_Object(new Date(skipYear, aIssueDate.getMonth(), aIssueDate.getDate()));

        scalarReportArray.push(addScalarItem('PremOff - Skip PUA Pymnt ALT', '1', ScalarTypes.SCALAR_BOOLEAN));
        scalarReportArray.push(addScalarItem('PremOff - Skip Date ALT', strDate, ScalarTypes.SCALAR_STRING));
      } else {
        scalarReportArray.push(addScalarItem('PremOff - Skip PUA Pymnt ALT', '0', ScalarTypes.SCALAR_BOOLEAN));
      }
    } else {
      if (isPremiumOffsetSelected(controlItems, formData)) {
        let offsetDate;
        let expiryDate;
        aGlobalVariable = getScalarItem(insuranceData.globalVariables, 'PO_PremiumOffsetDuration');
        if (!isEmpty(aGlobalVariable)) {
          iSkip = aGlobalVariable.integerValue;
          skipYear = insuranceData.dblTableData_Main[iSkip][ReportColumns.CalendarYear];

          offsetDate = new Date(skipYear, aIssueDate.getMonth(), aIssueDate.getDate());
          expiryDate = new Date(inforcePolicy.expiryDate);
          //strDate = format_Display_Date_Object(new Date(skipYear, inforcePolicy.issueDate.getMonth(), inforcePolicy.issueDate.getDate));

          scalarReportArray.push(addScalarItem('PremOff - Cannot elect PO', booleanToString(offsetDate >= expiryDate), ScalarTypes.SCALAR_BOOLEAN));
          scalarReportArray.push(addScalarItem('PremOff - Skip Date PO', format_Display_Date_Object(offsetDate), ScalarTypes.SCALAR_STRING));
        }

        aGlobalVariable = getScalarItem(insuranceData.globalVariables, 'PO_ALT_PremiumOffsetDuration');
        if (!isEmpty(aGlobalVariable)) {
          iSkip = aGlobalVariable.integerValue;
          skipYear = insuranceData.dblTableData_Main[iSkip][ReportColumns.CalendarYear];

          offsetDate = new Date(skipYear, aIssueDate.getMonth(), aIssueDate.getDate());
          scalarReportArray.push(addScalarItem('PremOff - Cannot elect PO_ALT', booleanToString(offsetDate >= expiryDate), ScalarTypes.SCALAR_BOOLEAN));
          scalarReportArray.push(addScalarItem('PremOff - Skip Date PO_ALT', format_Display_Date_Object(offsetDate), ScalarTypes.SCALAR_STRING));
        }
      }
      scalarReportArray.push(addScalarItem('PremOff - Skip PUA Pymnt', '0', ScalarTypes.SCALAR_BOOLEAN));
      scalarReportArray.push(addScalarItem('PremOff - Skip PUA Pymnt ALT', '0', ScalarTypes.SCALAR_BOOLEAN));
      scalarReportArray.push(addScalarItem('PremOff - Skip PUA Pymnt PO_ALT', '0', ScalarTypes.SCALAR_BOOLEAN));
    }
    aGlobalVariable = getScalarItem(insuranceData.globalVariables, 'PO_IsOffsetBeforePremDur');
    if (!isEmpty(aGlobalVariable)) {
      scalarReportArray.push(addScalarItem('PO_IsOffsetBeforePremDur', aGlobalVariable.booleanValue, ScalarTypes.SCALAR_BOOLEAN));
    }

    aGlobalVariable = getScalarItem(insuranceData.globalVariables, 'PO_ALT_IsOffsetBeforePremDur');
    if (!isEmpty(aGlobalVariable)) {
      scalarReportArray.push(addScalarItem('PO_ALT_IsOffsetBeforePremDur', aGlobalVariable.booleanValue, ScalarTypes.SCALAR_BOOLEAN));
    }
  }

  scalarReportArray.push(addScalarItem('ESA_TransactionCount', reportBuildModel.esa_TransactionCount, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('SegFund_ActivityCount', reportBuildModel.segFund_ActivityCount, ScalarTypes.SCALAR_INTEGER));
  scalarReportArray.push(addScalarItem('Dividend Paid Label', globalVariables.DividendPaidLabel, ScalarTypes.SCALAR_STRING));

  scalarReportArray.push(addScalarItem('Show RPU', booleanToString(!IsProductExcludeRPU(inforcePolicy.prodID)), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('IsPaidUp', isPolicyPaidUp(inforcePolicy), ScalarTypes.SCALAR_BOOLEAN));

  //***************** Added for Solve Demo ******************
  scalarReportArray.push(addScalarItem('chkUseSolve', booleanToString(formData['chkUseSolve']), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('rdoTakeLoan', booleanToString(formData['chkTakeLoan']), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('rdoPartialSurrenderWL', booleanToString(formData['chkPartialSurrWL']), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('rdoPartialSurrenderUL', booleanToString(formData['chkPartialSurrUL']), ScalarTypes.SCALAR_BOOLEAN));
  scalarReportArray.push(addScalarItem('rdoFaceReduction', booleanToString(formData['chkFaceReduction']), ScalarTypes.SCALAR_BOOLEAN));

  scalarReportArray.push(addScalarItem('txtLoanAmount', formData['txtLoanAmount'], ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('txtPartialSurrenderAmountWL', formData['txtCashSurrAmtWL'], ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('txtPartialSurrenderAmountUL', formData['txtCashSurrAmtUL'], ScalarTypes.SCALAR_DOUBLE));
  scalarReportArray.push(addScalarItem('txtNewFaceAmount', formData['txtNewFaceAmount'], ScalarTypes.SCALAR_DOUBLE));

  //*********************************************************

  console.log('ending custom inforce scalars');
  return scalarReportArray;
};

export function buildReportListItems(isStatusReport, inforceData, inforceFormData, controlItems, reportBuildModel) {
  let outArray = [];

  if (isStatusReport) {
    switch (inforceData.policy.policyType) {
      case PolicyTypes.DecreasingIncreasingTerm:
      case PolicyTypes.Disability:
      case PolicyTypes.Endowment:
      case PolicyTypes.Enhanced:
      case PolicyTypes.LevelTerm:
      case PolicyTypes.PaidUpLife:
      case PolicyTypes.TermWithCV:
      case PolicyTypes.WholeLife:
      case PolicyTypes.CriticalIllness:
        outArray.push({
          name: 'Status',
          reportFile: 'Status_T10.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        break;
      case PolicyTypes.UL:
        outArray.push({
          name: 'Status',
          reportFile: 'Status_UL.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        //ToDo: check if not terminated
        if (reportBuildModel.ul_TransactionCount > 0) {
          outArray.push({name: 'Transactions', reportFile: 'Transactions_UL.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        }

        //if UL_COIActivityCount > 0 add PPR_COIActivity_UL.rtm
        if (reportBuildModel.ul_COIActivityCount > 0) {
          outArray.push({name: 'COI Activity', reportFile: 'COIActivity.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        }

        break;
      case PolicyTypes.DeferredAnnuity:
        outArray.push({
          name: 'Status',
          reportFile: 'Status_APRA.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        break;
      case PolicyTypes.ESA_ISA:
        if (inforceData.inforcePolicy.annualPayoutAmount > 0) {
          outArray.push({
            name: 'Status',
            reportFile: 'Status_ESA_Payout.rtm',
            custom: 0,
            required: 1,
            pageBreak: 0,
            custColFile: '',
          });
        } else {
          outArray.push({
            name: 'Status',
            reportFile: 'Status_ESA.rtm',
            custom: 0,
            required: 1,
            pageBreak: 0,
            custColFile: '',
          });
        }
        if (reportBuildModel.esa_TransactionCount > 0) {
          outArray.push({name: 'Transactions', reportFile: 'Transactions_ESA.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        }
        break;
      case PolicyTypes.RRIF:
        outArray.push({
          name: 'Status',
          reportFile: 'Status_ESARRIF.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        if (reportBuildModel.esa_TransactionCount > 0) {
          outArray.push({name: 'Transactions', reportFile: 'Transactions_ESA.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        }

        break;
      case PolicyTypes.SegregatedFund:
        if (inforceData.inforcePolicy.annualPayoutAmount > 0) {
          outArray.push({
            name: 'Status',
            reportFile: 'Status_SegFund_Payout.rtm',
            custom: 0,
            required: 1,
            pageBreak: 0,
            custColFile: '',
          });
        } else {
          outArray.push({
            name: 'Status',
            reportFile: 'Status_SegFund.rtm',
            custom: 0,
            required: 1,
            pageBreak: 0,
            custColFile: '',
          });
        }
        if (reportBuildModel.segFund_ActivityCount > 0) {
          outArray.push({name: 'Activity', reportFile: 'Activity_SegFund.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        }
        break;
      case PolicyTypes.SegregatedFundRRIF:
        if (isProductLIF(inforceData.policy.prodID)) {
          outArray.push({
            name: 'Status',
            reportFile: 'Status_SegFundLIF.rtm',
            custom: 0,
            required: 1,
            pageBreak: 0,
            custColFile: '',
          });
        } else {
          outArray.push({
            name: 'Status',
            reportFile: 'Status_SegFundRRIF.rtm',
            custom: 0,
            required: 1,
            pageBreak: 0,
            custColFile: '',
          });
        }
        if (reportBuildModel.segFund_ActivityCount > 0) {
          outArray.push({name: 'Activity', reportFile: 'Activity_SegFund.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        }
        break;
      default:
        break;
    }
  } else {
    if ((inforceData.policy.policyType === PolicyTypes.UL && inforceFormData['chkCoverPageInforce'] === true) || inforceData.policy.policyType !== PolicyTypes.UL) {
      outArray.push({
        name: 'Cover',
        reportFile: 'Cover_PPR.rtm',
        custom: 0,
        required: 1,
        pageBreak: 0,
        custColFile: '',
      });
    }

    if (!(inforceData.policy.policyType === PolicyTypes.UL)) {
      outArray.push({
        name: 'Policy',
        reportFile: 'PolicyDescription.rtm',
        custom: 0,
        required: 1,
        pageBreak: 0,
        custColFile: '',
      });
    }

      switch (inforceData.policy.policyType) {
      case PolicyTypes.DecreasingIncreasingTerm:
      case PolicyTypes.Disability:
      case PolicyTypes.Endowment:
        outArray.push({name: 'Status', reportFile: 'PPR_Status_T10.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        break;
      case PolicyTypes.LevelTerm:
        outArray.push({name: 'Status', reportFile: 'PPR_Status_T10.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        if (IsProductParticipatingTerm(inforceData.policy.prodID)) {
          outArray.push({name: 'Status', reportFile: 'PolicyValue_T10_Par.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
          outArray.push({name: 'Status', reportFile: 'PolicyValue_T10_ALT_Par.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        } else if (IsProductNonParticipatingTerm(inforceData.policy.prodID)) {
          outArray.push({name: 'Policy Value', reportFile: 'PolicyValue_T10.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        } else if (IsProductParticipatingT100(inforceData.policy.prodID)) {
          outArray.push({name: 'Status', reportFile: 'PolicyValue_T100_Par.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
          outArray.push({name: 'Status', reportFile: 'PolicyValue_T100_ALT_Par.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        } else if (IsProductCurrentPremiumT100(inforceData.policy.prodID)) {
          outArray.push({name: 'Status', reportFile: 'PolicyValue_T100_Current.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        } else if (IsProductNonParticipatingT100(inforceData.policy.prodID)) {
          outArray.push({name: 'Policy Value', reportFile: 'PolicyValue_T100.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        }
        break;
      case PolicyTypes.PaidUpLife:
        outArray.push({name: 'Status', reportFile: 'PPR_Status_T10.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        outArray.push({
          name: 'Policy Value',
          reportFile: 'PolicyValue_T10.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        break;
      case PolicyTypes.TermWithCV:
        //Status Page is not included in the demo because it requires a lot of code to build summary items and string data array columns
        outArray.push({name: 'Status', reportFile: 'PPR_Status_T10.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        break;
      case PolicyTypes.WholeLife:
      case PolicyTypes.Enhanced:
      case PolicyTypes.DeferredAnnuity:
        if (inforceData.policy.policyType === PolicyTypes.DeferredAnnuity) {
          //Status Page is not included in the demo because it requires a lot of code to build summary items and string data array columns
          outArray.push({name: 'Status', reportFile: 'PPR_Status_APRA.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        } else {
          //Status Page is not included in the demo because it requires a lot of code to build summary items and string data array columns
          outArray.push({name: 'Status', reportFile: 'PPR_Status_T10.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        }
        switch (inforceData.policy.dividendPayoutType) {
          case DividendPayoutTypes.PUA:
            if (isPremiumOffsetSelected(controlItems, inforceFormData)) {
              outArray.push({
                  name: 'Policy Value',
                  reportFile: 'PolicyValue_WL_PO_PUA.rtm',
                  custom: 0,
                  required: 1,
                  pageBreak: 0,
                  custColFile: '',
              });
              outArray.push({
                  name: 'Policy Value',
                  reportFile: 'PolicyValue_WL_PO_ALT_PUA.rtm',
                  custom: 0,
                  required: 1,
                  pageBreak: 0,
                  custColFile: '',
              });
            } else {
              if (inforceFormData['chkUseSolve'] && inforceFormData['chkPartialSurrWL']) {
                outArray.push({
                    name: 'Policy Value',
                    reportFile: 'PolicyValue_WL_PUA_Surrender.rtm',
                    custom: 0,
                    required: 1,
                    pageBreak: 0,
                    custColFile: '',
                });
              } else {
                outArray.push({
                    name: 'Policy Value',
                    reportFile: 'PolicyValue_WL_PUA.rtm',
                    custom: 0,
                    required: 1,
                    pageBreak: 0,
                    custColFile: '',
                });
                outArray.push({
                    name: 'Policy Value',
                    reportFile: 'PolicyValue_WL_ALT_PUA.rtm',
                    custom: 0,
                    required: 1,
                    pageBreak: 0,
                    custColFile: '',
                });
              }
            }
            break;
          case DividendPayoutTypes.DOD:
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_WL_DOD.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_WL_ALT_DOD.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
            if (isPremiumOffsetSelected(controlItems, inforceFormData)) {
              outArray.push({
                name: 'Policy Value',
                reportFile: 'PolicyValue_WL_PO_DOD.rtm',
                custom: 0,
                required: 1,
                pageBreak: 0,
                custColFile: '',
              });
              outArray.push({
                name: 'Policy Value',
                reportFile: 'PolicyValue_WL_PO_ALT_DOD.rtm',
                custom: 0,
                required: 1,
                pageBreak: 0,
                custColFile: '',
              });
            }
            break;
          case DividendPayoutTypes.DRP:
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_WL_DRP.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_WL_ALT_DRP.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
            break;
          case DividendPayoutTypes.LRD:
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_WL_LRD.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_WL_ALT_LRD.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
            break;
          case DividendPayoutTypes.PIC:
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_WL_PIC.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_WL_ALT_PIC.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
            break;
          case DividendPayoutTypes.Enhanced:
            if (inforceFormData['chkUseSolve'] && inforceFormData['chkPartialSurrWL']) {
              outArray.push({
                name: 'Policy Value',
                reportFile: 'PolicyValue_WL_PUA_Surrender.rtm',
                custom: 0,
                required: 1,
                pageBreak: 0,
                custColFile: '',
              });
            } else {
              outArray.push({
                name: 'Policy Value',
                reportFile: 'PolicyValue_WL_ENH.rtm',
                custom: 0,
                required: 1,
                pageBreak: 0,
                custColFile: '',
              });
            }
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_WL_ALT_ENH.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
            if (isPremiumOffsetSelected(controlItems, inforceFormData)) {
              outArray.push({
                name: 'Policy Value',
                reportFile: 'PolicyValue_WL_PO_PUA.rtm',
                custom: 0,
                required: 1,
                pageBreak: 0,
                custColFile: '',
              });
              outArray.push({
                name: 'Policy Value',
                reportFile: 'PolicyValue_WL_PO_ALT_PUA.rtm',
                custom: 0,
                required: 1,
                pageBreak: 0,
                custColFile: '',
              });
            }
            break;
          case DividendPayoutTypes.None:
            if (isProdIDSOSBA(inforceData.policy.prodID)) {
              outArray.push({
                name: 'Policy Value',
                reportFile: 'PolicyValue_WL_NONE.rtm',
                custom: 0,
                required: 1,
                pageBreak: 0,
                custColFile: '',
              });
              outArray.push({
                name: 'Policy Value',
                reportFile: 'PolicyValue_WL_ALT_NONE.rtm',
                custom: 0,
                required: 1,
                pageBreak: 0,
                custColFile: '',
              });
            } else {
              if (isProductEssentialLife(inforceData.policy.prodID)) {
                outArray.push({
                  name: 'Policy Value',
                  reportFile: 'PolicyValue_EssentialLife.rtm',
                  custom: 0,
                  required: 1,
                  pageBreak: 0,
                  custColFile: '',
                });
              } else {
                outArray.push({
                  name: 'Policy Value',
                  reportFile: 'PolicyValue_T100.rtm',
                  custom: 0,
                  required: 1,
                  pageBreak: 0,
                  custColFile: '',
                });
              }
            }
            break;
          default:
            break;
        }
        break;
      case PolicyTypes.CriticalIllness:
        outArray.push({name: 'Status', reportFile: 'PPR_Status_T10.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        outArray.push({
          name: 'Policy Value',
          reportFile: 'PolicyValue_CI.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        break;
      case PolicyTypes.UL:
        outArray.push({name: 'Status', reportFile: 'PPR_Status_UL.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
        outArray.push({
          name: 'Policy Value',
          reportFile: 'PolicyDescription_UL.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });

        if (inforceFormData['chkTransactionInfo'] === true) {
          //if UL_TransactionCount > 0 add PPR_Transactions_UL.rtm
          if (reportBuildModel.ul_TransactionCount > 0) {
            outArray.push({name: 'Transactions', reportFile: 'PPR_Transactions_UL.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
          }
        }

        if (inforceFormData['chkCOIInfo'] === true) {
          //if UL_COIActivityCount > 0 add PPR_COIActivity_UL.rtm
          if (reportBuildModel.ul_COIActivityCount > 0) {
            outArray.push({name: 'COI Activity', reportFile: 'PPR_COIActivity_UL.rtm', custom: 0, required: 1, pageBreak: 0, custColFile: ''});
          }
        }

        if (inforceFormData['chkFutureScenarios'] === true) {
            if (inforceFormData['chkUseSolve'] === true && inforceFormData['chkPartialSurrUL'] === true) {
          //Temporarily suspend UL Policy Partial Surrender Solve
          //  outArray.push({
          //    name: 'Policy Value',
          //    reportFile: 'PolicyValue_UL_Surrender.rtm',
          //    custom: 0,
          //    required: 1,
          //    pageBreak: 0,
          //    custColFile: '',
          //  });
          } else {
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_UL.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
          }

          if ((inforceFormData['cboSolveType'] === 'I') || (inforceFormData['cboSolveType'] === 'VI')) {
              outArray.push({
                  name: 'Income Distribution',
                  reportFile: 'Income_UL.rtm',
                  custom: 0,
                  required: 1,
                  pageBreak: 0,
                  custColFile: '',
              });
          }

          outArray.push({
            name: 'Policy Value',
            reportFile: 'PolicyValue_UL_ALT.rtm',
            custom: 0,
            required: 1,
            pageBreak: 0,
            custColFile: '',
          });

          if (inforceFormData['chkUseClientRates'] === true) {
            outArray.push({
              name: 'Policy Value',
              reportFile: 'PolicyValue_UL_ALT2.rtm',
              custom: 0,
              required: 1,
              pageBreak: 0,
              custColFile: '',
            });
          }
        }
        break;
      case PolicyTypes.RRIF:
      case PolicyTypes.ESA_ISA:
        outArray.push({
          name: 'Status',
          reportFile: 'Status_ESA.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        outArray.push({
          name: 'Transactions',
          reportFile: 'Transactions_ESA.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        break;
      case PolicyTypes.SegregatedFund:
        outArray.push({
          name: 'Status',
          reportFile: 'Status_SegFund.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        outArray.push({
          name: 'Activity',
          reportFile: 'Activity_SegFund.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        break;
      case PolicyTypes.SegregatedFundRRIF:
        outArray.push({
          name: 'Status',
          reportFile: 'Status_SegFundRRIF.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        outArray.push({
          name: 'Activity',
          reportFile: 'Activity_SegFund.rtm',
          custom: 0,
          required: 1,
          pageBreak: 0,
          custColFile: '',
        });
        break;
      default:
        break;
    }
    // ********************* ADDED FOR SOLVE DEMO *****************
    if (inforceFormData['chkUseSolve']) {
      if (inforceFormData['chkTakeLoan']) {
        if (inforceData.policy.policyType === PolicyTypes.WholeLife || inforceData.policy.policyType === PolicyTypes.Enhanced) {
          outArray.push({
            name: 'Policy Loan',
            reportFile: 'LoanValue_WL.rtm',
            custom: 0,
            required: 1,
            pageBreak: 0,
            custColFile: '',
          });
        } else if (inforceData.policy.policyType === PolicyTypes.UL) {
        //Temporarily suspend UL Policy Loan Solve
        //  outArray.push({
        //    name: 'Policy Loan',
        //    reportFile: 'LoanValue_UL.rtm',
        //    custom: 0,
        //    required: 1,
        //    pageBreak: 0,
        //    custColFile: '',
        //  });
        }
      }
    }
  }
  return outArray;
}

//Not used. Coming from BackEnd call
export function buildReportColumnStringItems() {
  let reportColumnStringItems = [
    {name: 'String Column 1', displayName: 'String 1', columnNumber: 1},
    {name: 'String Column 2', displayName: 'String 2', columnNumber: 2},
  ];

  return reportColumnStringItems;
}
export function buildReportSettings() {
  let reportSettings = [
    {
      name: 'FormatLanguage',
      integerValue: 0,
      longIntValue: 0,
      doubleValue: 0,
      singleValue: 0,
      booleanValue: 0,
      stringValue: 'E',
      dataType: REPORT_SETTING.STRING,
    },
    {
      name: 'RecreateArrayTable',
      booleanValue: 1,
      dataType: REPORT_SETTING.BOOLEAN,
    },
    {
      name: 'RecreateScalarTable',
      booleanValue: 1,
      dataType: REPORT_SETTING.BOOLEAN,
    },
    {
      name: 'ScalarMethod',
      stringValue: 'D',
      dataType: REPORT_SETTING.STRING,
    },
    {
      name: 'ReadArrayData',
      booleanValue: 1,
      dataType: REPORT_SETTING.BOOLEAN,
    },
    {
      name: 'ReadScalarData',
      booleanValue: 1,
      dataType: REPORT_SETTING.BOOLEAN,
    },
    {
      name: 'ReadReportList',
      booleanValue: 1,
      dataType: REPORT_SETTING.BOOLEAN,
    },
    {
      name: 'ReportTitle',
      stringValue: 'ProUI Report One',
      dataType: REPORT_SETTING.STRING,
    },
    {
      name: 'OwnerPassword',
      stringValue: 'PDFOwner',
      dataType: REPORT_SETTING.STRING,
    },
    {
      name: 'CustomDialog',
      booleanValue: 1,
      dataType: REPORT_SETTING.BOOLEAN,
    },
    {
      name: 'ZoomSetting',
      stringValue: 'PERCENTAGE',
      dataType: REPORT_SETTING.STRING,
    },
    {
      name: 'ZoomPercentage',
      integerValue: 121,
      dataType: REPORT_SETTING.INTEGER,
    },
    {
      name: 'ShowThumbnails',
      booleanValue: 1,
      dataType: REPORT_SETTING.BOOLEAN,
    },
    {
      name: 'ShowReportSelection',
      booleanValue: 0,
      dataType: REPORT_SETTING.BOOLEAN,
    },
    {name: 'PDFPath', stringValue: '', dataType: REPORT_SETTING.STRING},
    {
      name: 'ExcelPath',
      stringValue: '',
      dataType: REPORT_SETTING.STRING,
    },
    {
      name: 'OpenExcelonPrint',
      booleanValue: 0,
      dataType: REPORT_SETTING.BOOLEAN,
    },
    {
      name: 'OpenPDFOnPrint',
      booleanValue: 0,
      dataType: REPORT_SETTING.BOOLEAN,
    },
  ];

  return reportSettings;
}

//Not used.  Coming from back end call.
export function buildReportDataArrayString() {
  let reportDataArrayString = [
    ['one', 'two'],
    ['three', 'four'],
    ['Five', 'six'],
    ['seven', 'eight'],
  ];

  return reportDataArrayString;
}

export function buildStatusInsuranceData(reportBuildModel, insuranceData) {
  let reportData = [];
  let reportCols = [
    {name: 'Year', displayName: 'Year', columnNumber: 1},
    {name: 'CalendarYear', displayName: 'CalendarYear', columnNumber: 2},
    {name: 'Age', displayName: 'Age', columnNumber: 3},
    {name: 'FilterCol', displayName: 'FilterCol', columnNumber: 4},
  ];

  let maxRows = reportBuildModel.tableDataString[0].length;
  for (let i = 1; i <= maxRows; i++) {
    reportData[i] = [];
    reportData[i][0] = 0;
    reportData[i][1] = 0;
    reportData[i][2] = 0;
    reportData[i][3] = 0;
    reportData[i][4] = 1;
  }

  insuranceData.columnItems = reportCols;
  insuranceData.dblTableData_Main = reportData;
  return insuranceData;
}
