import {ActionTypes} from '../types';

export const setAlert = (msg, alertType, name, timeout = 5000) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ALERT,
    name,
    payload: {msg, alertType},
  });

  setTimeout(() => dispatch({type: ActionTypes.REMOVE_ALERT, name}), timeout);
};

export const removeAlert = (name) => {
  return {
    type: ActionTypes.REMOVE_ALERT,
    name,
  };
};
