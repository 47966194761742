import {ActionTypes} from '../types';

const initialState = {
  isAuthenticated: JSON.parse(sessionStorage.getItem('auth')),
  username: sessionStorage.getItem('username'),
  isAuthenticating: false,
};

const authReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
      };
      break;
    case ActionTypes.AUTH_SUCCESS:
    case ActionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        refreshToken: payload,
      };
    case ActionTypes.REFRESH_TOKEN_FAIL:
      return {...state, isAuthenticating: false};
    case ActionTypes.LOGIN_SUCCESS:
      sessionStorage.setItem('username', payload.username);
      sessionStorage.setItem('auth', JSON.stringify(true));

      return {
        ...state,
        user: payload,
        isAuthenticated: true,
        isAuthenticating: false,
      };
    case ActionTypes.UPDATE_PROFILE:
      sessionStorage.setItem('user', JSON.stringify(payload));
      return {
        ...state,
        user: payload,
        isAuthenticated: true,
        isAuthenticating: false,
      };
    case ActionTypes.LOGOUT:
    case ActionTypes.LOGIN_FAIL:
    case ActionTypes.AUTH_ERROR:
    //Demo code to keep "BI" data in session storage. Real function is to clear session storage fully.
      let cache = JSON.parse(sessionStorage.getItem('bi'));
      sessionStorage.clear();
      if (cache) {
        sessionStorage.setItem('bi', JSON.stringify(cache));
      }
      return {
        user: null,
        isAuthenticated: false,
        isAuthenticating: false,
      };

    case ActionTypes.SESSION_TIMEOUT:
      sessionStorage.removeItem('auth');
      sessionStorage.removeItem('refreshToken');
      sessionStorage.setItem('isSessionExpired', true);
      return {
        user: null,
        isAuthenticated: false,
      };
    case ActionTypes.SET_USERNAME:
      sessionStorage.setItem('username', payload.username);
      return {...state, isAuthenticating: false};
    case ActionTypes.ALL_USERS:
      return {
        ...state,
        users: payload,
        isAuthenticating: false,
      };

    default:
      return state;
  }
};

export {authReducer as auth};
