import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

export const withPCTranslation = (WrappedComponent) => {
  const mapStateToProps = (state) => ({
    user: state.auth.user,
    controlItems: state.setupItems.controlItems,
  });
  return connect(mapStateToProps)(
    withTranslation()(
      class ContainerComponent extends Component {
        state = {
          toggle: false,
        };
        toggleState = () => {
          this.setState((prevState) => {
            return {toggle: !prevState.toggle};
          });
        };

        tInput = (key, options) => {
          let value;
          if (this.props.user && this.props.user['inputLanguage'] === 'French') {
            value = this.props.t(`${key}-fr`, options);
            if (value === `${key}-fr`) {
              return '';
            } else {
              return value;
            }
          } else {
            value = this.props.t(`${key}-en`, options);
            if (value === `${key}-en`) {
              return '';
            } else {
              return value;
            }
          }
        };
        tReport = (key, options) => {
          let value;
          if (this.props.user && this.props.user['reportLanguage'] === 'French') {
            value = this.props.t(`${key}-fr`, options);
            if (value === `${key}-fr`) {
              return '';
            } else {
              return value;
            }
          } else {
            value = this.props.t(`${key}-en`, options);
            if (value === `${key}-en`) {
              return '';
            } else {
              return value;
            }
          }
        };
        render() {
          return <WrappedComponent {...this.props} tInput={this.tInput} t={this.tInput} tReport={this.tReport} user={this.props.user} controlItems={this.props.controlItems} />;
        }
      }
    )
  );
};

export default withPCTranslation;
