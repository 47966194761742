import {
  PolicyTypes,
  MAX_ARRAY,
  RiderTypes,
  FundTypes,
  Alternate_IntRate,
  CoverageTypes,
} from 'core/constants/calcConstants';
import {IsProdIDFlexLife2} from 'custom/common/utils/lookupFunctions';
import {globalVariables} from './util';

//Core - Calculation Object
export function columnItemsMerge(colArray1, colArray2, prefixStr) {
  let mergedArray = [...colArray1];
  for (let idx = 0; idx < colArray2.length; idx++) {
    let arrItem = {...colArray2[idx]};
    arrItem.name = prefixStr + '_' + colArray2[idx].name;
    arrItem.displayName = prefixStr + ' ' + colArray2[idx].displayName;
    arrItem.columnNumber = mergedArray.length + 1;

    mergedArray.push(arrItem);
  }
  return mergedArray;
}
//Core - Calculation Object
export function dblTableDataMerge(arr1, arr2) {
  let new2dArr = [];
  let temp1dArr = [];
  let temp0Arr;
  let finalLen = 0;
  if (arr1.length > arr2.length) {
    temp0Arr = Array(arr2[0].length).fill(0);
    finalLen = arr1.length;
  } else {
    temp0Arr = Array(arr1[0].length).fill(0);
    finalLen = arr2.length;
  }

  for (let idx = 0; idx < finalLen; idx++) {
    if (idx < arr1.length) {
      temp1dArr = [...arr1[idx]];
    } else {
      temp1dArr = [...temp0Arr];
    }
    if (idx < arr2.length) {
      temp1dArr = temp1dArr.concat(arr2[idx].slice(1));
    } else {
      temp1dArr = temp1dArr.concat(temp0Arr.slice(1));
    }
    new2dArr.push(temp1dArr);
  }

  return new2dArr;
}

//Core - Calculation Object
export function ResetFaceAmounts(coverages) {
  for (let aCoverageItem of coverages) {
    aCoverageItem.faceAmount = aCoverageItem.initialFaceAmount;
    if (aCoverageItem.coverages.length > 0) {
      ResetFaceAmounts(aCoverageItem.coverages);
    }
  }
}

//Core - Calculation Object
export function ResetFundValues(coverages) {
  for (let aCoverageItem of coverages) {
    ResetFundValuesInCoverage(aCoverageItem);
    if (aCoverageItem.coverages.length > 0) {
      ResetFundValues(aCoverageItem.coverages);
    }
  }
}
//Core - Calculation Object
export function ResetFundValuesInCoverage(aCoverageItem) {
  for (let aFundItem of aCoverageItem.funds) {
    aFundItem.fundValue = aFundItem.initialFundValue;
    for (let i = 0; i < aFundItem.fundValueArray.length; i++) {
      aFundItem.fundValueArray[i] = 0;
    }
  }
}

//Core - Calculation Object
export function AdjustCOIMultiplier(coverages, coiIncrease, increaseStartYear) {
  for (let aCoverageItem of coverages) {
    if (aCoverageItem.policyType === PolicyTypes.UL || aCoverageItem.riderType === RiderTypes.UL) {
      aCoverageItem.coiRateMultiplier = coiIncrease;
      aCoverageItem.coiRateMultiplierStartYear = increaseStartYear;
    }
    if (aCoverageItem.coverages.length > 0) {
      AdjustCOIMultiplier(aCoverageItem.coverages, coiIncrease, increaseStartYear);
    }
  }
}

export function getCoverageItemByType(coverages, coverageType) {
  let returnItem = {};
  for (let coverageItem of coverages) {
    if (coverageItem.coverageType === coverageType) {
      return coverageItem;
    }

    if (coverageItem.coverages.length > 0) {
      returnItem = getCoverageItemByType(coverageItem.coverages, coverageType);
      //We check to see if the object is empty by seeing if it has the coverageID property
      if (returnItem.hasOwnProperty('coverageID')) {
        return returnItem;
      }
    }
  }
  return returnItem;
}

export function getCoverageItemByRiderType(coverages, coverageType, riderType) {
  let returnItem = {};
  for (let coverageItem of coverages) {
    if (coverageItem.coverageType === coverageType && coverageItem.riderType === riderType) {
      return coverageItem;
    }

    if (coverageItem.coverages.length > 0) {
      returnItem = getCoverageItemByRiderType(coverageItem.coverages, coverageType, riderType);
      //We check to see if the object is empty by seeing if it has the coverageID property
      if (returnItem.hasOwnProperty('coverageID')) {
        return returnItem;
      }
    }
  }
  return returnItem;
}

export function getCoverageItemByCoverageID(coverages, coverageID) {
  let returnItem = {};
  for (let coverageItem of coverages) {
    if (coverageItem.coverageID === coverageID) {
      return coverageItem;
    }

    if (coverageItem.coverages.length > 0) {
      returnItem = getCoverageItemByCoverageID(coverageItem.coverages, coverageID);
      //We check to see if the object is empty by seeing if it has the coverageID property
      if (returnItem.hasOwnProperty('coverageID')) {
        return returnItem;
      }
    }
  }
  return returnItem;
}

export function getDisabilityItem(disabilityItems, disabilityType) {
  for (let disabilityItem of disabilityItems) {
    if (disabilityItem.disabilityType.toUpperCase() === disabilityType.toUpperCase()) {
      return disabilityItem;
    }
  }
  return {};
}
export function getCoverageByID(coverages, coverageID, parentCoverageID, ignoreParentID) {
  let returnItem = {};

  for (let coverageItem of coverages) {
    if (ignoreParentID) {
      if (coverageItem.coverageID === coverageID) {
        return coverageItem;
      }
    } else {
      if (
        coverageItem.coverageID === coverageID &&
        coverageItem.parentCoverageID === parentCoverageID
      ) {
        return coverageItem;
      }
    }

    if (coverageItem.coverages.length > 0) {
      returnItem = getCoverageByID(
        coverageItem.coverages,
        coverageID,
        parentCoverageID,
        ignoreParentID
      );
      //We check to see if the object is empty by seeing if it has the coverageID property
      if (returnItem.hasOwnProperty('coverageID')) {
        return returnItem;
      }
    }
  }
  return returnItem;
}
//Core - Calculation Object
export function getSolveByName(policy, name) {
  for (let solveItem of policy.solves) {
    if (solveItem.name.toUpperCase() === name.toUpperCase()) {
      return solveItem;
    }
  }
  return {};
}
//Core - Calculation Object
export function addSolveItem(policy, name, solveItem) {
  let index = getSolveIndex(policy, name);
  if (index === -1) {
    policy.solves.push(solveItem);
  } else {
    policy.solves[index] = solveItem;
  }
}
//Core - Calculation Object
function getSolveIndex(policy, name) {
  for (let i = 0; i < policy.solves.length - 1; i++) {
    if (policy.solves[i].name.toUpperCase() === name.toUpperCase()) {
      return i;
    }
  }

  return -1;
}
//Core - Calculation Object
export function setSolveIsActive(policy, name, isActive) {
  let index = getSolveIndex(policy, name);
  if (index > -1) {
    policy.solves[index].isActive = isActive;
  }
}

//Core - calculation object
export function InitializeCalcArray(calcArray) {
  calcArray = [];
  for (let i = 0; i <= MAX_ARRAY; i++) {
    calcArray.push(0);
  }
  return calcArray;
}
//Core - calculation object
export function removeFromProductSetupList(aList, name) {
  let index = -1;
  for (let i = 0; i < aList.length; i++) {
    if (aList[i].toUpperCase() === name.toUpperCase()) {
      index = i;
      break;
    }
  }
  if (index >= 0) {
    aList.splice(index, 1);
  }
  return aList;
}

//Core - calculation object
export function removeFund(aFundItems, fundName) {
  let index = -1;
  for (let i = 0; i < aFundItems.length; i++) {
    if (aFundItems[i].fundName.toUpperCase() === fundName.toUpperCase()) {
      index = i;
      break;
    }
  }
  if (index >= 0) {
    aFundItems.splice(index, 1);
  }
  return aFundItems;
}

//Core - calculation object
export function setFundAllocations(aCalcPolicy, aPPRPolicy) {
  let dblTotal = 0;
  let dblAllocation = 0;
  let dblFundValue = 0;

  for (let aFundItem of aCalcPolicy.coverages[0].funds) {
    if (!aFundItem.isExternalFund) {
      dblTotal = dblTotal + getFundValue(aFundItem, aPPRPolicy);
    }
  }

  dblTotal = PCRound(dblTotal, 2);

  for (let aFundItem of aCalcPolicy.coverages[0].funds) {
    if (!aFundItem.isExternalFund) {
      dblFundValue = getFundValue(aFundItem, aPPRPolicy);

      if (dblFundValue === 0) {
        dblAllocation = 0;
      } else {
        dblAllocation = PCRound((dblFundValue / dblTotal) * 100, 4);
      }

      //aFundItem.ChargeAllocation.AllocationPercent = dblAllocation
      //aFundItem.ChargeAllocation.IsIncludedInAllocation = True

      //aFundItem.DepositAllocation.AllocationPercent = dblAllocation
      //aFundItem.DepositAllocation.IsIncludedInAllocation = True
    }
  }
}

//Core - calculation object
export function getFundValue(aFundItem, aPPRPolicy) {
  console.log('inside calcUtils.getFundValue');
  //for DIA we use the fund's current value not the value on calculation start
  if (aFundItem.fundType === FundTypes.DIA) {
    let aPPRFundItem = FindFundbyID(aPPRPolicy.coverages[0].funds, aFundItem.fundID);
    if (aPPRFundItem) {
      return aPPRFundItem.currentFundValue;
    }
  }
  return aFundItem.fundValue;
}

//Core - calculation object
export function setPolicyFeeMultiplier(calcPolicy, multiplier) {
  calcPolicy.baseCoverage.policyFeeRateData.customRateMultiplier =
    calcPolicy.baseCoverage.policyFeeRateData.customRateMultiplier * multiplier;
}

//Core - calculation object
export function setULCOIMultiplier(coverages, multiplier) {
  for (let aCoverageItem of coverages) {
    setCoverageULCOIMultiplier(aCoverageItem, multiplier);
    if (aCoverageItem.coverages.length > 0) {
      setULCOIMultiplier(aCoverageItem.coverages, multiplier);
    }
  }
}

//Core - calculation object
export function setCoverageULCOIMultiplier(aCoverageItem, multiplier) {
  if (aCoverageItem.productSetup.isCalcPremium) {
    aCoverageItem.premiumRateData.customRateMultiplier =
      aCoverageItem.premiumRateData.customRateMultiplier * multiplier;
  }

  if (aCoverageItem.productSetup.isCalculateCharges) {
    aCoverageItem.coiLevelRateData.customRateMultiplier =
      aCoverageItem.coiLevelRateData.customRateMultiplier * multiplier;
    aCoverageItem.coiyrtRateData.customRateMultiplier =
      aCoverageItem.coiyrtRateData.customRateMultiplier * multiplier;
  }
}

//Core - calculation object
export function InitializeInterestRatesUL(calcPolicy) {
  InitializeDIAInterestRates(calcPolicy);
  if (IsProdIDFlexLife2(calcPolicy.prodID)) {
    InitializeGIAInterestRates(calcPolicy);
    InitializeSegFundInterestRates(calcPolicy);
  }
}

//Core - calculation object
export function RestoreInterestRatesUL(calcPolicy) {
  RestoreDIAInterestRates(calcPolicy);
  if (IsProdIDFlexLife2(calcPolicy.prodID)) {
    RestoreGIAInterestRates(calcPolicy);
    RestoreSegFundInterestRates(calcPolicy);
  }
}

//Core - calculation object
export function InitializeDIAInterestRates(calcPolicy) {
  InitializeFundInterestRates(FundTypes.DIA, calcPolicy);
}

//Core - calculation object
export function RestoreDIAInterestRates(calcPolicy) {
  RestoreFundInterestRates(FundTypes.DIA, calcPolicy);
}

//Core - calculation object
export function InitializeGIAInterestRates(calcPolicy) {
  InitializeFundInterestRates(FundTypes.GIA, calcPolicy);
}

//Core - calculation object
export function RestoreGIAInterestRates(calcPolicy) {
  RestoreFundInterestRates(FundTypes.GIA, calcPolicy);
}

//Core - calculation object
export function InitializeSegFundInterestRates(calcPolicy) {
  InitializeFundInterestRates(FundTypes.SegFund, calcPolicy);
}

//Core - calculation object
export function RestoreSegFundInterestRates(calcPolicy) {
  RestoreFundInterestRates(FundTypes.SegFund, calcPolicy);
}

//Core - calculation object
export function InitializeFundInterestRates(fundType, calcPolicy) {
  for (let aFundItem of calcPolicy.coverages[0].funds) {
    if (aFundItem.fundType === fundType) {
      CopyInterestRateDataToInitial(aFundItem);
    }
  }
}

//Core - calculation object
export function RestoreFundInterestRates(fundType, calcPolicy) {
  for (let aFundItem of calcPolicy.coverages[0].funds) {
    if (aFundItem.fundType === fundType) {
      RestoreInterestRateDataFromInitial(aFundItem);
    }
  }
}

//Core - calculation object
export function CopyInterestRateDataToInitial(fundItem) {
  fundItem.initialIsUseInterestRate = fundItem.isUseInterestRate;
  fundItem.initialInterestRate = fundItem.interestRate;

  fundItem.initialFundRateData.rateArray = [];
  for (let i = 0; i < fundItem.fundRateData.rateArray.length; i++) {
    fundItem.initialFundRateData.rateArray[i] = fundItem.fundRateData.rateArray[i];
  }
}

//Core - calculation object
export function RestoreInterestRateDataFromInitial(fundItem) {
  fundItem.isUseInterestRate = fundItem.initialIsUseInterestRate;
  fundItem.interestRate = fundItem.initialInterestRate;

  fundItem.fundRateData.rateArray = [];
  for (let i = 0; i < fundItem.initialFundRateData.rateArray.length; i++) {
    fundItem.fundRateData.rateArray[i] = fundItem.initialFundRateData.rateArray[i];
  }
}

//Core - calculation object
export function AdjustDODInterestRate(intRateAdjustAmount, calcPolicy) {
  //The interest rate adjustment occurs from year 2 onwards
  AdjustFundInterestRate(
    intRateAdjustAmount,
    Alternate_IntRate.StartYear,
    FundTypes.DOD,
    calcPolicy
  );
}

export function ResetDODInterestRateFlag(calcPolicy) {
  ResetFundInterestRateFlag(FundTypes.DOD, calcPolicy);
}

//Core - calculation object
export function AdjustInterestRatesUL(intRateAdjustAmount, calcPolicy) {
  AdjustDIAInterestRate(intRateAdjustAmount, calcPolicy);
  if (IsProdIDFlexLife2(calcPolicy.prodID)) {
    AdjustGIAInterestRate(intRateAdjustAmount, calcPolicy);
    AdjustSegFundInterestRate(intRateAdjustAmount, calcPolicy);
  }
}

export function SetInterestRatesULTwoTier(dblRate1, intFromYear, intToYear, dblRate2, calcPolicy) {
  let intMaxDuration;
  let rateArray = [];

  for (let aFundItem of calcPolicy.coverages[0].funds) {
    if (
      (aFundItem.fundType = FundTypes.DIA) ||
      (IsProdIDFlexLife2(calcPolicy.prodID) &&
        ((aFundItem.FundType = aFundItem.fundType = FundTypes.GIA) ||
          (aFundItem.FundType = aFundItem.fundType = FundTypes.SegFund)))
    ) {
      //we must change it to use the interest rate array and populate the array accordingly
      aFundItem.isUseInterestRate = false;
      aFundItem.fundRateData.isReadRate = false;
      intMaxDuration = aFundItem.fundRateData.rateArray.length - 1;
      console.log(`intMaxDuration=${intMaxDuration}`);

      for (let i = intFromYear; i <= Math.min(intMaxDuration, intToYear); i++) {
        aFundItem.fundRateData.rateArray[i] = dblRate1;
        //console.log(`i=${i}`);
        //console.log(`dblRate1=${dblRate1}`);
      }

      for (let i = Math.min(intMaxDuration, intToYear) + 1; i <= intMaxDuration; i++) {
        aFundItem.fundRateData.rateArray[i] = dblRate2;
      }
    }
  }
}

//Core - calculation object
export function AdjustDIAInterestRate(intRateAdjustAmount, calcPolicy) {
  //'The interest rate adjustment occurs from year 3 onwards
  AdjustFundInterestRate(
    intRateAdjustAmount,
    Alternate_IntRate.StartYear_UL,
    FundTypes.DIA,
    calcPolicy
  );
}

//Core - calculation object
export function AdjustGIAInterestRate(intRateAdjustAmount, calcPolicy) {
  //'The interest rate adjustment occurs from year 3 onwards
  AdjustFundInterestRate(
    intRateAdjustAmount,
    Alternate_IntRate.StartYear_UL,
    FundTypes.GIA,
    calcPolicy
  );
}

//Core - calculation object
export function AdjustSegFundInterestRate(intRateAdjustAmount, calcPolicy) {
  //'The interest rate adjustment occurs from year 3 onwards
  AdjustFundInterestRate(
    intRateAdjustAmount,
    Alternate_IntRate.StartYear_UL,
    FundTypes.SegFund,
    calcPolicy
  );
}

//Core - calculation object
export function AdjustFundInterestRate(
  intRateAdjustAmount,
  intRateStartYear,
  fundType,
  calcPolicy
) {
  for (let aFundItem of calcPolicy.coverages[0].funds) {
    if (aFundItem.fundType === fundType) {
      aFundItem.fundRateData.isReadRate = false;
      if (aFundItem.isUseInterestRate) {
        //we must change it to use the interest rate array and populate the array accordingly
        aFundItem.isUseInterestRate = false;
        for (let i = 1; i <= intRateStartYear - 1; i++) {
          aFundItem.fundRateData.rateArray[i] = aFundItem.interestRate;
        }
        for (let i = intRateStartYear; i < aFundItem.fundRateData.rateArray.length; i++) {
          aFundItem.fundRateData.rateArray[i] = aFundItem.interestRate + intRateAdjustAmount;
        }
      } else {
        for (let i = intRateStartYear; i < aFundItem.fundRateData.rateArray.length; i++) {
          aFundItem.fundRateData.rateArray[i] =
            aFundItem.fundRateData.rateArray[i] + intRateAdjustAmount;
        }
      }
    }
  }
}

export function ResetFundInterestRateFlag(fundType, calcPolicy) {
  //This function resets aFundItem.fundRateData.isReadRate to true so that the InitializeCalculation will
  //reset the rates by reading them again
  for (let aFundItem of calcPolicy.coverages[0].funds) {
    if (aFundItem.fundType === fundType) {
      aFundItem.fundRateData.isReadRate = true;
    }
  }
}

export function AdjustDividendMultiplier(policy, dividendAlternateScalePercent) {
  let aBaseCoverage = policy.coverages[0];
  aBaseCoverage.dividendMultiplier = dividendAlternateScalePercent;
  let aPUACoverage = getCoverageItemByType(policy.coverages, CoverageTypes.PUA);
  if (aPUACoverage) {
    aPUACoverage.dividendMultiplier = dividendAlternateScalePercent;
  }
}
export function TruncateRPUs(insuranceData, inforcePolicy, columnPrefix) {
  //show the RPU death benefit according to issue age, as follows:
  //Issue age < 65, start with the 20th policy anniversary
  //Between 66 and 75 inclusive start at anniversary following 85th birthday
  //Over age 75, start with the 10th policy anniversary
  //ALSO it turns out that the RPU rate at age 100 is wrong so we have to adjust the age 100 RPU value

  let colRPU = GetColumnNumber(columnPrefix & 'RPU', insuranceData.columnItems);
  let colAge = GetColumnNumber(columnPrefix & 'Age', insuranceData.columnItems);
  let colTotalDeathBenefitBaseBOY = GetColumnNumber(
    columnPrefix & 'TotalDeathBenefitBaseBOY',
    insuranceData.columnItems
  );

  let endAge = 0;

  if (colRPU !== 0 && colAge !== 0) {
    let issueAge = inforcePolicy.baseCoverage.insured.issueAge;
    if (issueAge < 65) {
      endAge = issueAge + 20;
    } else {
      if (issueAge > 75) {
        endAge = issueAge + 20;
      } //between 65 and 75, inclusive
      else endAge = 86;
    }
  }

  for (let i = 1; i <= insuranceData.tblDataArray_Main.length; i++) {
    if (insuranceData.tblDataArray_Main[i][colAge] < endAge) {
      insuranceData.tblDataArray_Main[i][colRPU] = 0;
    } else {
      if (insuranceData.tblDataArray_Main[i][colAge] === 100) {
        insuranceData.tblDataArray_Main[i][colRPU] =
          insuranceData.tblDataArray_Main[i][colTotalDeathBenefitBaseBOY];
      }
    }
  }
}
export function SetFundAverageInterestRate(policy) {
  let averageRate = calculateAverageInterestRate(policy);

  if (policy.baseCoverage.funds.length === 2) {
    //do nothing
    return '';
  }

  for (let aFundItem of policy.coverages[0].funds) {
    if (!aFundItem.isExternalFund) {
      aFundItem.fundRateData.isReadRate = false;
      for (let i = 1; i === aFundItem.fundRateData.rateArray.length; i++) {
        aFundItem.fundRateData.rateArray[i] = averageRate;
      }
    }
  }
}

export function ReSetFundAverageInterestRate(policy) {
  if (policy.baseCoverage.funds.length === 2) {
    //do nothing
    return '';
  }

  for (let aFundItem of policy.coverages[0].funds) {
    if (!aFundItem.isExternalFund) {
      aFundItem.isReadRate = true;
    }
  }
}

function calculateAverageInterestRate(policy) {
  let averageRate = 0;
  let fundName = '';
  console.log('inside calculateAverageInterestRate policy = ', policy);

  for (let aFundItem of policy.coverages[0].funds) {
    if (!aFundItem.isExternalFund) {
      fundName = 'InterestRate_' + aFundItem.fundID;
      globalVariables[fundName] = aFundItem.fundRateData.rateArray[1];
      averageRate =
        averageRate +
        (aFundItem.chargeAllocation.allocationPercent.toFixed(3) / 100) *
          aFundItem.fundRateData.rateArray[1];
    }
  }
  averageRate = averageRate.toFixed(5);
  globalVariables.AverageInterestRate = averageRate;
  console.log('inside calculateAverageInterestRate global variables = ', globalVariables);
  return averageRate;
}
//Core - calculation object
export function ModalFactor(premiumMode) {
  return 12 / premiumMode;
}

//Core - util
function Min(a, b) {
  if (a < b) {
    return a;
  } else {
    return b;
  }
}

//Core - Calculation Object
//ToDo: this is duplicate from illustration.js.  This should be in a core module.
export function GetColumnNumber(columnName, columnItems) {
  for (let columnItem of columnItems) {
    if (columnItem.name.toUpperCase() === columnName.toUpperCase()) {
      return columnItem.columnNumber;
    }
  }
  return 0;
}

export function GetInitialColumn(columnName, columnItems, dblTableMain) {
  let columnNumber = GetColumnNumber(columnName, columnItems);
  // console.log(columnNumber)
  return dblTableMain[1][columnNumber];
}

export function GetInitialPremiumBase(coverageID, columnItems, dblTableMain) {
  let BasePremium = GetInitialColumn('AnnualPremium_' + coverageID, columnItems, dblTableMain);
  let FlatExtraPremium = GetInitialColumn(
    'AnnualFEPremium_' + coverageID,
    columnItems,
    dblTableMain
  );
  let MultipleExtraPremium = GetInitialColumn(
    'AnnualMEPremium_' + coverageID,
    columnItems,
    dblTableMain
  );
  console.log(
    `BasePremium: ${BasePremium}, FlatExtraPremium: ${FlatExtraPremium}, MultiExtraPremium: ${MultipleExtraPremium}`
  );

  return BasePremium - FlatExtraPremium - MultipleExtraPremium;
}

export function GetInitialPremiumBaseFE(coverageID, columnItems, dblTableMain) {
  return GetInitialColumn('AnnualFEPremium_' + coverageID, columnItems, dblTableMain);
}

export function GetInitialPremiumBaseME(coverageID, columnItems, dblTableMain) {
  return GetInitialColumn('AnnualMEPremium_' + coverageID, columnItems, dblTableMain);
}

export function GetInitialPremium(coverageID, columnItems, dblTableMain) {
  //Flip-Flopping between showing Annual and Modal for the riders/beneftis
  return GetInitialColumn('AnnualPremium_' + coverageID, columnItems, dblTableMain);
  //return GetInitialColumn('ModalPremium_' + coverageID, columnItems, dblTableMain);
}

export function GetCoverageID(coverageName, coverageListItems) {
  if (coverageListItems[coverageName]) return coverageListItems[coverageName].coverageID;
  else return '000';
  coverageListItems.forEach((coverageListItem) => {
    if (coverageListItem.name.toUpperCase() === coverageName.toUpperCase()) {
      return coverageListItem.coverageID;
    }
  });
  return '000';
}

//ToDo This needs to be moved to a core utils module
export function FindFund(coverages, fundType) {
  for (let coverageItem of coverages) {
    let aFundItem = FindFundInCoverage(coverageItem, fundType);
    if (aFundItem) {
      return aFundItem;
    }
    if (coverageItem.coverages.length > 0) {
      let aFundItem = FindFund(coverageItem.coverages, fundType);
      if (aFundItem) {
        return aFundItem;
      }
    }
  }
  return null;
}

export function FindFundInCoverage(coverageItem, fundType) {
  for (let fundItem of coverageItem.funds) {
    if (fundItem.isActive) {
      if (fundItem.fundType === fundType) {
        return fundItem;
      }
    }
  }
  return null;
}

function FindFundbyID(coverageItem, fundID) {
  for (let fundItem of coverageItem.funds) {
    if (fundItem.isActive) {
      if (fundItem.fundID === fundID) {
        return fundItem;
      }
    }
  }
  return null;
}

export function PCRound(n, digits) {
  var negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (n < 0) {
    negative = true;
    n = n * -1;
  }
  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.round(n) / multiplicator).toFixed(2);
  if (negative) {
    n = (n * -1).toFixed(2);
  }
  return n;
}
