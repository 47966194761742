import axios from 'axios';
import {ActionTypes} from '../types';
import {setAlert} from './alertActions';

export const loadControlItems = () => async (dispatch) => {
  dispatch({type: ActionTypes.DATA_LOADING});
  try {
    const {data} = await axios.get('/api/GetControlItems');
    if (data && Object.keys(data).length > 0) {
      dispatch({
        type: ActionTypes.CONTROLITEMS_LOAD,
        payload: data,
      });
    } else {
      dispatch(loadDataFail());
    }
  } catch (error) {
    console.log(error);
    dispatch(loadDataFail());
  }
};

export const loadInterfaceSetupItems = (id = 0) => async (dispatch, getState) => {
  dispatch({type: ActionTypes.DATA_LOADING});
  let res;
  try {
    if (id === 0) {
      res = await axios.get(`/api/getAllInterfaceSetupItems/`);
      dispatch({
        type: ActionTypes.INTERFACESETUPITEMS_LOAD,
        payload: res.data,
      });
    } else if (getState().setupItems && getState().setupItems.interfaceSetupArray) {
      let interfaceItem = getState().setupItems.interfaceSetupArray.find(
        (item) => item.prodID === id
      );

      dispatch({
        type: ActionTypes.INTERFACESETUPITEMS_UPDATE,
        payload: interfaceItem,
      });

      res = await axios.get(`/api/allSetups/${id}`);
      dispatch({
        type: ActionTypes.ALLSETUPS_LOAD,
        payload: res.data,
      });
    } else {
      dispatch(loadDataFail());
    }
  } catch (error) {
    console.log(error);
    dispatch(loadDataFail());
  }
};

export const loadDataFail = () => (dispatch) => {
  dispatch({type: ActionTypes.DATA_REMOVE});
  dispatch({type: ActionTypes.AUTH_ERROR});
};

export const updateControlItems = (name, parameter, value) => {
  return {
    type: ActionTypes.UPDATE_CONTROLITEMS,
    payload: {
      name,
      parameter,
      value,
    },
  };
};

export const updateControlItemsDB = (controlItems) => async (dispatch) => {
  try {
    const res = await axios.post('/api/updateControlItems', controlItems);
    dispatch(loadControlItems());
    dispatch(setAlert('Control is successfully saved', 'Success', 'controlItem'));
  } catch (error) {
    console.log(error);
    dispatch(
      setAlert('An error occurred while saving. Please try again!!!', 'Error', 'controlItem')
    );
  }
};

export const deleteControlItemDB = (controls) => async (dispatch) => {
  try {
    const res = await axios.post('/api/deleteControlItems', controls);
    dispatch(loadControlItems());
  } catch (error) {
    console.log(error);
  }
};

export const updateInterfaceSetupItemsDB = (interfaceSetupItem) => async (dispatch) => {
  try {
    const res = await axios.post('/api/updateInterfaceSetup', interfaceSetupItem);
    dispatch(loadInterfaceSetupItems());
    dispatch(setAlert('InterfaceSetup is successfully saved', 'Success', 'interfaceSetupItem'));
  } catch (error) {
    console.log(error);
    dispatch(
      setAlert('An error occurred while saving. Please try again!!!', 'Error', 'controlItem')
    );
  }
};

export const deleteInterfaceSetupItemDB = (interfaceSetupItem) => async (dispatch) => {
  try {
    const res = await axios.post('/api/deleteInterfaceSetup', interfaceSetupItem);
    dispatch(loadInterfaceSetupItems());
  } catch (error) {
    console.log(error);
  }
};
