import React, {Fragment, useContext} from 'react';
import {useSelector} from 'react-redux';
import {ICONS, INPUTTYPES, STYLES} from 'core/constants/uiConstants';
import ValidationContext from './ValidationContext';
import {Icon} from 'core/uicore';
import {usePCTranslation} from 'core/hooks';

const ValidationMessage = ({field, useIcon, propStyle: {validationmsg, erroricon}}) => {
  const {errors, formErrors} = useContext(ValidationContext);
  const {t} = usePCTranslation();
  const controlItems = useSelector((state) => state.setupItems.controlItems);

  if (field === '') {
    const errorArray = Object.keys(errors).filter((item) => controlItems[item].isDirty === true);
    const listEl = (item) => (
      <li className={`${STYLES.BASIC} ${STYLES.VALIDATION} ${validationmsg}`} key={`${item}: ${errors[item]}`}>
        {useIcon && <Icon icon={ICONS.BULLET} size={5} float='left' />}
        {t(`${item}-error`) === '' ? t(`${item}-caption`) : t(`${item}-error`)}
        {/* {controlItems[item].fieldType === INPUTTYPES.INPUT_CHECK ? t(`${item}-error`) : t(`${item}-caption`)} */}
      </li>
    );

    return <ul>{errorArray.map((item) => listEl(item))}</ul>;
  } else if (field === 'form') {
    return (
      <Fragment>
        {Object.keys(formErrors).map((error) => (
          <div key={error} className='bgErrorColor paddingMed marginSm'>
            <label className={`${STYLES.BASIC} ${STYLES.LABEL} errorlabel fontErrorColor fontSm`} name={error}>
              {formErrors[error]}
            </label>
          </div>
        ))}
      </Fragment>
    );
  } else {
    const fieldError = errors[field];
    if (fieldError && fieldError.length > 0) {
      if (useIcon) {
        return (
          <div className={`${STYLES.BASIC} ${STYLES.ERRORICON} ${erroricon}`}>
            <Icon icon={ICONS.ERROR} size={18} key={field} />
          </div>
        );
      } else {
        return (
          <label className={`${STYLES.BASIC} ${STYLES.LABEL} errorlabel fontErrorColor fontSm`} name={field}>
            {fieldError}
          </label>
        );
      }
    } else {
      return null;
    }
  }
};
export default ValidationMessage;
