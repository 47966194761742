import React, {Component, useEffect} from 'react';
import {withRouter} from 'react-router';
import {useLocation} from 'react-router-dom';

// Scroll restoration based on https://reacttraining.com/react-router/web/guides/scroll-restoration.

//ScrollToTop HoC implementation
export const withScrollToTop = withRouter(
  class ScrollToTopWithoutRouter extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
    }

    render() {
      return null;
    }
  }
);

//ScrollToTop - Hook implementation
export const ScrollToTopController = ({condition}) => {
  // console.log(condition);
  // this assumes that current router state is accessed via hook
  // but it does not matter, pathname and search (or that ever) may come from props, context, etc.
  // const  location = useLocation();

  // just run the effect on pathname and/or search change
  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [condition]);

  // renders nothing, since nothing is needed
  return null;
};
