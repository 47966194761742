import React from 'react';
import {STYLES} from 'core/constants/uiConstants';

const TooltipIcon = ({name}) => {
  return (
    <div className={STYLES.TOOLTIPICON} name={name}>
      <i className='far fa-question-circle' />
    </div>
  );
};

export default TooltipIcon;
