import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import rootReducers from '../reducers';
import thunk from 'redux-thunk';

const middlewares = [thunk];
const initialState = {};
const enhancers = () => {
  if (process.env.NODE_ENV !== 'development') {
    console.log('Production');
    return applyMiddleware(...middlewares);
  } else {
    console.log('Development');
    return composeWithDevTools(applyMiddleware(...middlewares));
  }
};
export const dataStore = createStore(rootReducers, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
// export const dataStore = createStore(rootReducers, initialState, enhancers());
