import React, { Fragment, useState, useRef, useEffect, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { PrivateRoute, withAutoLogout } from "core/hocs";
import Header from "./Header";
import { useRouter } from "core/hooks";
import { Spinner } from "core/uicore";
import { NEW_BUSINESS } from "../utils/constants";
import { ScrollToTopController } from "core/hooks/ScrollToTop";
import WelcomeModal from "./WelcomeModal";
import BusinessIntelligence from "custom/bi/BusinessIntelligence";
const TemplateApp = React.lazy(() => import("custom/template/TemplateApp"));
const DeveloperApp = React.lazy(() => import("custom/developer/DeveloperApp"));
const Admin = React.lazy(() => import("custom/admin/Admin"));
const ProNeeds = React.lazy(() => import("custom/proNeeds/ProNeeds"));
const Illustration = React.lazy(() => import("custom/newbiz/Illustration"));
const SavedIllustration = React.lazy(() =>
  import("custom/newbiz/ui/SavedIllustration")
);
const Inforce = React.lazy(() => import("custom/inforce/Inforce"));
const Eapplication = React.lazy(() =>
  import("custom/eApplication/Eapplication")
);
const EAppSettings = React.lazy(() =>
  import("custom/admin/EappUI/EAppSettings")
);

const Routes = ({ ...props }) => {
  const [myRef, setMyRef] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const illusRef = useRef();
  const savedIllusRef = useRef();
  const { location, pathname } = useRouter();
  const { isAdminAccessible, isInforceAccessible, isNewBusinessAccessible } =
    props;
  useEffect(() => {
    if (props.auth && props.auth.user && props.auth.user["isFirstRun"]) {
      setWelcomeModal(true);
    }
  }, []);

  useEffect(() => {
    if (pathname === "/illustration") {
      setMyRef(illusRef);
    } else if (pathname === "/illustration/savedIllustration") {
      setMyRef(savedIllusRef);
    }
  }, [pathname, illusRef, savedIllusRef]);

  useEffect(() => {
    /**
     * Example for below line of code:
     * location.pathname -> /inforce/alphalookup
     * page -> inforce
     * if invalid page, redirect to login, login redirects user to default app if logged in
     */
    const pathArray = pathname.split("/");
    const page = pathArray[1].toLowerCase();
    if (!(page in props.routes)) {
      setInvalidUrl(true);
    } else if (pathArray.length > 2) {
      /**
       * Example for below line of code:
       * location.pathname -> /inforce/alphalookup
       * pageArray.length -> 3
       * Loop through the array of particular page and look for valid path
       * if invalid path, redirect to login, login redirects user to default app if logged in
       */
      for (let i = 0; i < props.routes[page].length; i++) {
        if (
          props.routes[page][i].paths[0] === pathname ||
          props.routes[page][i].paths[1] === pathname
        ) {
          setInvalidUrl(false);
          break;
        } else if (i === props.routes[page].length - 1) {
          setInvalidUrl(true);
        }
      }
    }
    setIsLoading(false);
  }, [pathname]);

  useEffect(() => {
    if (props?.auth?.user?.isWhiteLabel) {
      document.documentElement.classList.remove(
        ...document.documentElement.classList
      );
      if (props?.auth?.user.whiteLabelProfile) {
        document.documentElement.classList.add(
          props?.auth?.user.whiteLabelProfile
        );
      }
    }
  }, [props?.auth?.user]);

  /**
   * Ref based call to a function in a child component.
   * Ref was set in above useEffect condition based on current route, whether it is createIllustration or savedIllustration
   */
  const updateIllusForm = (formData, isSharedOwnershipComplete) => {
    myRef.current.loadSharedOwnershipModalForm(
      formData,
      isSharedOwnershipComplete
    );
  };

  if (invalidUrl) {
    return <Redirect to="/login" />;
  }
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Fragment>
      {welcomeModal && (
        <WelcomeModal
          {...props}
          onWelcomeModalClose={() => setWelcomeModal(false)}
        />
      )}
      <ScrollToTopController condition={pathname} />
      <Header {...props} updateIllusForm={updateIllusForm} />
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

          <PrivateRoute
            path="/eapplication"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={
              props.isEapplicationAccessible() ||
              props.isEapplicationAdministrator()
            }
          >
            {location.state?.from === NEW_BUSINESS ||
            props.isEapplicationAdministrator() ||
            true ? (
              <Eapplication {...props} />
            ) : (
              <Redirect to="/login" />
            )}
          </PrivateRoute>
          <PrivateRoute
            path="/eapp-settings"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={true}
          >
            <EAppSettings {...props} />
          </PrivateRoute>

          <PrivateRoute
            path="/illustration/savedIllustration"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={props.isNewBusinessAccessible()}
          >
            <SavedIllustration {...props} ref={savedIllusRef} />
          </PrivateRoute>
          <PrivateRoute
            path="/illustration"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={props.isNewBusinessAccessible()}
          >
            <Illustration {...props} ref={illusRef} />
          </PrivateRoute>
          <PrivateRoute
            path="/inforce"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={props.isInforceAccessible()}
          >
            <Inforce {...props} />
          </PrivateRoute>
          <PrivateRoute
            path="/proneeds"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={true}
          >
            <ProNeeds {...props} />
          </PrivateRoute>
          <PrivateRoute
            path="/admin"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={props.isAdminAccessible()}
          >
            <Admin {...props} />
          </PrivateRoute>
          <PrivateRoute
            path="/template"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={true}
          >
            <TemplateApp {...props} />
          </PrivateRoute>
          <PrivateRoute
            path="/developer"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            // isAuthorized={props.isDeveloper()}
            isAuthorized={true}
          >
            <DeveloperApp {...props} />
          </PrivateRoute>
          <PrivateRoute
            path="/bi"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={props.isBusinessIntelligenceAccesible()}
          >
            <BusinessIntelligence {...props} />
          </PrivateRoute>
          <PrivateRoute
            path="/eapp-settings"
            isAdmin={isAdminAccessible}
            isInforce={isInforceAccessible}
            isNewBusiness={isNewBusinessAccessible}
            isAuthorized={true}
          >
            <EAppSettings {...props} />
          </PrivateRoute>
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

// export default withAutoLogout(Routes);

export default Routes;
