export const isLaterDate = (to, fro) => {
  //Date format is DD/MM/YYYY
  let toNumeric = parseInt(to.slice(6) + to.slice(3, 5) + to.slice(0, 2));
  let froNumeric = parseInt(fro.slice(6) + fro.slice(3, 5) + fro.slice(0, 2));

  return toNumeric >= froNumeric;
};

export const buildAgentName = (agent) => {
    //remove all 0s first
    let padAgent = agent.agentID.replace('0', '');
    padAgent = PadRight(padAgent, '0', 6);
    if (padAgent === '') {
        return agent.agentName;
    } else {
        return agent.agentName + "   #" + padAgent;
    }
}

export const PadRight = (padString, padCharacter, padLength) => {
    if (padString.trim() === '') {
        return '';
    } else {
        let newString = padCharacter.repeat(padLength) + padString;
        return newString.substring(newString.length - padLength, newString.length);
    }
}