import {ActionTypes} from 'custom/common/redux/Types';
import axios from 'axios';
import {getPageConceptSelection} from 'custom/common/utils/customEvents';
import {setAlert} from 'core/redux/actions';

export const updateReportOptionsProfile = (formData) => async (dispatch, getState) => {
  let user = {...getState().auth.user};
  const isAuthenticated = getState().auth.isAuthenticated;

  if (isAuthenticated) {
    let { pageSelection, conceptSelection, incomePayout } =
      getPageConceptSelection();
    let reportFormData = {
      chkCoverPage: pageSelection["chkCoverPage"],
      chkTaxPage: pageSelection["chkTaxPage"],
      chkUnderwritingPage: pageSelection["chkUnderwritingPage"],
      chkComplianceNotice: pageSelection["chkComplianceNotice"],
      chkBenefitsPage: pageSelection["chkBenefitsPage"],
      chkCoveragePage: pageSelection["chkCoveragePage"],
      chkProductCompare: conceptSelection["chkProductCompare"],
      chkInternalRoR: conceptSelection["chkInternalRoR"],
      chkCharitableGiving: conceptSelection["chkCharitableGiving"],
      chkInsuranceInvestment: conceptSelection["chkInsuranceInvestment"],
      chkSharedOwnership: conceptSelection["chkSharedOwnership"],
      chkIncomeStoryboardPage: incomePayout["chkIncomeStoryboardPage"],
      chkIncomeNonRegPage: incomePayout["chkIncomeNonRegPage"],
      txtCharitableGivingIntRate: formData["txtCharitableGivingIntRate"],
      txtCharitableGivingTaxRate: formData["txtCharitableGivingTaxRate"],
      txtInsuranceInvestmentIntRate: formData["txtInsuranceInvestmentIntRate"],
      txtInsuranceInvestmentTaxRate: formData["txtInsuranceInvestmentTaxRate"],
      txtEmployerBenefitPeriod: formData["txtEmployerBenefitPeriod"],
      txtEmployerPayPeriod: formData["txtEmployerPayPeriod"],
      rdoDuration: formData["rdoDuration"],
      cboSelectedYears: formData["cboSelectedYears"],
      cboIntervalYears: formData["cboIntervalYears"],
      txtPy1: formData["txtPy1"],
      txtPy2: formData["txtPy2"],
      txtPy3: formData["txtPy3"],
      txtPy4: formData["txtPy4"],
      txtPy5: formData["txtPy5"],
      txtAgeD1: formData["txtAgeD1"],
      txtAgeD2: formData["txtAgeD2"],
      txtAgeD3: formData["txtAgeD3"],
      txtAgeD4: formData["txtAgeD4"],
      txtAgeD5: formData["txtAgeD5"],
      txtIncomePayoutLoanRate: formData["txtIncomePayoutLoanRate"],
      txtIncomePayoutIntRate: formData["txtIncomePayoutIntRate"],
      txtIncomePayoutTaxRate: formData["txtIncomePayoutTaxRate"],
    };
    const userFormData = { ...user["formData"], ...reportFormData };
    user["formData"] = reportFormData;

    try {
      const res = await axios.post("/users/update", user);
      user["formData"] = userFormData;
      dispatch({
        type: ActionTypes.UPDATE_PROFILE,
        payload: user,
      });
      console.log("Updating reports profile", res.data);
    } catch (error) {
      if (error && error.response) {
        dispatch(
          setAlert(
            error.response.data["message"],
            "Error",
            "updateProfile",
            5000
          )
        );
        console.log(error.response);
      }
    }
  }
};
