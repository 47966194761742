import React, {useState, useEffect, Fragment, useContext} from 'react';
import {useSelector} from 'react-redux';
import {ValidationMessage, ValidationContext} from '../formValidation';
import {STYLES} from 'core/constants/uiConstants';
import {TooltipIcon, TooltipText} from '../uicore';
import {usePCTranslation} from 'core/hooks';

const TextboxSubmit = ({getchangeCallback, getfocusCallback, lostfocusCallback, getclickCallback, stateData, name, text, autoFocus, readOnly, refItem, propStyle, buttonName, buttonType}) => {
  const {t} = usePCTranslation();
  const {errors, formErrors} = useContext(ValidationContext);
  const {controlItems} = useSelector((state) => ({
    controlItems: state.setupItems.controlItems ? state.setupItems.controlItems : null,
  }));

  let isDisabled = false;
  if (controlItems && controlItems[buttonName]) {
    isDisabled = controlItems[buttonName].displayDisabled;
  }
  const [isDisable, setIsDisable] = useState(false);
  useEffect(() => {
    setIsDisable(isDisabled);
  }, [isDisabled]);

  const controlOnChange = (event) => {
    event.persist();
    event.preventDefault();
    //the event.target.value is the after-change value here but...
    getchangeCallback(event, event.target.name, event.target.value);
    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnClick = (event) => {
    event.persist();
    //the event.target.value is the after-change value here but...
    getclickCallback(event, buttonName, event.target.value);
    //We do not want to stop this so that it can go to the validation routines
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnFocus = (event) => {
    getfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };

  const controlOnLostFocus = (event) => {
    lostfocusCallback(event, event.target.name, event.target.value);
    //DO NOT propagate this event up as it should only be for the current control
    event.stopPropagation();
  };
  let containerClass = `${STYLES.INPUTCONTAINER} ${propStyle.textcontainer}`;
  if ((errors[name] || formErrors[name]) && controlItems[name] && controlItems[name].isDirty) {
    containerClass += ' errorState';
  }
  if (controlItems && controlItems[name] && controlItems[name].displayDisabled) containerClass += ' disabled';
  if (controlItems && controlItems[name] && controlItems[name].displayHidden) containerClass += ' hiddenField';

  const convertUnicode = (input) => {
    return input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
      var charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });
  };

  const submitInput = () => {
    return (
      <div className={`${STYLES.INPUTSUBMIT} input-submit`} id={name}>
        <input
          className={`${STYLES.BASIC} ${STYLES.TEXT} ${propStyle.control}`}
          value={stateData && stateData[name] ? stateData[name] : ''}
          name={name}
          readOnly={(controlItems[name] && controlItems[name].readOnly) || readOnly}
          onFocus={controlOnFocus}
          onChange={controlOnChange}
          autoFocus={autoFocus}
          onBlur={controlOnLostFocus}
          ref={refItem}
          autoComplete='new_off'
        />
        <button name={buttonName} type='submit' className={`${propStyle.submitButton}`} ref={refItem} disabled={isDisable} onClick={controlOnClick}>
          {/* {convertUnicode(buttonText)} */}
          <img id={buttonName} src={buttonType} alt={convertUnicode('\u203A')} />
        </button>
      </div>
    );
  };

  return (
    <div id={name} className={containerClass}>
      <label className={`${STYLES.BASIC} ${STYLES.LABEL} ${propStyle.label}`} id={name}>
        {t(`${name}-caption`)}
      </label>
      {!controlItems[name].showTooltip ? (
        submitInput()
      ) : controlItems[name].showTooltipIcon ? (
        <Fragment>
          <div className={`${STYLES.TOOLTIP}`}>
            <TooltipText name={name} />
            <TooltipIcon name={name} />
          </div>
          {submitInput()}
        </Fragment>
      ) : (
        <div className={`${STYLES.TOOLTIP}`}>
          <TooltipText name={name} />
          {submitInput()}
        </div>
      )}

      <ValidationMessage field={name} size='10' propStyle={{validationmsg: 'textboxvalidation'}} />
    </div>
  );
};
TextboxSubmit.defaultProps = {
  autoFocus: false,
  readOnly: false,
};

export {TextboxSubmit};
