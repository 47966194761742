import React from 'react';
import {Button} from 'core/controls';

const QuestionModalFooter = ({
  isDisable,
  controlGetFocus,
  controlLostFocus,
  controlClick,
  setElement,
  onSelectOK,
  onSelectCancel,
  btnCancel,
  btnOK,
  isInfo,
  isButtonTogether = false,
  isReverse = false,
}) => {
  return (
    <div
      className={`flexContainer modalControls${
        isButtonTogether ? '' : ' justifyBetween'
      } paddingMed borderTop borderNeutral borderThin noCols alignCenter bgWhite borderRadiusBottomLg`}>
      {!isInfo && (
        <Button
          text=""
          getfocusCallback={controlGetFocus}
          getclickCallback={(event, name, value) => {
            controlClick(event, name, value);
            onSelectCancel();
          }}
          lostfocusCallback={controlLostFocus}
          name={btnCancel}
          refItem={setElement}
          propStyle={{
            label: 'buttonlabel',
            control: `buttoncontrol bgPrimaryColor borderRadiusAll paddingMedWide marginMed ${
              !isReverse ? 'buttonOutline' : ''
            }`,
          }}
        />
      )}
      <Button
        text={''}
        getfocusCallback={controlGetFocus}
        getclickCallback={(event, name, value) => {
          controlClick(event, name, value);
          onSelectOK();
        }}
        lostfocusCallback={controlLostFocus}
        name={btnOK}
        refItem={setElement}
        propStyle={{
          label: 'buttonlabel',
          control: `buttoncontrol bgPrimaryColor borderRadiusAll paddingMedWide marginMed ${
            isReverse ? 'buttonOutline' : ''
          }`,
        }}
        disableButton={isDisable}
      />
    </div>
  );
};
QuestionModalFooter.defaultProps = {
  isDisable: false,
};

export default QuestionModalFooter;
