import React, {useState} from 'react';
import {STYLES} from 'core/constants/uiConstants';

const SidebarContainer = ({children, name, styling}) => {
  const [isVisible, setIsVisible] = useState(true);
  let classSidebarContainer = `${STYLES.SIDEBARCONTAINER} ${styling}`;

  const toggleSideBar = (event) => {
    if (event.target.name && event.target.name !== name) {
    } else {
      setIsVisible(!isVisible);
    }
  };
  if (isVisible) {
    classSidebarContainer += ' sidebar-toggle';
  }
  return (
    <div
      id={name}
      className={classSidebarContainer}
      name={name}
      // onClick={toggleSideBar}
    >
      {children}
    </div>
  );
};

export default SidebarContainer;
