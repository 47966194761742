export const profileFormData = {
  formName: 'profile',
  CurrentField: 'txtUserName',
  txtUserName: '',
  txtEmailAddress: '',
  txtPrimaryPhone: '',
  txtSecondaryPhone: '',
  txtFaxNumber: '',
  chkPrimaryPhone: false,
  chkSecondaryPhone: false,
  imgProfilePicture: '',
  rdoInputLanguage: 'EN',
  rdoReportLanguage: 'EN',
  rdoFontSize: 'Large',
  rdoDefaultApp: 'New Business',
  btnSaveProfile: '',
  btnCancelProfile: '',
};
