import {combineReducers} from 'redux';

import * as custom from 'custom/common/redux/reducers';
import {alert} from './alertReducers';
import {auth} from './authReducers';
import {formData} from './formDataReducers';
import {setupItems} from './setupReducers';
import {roles} from './rolesReducers';
export default combineReducers({
  ...custom,
  alert,
  auth,
  formData,
  setupItems,
  roles,
});
