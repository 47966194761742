import React, {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import update from 'immutability-helper';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import WidgetCard from './WidgetCard';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';

const style = {
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  margin: '1rem',
};

const ProfileWidgets = ({widgetsData, updateWidgetsData}) => {
  const handleToggle = (id, toggle) => {
    let cloneWidgets = cloneDeep(widgetsData);
    // cloneWidgets[id].isActive = toggle;
    const card = cloneWidgets.find((card) => card.widgetID === id);
    card.isActive = toggle;
    updateWidgetsData(cloneWidgets);
  };

  const moveCard = (dragIndex, hoverIndex) => {
    updateWidgetsData(
      update(widgetsData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, widgetsData[dragIndex]],
        ],
      })
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <p>Reorder your widgets or change the visibility of the widgets you see on your dashboard.</p>
      <div style={style}>
        {widgetsData.map((card, idx) => (
          <WidgetCard
            key={card.widgetID}
            index={idx}
            moveCard={moveCard}
            widget={card}
            onToggle={handleToggle}
          />
        ))}
      </div>
    </DndProvider>
  );
};

export default ProfileWidgets;
