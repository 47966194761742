import axios from 'axios';
import {ActionTypes} from '../types';
import {setAlert} from './alertActions';
import {setAuthToken, setInterceptor} from 'core/utils/setAuthConfig';
import {loadControlItems} from './setupActions';
import {setRoutes, clearCustomStore} from 'custom/common/redux/actions';
import {sendTrackingInfo} from 'core/api/trackingWebService';

//LoginTracking
const trackLoginUser = (user) => {
  const { trackingUserName, username, agentID } = user;
  const trackingData = {
    detail: {
      formName: 'login',
      CurrentField: 'btnLogin',
    },
    header: {
      agentID,
      eventType: 'BUTTON_CLICK',
      formName: 'login',
      name: 'btnLogin',
      timestamp: Math.trunc(Date.now() / 1000),
      username: trackingUserName,
      loginUserName: username
    },
  };
  sendTrackingInfo(trackingData);
};
//Fetch User
export const fetchUser = (account = {}, isRefresh) => async (dispatch, getState) => {
  dispatch({type: ActionTypes.LOGIN_REQUEST});
  const token = JSON.parse(sessionStorage.getItem('refreshToken'));
  try {
    let data;
    const tokenMethod = sessionStorage.getItem('tokenMethod');
    if (tokenMethod === 'AD') {
      const responseAD = await axios.get(`/users/${account?.username}`);
      data = responseAD.data;
    } else {
      const response = await axios.post('/users/login', token);
      data = response.data;
    }
    /**
     * Below check is for session expiry case. Where form data persists in session storage
     * After session expiry, if some different user tries to login. All session storage and redux data is first cleared with logout() action
     * Then latest captured refresh token is restored in session which also gets cleared in logout() action call.
     * Paramater in logout ensures we don't make unnecessary call to backend to clear our refresh token
     * After this normal code execution continues
     */
    const username = sessionStorage.getItem('username');
    if (username && username !== data.username) {
      dispatch(logout(false));
      sessionStorage.setItem('refreshToken', JSON.stringify(token));
    }
    dispatch({
      type: ActionTypes.LOGIN_SUCCESS,
      payload: data,
    });
    dispatch(setRoutes(data));
    if (data['fontSize'] === 'Large') {
      document.body.classList.add('altFont');
    } else if (data['data'] === 'Small') {
      document.body.classList.remove('altFont');
    }
    if (!getState().setupItems.controlItems) {
      dispatch(loadControlItems());
    }
    if (!isRefresh) {
      trackLoginUser(data);
    }
  } catch (error) {
    console.log(error);
    dispatch({type: ActionTypes.LOGIN_FAIL});
    if (error.response) {
      dispatch(setAlert(error.response.data['message'], 'Error', 'login'));
    }
  }
};

// Login User
let cancelToken;
export const login = (username, password) => async (dispatch) => {
  dispatch({type: ActionTypes.LOGIN_REQUEST});
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.');
  }
  cancelToken = axios.CancelToken.source();
  var generateTrackingUserName = false;
  const body = { username, password, generateTrackingUserName };
  try {
    const {data} = await axios.post('/users/authenticate', body, {cancelToken: cancelToken.token});

    sessionStorage.setItem('refreshToken', JSON.stringify(data));
    // setAuthToken(data.jwt);
    setInterceptor();
    dispatch({type: ActionTypes.AUTH_SUCCESS, payload: data});
    dispatch(fetchUser(undefined, false));
  } catch (error) {
    dispatch({type: ActionTypes.AUTH_ERROR});
    if (error && error.response) {
      dispatch(setAlert(error.response.data['message'], 'Error', 'login'));
    }
    console.log(error);
  }
};

//Update user profile field
export const updateUserProfileField = (fieldName, value) => async (dispatch, getState) => {
  let user = {...getState().auth.user};
  const isAuthenticated = getState().auth.isAuthenticated;

  if (isAuthenticated) {
    user[fieldName] = value;
    try {
      const res = await axios.post('/users/update', user);
      dispatch({
        type: ActionTypes.UPDATE_PROFILE,
        payload: res.data,
      });
    } catch (error) {
      if (error && error.response) {
        console.log(error.response.data);
      }
    }
  }
};

//Update User Profile
export const updateUserProfile = (formData, t) => async (dispatch, getState) => {
  let user = {...getState().auth.user};
  const isAuthenticated = getState().auth.isAuthenticated;

  if (isAuthenticated) {
    user['profileName'] = formData['txtUserName'];
    user['emailAddress'] = formData['txtEmailAddress'];
    user['phoneNumberPrimary'] = formData['txtPrimaryPhone'];
    user['phoneNumberSecondary'] = formData['txtSecondaryPhone'];
    user['faxNumber'] = formData['txtFaxNumber'];
    user['isMobilePrimary'] = formData['chkPrimaryPhone'];
    user['isMobileSecondary'] = formData['chkSecondaryPhone'];
    user['inputLanguage'] = formData['rdoInputLanguage'];
    user['reportLanguage'] = formData['rdoReportLanguage'];
    user['fontSize'] = formData['rdoFontSize'];
    user['defaultApplication'] = formData['rdoDefaultApp'];
    user['formData'] = {};
    if (formData['widgetsData']) {
      user['widgets'] = formData['widgetsData'];
    }
    try {
      const res = await axios.post('/users/update', user);
      dispatch({
        type: ActionTypes.UPDATE_PROFILE,
        payload: res.data,
      });
      dispatch(setAlert(t('msg-InfoUpdatedSaved'), 'Success', 'updateProfile'));
    } catch (error) {
      dispatch(setAlert(error.response.data['message'], 'Error', 'updateProfile', 5000));
      console.log(error.response.data);
      // dispatch({type: ActionTypes.LOGIN_FAIL});
    }
  }
};

//Api for only Admin user
export const getAllUsers = (updatedUser) => async (dispatch, getState) => {
  let res;
  try {
    res = await axios.get('/users');
    console.log(res.data);
    dispatch({type: ActionTypes.ALL_USERS, payload: res.data});
  } catch (error) {
    if (error.response) {
      console.log(error.responderEnd);
    }
  }

  if (updatedUser) {
    const currentUser = getState().auth.user;
    if (currentUser.username === updatedUser.username) {
      updatedUser.formData = {...currentUser.formData};
      updatedUser.system = {...currentUser.system};
      dispatch({
        type: ActionTypes.UPDATE_PROFILE,
        payload: updatedUser,
      });
    }
  }
};

// Logout / Clear Profile
export const logout =
  (callApi = true) =>
  async (dispatch) => {
    let token = JSON.parse(sessionStorage.getItem("refreshToken"));
    const tokenMethod = sessionStorage.getItem("tokenMethod");

    dispatch({ type: ActionTypes.REMOVE_ALERT, name: "updateProfile" });
    dispatch({ type: ActionTypes.LOGOUT });
    dispatch({ type: ActionTypes.CLEAR_PROFILE });
    dispatch({ type: ActionTypes.CLEAR_FORMDATA, payload: {} });
    dispatch({ type: ActionTypes.DATA_REMOVE });
    dispatch(clearCustomStore());

    if (callApi && token && tokenMethod !== "AD") {
      try {
        console.log("Logout by clicking logout button");
        await axios.post("/users/logout", token);
      } catch (error) {
        console.log(error);
      }
    }
  };

// Session Timeout
export const sessionTimeout = () => async (dispatch) => {
  let token = JSON.parse(sessionStorage.getItem("refreshToken"));
  const tokenMethod = sessionStorage.getItem("tokenMethod");

  if (token && tokenMethod !== "AD") {
    try {
      console.log("Session timed out");
      await axios.post("/users/logout", token);
    } catch (error) {
      console.log(error);
    }
  } else {
    let keys = Object.keys(sessionStorage);
    let adKeys = keys.filter((key) => key.includes("login.windows.net"));
    adKeys.forEach((key) => sessionStorage.removeItem(key));
  }

  document.documentElement.classList.remove(
    ...document.documentElement.classList
  );

  // dispatch({type: ActionTypes.DATA_REMOVE});
  dispatch({ type: ActionTypes.SESSION_TIMEOUT });
};
