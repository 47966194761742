import {ActionTypes} from '../types';

const initialState = {};

const alertReducer = (state = initialState, action) => {
  const {type, payload, name} = action;
  switch (type) {
    case ActionTypes.SET_ALERT:
      return {...state, [name]: payload};
    case ActionTypes.REMOVE_ALERT:
      let newState = {...state};
      delete newState[name];
      return newState;
    default:
      return state;
  }
};

export {alertReducer as alert};
