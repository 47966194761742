import axios from 'axios';

export const sendTrackingInfo = async (trackingData) => {
  try {
    const {data} = await axios.post('/tracking/sendTrackingInfo', trackingData);
    if(data?.header?.trackingID){
      sessionStorage.setItem('currentTrackingID', data.header.trackingID);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getTrackingInfo = async (userName) => {
  try {
    let res = await axios.get(`/tracking/getTrackingInfo/${userName}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllTrackingInfo = async () => {
  try {
    let res = await axios.get('/tracking/getAllTrackingInfo');
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
