//import { globalVariables } from "./util";

let globalVariables = {
  isAppLoading: true,
};
const $ = require('jquery');

export function hideControlBatch(fieldArray, controlItems, updateControlItems) {
  for (let item = 0; item < fieldArray.length - 1; item++) {
    hideControl(fieldArray[item], controlItems, updateControlItems, false);
  }
  hideControl(fieldArray[fieldArray.length - 1], controlItems, updateControlItems, true);
}
export function hideControl(
  name,
  controlItems,
  updateControlItems,
  isUpdateControlItems = true
) {
  //We are using jquery to hide and show controls
  //if(controlItems[name].disabled !== true){

  // $(`input[name=${name}]`).hide();
  // $(`select[name=${name}]`).hide();
  // $(`label[name=${name}]`).hide();
  // $(`div[name=${name}]`).hide();
  // $(`li[name=${name}]`).hide();
  // $(`#${name}`).hide();
  $(`div[id*=${name}]`).each((i, el) => {
    $(el).hide();
  });
  $(`input[name*=${name}]`).each((i, el) => {
    $(el).hide();
  });
  $(`select[name*=${name}]`).each((i, el) => {
    $(el).hide();
  });
  $(`label[name*=${name}]`).each((i, el) => {
    $(el).hide();
  });
  $(`div[name*=${name}]`).each((i, el) => {
    $(el).hide();
  });
  $(`li[name*=${name}]`).each((i, el) => {
    $(el).hide();
  });
  $(`h2[id*=${name}]`).each((i, el) => {
    $(el).hide();
  });
  $(`h1[id*=${name}]`).each((i, el) => {
    $(el).hide();
  });
  if (controlItems && controlItems[name]) {
    controlItems[name].disabled = true; //Global variable, not for page rendering

    if (isUpdateControlItems && !globalVariables.isAppLoading) {
      updateControlItems(name, "displayHidden", true);
    } else {
      controlItems[name].displayHidden = true;
    }
  }
  //
  // }
}

export function showControlBatch(fieldArray, controlItems, updateControlItems) {
  for (let item = 0; item < fieldArray.length - 1; item++) {
    showControl(fieldArray[item], controlItems, updateControlItems, false);
  }
  showControl(
    fieldArray[fieldArray.length - 1],
    controlItems,
    updateControlItems,
    true
  );
}
export function showControl(
  name,
  controlItems,
  updateControlItems,
  isUpdateControlItems = true
) {
  //We are using jquery to hide and show controls
  // if(controlItems[name].disabled !== false){
  // $(`input[name=${name}]`).show();
  // $(`select[name=${name}]`).show();
  // $(`label[name=${name}]`).show();
  // //Removing style attribute, it was adding {display: block;} for UL Options tab and displaying tab contents in all tabs
  // $(`div[name=${name}]`).show().removeAttr('style');
  // $(`li[name=${name}]`).show();
  // $(`#${name}`).show();
  $(`div[id*=${name}]`).each((i, el) => {
    $(el).show().removeAttr("style");
  });
  $(`input[name*=${name}]`).each((i, el) => {
    $(el).show().removeAttr("style");
  });
  $(`select[name*=${name}]`).each((i, el) => {
    $(el).show().removeAttr("style");
  });
  $(`label[name*=${name}]`).each((i, el) => {
    $(el).show().removeAttr("style");
  });
  $(`div[name*=${name}]`).each((i, el) => {
    $(el).show().removeAttr("style");
  });
  $(`li[name*=${name}]`).each((i, el) => {
    $(el).show().removeAttr("style");
  });
  $(`h2[id*=${name}]`).each((i, el) => {
    $(el).show().removeAttr("style");
  });
  $(`h1[id*=${name}]`).each((i, el) => {
    $(el).show().removeAttr("style");
  });

  if (controlItems && controlItems[name]) {
    controlItems[name].disabled = false;
    if (isUpdateControlItems && !globalVariables.isAppLoading) {
      updateControlItems(name, "displayHidden", false);
    } else {
      controlItems[name].displayHidden = false;
    }
  }
  // }
}

export function disableControlBatch(fieldArray, elementItems, controlItems, updateControlItems) {
  for (let item = 0; item < fieldArray.length - 1; item++) {
    disableControl(fieldArray[item], elementItems, controlItems, updateControlItems, false);
  }
  if (fieldArray.length > 0) {
    disableControl(
      fieldArray[fieldArray.length - 1],
      elementItems,
      controlItems,
      updateControlItems,
      true
    );
  }
}

export function disableControl(
  name,
  elementItems,
  controlItems,
  updateControlItems,
  isUpdateControlItems = true
) {
  if (elementItems && elementItems[name]) {
    let element = elementItems[name];
    element.disabled = 'disabled';
  }
  //updateControlItems(name, 'disabled', true);
  if (controlItems && controlItems[name]) {
    controlItems[name].disabled = true;
    if (isUpdateControlItems && !globalVariables.isAppLoading) {
      updateControlItems(name, 'displayDisabled', true);
    } else {
      controlItems[name].displayDisabled = true;
    }
  }
}
export function enableControlBatch(fieldArray, elementItems, controlItems, updateControlItems) {
  for (let item = 0; item < fieldArray.length - 1; item++) {
    enableControl(fieldArray[item], elementItems, controlItems, updateControlItems, false);
  }
  if (fieldArray.length > 0) {
    enableControl(
      fieldArray[fieldArray.length - 1],
      elementItems,
      controlItems,
      updateControlItems,
      true
    );
  }
}
export function enableControl(
  name,
  elementItems,
  controlItems,
  updateControlItems,
  isUpdateControlItems = true
) {
  if (elementItems && elementItems[name]) {
    let element = elementItems[name];
    element.disabled = '';
  }
  //updateControlItems(name, 'disabled', false);
  controlItems[name].disabled = false;
  if (isUpdateControlItems && !globalVariables.isAppLoading) {
    updateControlItems(name, 'displayDisabled', false);
  } else {
    controlItems[name].displayDisabled = false;
  }
}

export function lockControl(name, elementItems, controlItems) {
  if (elementItems && controlItems && elementItems[name] && controlItems[name]) {
    controlItems[name].locked = true;
  }
}

export function unlockControl(name, elementItems, controlItems) {
  if (elementItems && controlItems && elementItems[name] && controlItems[name]) {
    controlItems[name].locked = false;
  }
}
