import React, {Component} from 'react';
import {ValidateData} from './coreValidation';
import ValidationContext from './ValidationContext';
import {withPCTranslation} from 'core/hocs';

class FormValidator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      formErrors: {},
      dirty: {},
      formSubmitted: false,
      getMessagesForField: this.getMessagesForField,
    };
  }

  //This runs when FormValidator is instantiated and rendered
  static getDerivedStateFromProps(props, state) {
    /* temporary for testing
        Object.keys(props.data).map(i => console.log(`props.data - ${i} = ${props.data[i]}`));
        Object.keys(props.rules).map(i => {
            console.log(`props.rules - ${i} = ${props.rules[i]}`)
            Object.keys(props.rules[i]).map(j => console.log(`props.rules[i][j] - ${j} = ${props.rules[i][j]}`));
        }
        );
        */

    //pass in a blank object if props.rules is undefined
    //state.errors = ValidateData(props.data, props.rules || {}, props.t, props.updateStoreFormData);
    state.errors = ValidateData(props.data, props.controlItems || {}, props.t, props.user || {});
    if (props.inlineErrorRef) {
      props.inlineErrorRef.current = state.errors;
    }
    //Temporary logging function to log all errors
    // Object.keys(state.errors).map((i) => console.log(`log error ${i} = ${state.errors[i]}`));

    //console.log(state.errors.form)
    // if (state.formSubmitted && Object.keys(state.errors).length === 0) {
    //   let formErrors = props.validateForm(props.data, props.controlItems);
    //   console.log(formErrors);
    //   if (Object.keys(formErrors).length > 0) {
    //     state.formErrors = formErrors;
    //   }
    //   state.formSubmitted = false;
    // }
    return state;
  }

  clearFormErrors = (field = '') => {
    if (this.state.formSubmitted === true) {
      if (field === '') {
        this.setState({formSubmitted: false, formErrors: {}});
      } else if (this.state.formErrors[field]) {
        let formErrors = {...this.state.formErrors};
        delete formErrors[field];
        this.setState({formErrors});
      }
    }
  };

  handleChange = (event) => {
    event.persist();
    let name = event.target.name;
    this.setState((state) => (state.dirty[name] = true));
    this.clearFormErrors(name);
  };
  formValid = () => {
    return Object.keys(this.state.formErrors).length === 0;
  };
  fieldsValid = () => {
    return Object.keys(this.state.errors).length === 0;
  };
  handleClick = (event) => {
    //event should be persisted so that event.target does not loose its value after async operation
    event.persist();
    this.clearFormErrors(event.target.name);
    if (
      event.target &&
      event.target.name &&
      this.props.submitField &&
      this.props.submitField.includes(event.target.name)
    ) {
      this.clearFormErrors();
      let formErrors = this.props.validateForm(this.props.data, this.props.controlItems);
      this.setState({formSubmitted: true, dirty: {}, formErrors}, () => {
        if (Object.keys(this.state.errors).length > 0) {
          Object.keys(this.state.errors).forEach(
            (field) => (this.props.controlItems[field].isDirty = true)
          );
        }
        Object.keys(formErrors).forEach((field) => (this.props.controlItems[field].isDirty = true));
        if (this.formValid() && this.fieldsValid()) {
          this.props.submit(event, event.target.name, event.target.value);
        } else {
          this.props.error(formErrors, event, event.target.name, event.target.value);
        }
      });
    }
  };

  getButtonClasses() {
    return this.state.formSubmitted && !this.formValid ? 'btn-danger' : 'btn-primary';
  }

  getMessagesForField = (field) => {
    if (field === '') {
      return this.state.errors;
    } else if (field === 'form') {
      return this.state.formErrors;
      // return this.state.formSubmitted || this.state.dirty[field] || true ? this.state.formErrors || [] : [];
    } else {
      return this.state.formSubmitted || this.state.dirty[field] || true
        ? this.state.errors[field] || []
        : [];
    }
  };

  render() {
    return (
      <React.Fragment>
        <ValidationContext.Provider value={this.state}>
          <div
            className={this.props.styling}
            onChange={this.handleChange}
            onClick={this.handleClick}>
            {this.props.children}
          </div>
        </ValidationContext.Provider>
      </React.Fragment>
    );
  }
}
/**
 * withPCTranslation: provides translation and controlItems
 */
export default withPCTranslation(FormValidator);
