import React, {Component} from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');

const Modal = ({onClick, children, propStyle}) => {
  return ReactDOM.createPortal(
    <div onClick={onClick} className={`modal ${propStyle}`}>
      {children}
    </div>,
    document.querySelector('#modal-root')
  );
};
// class Modal extends Component {
//     constructor(props){
//         super(props);
//         this.el = document.createElement('div');
//     }
//     componentDidMount(){
//         modalRoot.appendChild(this.el);
//     }
//     componentWillUnmount(){
//         modalRoot.removeChild(this.el);
//     }
//     render() {
//         return ReactDOM.createPortal(this.props.children, this.el)
//     }
// }

export default Modal;
