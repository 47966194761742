import React, {Fragment, useState, useEffect} from 'react';
import {STYLES} from 'core/constants/uiConstants';

const TabContent = ({children, activeTab}) => {
  const [active, setActive] = useState('');

  useEffect(() => {
    if (activeTab === '') {
      setActive(children[0].props.name);
    } else {
      setActive(activeTab);
    }
  }, [activeTab]);
  return (
    <Fragment>
      {children.map((child) => {
        if (child === undefined) {
          return undefined;
        }
        const {name, children, styling} = child.props;
        let classNames = `${STYLES.TABCONTENT} ${styling} `;
        if (name === active) classNames += STYLES.TABCONTENTACTIVE;
        return (
          <div key={name} className={classNames} name={name}>
            {children}
          </div>
        );
      })}
    </Fragment>
  );
};

export default TabContent;
