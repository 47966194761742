import React, {Component} from 'react';
import { closeIcon } from "assets";
import {Button} from 'core/controls';
import {Modal, ModalTitle} from 'core/uicore';
import SharedConceptModalFooter from './ui/SharedConceptModalFooter';
import ModalSelection from './ui/ModalSelection';

export const FUND_SELECTION = 'fundSelection';
export const BENEFICIARY_DESIGNATIONS = 'beneficiaryDesignations';
export const PRODUCT_SELECTION = 'productSelection';
export const PERSONAL_INFO = 'personalInfo';
export const PREMIUM_ALLOCATION = 'premiumAllocation';
export const EMPLOYER_BENEFIT = 'employerBenefit';
export const READ_ONLY = 'readOnly';
const SLIDE_COUNT = 17;

class SharedOwnershipModal extends Component {
  state = {
    modalIndex: 1,
    pageCount: 1,
    currPage: 1,
    modalName: READ_ONLY,
    formData: {
      formName: 'sharedOwnershipConcept',
      readOnly: {
        numErrors: 0,
      },
      personalInfo: {
        formName: 'sharedOwnershipConcept',
        txtSharedInsuredName: 'Insured Name',
        txtSharedAge: '30',
        txtSharedRetirementAge: '65',
        rdoSharedSex: 'M',
        rdoSharedSmokeClass: 'S',
        txtCompanyName: 'Company Name',
        txtSharedEmployerPayPeriod: '35',
        txtSharedEmployerBenefitPeriod: '35',
        numErrors: 0,
      },
      beneficiaryDesignations: {
        formName: 'sharedOwnershipConcept',
        txtSharedBeneficiaryName1: 'Company Name',
        cboSharedRelationshipToInsured1: 'EM',
        txtSharedPercentShare1: 'NAAR',
        chkSharedIrrevocable1: true,
        txtSharedBeneficiaryName2: 'Name',
        cboSharedRelationshipToInsured2: 'SP',
        txtSharedPercentShare2: '100% of Fund',
        chkSharedIrrevocable2: false,
        txtSharedBeneficiaryName3: '',
        cboSharedRelationshipToInsured3: '',
        txtSharedPercentShare3: '',
        chkSharedIrrevocable3: false,
        txtSharedBeneficiaryName4: '',
        cboSharedRelationshipToInsured4: '',
        txtSharedPercentShare4: '',
        chkSharedIrrevocable4: false,
        txtSharedBeneficiaryName5: '',
        cboSharedRelationshipToInsured5: '',
        txtSharedPercentShare5: '',
        chkSharedIrrevocable5: false,
        numErrors: 0,
      },
      premiumAllocation: {
        formName: 'sharedOwnershipConcept',
        rdoSharedPaymentMode: '012',
        txtSharedPremiumAmount: '10000',
        numErrors: 0,
      },
      productSelection: {
        formName: 'sharedOwnershipConcept',
        txtSharedDeathBenefitAmount: '100000',
        rdoSharedCOIType: 'L',
        rdoSharedDeathBenefitType: 'LF',
        chkSharedFundSelection1: true,
        cboSharedFundSelection1: 'DI',
        txtSharedFundSelectionInterestRate1: '1.25',
        txtSharedFundSelectionAllocationPercent1: '100',
        chkSharedFundSelection2: false,
        cboSharedFundSelection2: 'CBF',
        txtSharedFundSelectionInterestRate2: '1.50',
        txtSharedFundSelectionAllocationPercent2: '0',
        chkSharedFundSelection3: false,
        cboSharedFundSelection3: 'CEFI',
        txtSharedFundSelectionInterestRate3: '5.95',
        txtSharedFundSelectionAllocationPercent3: '0',
        chkSharedFundSelection4: false,
        cboSharedFundSelection4: 'APEF',
        txtSharedFundSelectionInterestRate4: '4.15',
        txtSharedFundSelectionAllocationPercent4: '0',
        numErrors: 0,
      },
    },
  };
  //modalNames are for handling individual modal windows formData. Counter start from 1, so there is one extra ReadOnly at start
  modalNames = [
    READ_ONLY, //Unused 0 index
    READ_ONLY,
    READ_ONLY,
    READ_ONLY,
    PERSONAL_INFO, //4
    READ_ONLY,
    READ_ONLY,
    READ_ONLY,
    READ_ONLY,
    BENEFICIARY_DESIGNATIONS, //9
    READ_ONLY,
    READ_ONLY,
    PRODUCT_SELECTION, //12
    PREMIUM_ALLOCATION, //13
    READ_ONLY,
    READ_ONLY,
    READ_ONLY,
    READ_ONLY,
  ];
  // imgArray = [C21, C22, C23, C24, C25, C26, C27, C28, C29, C210, C211, C212, C213, C214, C215, C216, C217];
  handleBack = () => {
    this.setState((prevState) => ({
      modalIndex: prevState.modalIndex - 1,
      modalName: this.modalNames[prevState.modalIndex - 1],
    }));
  };
  handleNext = () => {
    if (this.state.modalIndex === SLIDE_COUNT) {
      this.handleFinish();
    } else {
      this.setState((prevState) => {
        return {
          modalIndex: prevState.modalIndex + 1,
          modalName: this.modalNames[prevState.modalIndex + 1],
        };
      });
    }
  };
  flattenData = (formData) => {
    let data = {
      formName: formData['formName'],
      ...formData[BENEFICIARY_DESIGNATIONS],
      ...formData[PRODUCT_SELECTION],
      ...formData[PREMIUM_ALLOCATION],
      ...formData[PERSONAL_INFO],
    };
    return data;
  };
  handleCancel = () => {
    this.props.onCancel(this.flattenData(this.state.formData));
  };
  handleFinish = () => {
    // this.props.OnCreateReport(this.formData);
    this.props.onFinish(this.flattenData(this.state.formData));
  };

  elementItems = {};
  updateModalFormData = (name, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [this.state.modalName]: {...this.state.formData[this.state.modalName], [name]: value},
      },
    });
    // this.formData[this.state.modalName][name] = value;
  };
  setElement = (element) => {
    if (element !== null) {
      this.elementItems[element.name] = element;
    }
  };
  controlChange = async (event, name, value) => {
    // await this.setState({modalName});
    //event.persist();
    await this.updateModalFormData(name, value);
    // this.props.globalChange(event, this.flattenData(this.state.formData), this.elementItems, this.updateModalFormData);
    //Below is right way to call globalChange, because it runs business rules in customEvent which should directly apply to formData, and not on flattened data
    this.props.globalChange(
      event,
      this.state.formData[this.state.modalName],
      this.elementItems,
      this.updateModalFormData
    );
    // this.formData[this.state.modalName] = {...this.formData[this.state.modalName]};
    // this.formData = {...this.formData};
  };
  controlClick = (event, name, value) => {
    this.props.globalClick(
      name,
      this.flattenData(this.state.formData),
      this.elementItems,
      this.updateModalFormData
    );
  };
  controlGetFocus = (event, name, value) => {
    this.props.globalGetFocus(
      event,
      this.state.formData,
      this.elementItems,
      this.updateModalFormData
    );
  };
  controlLostFocus = (event, name, value) => {
    this.props.globalLostFocus(
      event,
      this.state.formData,
      this.elementItems,
      this.updateModalFormData
    );
  };
  submitData = () => {};
  setBtnNext = () => {
    if (this.state.modalIndex === SLIDE_COUNT) {
      return 'btnSharedOwnershipFinish';
    }
    return 'btnSharedOwnershipNext';
  };
  loadSetupItems = async () => {
    // await this.props.loadInterfaceSetupItems();
    // this.props.controlItems['cboProduct'].useOptions = true;
    // this.props.controlItems['cboProduct'].optionItems = this.buildProductOptionItems(this.props.interfaceSetupArray);
    if (this.props.interfaceSetupArray && this.props.interfaceSetupArray.length > 0) {
      this.props.loadInterfaceSetupItems(602);
    } else {
      console.log('issue check');
      await this.sleep(3);
      this.props.loadInterfaceSetupItems(602);
    }
  };
  sleep(seconds) {
    return new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000));
  }
  componentDidMount() {
    this.loadSetupItems();
  }
  render() {
    const {
      controlChange,
      controlClick,
      controlGetFocus,
      controlLostFocus,
      setElement,
      elementItems,
      updateModalFormData,
      handleCancel,
      handleBack,
      handleNext,
      submitData,
      setBtnNext,
      state: {modalIndex, formData, modalName},
      props: {t, onLoad, controlItems},
    } = this;
    return (
      <Modal onClick={(e) => e.stopPropagation()} propStyle="modalDark">
        <div className="modalcontainerLg paddingLg borderRadiusAllLg">
          <Button
            text="Cancel"
            getfocusCallback={controlGetFocus}
            getclickCallback={(event, name, value) => {
              controlClick(event, name, value);
              handleCancel();
            }}
            lostfocusCallback={controlLostFocus}
            name="btnXSharedOwnershipCancel"
            refItem={setElement}
            propStyle={{
              label: "buttonlabel",
              control: `modalClose`,
            }}
            logo={{
              srcLogo: closeIcon,
              altLogo: "",
              classLogo: "new-logo branding-image-filter",
            }}
            showIcon={true}
          />

          {/* <ValidationMessage
            field="form"
            useIcon={false}
            propStyle={{ validationmsg: "formvalidation" }}
          /> */}
          <div className="PROFILE">
            <div className="">
              <ModalTitle title={"SharedOwnership"} t={t} fontSize="fontHdr2" />
              <ModalSelection
                controlChange={controlChange}
                controlClick={controlClick}
                controlGetFocus={controlGetFocus}
                controlLostFocus={controlLostFocus}
                setElement={setElement}
                elementItems={elementItems}
                controlItems={controlItems}
                formData={formData}
                onLoad={onLoad}
                updateFormData={updateModalFormData}
                modalIndex={modalIndex}
                t={t}
              />
            </div>
            <SharedConceptModalFooter
              controlGetFocus={controlGetFocus}
              controlLostFocus={controlLostFocus}
              controlClick={controlClick}
              setElement={setElement}
              onSelectOK={handleNext}
              onSelectCancel={handleCancel}
              onSelectBack={handleBack}
              modalIndex={modalIndex}
              totalPages={SLIDE_COUNT}
              formData={formData}
              modalName={modalName}
              btnOK={setBtnNext()}
              btnCancel={"btnSharedOwnershipCancel"}
              btnBack={"btnSharedOwnershipBack"}
              isDisable={modalIndex === 1}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default SharedOwnershipModal;
