import {
  arrowLongCurvedLogo,
  arrowSmallLogo,
  arrowStraightLogo,
  blueExecLogo,
  companyLogo,
  ProcompGreyLogo,
  purpleExecCorpLogo,
} from 'assets';
import React from 'react';

const IntroModal6 = ({modalIndex, imgArray}) => {
  return (
    <div className="slideshowContainer">
      <main className="slideshowContents">
        <header className="sharedOwnershipHdr">
          <div className="flexContainer alignCenter fullWidth">
            <img
              id="intro-6"
              className=""
              style={{width: '6rem', height: '6rem'}}
              src={ProcompGreyLogo}
              alt=""
            />
            <h3 className="fontHdrCapitalize textAlignCenter">HOW THE CONCEPT WORKS</h3>
          </div>
          <h1 className="fontHdr1 fontPrimaryColor textAlignCenter">
            Ownership at Executive Retirement
          </h1>
        </header>
        <section className="sharedOwnershipContainer noWrap">
          <section className="flexContainer twoCol">
            <section>
              <img id="arrow-long-curve" src={arrowLongCurvedLogo} alt="arrowLongCurve" />
              <img id="purple-exec-corp-6" src={purpleExecCorpLogo} alt="RetireBrief" />
              <div
                className="bgWhite boxShadowAll paddingLg"
                style={{minHeight: '10rem', maxWidth: '25rem', margin: '0 auto'}}>
                <h3 className="fontHdr2">Executive RETIRES at the end of the Benefit Period</h3>
              </div>
            </section>
            <section>
              <div>
                <p className="fontHdr2 textAlignCenter">
                  Corporation transfers it's interests in Policy back to Executive
                </p>
                <img id="arrow-straight" src={arrowSmallLogo} alt="arrowStraight" />
              </div>
              <img id="blue-exec-6" src={blueExecLogo} alt="RetireBrief" />
              <div
                className="bgWhite boxShadowAll paddingLg"
                style={{minHeight: '10rem', maxWidth: '25rem', margin: '0 auto'}}>
                <h3 className="fontHdr2 fontPrimaryColor">
                  Executive Regains full ownership and benefits
                </h3>
              </div>
            </section>
          </section>
        </section>
      </main>
    </div>
  );
};

export default IntroModal6;
