// export const DataTypes = {
//   CONTROLITEMS: 'controlItems',
//   INSURANCEDATA: 'insuranceData',
//   INSURANCEDATAFULL: 'insuranceDataFull',
//   PRODUCTSETUPITEMS: 'productSetupItems',
//   INTERFACESETUPITEMS: 'interfaceSetupItems',
// };

export const ActionTypes = {
  HIDE_DEV_ROUTE: "HIDE_DEV_ROUTE",
  SET_ROUTES: "SET_ROUTES",
  HIDE_TRACKING: "HIDE_TRACKING",
  HIDE_USERS: "HIDE_USERS",
  HIDE_BI_DETAILS: "HIDE_BI_DETAILS",
  ALL_ROLES: "ALL_ROLES",
  GET_ROLE_BY_NAME: "GET_ROLE_BY_NAME",
  UPDATE_ROLE: "UPDATE_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  GET_FIRSTYEAR_PREMIUM: "get_first_year_premium",
  REMOVE_FIRSTYEAR_PREMIUM: "remove_first_year_premium",
  INSURANCEDATA_LOAD: "insurancedata_load",
  CREATE_ILLUSTRATION: "CREATE_ILLUSTRATION",
  GET_ILLUSTRATION_HEADERS: "GET_ILLUSTRATION_HEADERS",
  GET_ILLUSTRATION_CASE: "GET_ILLUSTRATION_CASE",
  DELETE_ILLUSTRATION_CASE: "DELETE_ILLUSTRATION_CASE",
  CHECK_ILLUSTRATION_CASE_EXIST: "CHECK_ILLUSTRATION_CASE_EXIST",
  INFORCE_LOAD: "inforce_load",
  INFORCE_LOAD_ERROR: "inforce_load_error",
  INFORCE_REMOVE: "inforce_remove",
  INFORCE_POST: "inforce_post",
  TREE_BUILD: "tree-build",
  TREE_UPDATE: "tree-update",
  TREE_REMOVE: "tree_remove",
  CREATE_GLOBAL_ARRAY: "global_array",
  FETCH_ALPHALOOKUP: "FETCH_ALPHALOOKUP",
  FETCHERROR_ALPHALOOKUP: "FETCHERROR_ALPHALOOKUP",
  CLEAR_ALPHALOOKUP: "CLEAR_ALPHALOOKUP",
  FETCH_DATAREPORTS: "FETCH_DATAREPORTS",
  FETCHERROR_DATAREPORTS: "FETCHERROR_DATAREPORTS",
  CLEAR_DATAREPORTS: "CLEAR_DATAREPORTS",
  CLEAR_CUSTOM_STORE: "CLEAR_CUSTOM_STORE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
};
