export const ScalarTypes = {
  SCALAR_BOOLEAN: 'scalar_boolean',
  SCALAR_STRING: 'scalar_string',
  SCALAR_INTEGER: 'scalar_integer',
  SCALAR_DOUBLE: 'scalar_double',
  SCALAR_LONGINT: 'scalar_longint',
  SCALAR_DATE: 'scalar_date',
};

export const PathTypes = {
  PATH_CUSTOM: 0,
  PATH_ADMINPOLICIESPATH: 1,
  PATH_REPORTPATH: 2,
  PATH_BATCHPATH: 3,
};
export const REPORT_SETTING = {
  NONE: 0,
  INTEGER: 1,
  LONGINT: 2,
  DOUBLE: 3,
  SINGLE: 4,
  BOOLEAN: 5,
  STRING: 6,
  DATE: 7,
};

export const ConstantValues = {
  DIVIDEND_ALTERNATESCALE: 75,
  ROWHIGHLIGHT_SPACING: 4,
};
