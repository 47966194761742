import React, {Fragment, useEffect, useState} from 'react';
// import {useSelector} from "react-redux";
import {Textbox, Selectbox, Radiobuttons} from 'core/controls';
import ValidateForm from 'custom/common/utils/customFormValidation';
import {FormStatus, FormValidator} from 'core/formValidation';
import {PREMIUM_ALLOCATION} from '../SharedOwnershipModal';
import {companyLogo, corpMoneyLogo, execMoneyLogo, ProcompGreyLogo} from 'assets';

const PremiumAllocationModal = ({
  controlGetFocus,
  controlChange,
  controlLostFocus,
  setElement,
  elementItems,
  controlItems,
  updateFormData,
  onLoad,
  formData,
  modalIndex,
}) => {
  // const [premiumAllocation, setPremiumAllocation] = useState(formData.premiumAllocation);

  const handleChange = (event, name, value) => {
    // setPremiumAllocation({...premiumAllocation, [name]: value});
    controlChange(event, name, value);
  };
  useEffect(() => {
    onLoad(formData.premiumAllocation, elementItems, updateFormData);
  }, []);
  const submitData = () => {};
  return (
    <FormStatus data={formData.premiumAllocation}>
      <FormValidator
        data={formData.premiumAllocation}
        submit={submitData}
        validateForm={ValidateForm}>
        <div className="slideshowContainer">
          <main className="slideshowContents">
            <header className="sharedOwnershipHdr">
              <div className="flexContainer alignCenter">
                <img
                  id="premium-allocation"
                  className=""
                  style={{width: '6rem', height: '6rem'}}
                  src={ProcompGreyLogo}
                  alt=""
                />
                <h2 className="fontHdrCapitalize textAlignCenter">
                  THE SHARED OWNERSHIP AGREEMENT
                </h2>
              </div>
              <h1 className="fontHdr1 textAlignCenter fontAltColorB">Premium Allocation</h1>
            </header>
            <section className="sharedOwnershipContainer flexContainer twoCol justifyBetween noWrap">
              <section>
                <section>
                  <h2 className="fontHdr2 fontErrorColor fontBold">Corporation</h2>
                  <div className="flexContainer noCols noWrap alignStart">
                    <img id="corp-money-13" src={corpMoneyLogo} alt="corporateimage" />
                    <div>
                      <p>Pays portion over agreed Benefit Period consisting of:</p>
                      <ul>
                        <li className="fontMed">Mortality charges</li>
                        <li className="fontMed">Premium Taxes and</li>
                        <li className="fontMed">Administration fees</li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section className="marginMed">
                  <h2 className="fontHdr2 fontPrimaryColor fontBold">Executive</h2>
                  <div className="flexContainer noCols noWrap alignStart">
                    <img id="exec-money-13" src={execMoneyLogo} alt="executiveimage" />
                    <div>
                      <p>Pays the balance of:</p>
                      <ul>
                        <li className="fontMed">The premium or</li>
                        <li className="fontMed">
                          If corporation pays, Executive incurs taxable benefit
                        </li>
                        <li className="fontMed">
                          May take additional deposits to Account Value with after-tax dollars
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </section>

              <section className="bgWhite boxShadowAll paddingLg">
                <Radiobuttons
                  text="Premium Mode"
                  getfocusCallback={controlGetFocus}
                  getchangeCallback={controlChange}
                  lostfocusCallback={controlLostFocus}
                  stateData={formData.premiumAllocation}
                  name="rdoSharedPaymentMode"
                  refItem={setElement}
                  propStyle={{
                    label: 'fontHdr3',
                    control: 'radiocontrol',
                    radiocontainer: 'radiocontainer marginSm',
                    inputcontainer: 'radiocontainer marginSm',
                    child: 'childcontrol',
                  }}
                />
                <Textbox
                  text="Premium Amount"
                  getfocusCallback={controlGetFocus}
                  getchangeCallback={controlChange}
                  lostfocusCallback={controlLostFocus}
                  stateData={formData.premiumAllocation['txtSharedPremiumAmount']}
                  name="txtSharedPremiumAmount"
                  refItem={setElement}
                  propStyle={{
                    label: 'textlabel',
                    control: 'textcontrol',
                    textcontainer: 'textcontainer',
                  }}
                />
              </section>
            </section>
          </main>
        </div>
      </FormValidator>
    </FormStatus>
  );
};

export default PremiumAllocationModal;
