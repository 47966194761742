import React, {useEffect, Fragment, useState} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {fetchUser, loadControlItems, login, setAlert} from 'core/redux/actions';
import {userLogo, helpLogo} from 'assets';
import {HeaderContainer, ButtonBar, Spinner, Modal} from 'core/uicore';
import {Button} from 'core/controls';
import Alert from 'core/uicore/Alert';
import {Logo} from 'core/uicore/Logo';
import {STYLES} from 'core/constants/uiConstants';
import axios from 'axios';
import {useMsal, useIsAuthenticated} from '@azure/msal-react';
import {loginRequest, tokenRequest} from 'lib/authConfig';
import {InteractionStatus} from '@azure/msal-browser';
import {setInterceptorAD} from 'core/utils/setAuthConfig';
import AboutModal from '../ui/AboutModal';

const Login = ({isInforce, isNewBusiness, isHeadOffice, isAdmin}) => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    username: 'user1',
    password: 'ProComp',
  });
  const [tokenMethod, setTokenMethod] = useState('');
  const [isAboutModal, setIsAboutModal] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {instance, inProgress} = useMsal();
  const isAuthenticatedUser = useIsAuthenticated();

  useEffect(() => {
    setTokenMethod(sessionStorage.getItem('tokenMethod'));
  }, [sessionStorage.getItem('tokenMethod')]);

  const {isAuthenticated, isAuthenticating, user} = auth;
  const {username, password} = formData;
  const elementItems = {};

  const setElement = (element) => {
    if (element !== null) {
      //console.log(`element name = ${element.name}`);
      //this.controlItems[element.name].element = element;
      elementItems[element.name] = element;
    }
  };

  //This is just to pass empty data to control Click callback, which inturns handle tracking events in framework
  const loginFormData = {formName: 'Login'};
  const updateFormData = () => {};

  //Tracking pending for Login page
  const controlClick = (event, name, value) => {
    event.persist();
  };

  let defaultFrom = '/login';
  if (user) {
    if (isNewBusiness && isNewBusiness() && user.defaultApplication === 'New Business') {
      defaultFrom = {pathname: 'illustration'};
    } else if (isInforce && isInforce() && user.defaultApplication === 'Inforce') {
      defaultFrom = {pathname: 'inforce'};
    } else if (isAdmin && isAdmin() && user.defaultApplication === 'Admin') {
      defaultFrom = {pathname: '/admin'};
    } else if (isNewBusiness && isNewBusiness()) {
      defaultFrom = {pathname: 'illustration'};
    } else if (isInforce && isInforce()) {
      defaultFrom = {pathname: 'inforce'};
    }
  }

  //Don't save previous state, on login user should go to default screen

  let from = defaultFrom;
  // let isSessionExpired = sessionStorage.getItem('isSessionExpired');
  if (user /*&& isSessionExpired*/) {
    from = location.state ? location.state.from : from;
    sessionStorage.setItem('isSessionExpired', false);
  }

  const handleChange = (e) => setFormData({...formData, [e.target.name]: e.target.value});

  const getUser = async () => {
    const account = instance.getActiveAccount();
    if (inProgress === InteractionStatus.None) {
      try {
        const response = await instance.acquireTokenSilent({
          ...tokenRequest,
          account,
        });
        const refreshToken = {jwt: response.accessToken};
        sessionStorage.setItem('refreshToken', JSON.stringify(refreshToken));

        setInterceptorAD();
        dispatch(fetchUser(account, false));
      } catch (error) {
        // dispatch({type: ActionTypes.AUTH_FAIL});
        if (error && error.response) {
          dispatch(setAlert(error.response.data['message'], 'Error', 'login'));
        }
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const tokenMethod = sessionStorage.getItem('tokenMethod');
    if (tokenMethod === 'AD') {
      const request = {...loginRequest, loginHint: username};
      try {
        const result = await instance.loginPopup(request);
        getUser();
      } catch (error) {
        console.log('error occured: ' + error);
      }
    } else {
      dispatch(login(username, password));
    }
  };

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  const dummy = (event, name, value) => {
    // console.log('Do Nothing');
  };

  const onDummyLogin = async () => {
    try {
      var generateTrackingUserName = false;
      const { data } = await axios.post('/users/authenticate', { username, password, generateTrackingUserName });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {(isAuthenticating ||
        (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.None)) && (
        <Modal onClick={(e) => e.stopPropagation()} className="modalDark">
          <Spinner />
        </Modal>
      )}

      <div
        className={`${STYLES.PAGEHEADER} flexContainer justifyBetween borderThin borderBottom borderLight`}>
        <Logo companyName="PROCOMP LIFE" name="companyName" propStyle={{logo: 'paddingMed'}} />
        <ButtonBar name="Profile Header" buttonbar="flexContainer autoCols justifyEnd">
          <Button
            text="Login"
            getfocusCallback={dummy}
            getclickCallback={(event, name, value) => {
              controlClick(event, name, value);
              // onSubmit(event);
              onDummyLogin();
            }}
            lostfocusCallback={dummy}
            name="btnLogin"
            refItem={setElement}
            propStyle={{
              label: 'buttonlabel',
              control: 'buttoncontrol buttonHdr borderPartialRight',
            }}
            logo={{
              srcLogo: userLogo,
              altLogo: '',
              classLogo: 'profile-logo',
            }}
            showIcon={true}
          />
          <Button
            text="Help"
            getfocusCallback={dummy}
            getclickCallback={(event, name, value) => {
              controlClick(event, name, value);
              setIsAboutModal(true);
            }}
            lostfocusCallback={dummy}
            name="btnHelp"
            refItem={setElement}
            propStyle={{
              label: 'buttonlabel',
              control: 'buttoncontrol buttonHdr',
            }}
            logo={{
              srcLogo: helpLogo,
              altLogo: '',
              classLogo: 'help-logo',
            }}
            showIcon={true}
          />
        </ButtonBar>
      </div>

      <div className="layoutCenter flexContainer alignCenter justifyCenter bgPrimaryColor">
        <h1 className="fontHdr1 fontPrimaryColor">Sign In</h1>
        <p className="">
          <i className="fas fa-user" /> Sign Into Your Account
        </p>
        <form onSubmit={onSubmit}>
          {tokenMethod !== 'AD' && (
            <div>
              <input
                className="marginMed paddingMed borderThin fontLg borderThick borderBottom borderLight bgNone"
                type="username"
                placeholder="User name"
                name="username"
                value={username}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {tokenMethod !== 'AD' && (
            <div className="">
              <input
                className="marginMed paddingMed borderThin fontLg borderThick borderBottom borderLight bgNone"
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={handleChange}
                minLength="5"
              />
            </div>
          )}
          <Button
            text="Login"
            type="submit"
            getfocusCallback={dummy}
            getclickCallback={(event, name, value) => {
              controlClick(event, name, value);
              onSubmit(event);
            }}
            lostfocusCallback={dummy}
            name="btnLogin"
            refItem={setElement}
            propStyle={{
              label: 'buttonlabel',
              control:
                'paddingMed marginMed borderRadiusAll fontLg boxShadowBottom fontPrimaryColor',
            }}
          />
        </form>
        <Alert name="login" styling="paddingMed" />
      </div>
      {isAboutModal && (
        <AboutModal
          controlGetFocus={() => {}}
          controlClick={controlClick}
          controlLostFocus={() => {}}
          setElement={setElement}
          onCancel={() => setIsAboutModal(false)}
        />
      )}
    </Fragment>
  );
};

export default Login;
