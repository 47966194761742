import * as customEvents from 'custom/common/utils/customEvents';
import {sendTrackingInfo} from 'core/api/trackingWebService';
import {INPUTTYPES} from 'core/constants/uiConstants';

function GlobalRunLostRecalls(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  interfaceSetupArray,
  allSetupItems,
  updateFormData,
  updateControlItems,
  inforceData
) {
  if (
    controlItems &&
    controlItems[name] &&
    controlItems[name].recallItems != null /*&& !controlItems[name].isError*/
  ) {
    // if (controlItems[name] && controlItems[name].recallItems != null) {
    controlItems[name].recallItems.forEach((name) => {
      customEvents.GlobalCustomLostFocus(
        name,
        user,
        controlItems,
        formData,
        elementItems,
        interfaceSetupItems,
        interfaceSetupArray,
        allSetupItems,
        updateFormData,
        updateControlItems,
        inforceData,
        false,
        true
      );
    });
  }
}

function GlobalRunRecalls(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  interfaceSetupArray,
  allSetupItems,
  updateFormData,
  updateControlItems,
  inforceData
) {
  if (
    controlItems &&
    controlItems[name] &&
    controlItems[name].recallItems != null /*&& !controlItems[name].isError*/
  ) {
    // if (controlItems[name] && controlItems[name].recallItems != null) {
    controlItems[name].recallItems.forEach((name) => {
      customEvents.GlobalCustomBusinessRules(
        name,
        user,
        controlItems,
        formData,
        elementItems,
        interfaceSetupItems,
        interfaceSetupArray,
        allSetupItems,
        updateFormData,
        updateControlItems,
        inforceData,
        false,
        true
      );
    });
  }
}

export function GlobalGetFocus(
  name,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  updateFormData,
  updateControlItems
) {
  formData["CurrentField"] = name;
  // updateFormData('CurrentField', name);
}

export function GlobalLostFocus(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  interfaceSetupArray,
  allSetupItems,
  updateFormData,
  updateControlItems,
  inforceData
) {
  /**
   * When we switch between fields, sometimes new field Get focus call happens before Lost focus of old field, thereby making CurrentField to null.
   */
  if (formData["CurrentField"] === name) {
    formData["CurrentField"] = "";
    // updateFormData('CurrentField', '');
  }

  customEvents.GlobalCustomLostFocus(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    interfaceSetupArray,
    allSetupItems,
    updateFormData,
    updateControlItems,
    inforceData,
    false,
    false
  );
  //Add back when user and inforceData is added into GlobalCustomLostFocus
  //customEvents.GlobalCustomBusinessRules(
  //    name,
  //    user,
  //    controlItems,
  //    formData,
  //    elementItems,
  //    interfaceSetupItems,
  //    updateFormData,
  //    updateControlItems,
  //    inforceData
  //);
  GlobalRunLostRecalls(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    interfaceSetupArray,
    allSetupItems,
    updateFormData,
    updateControlItems,
    inforceData
  );
}

const BUTTON_CLICK = 'BUTTON_CLICK';
const TAB_CLICK = 'TAB_CLICK';
const CONTROL_CHANGE = 'CONTROL_CHANGE';
const RUN_QUERY = 'RUN_QUERY';
const PAGE_LOAD = 'PAGE_LOAD';

const getEventType = (controlItems, name) => {
  let eventType = BUTTON_CLICK;
  switch (controlItems[name].fieldType) {
    case INPUTTYPES.INPUT_BUTTON:
      eventType = BUTTON_CLICK;
      break;
    case INPUTTYPES.INPUT_TAB:
      eventType = TAB_CLICK;
      break;
    case INPUTTYPES.INPUT_UPPER:
    case INPUTTYPES.INPUT_REAL:
    case INPUTTYPES.INPUT_LABEL:
    case INPUTTYPES.INPUT_COMBO:
    case INPUTTYPES.INPUT_CHECK:
    case INPUTTYPES.INPUT_INTEGER:
      eventType = CONTROL_CHANGE;
      break;
    case INPUTTYPES.INPUT_PAGE:
      eventType = PAGE_LOAD;
      break;
    default:
      break;
  }
  return eventType;
};
function GlobalBuildTrackingData(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  updateFormData,
  updateControlItems,
  inforceData
) {
  let trackingData = {};

  //Remove the milliseconds from the timestamp
  let timestamp = Math.trunc(Date.now() / 1000);
  let formName = formData['formName'];
  let eventType = getEventType(controlItems, name);

  const { trackingUserName, username, agentID } = user;
  trackingData.header = {
    username: trackingUserName,
    loginUserName: username,
    eventType,
    formName,
    name,
    agentID,
    timestamp,
  };
  let customData = customEvents.GlobalCustomBuildTrackingData(
    name,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    updateFormData,
    inforceData
  );
  if (!customData) {
    trackingData.detail = {...formData};
  } else {
    trackingData.detail = customData;
  }

  return trackingData;
}
function GlobalTrackingEvents(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  updateFormData,
  updateControlItems,
  inforceData
) {
  if (controlItems && controlItems[name] && controlItems[name].isTracking) {
    let trackingData = {};
    trackingData = GlobalBuildTrackingData(
      name,
      user,
      controlItems,
      formData,
      elementItems,
      interfaceSetupItems,
      updateFormData,
      updateControlItems,
      inforceData
    );
    sendTrackingInfo(trackingData);
  }
}
export function GlobalChange(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  interfaceSetupArray,
  allSetupItems,
  updateFormData,
  updateControlItems,
  inforceData
) {
  GlobalTrackingEvents(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    updateFormData,
    updateControlItems,
    inforceData
  );
  customEvents.GlobalCustomBusinessRules(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    interfaceSetupArray,
    allSetupItems,
    updateFormData,
    updateControlItems,
    inforceData,
    false,
    false
  );
  GlobalRunRecalls(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    interfaceSetupArray,
    allSetupItems,
    updateFormData,
    updateControlItems,
    inforceData
  );
}

export function GlobalClick(
  name,
  user,
  controlItems,
  formData,
  elementItems,
  interfaceSetupItems,
  allSetupItems,
  updateFormData,
  updateControlItems,
  inforceData
) {
  GlobalTrackingEvents(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    updateFormData,
    updateControlItems,
    inforceData
  );
  customEvents.GlobalCustomClickBusinessRules(
    name,
    user,
    controlItems,
    formData,
    elementItems,
    interfaceSetupItems,
    allSetupItems,
    updateFormData,
    updateControlItems,
    inforceData
  );
}
